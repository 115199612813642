import type { FC } from 'react';
import { List, ListItemButton, Collapse } from '@mui/material';
import { NavLink } from 'react-router-dom';
import classes from './SideBarButton.module.scss'
import type { SideNavItem } from '../../models/SideNav';
import SideNavService from '../../services/SideNavService';

interface SideBarNavListProps {
  open: boolean
  selectedItem: SideNavItem
  listItems: SideNavItem[]
}

// This component represents the drop down list of pages when the user
// clicks on one of the side bar buttons
const SideBarNavList: FC<SideBarNavListProps> = ({
  open,
  selectedItem,
  listItems
}: SideBarNavListProps) => {
  const navLinkClicked = (item: SideNavItem) => {
    SideNavService.setPreSideNavData(item);
  }

  return (
    <Collapse in={open} timeout="auto" unmountOnExit className={classes.Sidebar}>
      <List component={'nav'} sx={{ padding: '0 0 0 50px' }}>
        {listItems?.map((item: SideNavItem) => (
          <NavLink
            to={item.url}
            key={item.title}
            style={{ textDecoration: 'none' }}
            onClick={() => navLinkClicked(item)}
          >
            <ListItemButton
              disableRipple
              className={classes.SideBarListItem}
            >
              <div
                className={`${classes.NavListButton} ${(item.id === selectedItem.id) ? classes.Selected : ''}`}
              >
                {item.title}
              </div>
            </ListItemButton>
          </NavLink>
        ))}
      </List>
    </Collapse>
  );
};

export default SideBarNavList;
