import { store } from '../../state/store';
import type { AuthUser } from '../auth/constants';
import type { Subscription, Currencies } from '../../models/Subscription';
import CurrencyService from './CurrencyService';
import AuthService from '../auth/AuthService';
import CrooglooSubscriptionService from './CrooglooSubscriptionService';
import { setProductList } from '../../state/slices/Subscription';
import { ManageSubscriptionModal } from '../../components/modals/ManageSubscription/constants';
import ModalService from '../ModalService';
import { CancelNotAvailableModal } from '../../components/modals/CancelNotAvailable/constants';
import { ConfirmCancellationModal } from '../../components/modals/ConfirmCancellation/constants';
import { SwitchProductionModal } from '../../components/modals/SwitchProduction/constants';
import { SecurityListIds } from '../../models/SecurityList';
import SecurityAdminService from '../SecurityAdmin';
import { setOtherAvailableProductions } from '../../state/slices/AuthReducer';
import { ExpiredTrialModal } from '../../components/modals/ExpiredTrial/constants';
import ProductionService from '../production/ProductionService';
import { ActivateSubscriptionFailModal } from '../../components/modals/ActivateSubscriptionFail/constants';

const { dispatch } = store;

const enum PaymentMethods {
  STRIPE = 'stripe',
  OTHER = 'other'
}

interface ISubscriptionManagerService {
  manageSubscription: () => Promise<void>
  endTrial: () => Promise<void>
  initProductList: () => Promise<void>
  wrapUpSubscription: () => Promise<void>
  showSwitchProduction: () => void
  accessToOtherCommunities: () => boolean
}

/**
 * Subscription Manager Service
 * Contains different functions to do with managing/updating subscriptions
 */
class SubscriptionManagerService implements ISubscriptionManagerService {
  /**
   * if user has ability to manage subscription it gets the current plan from the product list
   * Then shows the modal/dialog displaying the different subscriptions
   * called from header dropdown
   */
  async manageSubscription (): Promise<void> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    if (crooglooAuth.email !== crooglooAuth.productionOwnerEmail) {
      console.debug('prevented use of manageSubscriptionBtn - should not be visible');
      return;
    }
    await this.initProductList();
    CrooglooSubscriptionService.setCurrentPlan(crooglooAuth.planId ?? '')
      .then(() => {
        ModalService.openCustomModal(ManageSubscriptionModal, {
          heading: 'subscription.manage.heading',
          confirmButton: 'action.confirm'
        });
      })
      .catch((err: Error) => {
        throw new Error(err.message);
      })
  }

  async endTrial (): Promise<void> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    if (crooglooAuth.email !== crooglooAuth.productionOwnerEmail && crooglooAuth.securityListId !== SecurityListIds.ADMIN) {
      ModalService.openCustomModal(ExpiredTrialModal, {
        heading: 'subscription.expiredTrial.heading',
        content: 'subscription.expiredTrial.content',
        confirmButton: 'action.ok'
      });
    } else {
      ProductionService.fetchStripePublicKey()
        .then(async () => {
          await this.initProductList();
          CrooglooSubscriptionService.setCurrentPlan(crooglooAuth.planId ?? '')
            .then(() => {
              ModalService.openCustomModal(ManageSubscriptionModal, {
                heading: 'subscription.endTrial.heading',
                confirmButton: 'action.confirm',
                metaData: ['allow']
              });
            })
            .catch((err: Error) => {
              throw new Error(err.message);
            })
        })
        .catch((err) => {
          console.error(err);
          ModalService.openCustomModal(ActivateSubscriptionFailModal, {
            heading: 'subscription.endTrial.failed.heading',
            content: 'subscription.endTrial.failed.content',
            confirmButton: 'action.ok'
          });
        })
    }
  }

  /**
   * Get subscription product list available to user and set in redux
   * first get currency based on users location
   */
  async initProductList (): Promise<void> {
    let productList: Subscription[] = store.getState().subscription.productList;
    if (Array.isArray(productList) && productList.length > 0) {
      return;
    }
    const currency: Currencies = await CurrencyService.getCurrency();
    productList = await AuthService.fetchStoreProducts(currency, '');
    dispatch(setProductList({ productList }));
  }

  /**
   * Wrap up subscription when cancel subscription selected in header dropdown
   */
  async wrapUpSubscription (): Promise<void> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    if ((crooglooAuth.email !== crooglooAuth.productionOwnerEmail) ||
      (crooglooAuth.securityListId?.toLowerCase() !== SecurityListIds.ADMIN.toLowerCase())
    ) {
      throw new Error('subscription.cancel.error.admin');
    }
    if (crooglooAuth.isTrial) {
      throw new Error('subscription.cancel.error.trial');
    }
    if (crooglooAuth.nbOfAvailableProds && crooglooAuth.nbOfAvailableProds > 1) {
      throw new Error('subscription.cancel.error.trial');
    }
    SecurityAdminService.fetchPaymentMethod()
      .then((paymentMethod: string) => {
        if (paymentMethod !== PaymentMethods.STRIPE) {
          ModalService.openCustomModal(CancelNotAvailableModal, {
            heading: 'subscription.cancel.notAvailable.heading',
            content: 'subscription.cancel.notAvailable.content',
            confirmButton: 'action.ok'
          });
          return;
        }
        ModalService.openCustomModal(ConfirmCancellationModal, {
          heading: 'subscription.cancel.confirm.heading',
          content: 'subscription.cancel.confirm.content',
          confirmButton: 'action.confirm'
        });
      })
      .catch((err) => {
        throw new Error(err.message);
      })
  }

  showSwitchProduction (): void {
    const authState = store.getState().auth;
    const otherAvailableProductions: string[] = authState.otherAvailableProductions;
    if (otherAvailableProductions.length > 0) {
      ModalService.openCustomModal(SwitchProductionModal, {
        heading: 'production.switch.heading',
        content: 'production.switch.content',
        confirmButton: 'action.select'
      });
    } else {
      throw new Error('error');
    }
  }

  /**
   * check to see if other productions are available and set in auth reducer
   */
  accessToOtherCommunities (): boolean {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    const otherProductions: string[] = [];
    if (crooglooAuth.communitiesAndAccess) {
      Object.keys(crooglooAuth.communitiesAndAccess).forEach((communityId: string) => {
        if (crooglooAuth.communitiesAndAccess?.[communityId] && communityId !== crooglooAuth.defaultCommunity) {
          otherProductions.push(communityId);
        }
      });
      dispatch(setOtherAvailableProductions({ productions: otherProductions }));
    }
    return otherProductions.length > 0;
  }
}

const subscriptionManagerService: ISubscriptionManagerService = new SubscriptionManagerService();
export default subscriptionManagerService;
