import React, { lazy, Suspense } from 'react';
import { type Props } from './Index';

const LazyDistributionLists = lazy(async () => await import('./Index'));

const DistributionLists = (props: Props) => (
  <Suspense fallback={null}>
    <LazyDistributionLists {...props} />
  </Suspense>
);

export default DistributionLists;
