import type { FC } from 'react';
import FormTextArea from '../forms/FormTextArea';
import { Grid } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import type { Control } from 'react-hook-form';
import type { MessageForm, MessageFormTypes } from '../../models/Message';
import { useTranslation, type UseTranslationResponse } from 'react-i18next';

interface Props {
  showSms: boolean
  name: MessageFormTypes
  formItemProps: { control: Control<MessageForm> }
  placeholder?: string
  sx?: SxProps<Theme>
}

/**
 * Text SMS Input Reusable Component
 * Used on the compose page
 */
const TextSMSInput: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  return (
    <Grid container item sx={{ display: (props.showSms) ? 'flex' : 'none' }}>
      <Grid
        item
        xs={12}
        sx={{
          padding: '1px',
          ...props.sx
        }}
      >
        <FormTextArea
          placeholder={props.placeholder ? String(t(props.placeholder)) : ''}
          id={props.name}
          name={props.name}
          formItemProps={props.formItemProps}
          rows={4}
        />
      </Grid>
    </Grid>
  )
}

export default TextSMSInput;
