import type { FC } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { MainBlack, BorderGrey, BlackGrey } from '../../theme/colors';
import { ComposeSubjectHeight } from '../../theme/styles';

interface Props {
  children: React.ReactNode
  isReadOnly: boolean
}

/**
 * Compose Subject Component used for the compose page
 * Handles to ui of the subject part of the compose page
 */
const ComposeSubject: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  return (
    <Grid item container
      sx={{
        textAlign: 'left',
        borderBottom: `1px solid ${String(BorderGrey)}`,
        paddingLeft: (props.isReadOnly) ? '16px' : '0px',
        flexWrap: 'nowrap'
      }}
    >
      <Grid item container xs={1} alignItems="center" sx={{ height: `${ComposeSubjectHeight}px`, maxWidth: '88px !important' }}>
        <Box component='span'
           sx={{
             color: (props.isReadOnly) ? BlackGrey : MainBlack,
             fontFamily: 'Inter',
             fontStyle: 'normal',
             fontWeight: 700,
             fontSize: '1rem',
             lineHeight: '20px',
             textTransform: 'capitalize'
           }}
        >
          {t('compose.form.labels.subject')}
        </Box>
      </Grid>
      <Grid item xs={11} container alignItems="center" sx={{ height: `${ComposeSubjectHeight}px` }}>
          {props.children}
      </Grid>
    </Grid>
  )
}

export default ComposeSubject;
