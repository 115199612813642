import { CrooglooUrl } from './CrooglooUrl';
import { SecurityPageName } from '../services/auth/constants';

export enum SideNavTitles {
  DASHBOARD = 'Basecamp',
  DISTRIBUTION = 'Distribution',
  SCRIPTS = 'Scripts',
  DOCUMENTS = 'Documents',
  CONTACTS = 'Contacts',
  TEMPLATES = 'Templates',
  SECURITY = 'Security'
}

export interface SideNavItem {
  id: string
  title: string
  url: CrooglooUrl
  securityPage?: SecurityPageName
}

export const DashboardSideNav: SideNavItem = {
  id: 'dashboard',
  title: 'Basecamp',
  url: CrooglooUrl.DASHBOARD
};

export const ComposeSideNav: SideNavItem = {
  id: 'compose',
  title: 'Compose',
  url: CrooglooUrl.COMPOSE,
  securityPage: SecurityPageName.Compose
};

export const OutboxSideNav: SideNavItem = {
  id: 'outbox',
  title: 'Outbox',
  url: CrooglooUrl.OUTBOX,
  securityPage: SecurityPageName.Outbox
}

export const DraftSideNav: SideNavItem = {
  id: 'drafts',
  title: 'Drafts',
  url: CrooglooUrl.DRAFT,
  securityPage: SecurityPageName.Drafts
}

export const ListSideNav: SideNavItem = {
  id: 'lists',
  title: 'Lists',
  url: CrooglooUrl.LISTS,
  securityPage: SecurityPageName.DistributionList
}

export const SentSideNav: SideNavItem = {
  id: 'sent',
  title: 'Sent',
  url: CrooglooUrl.SENT,
  securityPage: SecurityPageName.Sent
}

export const GenerateSideNav: SideNavItem = {
  id: 'gen-sides',
  title: 'Generate Sides',
  url: CrooglooUrl.GENERATESIDES,
  securityPage: SecurityPageName.SidesGeneration
}

export const ScriptSideNav: SideNavItem = {
  id: 'scripts',
  title: 'Scripts',
  url: CrooglooUrl.SCRIPTS,
  securityPage: SecurityPageName.Scripts
}

export const SideSideNav: SideNavItem = {
  id: 'sides',
  title: 'Sides',
  url: CrooglooUrl.SIDES,
  securityPage: SecurityPageName.Sides
}

export const WatermarkSideNav: SideNavItem = {
  id: 'watermark',
  title: 'Watermark',
  url: CrooglooUrl.WATERMARK,
  securityPage: SecurityPageName.Watermark
}

export const DocumentsSideNav: SideNavItem = {
  id: 'files',
  title: 'Files',
  url: CrooglooUrl.FILES,
  securityPage: SecurityPageName.Documents
}

export const AllContactsSideNav: SideNavItem = {
  id: 'all',
  title: 'All',
  url: CrooglooUrl.ALLCONTACTS,
  securityPage: SecurityPageName.AllContacts
}

export const CrewSideNav: SideNavItem = {
  id: 'crew',
  title: 'Crew',
  url: CrooglooUrl.CREW,
  securityPage: SecurityPageName.Crew
}
export const CastSideNav: SideNavItem = {
  id: 'cast',
  title: 'Cast',
  url: CrooglooUrl.CAST,
  securityPage: SecurityPageName.Cast
}
export const AgentSideNav: SideNavItem = {
  id: 'agent',
  title: 'Agent',
  url: CrooglooUrl.AGENT,
  securityPage: SecurityPageName.Agent
}
export const VendorSideNav: SideNavItem = {
  id: 'vendor',
  title: 'Vendor',
  url: CrooglooUrl.VENDOR,
  securityPage: SecurityPageName.Vendor
}
export const UnionSideNav: SideNavItem = {
  id: 'union',
  title: 'Union',
  url: CrooglooUrl.UNION,
  securityPage: SecurityPageName.Union
}
export const StudioSideNav: SideNavItem = {
  id: 'studio',
  title: 'Studio',
  url: CrooglooUrl.STUDIO,
  securityPage: SecurityPageName.Studio
}
export const DistributionListSideNav: SideNavItem = {
  id: 'dist-list',
  title: 'Distribution Lists',
  url: CrooglooUrl.LISTS,
  securityPage: SecurityPageName.DistributionList
}

export const TemplatesSideNav: SideNavItem = {
  id: 'templates',
  title: 'Templates',
  url: CrooglooUrl.TEMPLATES,
  securityPage: SecurityPageName.Templates
}

export const UploadSideNav: SideNavItem = {
  id: 'upload',
  title: 'Upload',
  url: CrooglooUrl.UPLOAD,
  securityPage: SecurityPageName.UploadTemplates
}

export const SecurityGroupsSideNav: SideNavItem = {
  id: 'security-groups',
  title: 'Groups',
  url: CrooglooUrl.SECURITYGROUPS,
  securityPage: SecurityPageName.SecurityLists
}

export const ManagePermissionSideNav: SideNavItem = {
  id: 'manage-perm',
  title: 'Permissions',
  url: CrooglooUrl.MANAGEPERMISSIONS,
  securityPage: SecurityPageName.ManagePermissions
}

export type SideNavGroup = Record<SideNavTitles, SideNavItem[]>

export const AllSideNavItems: SideNavItem[] = [
  DashboardSideNav, ComposeSideNav, OutboxSideNav, DraftSideNav, ListSideNav, SentSideNav, ComposeSideNav,
  OutboxSideNav, DraftSideNav, ListSideNav, SentSideNav, GenerateSideNav, ScriptSideNav, SideSideNav,
  DocumentsSideNav, WatermarkSideNav, AllContactsSideNav, CrewSideNav, CastSideNav, AgentSideNav,
  StudioSideNav, UnionSideNav, VendorSideNav, DistributionListSideNav, TemplatesSideNav, UploadSideNav,
  SecurityGroupsSideNav, ManagePermissionSideNav
];

export const SideNavGroups: SideNavGroup = {
  [SideNavTitles.DASHBOARD]: [],
  [SideNavTitles.DISTRIBUTION]: [ComposeSideNav, SentSideNav, DraftSideNav, OutboxSideNav, ListSideNav],
  [SideNavTitles.SCRIPTS]: [GenerateSideNav, ScriptSideNav, SideSideNav],
  [SideNavTitles.DOCUMENTS]: [DocumentsSideNav, WatermarkSideNav],
  [SideNavTitles.CONTACTS]: [
    AllContactsSideNav, CrewSideNav, CastSideNav, AgentSideNav,
    StudioSideNav, UnionSideNav, VendorSideNav, DistributionListSideNav
  ],
  [SideNavTitles.TEMPLATES]: [TemplatesSideNav, UploadSideNav],
  [SideNavTitles.SECURITY]: [SecurityGroupsSideNav, ManagePermissionSideNav]
}
