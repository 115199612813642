import React, { lazy, Suspense } from 'react';
import type { FileInformationProps } from './FileInformation';

const LazyFileInformation = lazy(async () => await import('./FileInformation'));

const FileInformation = (props: FileInformationProps) => (
  <Suspense fallback={null}>
    <LazyFileInformation {...props} />
  </Suspense>
);

export default FileInformation;
