import type { FC } from 'react';
import TertiaryButton from '../common/buttons/TertiaryButton';
import SuccessButton from '../common/buttons/SuccessButton';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { MainBlack } from '../../theme/colors';

interface Props {
  currentStepIndex: number
  handleClose: () => void
  successButton: string
  handleNextStep: () => void
  handleConfirm: () => void
}

/**
 * Save Template Buttons Component for the save template flow
 * Contains a cancel button and a success (next/confirm) button depending on the current step
 */
const saveTemplateButtons: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  return (
      <>
          <TertiaryButton disableRipple onClick={props.handleClose} sx={{ fontWeight: 700, color: MainBlack }}>{t('action.cancel')}</TertiaryButton>
          {
            ([0, 1].includes(props.currentStepIndex))
              ? <SuccessButton onClick={props.handleNextStep}>{t('action.next')}</SuccessButton>
              : <SuccessButton onClick={props.handleConfirm}>{t(props.successButton)}</SuccessButton>
          }
      </>
  )
}

export default saveTemplateButtons;
