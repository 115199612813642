import type { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import type { OptionProps } from '../../models/OptionProps';
import { MainWhite } from '../../theme/colors';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { permissionFilter } from '../../services/auth/PermissionService';

type VerticalOrigin = number | 'center' | 'bottom' | 'top';
type HorizontalOrigin = number | 'center' | 'left' | 'right';

interface MenuOrigins {
  anchorVertical?: VerticalOrigin
  anchorHorizontal?: HorizontalOrigin
  transformVertical?: VerticalOrigin
  transformHorizontal?: HorizontalOrigin
}

interface Props {
  anchorEl: null | HTMLElement
  handleClose: (event: React.SyntheticEvent) => void
  handleSelect: (event: React.SyntheticEvent, value: string) => void
  options: OptionProps[]
  pageTitle?: string
  menuOrigins?: MenuOrigins
  sx?: any
}

/**
 * Reusable Custom Menu Dropdown Component
 * options have to be in the model of {OptionProps}
 */
const BasicMenuDropdown: FC<Props> = (props: Props) => {
  // whether menu is open or not
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const open = Boolean(props.anchorEl);
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={open}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: props.menuOrigins?.anchorVertical ?? 'bottom',
        horizontal: props.menuOrigins?.anchorHorizontal ?? 'right'
      }}
      transformOrigin={{
        vertical: props.menuOrigins?.transformVertical ?? 'top',
        horizontal: props.menuOrigins?.transformHorizontal ?? 'right'
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '200px',
          borderRadius: '4px',
          background: MainWhite,
          boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
          ...props.sx
        },
        '& .MuiMenuItem-root': {
          padding: '6px 16px',
          alignSelf: 'stretch',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '20px'
        }
      }}
    >
      {(props.options.length > 0)
        ? props.options.filter(permissionFilter).map((option: OptionProps) => {
          if (props.pageTitle && option.showOn && !option.showOn.includes(props.pageTitle)) return null;
          return <MenuItem
            key={option.key}
            value={option.value}
            onClick={(event: React.SyntheticEvent) => { props.handleSelect(event, option.value) }}
            sx={{ ...option.sx }}
          >
            {t(option.label)}
          </MenuItem>
        })
        : <MenuItem
          key={'empty-option'}
          value={''}
          onClick={() => { }}
          >
            {t('common.labels.noOptions')}
          </MenuItem>
      }
    </Menu>
  )
}

export default BasicMenuDropdown;
