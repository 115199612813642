import type { OptionProps, ProvinceTypes } from '../../models/OptionProps';
import { Categories } from '../../models/Contacts';

export const AddContactDrawer: string = 'AddContactDrawer';

export const CategoryOptions: OptionProps[] = [
  {
    key: 'option-category-crew',
    label: 'contacts.addContact.basicInfo.category.crew',
    value: Categories.Crew
  },
  {
    key: 'option-category-cast',
    label: 'contacts.addContact.basicInfo.category.cast',
    value: Categories.Cast
  },
  {
    key: 'option-category-agent',
    label: 'contacts.addContact.basicInfo.category.agent',
    value: Categories.Agent
  },
  {
    key: 'option-category-studio',
    label: 'contacts.addContact.basicInfo.category.studio',
    value: Categories.Studio
  },
  {
    key: 'option-category-union',
    label: 'contacts.addContact.basicInfo.category.union',
    value: Categories.Union
  },
  {
    key: 'option-category-vendor',
    label: 'contacts.addContact.basicInfo.category.vendor',
    value: Categories.Vendor
  }
];
export const Provinces: ProvinceTypes[] = [
  {
    key: 'common.states.usStates',
    provinces: [
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY'
    ]
  },
  {
    key: 'common.states.canadianProvinces',
    provinces: [
      'AB',
      'BC',
      'MB',
      'NB',
      'NF',
      'NT',
      'NS',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT'
    ]
  }
];

export const GiftSizes: string[] = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL'
];

export const PersonIdString: string = 'PERSON_';
export const DepartmentIdString: string = 'DEPARTMENT_';
export const emptyString: string = '~';
