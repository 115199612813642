import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Typography
} from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { paperStyle, sliderStyle } from '../../theme/styles';
import SuccessButton from '../common/buttons/SuccessButton';
import MainSection from '../common/MainSection';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import styles from './Watermark.module.scss';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import OpacityIcon from '@mui/icons-material/Opacity';
import ChipAttachArea from '../ChipAttachArea';
import type { FileAttachment } from '../../models/Document';
import { FileType } from '../../models/Document';
import { CrooglooGreen } from '../../theme/colors';
import SearchFilesFlow from '../searchFiles/SearchFilesFlow';
import WatermarkedFile from '../modals/WatermarkedFile/WatermarkedFile.lazy';
import { WatermarkedFileModal } from '../modals/WatermarkedFile/constants';
import ModalService from '../../services/ModalService';
import { SearchFilesModal } from '../searchFiles/StepsModel';
import type { RootState } from '../../state/store';
import UploadService from '../../services/UploadService';
import Toast from '../common/toast/Toast';
import ListsDrawer from './ListsDrawer/ListsDrawer.lazy';
import {
  defaultOpacity,
  defaultOrientation,
  defaultOutputMode,
  defaultSize,
  drawerWidth,
  FirstLine,
  firstLineLabels,
  lineInputStyle,
  maxNbOfSelectedFiles,
  opacityPaperStyle,
  type OutputMode,
  SecondLine,
  secondLineLabels
} from './config';
import ScriptService from '../../services/ScriptService';
import DistributionService from '../../services/distribution/DistributionService';
import PersonService from '../../services/person/PersonService';
import type { DistributionListName } from '../../models/DistributionList';
import type { Person } from '../../models/Person';
import type { ChipDropdownData } from '../common/ChipsDropdown';
import CustomChip from '../common/buttons/Chip';
import DocumentTreeService from '../../services/documents/DocumentTreeService';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { setPageTitle } from '../../state/slices/pageTitle';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { ToastProps } from '../../models/Toast';
import type { AlertColor } from '@mui/material'

export enum WatermarkPage {
  Title = 'Watermark'
}

export interface WatermarkProps {
  pageTitle: WatermarkPage
}

const Watermark: FC<WatermarkProps> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle])

  // Translation fct
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const uploadFiles: FileAttachment[] = useSelector((state: RootState) => state.uploadFiles.files);

  useEffect(() => {
    DistributionService.fetchDistributionListNames();
    PersonService.fetchContacts();
    DocumentTreeService.initAllDocuments();

    setFirstLine(FirstLine.Label);
    setSecondLine(SecondLine.Blank);
  }, []);

  const [, updateState] = useState();

  const forceUpdate = useCallback(() => { updateState({} as any); }, []);

  // Reset files and settings
  const clear = (): void => {
    setOpacity(defaultOpacity);
    setOutputMode(defaultOutputMode);
    setFirstLine(undefined);
    setSecondLine(undefined);
    setFirstLineLabel('');
    setSecondLineLabel('');
    setSelectedContacts([]);
    setSelectedLists([]);
    setExtraContacts([]);
    UploadService.clearUploadedFiles();
  }

  const [outputMode, setOutputMode] = useState<OutputMode>(defaultOutputMode);

  const handleOutputModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOutputMode((event.target as HTMLInputElement).value as OutputMode);
  };

  // Drawer open/close functions
  const toggleDrawer = (): void => setOpen(!isOpen);

  // Drawer state
  const [isOpen, setOpen] = useState<boolean>(true);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  const [firstLine, setFirstLine] = useState<FirstLine | undefined>();
  const [secondLine, setSecondLine] = useState<SecondLine | undefined>();

  const [firstLineLabel, setFirstLineLabel] = useState<string>('');
  const [secondLineLabel, setSecondLineLabel] = useState<string>('');

  const [selectedLists, setSelectedLists] = useState<DistributionListName[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<Person[]>([]);
  const [extraContacts, setExtraContacts] = useState<ChipDropdownData[]>([]);

  const [isOpacitySliderVisible, setOpacitySliderVisibility] = useState<boolean>(false);

  const closeToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  const [opacity, setOpacity] = useState<number>(
    defaultOpacity
  );

  const handleOpacitySliderChange = (_event: Event, newOpacity: number | number[]) => {
    setOpacity(newOpacity as number);
  };

  const selectContact = (contact: Person): void => {
    if (!selectedContacts.some((c: Person) => c.id === contact.id)) {
      selectedContacts.push(contact);
      setSelectedContacts(selectedContacts);
      forceUpdate();
    }
  }

  const deSelectContact = (contact: Person): void => {
    const contactIndex = selectedContacts.findIndex((c: Person) => c.id === contact.id);
    if (contactIndex >= 0) {
      selectedContacts.splice(contactIndex, 1);
      setSelectedContacts(selectedContacts);
      forceUpdate();
    }
  }

  const selectExtraContact = (contact: ChipDropdownData): void => {
    if (!extraContacts.some((c: ChipDropdownData) => c.id === contact.id)) {
      extraContacts.push(contact);
      setExtraContacts(extraContacts);
      forceUpdate();
    }
  }

  const deSelectExtraContact = (contact: ChipDropdownData): void => {
    const contactIndex = extraContacts.findIndex((c: ChipDropdownData) => c.id === contact.id);
    if (contactIndex >= 0) {
      extraContacts.splice(contactIndex, 1);
      setExtraContacts(extraContacts);
      forceUpdate();
    }
  }

  const selectList = (list: DistributionListName): void => {
    if (!selectedLists.some((l: DistributionListName) => l.id === list.id)) {
      selectedLists.push(list);
      setSelectedLists(selectedLists);
      forceUpdate();
    }
  }

  const deSelectList = (list: DistributionListName): void => {
    const listIndex = selectedLists.findIndex((l: DistributionListName) => l.id === list.id);
    if (listIndex >= 0) {
      selectedLists.splice(listIndex, 1);
      setSelectedLists(selectedLists);
      forceUpdate();
    }
  }

  const handleSelectedContact = (contact: Person, isSelected: boolean): void => {
    isSelected ? deSelectContact(contact) : selectContact(contact);
  }

  const handleSelectedList = (list: DistributionListName, isSelected: boolean): void => {
    isSelected ? deSelectList(list) : selectList(list);
  }

  const handleSelectedExtraContact = (contact: ChipDropdownData, isSelected: boolean): void => {
    isSelected ? deSelectExtraContact(contact) : selectExtraContact(contact);
  }

  const handleProcessInitiatedModal = (): void => {
    closeToast();
    ScriptService.processWatermarkingJob(
      uploadFiles, firstLine, firstLineLabel, secondLine, secondLineLabel,
      selectedLists, selectedContacts, extraContacts, outputMode, opacity,
      defaultSize, defaultOrientation
    )
      .then(() => {
        ModalService.openCustomModal(
          WatermarkedFileModal,
          {
            heading: 'watermark.submitPopup.title',
            content: 'watermark.submitPopup.content',
            confirmButton: 'action.close'
          }
        );
        clear();
        handleShowToast('watermark.submitPopup.success', 'warning', ToastIcons.Time);
      })
      .catch((err) => {
        console.error(err);
        handleShowToast(err.message, 'error', ToastIcons.Info);
      });
  }

  const handleSearchFiles = (): void => {
    ModalService.openCustomModal(
      SearchFilesModal,
      {
        content: '',
        heading: 'compose.searchFiles.SelectDocuments.title',
        confirmButton: 'action.select'
      }
    )
  }

  return (
    <Box className={styles.Watermark} data-testid="Watermark" sx={{ display: 'flex', width: '100%' }}>
      <SlideDrawer
        drawerWidth={drawerWidth}
        open={isOpen}
        toggleDrawer={toggleDrawer}
        anchor={'left'}
        buttonText={'watermark.drawer.buttonText'}
      >
        <ListsDrawer
          selectedLists={selectedLists}
          selectedContacts={selectedContacts}
          setSelectedContact={handleSelectedContact}
          setSelectedList={handleSelectedList}
          extraContacts={extraContacts}
          setExtraContact={handleSelectedExtraContact}
        />
      </SlideDrawer>
      <MainSection
        open={isOpen}
        drawerwidth={drawerWidth}
        sx={{
          paddingX: 4,
          display: 'flex',
          justifyContent: 'center'
        }}>
        <WatermarkedFile/>
        <SearchFilesFlow
          acceptedFileTypes={[FileType.PDF]}
          maxNumberOfFiles={maxNbOfSelectedFiles}
          noDropbox
        />
        <Paper
          component="form"
          sx={{
            ...paperStyle,
            minHeight: 0,
            paddingY: 2,
            paddingX: 3,
            width: '707px',
            fontSize: '0.8rem',
            boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
            height: 'fit-content'
          }}
        >
          <Grid container textAlign={'start'} alignItems={'center'} justifyContent={'spaceBetween'}>
            {/* Document selection section */}
            <Grid className={styles.chipContainer} item xs={6} md={6} textAlign={'start'}>
              <Chip
                className={styles.chip}
                label="Select Document"
                variant="outlined"
                onClick={handleSearchFiles}
              />
            </Grid>
            <Grid item xs={6} md={6} alignItems={'center'} style={{ display: 'flex', justifyContent: 'right' }}>
              <Box
                className={styles.actionBtn}
                sx={{ position: 'relative', color: isOpacitySliderVisible ? CrooglooGreen : 'inherit' }}
                onClick={() => setOpacitySliderVisibility(!isOpacitySliderVisible)}
              >
                <OpacityIcon />
                &nbsp;{t('watermark.buttons.opacity')}
                {isOpacitySliderVisible && <Paper
                  sx={opacityPaperStyle}
                  // prevent parent click listener from hiding slider
                  onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation()}
                >
                  <Grid container alignItems={'center'}>
                    <Grid xs={10} item>
                      <Slider
                        size="small"
                        defaultValue={defaultOpacity}
                        value={opacity}
                        onChange={handleOpacitySliderChange}
                        min={10}
                        max={100}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        sx={sliderStyle}
                      />
                    </Grid>
                    <Grid xs={2} item textAlign={'center'}>
                      <Typography
                        fontSize='0.75rem'
                        fontWeight='bold'
                        paddingLeft='5px'
                      >
                        {opacity}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>}
              </Box>
              <Box
                className={styles.actionBtn}
                onClick={clear}
              >
                <AutorenewIcon />
                &nbsp;{t('watermark.buttons.clear')}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              className={styles.fileChipArea}
            >
              {Boolean(uploadFiles.length) && <ChipAttachArea
                files={uploadFiles}
                sx={{
                  border: 'none',
                  boxShadow: 'none'
                }}
              />}
            </Grid>

            {/* Line 1 section */}
            <Grid item xs={12} md={12}>
              <div className={styles.paperSectionTitle}>{t('watermark.sections.lineOne')}</div>
            </Grid>
            <Grid container item xs={12} md={12} className={styles.chipContainer} spacing={1}>
              <Grid item>
                <Chip
                  className={(firstLine === FirstLine.Name) ? styles.selectedChip : styles.chip}
                  label={t(firstLineLabels.FullName)}
                  variant="outlined"
                  onClick={() => setFirstLine(FirstLine.Name)}
                />
              </Grid>
              {(firstLine === FirstLine.Name) &&
                <>
                  {selectedLists.map((list: DistributionListName) => (
                    <CustomChip
                      key={list.id}
                      label={list.name}
                      variant="outlined"
                      onClick={() => {
                        deSelectList(list)
                      }}
                      onDelete={() => {
                        deSelectList(list)
                      }}
                    />
                  ))}
                  {selectedContacts.map((contact: Person) => (
                    <CustomChip
                      key={contact.id}
                      label={`${contact.firstName} ${contact.lastName}`}
                      variant="outlined"
                      onClick={() => {
                        deSelectContact(contact)
                      }}
                      onDelete={() => {
                        deSelectContact(contact)
                      }}
                    />
                  ))}
                  {extraContacts.map((contact: ChipDropdownData) => (
                    <CustomChip
                      key={contact.id}
                      label={`${contact.name}`}
                      variant="outlined"
                      onClick={() => {
                        deSelectExtraContact(contact)
                      }}
                      onDelete={() => {
                        deSelectExtraContact(contact)
                      }}
                    />
                  ))}
                </>
              }
            </Grid>
            {(firstLine !== FirstLine.Name) && <Grid container item xs={12} md={12} className={styles.chipContainer} spacing={4}>
              <Grid item>
                <Chip
                  className={(firstLine === FirstLine.Label) ? styles.selectedChip : styles.chip}
                  label={t(firstLineLabels.Other)}
                  variant="outlined"
                  onClick={() => setFirstLine(FirstLine.Label)}
                />
              </Grid>
              <Grid item sx={{ width: '70%' }}>
                <Input
                  placeholder={String(t('watermark.labelInput.placeholder'))}
                  disableUnderline
                  sx={lineInputStyle}
                  value={firstLineLabel}
                  style={{ color: firstLine !== FirstLine.Label ? 'gray' : 'inherit' }}
                  onChange={({ target: { value } }) => {
                    if (value && firstLine !== FirstLine.Label) {
                      setFirstLine(FirstLine.Label);
                    }
                    setFirstLineLabel(value);
                  }}
                />
              </Grid>
              </Grid>
            }
            <br/><br/><br/>
            {/* Line 2 section */}
            <Grid item xs={12} md={12}>
              <div className={styles.paperSectionTitle}>{t('watermark.sections.lineTwo')}</div>
            </Grid>
            <Grid container item xs={12} md={12} className={`${styles.chipContainer} ${styles.chipSpacingContainer}`}>
              {Object.entries(secondLineLabels)
                .slice(0, 5)
                .map(([optionKey, labelTranslationKey]: [string, string]) => (
                  <Grid item key={`secondLine_${optionKey}`}>
                    <Chip
                      className={(secondLine === optionKey as SecondLine) ? styles.selectedChip : styles.chip}
                      label={t(labelTranslationKey)}
                      variant="outlined"
                      onClick={() => setSecondLine(optionKey as SecondLine)}
                    />
                  </Grid>
                ))
              }
            </Grid>
            <Grid container item xs={12} md={12} className={`${styles.chipContainer}`} spacing={4}>
              <Grid item>
                <Chip
                  className={(secondLine === SecondLine.Label) ? styles.selectedChip : styles.chip}
                  label={t(secondLineLabels.Other)}
                  variant="outlined"
                  onClick={() => setSecondLine(SecondLine.Label)}
                />
              </Grid>
              <Grid item sx={{ width: '70%' }}>
                <Input
                  placeholder={String(t('watermark.labelInput.placeholder'))}
                  disableUnderline
                  sx={lineInputStyle}
                  value={secondLineLabel}
                  style={{ color: secondLine !== SecondLine.Label ? 'gray' : 'inherit' }}
                  onChange={({ target: { value } }) => {
                    if (value && secondLine !== SecondLine.Label) {
                      setSecondLine(SecondLine.Label);
                    }
                    setSecondLineLabel(value)
                  }}
                />
              </Grid>
            </Grid>
            <br/><br/><br/>

            {/* Output options section */}
            <Grid item xs={12} md={12}>
              <FormControl sx={{ minWidth: '70%' }}>
                <FormLabel
                  className={styles.paperSectionTitle}
                  id="output-mode-label"
                >
                  {t('watermark.sections.outputOptions.title')}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="output-mode-label"
                  value={outputMode}
                  onChange={handleOutputModeChange}
                  name="output-mode"
                  className={styles.radioGroup}
                >
                  <FormControlLabel
                    value="combined"
                    control={<Radio sx={{ '&.Mui-checked': { color: CrooglooGreen } }}/>}
                    label={
                      <Typography className={styles.radioLabel}>{t('watermark.sections.outputOptions.combined')}</Typography>
                    }
                  />
                  <FormControlLabel
                    value="individual"
                    control={<Radio sx={{ '&.Mui-checked': { color: CrooglooGreen } }} />}
                    label={
                      <Typography className={styles.radioLabel} fontSize={'0.9rem'}>{t('watermark.sections.outputOptions.individual')}</Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Submit section */}
            <Grid
              item
              xs={12}
              md={12}
            >
              <SuccessButton
                sx={{ width: '100%', marginTop: '20px' }}
                onClick={handleProcessInitiatedModal}
              >
                {t('watermark.submitBtn')}
              </SuccessButton>
            </Grid>
          </Grid>
        </Paper>
      </MainSection>
      <Toast
        open={toastStatus.isShown}
        onClose={closeToast}
        type={toastStatus.type}
        title={String(t(toastStatus.message, { max: maxNbOfSelectedFiles }))}
        icon={toastStatus.icon}
      />
    </Box>
  )
};

export default Watermark;
