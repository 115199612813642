/*
 * Reducer file for the Sides slice
 */

import { createSlice } from '@reduxjs/toolkit';
import { SideSlice } from './constants';
import type { Document } from '../../models/Document';
import type { AllScripts, GenerateScripts } from '../../models/Script';
import type { Scene } from '../../models/Scene';
import type { SideRequest, SelectionData } from '../../models/Side';

export interface SidesState {
  callSheets: Document[]
  allScripts: AllScripts
  selectedScenes: Scene[]
  sidesRequest: SideRequest
  selectionData: SelectionData
}

const initialSidesRequest: SideRequest = {
  isRevisions: false,
  printOptions: '',
  fileNames: '',
  unselScenesRemovalMode: 0,
  withContinuityArrows: false,
  withCircledScenes: false,
  contArrowsColor: 'black',
  sceneCirclesColor: 'black'
}

const initialSelectionData: SelectionData = {
  scenes: '',
  scripts: '',
  filesId: ''
}

const initialState: SidesState = {
  callSheets: [],
  allScripts: {
    latest: [],
    other: []
  },
  selectedScenes: [],
  sidesRequest: initialSidesRequest,
  selectionData: initialSelectionData
}

export const sidesSlice = createSlice({
  name: SideSlice,
  initialState,
  reducers: {
    setCallSheets: (state, action) => {
      state.callSheets = action.payload.callSheets;
    },
    setAllScripts: (state, action) => {
      state.allScripts = {
        latest: action.payload.latest,
        other: action.payload.other
      }
    },
    setScriptScenes: (state, action) => {
      const isLatestScript: boolean = action.payload.isLatest;
      const scriptId: string = action.payload.scriptId;
      const allScripts: AllScripts = { ...state.allScripts };
      let scripts: GenerateScripts[];
      if (isLatestScript) {
        scripts = allScripts.latest;
      } else {
        scripts = allScripts.other;
      }
      scripts.forEach((s: GenerateScripts) => {
        if (s.id === scriptId) {
          s.scenes = action.payload.scenes;
          s.characters = action.payload.characters;
          s.locations = action.payload.locations;
        }
      });
      if (isLatestScript) {
        allScripts.latest = [...scripts];
      } else {
        allScripts.other = [...scripts];
      }
      state.allScripts = { ...allScripts };
    },
    setAllSelectedScenes: (state, action) => {
      state.selectedScenes = action.payload.scenes;
    },
    setSidesRequest: (state, action) => {
      state.sidesRequest = action.payload.tmp;
    },
    setSelectionData: (state, action) => {
      state.selectionData = action.payload.data;
    },
    addCallSheetsToSelectionData: (state, action) => {
      state.selectionData.callSheetId = action.payload.callSheetId;
      state.selectionData.callSheetPages = action.payload.callSheetPages;
    }
  }
})

export const {
  setCallSheets, setAllScripts, setScriptScenes,
  setAllSelectedScenes, setSidesRequest, setSelectionData, addCallSheetsToSelectionData
} = sidesSlice.actions;
export default sidesSlice.reducer;
