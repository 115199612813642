import React from 'react';
import type { FC } from 'react';
import styles from './TabHeaders.module.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { type SxProps, type Theme } from '@mui/material';

export interface TabHeadersProps {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  tabs: string[]
  centerHeaders?: boolean
  spaceHeaders?: boolean
  sx?: SxProps<Theme>
}

/**
 * The common Tab Header used for the slide drawer
 * This is called from the child component of the slide drawer (DocumentLists for Scripts/Sides & DrawerTab for Compose Page)
 * @param tab {number} the current tab being displayed
 * @param onChange the function to change the tab
 * @param tabs {string[]} an array of the tab headers to be displayed
 */
const TabHeaders: FC<TabHeadersProps> = (props: TabHeadersProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  return (
    <div className={styles.TabHeaders} data-testid="TabHeaders">
      <Tabs
        value={props.tab}
        onChange={props.onChange}
        aria-label="basic-tabs"
        className={styles.tabContainer}
        classes={{
          indicator: styles.tabIndicator,
          flexContainer: (props.spaceHeaders) ? styles.headersSpaced : ''
        }}
      >
        {props.tabs.map((tab: string, index: number) => (
          <Tab
            sx={props.sx}
            key={`tab-${index}`}
            label={t(tab)}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
            disableRipple
            classes={{
              root: `${styles.tabRoot} ${(props.centerHeaders) ? styles.centered : ''}`,
              selected: styles.selectedTab
            }}/>
        ))}
      </Tabs>
    </div>
  )
};

export default TabHeaders;
