import type { ApiDataType, PdfLinkResponse } from '../auth/constants';
import { DocumentsApi } from '../auth/constants';
import AuthService from '../auth/AuthService';
import type { ViewableDocument } from './constants';
import { ErrorMessage, PublishToStudioFolderName } from './constants';
import type { Script } from '../../models/Script';
import type { Side } from '../../models/Side';
import type { WSFolderItemsResponse, WSResponse } from '../../models/WSResponse';
import type {
  CollectionFile,
  DirectoryDestination,
  Document,
  DocumentCard,
  DocumentResponseItems,
  DocumentsResponse,
  FileAttachment,
  FileCollectionMap,
  OtherFileAttachment,
  ShortenedFolder,
  UrlsToAdd
} from '../../models/Document';
import { FileType } from '../../models/Document';
import {
  addDocumentCard,
  DefaultDocumentPath,
  editDocumentCard,
  handleSelectedFile,
  handleSelectedFolder,
  removeDocumentCards,
  setAllFolders,
  setDocumentToEdit,
  setPublishToStudioExists
} from '../../state/slices/Documents';
import { editUploadedFile, setPredefinedFileType } from '../../state/slices/UploadFiles';
import { DeleteMethod, GetMethod, PatchMethod, PostMethod } from '../constants';
import { store } from '../../state/store';
import ModalService from '../ModalService';
import { OptionPopups } from '../../components/Documents/Constants';
import type { GridRowsProp } from '@mui/x-data-grid';
import moment from 'moment';
import { ESignatureModal } from '../../components/modals/ESignature/constants';
import UtilityService from '../UtilityService';
import DocumentTreeService from './DocumentTreeService';
import UploadService from '../UploadService';
import { NewFolderModal } from '../../components/modals/NewFolder/constants';
import { MergeFilesModal } from '../../components/modals/MergeFiles/constants';
import type { FolderItems } from '../../models/FolderItems';
import ViewService, { BoxFolderId, DropboxFolderId, GDriveFolderId } from '../ViewService';
import type { FileToUpload } from '../../models/Upload';
import type { PageMenus } from '../../models/FileTypes';
import { CrooglooFileTypes, FileTypes } from '../../models/FileTypes';
import DownloadNotifications from '../notifications/DownloadNotifications';
import type { ToastProps } from '../../models/Toast';
import { ToastIcons } from '../../models/Toast';
import { FileViewerModal } from '../../components/FileViewerPopup/constants';
import { MoveDocumentModal } from '../../components/modals/MoveDocument/constants';
import { CrooglooUrl } from '../../models/CrooglooUrl';
import { SecurityListIds } from '../../models/SecurityList';
import GoogleService from '../../services/GoogleService';

const { dispatch } = store;

interface PrependCallSheet {
  callSheetDocId: string
  sidesDocsId: string
  pagesToExtract: string
}

const CreateTemplateOptions: string[] = ['forms', 'reports', 'memos', 'spreadsheets', 'templates'];
const ComposeOptions: string[] = ['sendByEmail', 'copyLink', 'sendAndTrack'];
const ArchiveOptions: string[] = ['archive', 'recover'];
export const RootFolderName: string = 'ROOT';
export const RootFolderDisplayName: string = 'Documents';

interface IDocumentService {
  fetchFile: (file: Script | Side | FileAttachment) => Promise<string | undefined>
  fetchSignedURL: (fileId: string) => Promise<WSResponse>
  fetchReadOnlyGoogleFormURL: (formType: string) => Promise<WSResponse>
  watermarkFile: (fileId: string) => Promise<any>
  documentsToGridRows: (documents: DocumentCard[]) => GridRowsProp
  changeFileName: (docId: string, value: string) => Promise<void>
  handleDocumentsMore: (option: string, navigate: any | null, document: DocumentCard[], type: CrooglooFileTypes) => Promise<ToastProps | null>
  downloadFolderAsZip: (folderId: string) => Promise<string>
  downloadItemsAsZip: (selectedIds: string[]) => Promise<void>
  fetchDownloadLink: (fileId: string) => Promise<string>
  fetchDocumentsBySubcategory: (type: string) => Promise<any>
  fetchAllFolders: () => void
  exportDistroListsToGoogleCsv: (selected: string[]) => Promise<void>
  fetchItemsInFolder: (folderId: string) => Promise<WSFolderItemsResponse>
  getFileSize: (file: UrlsToAdd) => Promise<number>
  validateFilesExistence: (files: FileAttachment[]) => Promise<string[]>
  fetchCallSheets: () => Promise<Document[]>
  deleteItemAndChildren: (fileId: string, parentId: string, page: string, removeAllCopies: string) => Promise<WSResponse>
  prependCallSheet: (data: PrependCallSheet) => Promise<WSResponse>
  handleDocumentsDownload: (documents: DocumentCard[]) => Promise<void>
  handleDocumentsSend: (documents: DocumentCard[], option: string) => Promise<void>
  fetchDocumentById: (documentId: string) => Promise<FileAttachment | undefined>
  getFileFromDocument: (document: Document) => FileAttachment
  changeArchiveStatus: (fileId: string, archive: boolean) => Promise<WSResponse>
  changeSubcategory: (fileId: string, category: string) => Promise<void>
  handleSharedDocumentFolder: (name: string, parent: string, limitTo: string, isSmartFolder: boolean, isRecursive: boolean) => Promise<void>
  addDocumentFolder: (name: string, parent: string, isSmartFolder: boolean) => Promise<string>
  changeAccessLevel: (fileId: string, limitTo: string, isRecursive: boolean) => Promise<void>
  mergeFiles: (filesIds: string[], name: string, parent: string, overWrite: boolean) => Promise<void>
  changeParentFolder: (oldParentFolderId: string, newParentFolderId: string, fileIds: string[]) => Promise<void>
  fetchDocumentsByName: (fileName: string) => Promise<DocumentResponseItems>
  addAttachment: (fileId: string) => Promise<void>
  saveDocument: (fileName: string, file: FileToUpload, page: PageMenus) => Promise<WSResponse>
  isTempDocDownloadReady: (taskId: string) => Promise<WSResponse>
  fetchTempDocDownloadLink: (taskId: string) => Promise<WSResponse>
  handleGettingWatermarkFile: (documentId: string) => Promise<string>
  handleDownloadFile: (url: string, documentName: string) => void
  openFile: (file: FileAttachment) => Promise<void>
  handleMoveFiles: (option: string, documents: DocumentCard[], type: CrooglooFileTypes) => void
  fetchPdfFromLink: (linkId: string) => Promise<PdfLinkResponse>
  openGoogleFile: (id: string) => void
  handleSelectedFilesAndFolders: (selectedFiles: string[], selectedFolders: string[]) => void
  importToCroogloo: (file: DocumentCard) => void
  getDropboxUploadUrl: (path: string, folder: boolean) => string
  getGDriveImportUrl: (fileId: string, crooglooFileId: string, fileName: string) => string
}

class DocumentService implements IDocumentService {
  /**
   * Returns the data of a file so it can be shown in the document viewer
   * @param file
   */
  async fetchFile (file: Script | Side | FileAttachment): Promise<string | undefined> {
    try {
      const document: ViewableDocument = getDocument(file);
      const isWatermark: boolean = document.isWatermarked;
      if (isWatermark) { // handle if file is Watermarked
        return await this.handleGettingWatermarkFile(document.id);
      } else { // handle if file is not Watermarked
        const response: WSResponse = await this.fetchSignedURL(document.id);
        const responseMessage: string = response.responseMessage ?? ErrorMessage;
        if (UtilityService.isSuccessResponse(response.responseCode) && responseMessage !== ErrorMessage) {
          const data = responseMessage + '&filename=' + file.fileName + ';';
          return await Promise.resolve(data);
        } else {
          return await Promise.reject(responseMessage);
        }
      }
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  /**
   * Gets the response of list documents by subcategory
   * @param fileType {string} scripts/sides
   */
  async fetchDocumentsBySubcategory (fileType: string): Promise<any> {
    const response: DocumentResponseItems = await makeDocumentCall('fetchDocumentsBySubcategory', {
      params: {
        subcategory: fileType
      }
    });

    return response?.items.map((item: DocumentsResponse) => ({
      id: item.properties.id,
      fileName: item.properties.fileName,
      isWatermarked: item.properties.isWatermarked,
      fileExtension: UtilityService.getFileExtension(item.properties.fileName),
      timeCreated: item.properties.timeCreated,
      fileURL: item.properties.fileURL,
      parentId: item.properties.parents.value,
      lastModifBaseTime: item.properties.lastModifBaseTime,
      size: Number(item.properties.size),
      subcategory: item.properties.subcategory,
      callSheetDocId: item.properties.callSheetDocId ?? ''
    }));
  }

  /**
   * Gets the response of a file not watermarked from the backend
   * @param formType {string} id of file to get the data to view
   */
  async fetchReadOnlyGoogleFormURL (formType: string): Promise<WSResponse> {
    const response: WSResponse = await makeDocumentCall('fetchReadOnlyGoogleFormUrl', {
      params: {
        formType
      }
    });
    return response;
  }

  /**
   * Gets the folder list
   */
  fetchAllFolders (): void {
    void makeDocumentCall('fetchAllFolders', { type: GetMethod })
      .then((folders) => {
        const shortenedFolders: ShortenedFolder[] = folders.items.map((item: any) => {
          const fileName: string = item.properties.fileName;
          return {
            fileURL: item.properties.fileURL,
            id: item.properties.id,
            parentFolder: (item.properties.parentFolder === RootFolderName) ? '' : item.properties.parentFolder,
            title: (fileName === RootFolderName) ? RootFolderDisplayName : fileName
          }
        });
        const result = buildRecursiveHierarchy(shortenedFolders);
        dispatch(setAllFolders({ folders: result }));
      })
  }

  async fetchDocumentsByName (fileName: string): Promise<DocumentResponseItems> {
    return await makeDocumentCall('fetchDocumentsByName', {
      params: {
        fileName
      }
    }).then((resp: DocumentResponseItems) => {
      return resp;
    })
      .catch((err) => {
        console.error(err);
        throw new Error('error');
      })
  }

  async addAttachment (fileId: string): Promise<void> {
    await new Promise((resolve, reject) => {
      this.fetchDocumentById(fileId)
        .then(async (file: FileAttachment | undefined) => {
          if (file) {
            if (!file.size || isNaN(file.size)) {
              file.size = await this.getFileSize(file);
            }
            UploadService.addUploadedFile(file);
            resolve(1);
          } else {
            console.error('Invalid file submitted!');
            reject(new Error('There was a problem attaching the existing file.'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('There was a problem attaching the existing file.'));
        })
    });
  }

  async exportDistroListsToGoogleCsv (selected: string[]): Promise<void> {
    return await makeDocumentCall('exportDistroListsToGoogleCsv', {
      params: {
        distributionListId: selected.join(',')
      },
      type: PostMethod
    }).then((resp: WSResponse) => {
      if (UtilityService.isSuccessResponse(resp.responseCode) && resp.responseMessage) {
        const responseMessage: string[] = resp.responseMessage.split(',');
        DownloadNotifications.add(
          false, false, responseMessage[0], responseMessage[1] ?? null, moment().valueOf()
        );
      } else {
        throw new Error('error');
      }
    })
      .catch((err) => {
        console.error(err);
        throw new Error('error');
      })
  }

  /**
   * Gets the response of a file not watermarked from the backend
   * @param fileId {string} id of file to get the data to view
   */
  async fetchSignedURL (fileId: string): Promise<WSResponse> {
    const response: WSResponse = await makeDocumentCall('fetchSignedURL', {
      params: {
        fileId
      }
    });
    return response;
  }

  /**
   * Gets the response of a watermarked file from the backend
   * @param fileId {string} id of file to get the data to view
   */
  async watermarkFile (fileId: string): Promise<any> {
    const response = await AuthService.authenticatedCall('/WatermarkServlet', {
      params: {
        fileId
      },
      responseType: 'blob'
    });
    return response;
  }

  async deleteItemAndChildren (fileId: string, parentId: string, page: string, removeAllCopies: string): Promise<WSResponse> {
    return await makeDocumentCall('deleteItemAndChildren', {
      params: {
        parentId,
        page,
        removeAllCopies
      },
      body: JSON.stringify({
        value: fileId
      }),
      type: DeleteMethod
    })
      .then((resp: WSResponse) => {
        if (UtilityService.isSuccessResponse(resp.responseCode)) {
          UploadService.removeUploadedFile(fileId);
        }
        return resp;
      })
      .catch((err) => {
        throw new Error(err);
      })
  }

  async prependCallSheet (data: PrependCallSheet): Promise<WSResponse> {
    return await makeDocumentCall('prependCallSheet', {
      params: {
        callSheetDocId: data.callSheetDocId,
        sidesDocsId: data.sidesDocsId,
        pagesToExtract: data.pagesToExtract,
        alt: 'json'
      },
      type: PostMethod
    });
  }

  /**
   * Changes the documentCard objects from backend to GripRowsProp to display in table
   * @param documents {DocumentCard[]} document folders/files from backend
   */
  documentsToGridRows (documents: DocumentCard[]): GridRowsProp {
    const rows: GridRowsProp = documents.map((document: DocumentCard) => {
      let linked: boolean = document.linked ?? false;
      if (document.name === PublishToStudioFolderName) {
        linked = true;
      }
      return {
        id: document.itemid,
        type: document.type,
        name: document.name,
        lastmod: (document.datemod) ? moment(document.datemod).format('MMM D, YYYY') : '',
        sharedText: getSharedText(document.shared),
        watermark: document.watermark ?? false,
        archived: document.archived ?? false,
        category: document.category,
        shared: document.shared,
        data: document.data,
        size: document.size,
        linked
      }
    });
    return rows;
  }

  /**
   * handles call to backend to change document name
   */
  async changeFileName (docId: string, value: string): Promise<void> {
    await new Promise((resolve, reject) => {
      makeDocumentCall('changeFileName', {
        params: {
          docId
        },
        type: PatchMethod,
        body: JSON.stringify({
          value
        })
      })
        .then((result: WSResponse) => {
          if (UtilityService.isSuccessResponse(result.responseCode)) {
            dispatch(editDocumentCard({ data: { id: docId, name: value } }));
            dispatch(editUploadedFile({ data: { id: docId, fileName: value } }));
            resolve('success');
          } else {
            reject(new Error('documents.renameFile.errors.server'))
          }
        }).catch((err) => {
          console.error(err);
          reject(new Error('documents.renameFile.errors.server'))
        });
    });
  }

  /**
   * handles selection of different options from documents table and buttons
   */
  async handleDocumentsMore (option: string, navigate: any | null, documents: DocumentCard[], type: CrooglooFileTypes): Promise<ToastProps | null> {
    const noResponse: null = null;
    return await new Promise<ToastProps | null>((resolve, reject) => {
      if (CreateTemplateOptions.includes(option)) {
        navigate(CrooglooUrl.TEMPLATES);
      }
      if (ComposeOptions.includes(option)) {
        this.handleDocumentsSend(documents, option)
          .then(() => {
            if (navigate) {
              navigate(CrooglooUrl.COMPOSE);
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
      if (ArchiveOptions.includes(option)) {
        const archived: boolean = option === 'archive';
        this.changeArchiveStatus(documents.map((doc: DocumentCard) => doc.id).join(','), archived)
          .then((result: WSResponse) => {
            if (UtilityService.isSuccessResponse(result.responseCode)) {
              const successType: string = (documents.length > 1) ? 'successMulti' : 'successSingle';
              documents.forEach((doc: DocumentCard) => {
                dispatch(editDocumentCard({ data: { id: doc.id, archived } }));
              });
              this.handleSelectedFilesAndFolders([], []);
              dispatch(setDocumentToEdit({ documents: [] }));
              resolve({
                message: `documents.${option}.${successType}`,
                isShown: true,
                type: 'success'
              });
            } else {
              throw new Error(`documents.${option}.error`);
            }
          })
          .catch((err) => {
            reject(err);
          })
      }
      switch (option) {
        case 'merge':
          if (documents.length <= 1) {
            reject(new Error('documents.merge.error.none'));
          } else {
            dispatch(setDocumentToEdit({ documents }));
            ModalService.openCustomModal(
              MergeFilesModal,
              {
                content: ''
              }
            );
            resolve(noResponse);
          }
          break;
        case 'printWatermark':
          this.handleDocumentsSend(documents, option)
            .then(() => {
              if (navigate) {
                navigate(CrooglooUrl.WATERMARK);
              }
            })
            .catch((err) => {
              console.error(err);
            });
          break;
        case 'sharepoint':
          ModalService.openCustomModal(
            ESignatureModal,
            {
              heading: 'documents.sharepoint.heading',
              content: 'documents.sharepoint.content',
              confirmButton: 'action.okay'
            }
          );
          resolve(noResponse);
          break;
        case 'download':
          this.handleDocumentsDownload(documents)
            .then(() => {
              this.handleSelectedFilesAndFolders([], []);
              resolve({
                message: 'documents.download.success',
                isShown: true,
                type: 'success'
              });
            })
            .catch((err) => {
              reject(err)
            });
          break;
        case 'downloadZip':
          this.downloadFolderAsZip(documents[0].id)
            .then((fileName: string) => {
              this.handleSelectedFilesAndFolders([], []);
              resolve({
                message: 'documents.download.successMore',
                isShown: true,
                type: 'success',
                content: { name: fileName },
                icon: ToastIcons.Time
              });
            })
            .catch((err) => {
              reject(err)
            });
          break;
        case 'folder':
          ModalService.openCustomModal(
            NewFolderModal,
            {
              heading: 'documents.newFolder.heading',
              content: 'documents.newFolder.content',
              confirmButton: 'action.create',
              contentValues: { name: 'folder' }
            }
          );
          resolve(noResponse);
          break;
        case 'shared':
          ModalService.openCustomModal(
            NewFolderModal,
            {
              heading: 'documents.sharedFolder.heading',
              content: 'documents.sharedFolder.content',
              confirmButton: 'action.create',
              contentValues: { name: 'shared' }
            }
          );
          resolve(noResponse);
          break;
        case 'dropboxUnlink':
          DocumentTreeService.unlinkDropbox()
            .then(() => {
              dispatch(editDocumentCard({ data: { id: DropboxFolderId, linked: false } }));
              resolve({
                message: 'documents.unlink.dropbox',
                isShown: true,
                type: 'success',
                icon: ToastIcons.Success
              });
            })
            .catch((err) => {
              reject(err)
            })
          break;
        case 'gDriveUnlink':
          GoogleService.unlinkGDrive();
          dispatch(editDocumentCard({ data: { id: GDriveFolderId, linked: false } }));
          resolve({
            message: 'documents.unlink.gDrive',
            isShown: true,
            type: 'success',
            icon: ToastIcons.Success
          });
          break;
        case 'boxUnlink':
          DocumentTreeService.unlinkBoxTokens();
          dispatch(editDocumentCard({ data: { id: BoxFolderId, linked: false } }));
          resolve({
            message: 'documents.unlink.box',
            isShown: true,
            type: 'success',
            icon: ToastIcons.Success
          });
          break;
        case MoveDocumentModal:
          this.handleMoveFiles(option, documents, type);
          resolve(noResponse);
          break;
        case 'publishToStudio':
          this.handleSharedDocumentFolder(
            PublishToStudioFolderName,
            DefaultDocumentPath,
            [SecurityListIds.ADMIN].join(','),
            true,
            false
          )
            .then(() => {
              dispatch(setPublishToStudioExists({ exists: true }));
              this.handleSelectedFilesAndFolders([], []);
              resolve({
                message: 'documents.newFolder.publishToStudio.success',
                isShown: true,
                type: 'success',
                icon: ToastIcons.Success
              });
            })
            .catch((err) => {
              console.error(err);
              throw new Error(err.message);
            })
          break;
        default:
          dispatch(setDocumentToEdit({ documents }));
          ModalService.openCustomModal(
            option,
            {
              ...OptionPopups[option],
              contentValues: { name: String(type) }
            }
          );
          resolve(null);
          break;
      }
    });
  }

  async handleDocumentsDownload (documents: DocumentCard[]): Promise<void> {
    await new Promise((resolve, reject) => {
      if (documents.length === 1) {
        const item: DocumentCard = documents[0];
        this.fetchDownloadLink(item.id)
          .then((url) => {
            if (url) {
              this.handleDownloadFile(url, item.name);
              resolve('');
            } else {
              throw new Error('documents.download.error');
            }
          })
          .catch(async (err) => {
            // handle download fail over
            const url: string = await this.handleGettingWatermarkFile(item.id);
            if (!url) {
              reject(err);
            } else {
              this.handleDownloadFile(url, item.name);
              resolve('');
            }
          });
      } else if (documents.length > 1) {
        const selectedIds: string[] = documents.map((document: DocumentCard) => document.id);
        this.downloadItemsAsZip(selectedIds)
          .then(() => {
            resolve('');
          })
          .catch((err) => {
            reject(err);
          })
      };
    })
  }

  async handleDocumentsSend (documents: DocumentCard[], option: string): Promise<void> {
    const fileCollection: FileCollectionMap = store.getState().documentTree.fileCollection;
    for (const document of documents) {
      const node: CollectionFile = fileCollection[document.id];
      let file: FileAttachment | undefined;
      if (!node) {
        file = await this.fetchDocumentById(document.id);
      } else {
        file = DocumentTreeService.getFileFromNode(node);
      }
      if (file) {
        // TODO: copyLink set link?
        UploadService.addUploadedFile(file);
      }
    }
  }

  /**
   * Downloads folder as zip from backend
   */
  async downloadFolderAsZip (folderId: string): Promise<string> {
    // TODO: handle google & dropbox - same for below download functions
    return await new Promise((resolve, reject) => {
      makeDocumentCall('downloadFolderAsZip', {
        params: {
          folderId
        }
      }).then((result: WSResponse) => {
        if (UtilityService.isSuccessResponse(result.responseCode)) {
          let fileName: string = '';
          if (result.responseMessage) {
            const responseMessage: string[] = result.responseMessage.split(',');
            fileName = responseMessage[1] ?? '';
            DownloadNotifications.add(
              false, false, responseMessage[0], responseMessage[1] ?? null, moment().valueOf()
            );
          }
          resolve(fileName);
        } else {
          reject(new Error('documents.download.error'));
        }
      }).catch((err) => {
        console.error(err);
        reject(new Error('documents.download.error'))
      })
    })
  }

  /**
   * Downloads different folders/files as a zip
   */
  async downloadItemsAsZip (selectedIds: string[]): Promise<void> {
    await new Promise((resolve, reject) => {
      makeDocumentCall('downloadItemsAsZip', {
        params: {
          value: selectedIds.join(',')
        }
      }).then((result: WSResponse) => {
        if (UtilityService.isSuccessResponse(result.responseCode)) {
          if (result.responseMessage) {
            const responseMessage: string[] = result.responseMessage.split(',');
            DownloadNotifications.add(
              false, false, responseMessage[0], responseMessage[1] ?? null, moment().valueOf()
            );
          }
          resolve('');
        } else {
          reject(new Error('documents.download.error'))
        }
      }).catch((err) => {
        console.error(err);
        reject(new Error('documents.download.error'))
      })
    })
  }

  /**
   * Downloads file from backend
   */
  async fetchDownloadLink (fileId: string): Promise<string> {
    return await makeDocumentCall('fetchDownloadLink', {
      params: {
        fileId
      }
    }).then((result: WSResponse) => {
      if (UtilityService.isSuccessResponse(result.responseCode)) {
        return result.responseMessage ?? '';
      } else {
        console.error('Error retrieving Data')
        return '';
      }
    }).catch((err) => {
      console.error(err);
      return '';
    })
  }

  async fetchItemsInFolder (folderId: string): Promise<WSFolderItemsResponse> {
    return await makeDocumentCall('fetchItemsInFolder', {
      params: {
        folderId
      }
    })
  }

  async getFileSize (file: UrlsToAdd): Promise<number> {
    return await new Promise((resolve) => {
      makeDocumentCall('getAttachmentSize', {
        params: {
          value: JSON.stringify({
            file
          })
        }
      })
        .then((resp: WSResponse) => {
          if (resp.responseCode === '-1') {
            console.error('Failed to get attachment size for: ');
            console.error(file);
          }
          const responseMessage: number = (resp.responseMessage) ? parseInt(resp.responseMessage) : 0;
          resolve(responseMessage);
        })
        .catch((err) => {
          console.error(err);
          resolve(0);
        })
    })
  }

  async validateFilesExistence (files: FileAttachment[]): Promise<string[]> {
    const filesIdToValidate = files.map(f => f.id).join(',');
    return await new Promise((resolve) => {
      if (filesIdToValidate.length > 0) {
        makeDocumentCall('validateFilesExistence', {
          params: { value: filesIdToValidate }
        })
          .then((resp: WSResponse) => {
            if (resp.responseCode) {
              if (resp.responseMessage && resp.responseMessage.trim().length > 0) {
                resolve(resp.responseMessage.split(','));
              } else {
                resolve([]);
              }
            } else {
              console.error('server error');
              resolve([]);
            }
          })
          .catch((err) => {
            console.error(err);
            resolve([]);
          })
      } else {
        resolve([]);
      }
    });
  }

  async fetchCallSheets (): Promise<Document[]> {
    return await makeDocumentCall('fetchCallSheets', {
      params: {
        targetEpisode: '2'
      }
    })
      .then((resp: DocumentResponseItems) => {
        const items: DocumentsResponse[] = resp.items;
        const callSheets: Document[] = items.map((item: DocumentsResponse) => ({ ...item.properties }));
        return callSheets;
      })
      .catch((err) => {
        console.error(err);
        return [];
      })
  }

  async fetchDocumentById (documentId: string): Promise<FileAttachment | undefined> {
    return await makeDocumentCall('fetchDocumentById', {
      params: {
        fileId: documentId
      }
    })
      .then((resp: DocumentsResponse) => {
        const document: Document = resp.properties;
        if (document.id && document.fileName && document.fileURL) {
          const file: FileAttachment = this.getFileFromDocument(document);
          return file;
        }
        return undefined;
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      })
  }

  getFileFromDocument (document: Document): FileAttachment {
    const fileSize: string = document.size || '0';
    return {
      id: document.id,
      fileName: document.fileName,
      url: document.fileURL,
      isWatermarked: document.isWatermarked ?? '0',
      size: parseInt(fileSize),
      subcategory: document.subcategory,
      isPublishedToStudio: false,
      cloudProvider: '',
      fileExtension: (UtilityService.getFileExtension(document.fileName) === 'pdf') ? FileType.PDF : FileType.Other,
      type: CrooglooFileTypes.File
    }
  }

  async changeArchiveStatus (fileId: string, archive: boolean): Promise<WSResponse> {
    return await makeDocumentCall('changeArchiveStatus', {
      params: {
        archive: String(archive)
      },
      type: PostMethod,
      body: JSON.stringify({
        value: fileId
      })
    })
  }

  async changeSubcategory (fileId: string, category: string): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      if (!category) {
        reject(new Error('documents.editCategory.error.empty'));
      } else {
        makeDocumentCall('changeSubcategory', {
          params: {
            newSubcategory: category
          },
          type: PostMethod,
          body: JSON.stringify({
            props: {
              docIds: fileId
            }
          })
        })
          .then((resp: WSResponse) => {
            if (UtilityService.isSuccessResponse(resp.responseCode)) {
              resolve();
            } else {
              reject(new Error('documents.editCategory.error.server'));
            }
          })
          .catch((err) => {
            console.error(err);
            reject(new Error('documents.editCategory.error.server'));
          })
      }
    })
  }

  async handleSharedDocumentFolder (
    name: string,
    parent: string,
    limitTo: string,
    isSmartFolder: boolean,
    isRecursive: boolean
  ): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      this.addDocumentFolder(name, parent, isSmartFolder)
        .then(async (id: string) => {
          await this.changeAccessLevel(id, limitTo, isRecursive);
          dispatch(editDocumentCard({ data: { id, shared: ViewService.replaceDHInShared(limitTo) } }))
          resolve();
        })
        .catch((err) => {
          reject(new Error(err.message));
        })
    });
  }

  async addDocumentFolder (name: string, parent: string, isSmartFolder: boolean): Promise<string> {
    return await makeDocumentCall('addDocumentFolder', {
      params: {
        folderName: name,
        parents: parent,
        isSmartFolder: String(isSmartFolder),
        page: 'menu_documents'
      },
      type: PostMethod,
      body: JSON.stringify({})
    })
      .then((resp: WSResponse) => {
        if (resp.entity) {
          const item: FolderItems = resp.entity.properties;
          const documentCard: DocumentCard = folderItemToDocumentCard(item, CrooglooFileTypes.Folder, isSmartFolder);
          dispatch(addDocumentCard({ documentCard }));
          return item.id;
        } else {
          throw new Error('documents.newFolder.error.server');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('documents.newFolder.error.server');
      })
  }

  async changeAccessLevel (fileId: string, limitTo: string, isRecursive: boolean): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      makeDocumentCall('changeAccessLevel', {
        params: {
          limitToType: 'securityList',
          isRecursive: String(isRecursive)
        },
        type: PostMethod,
        body: JSON.stringify({
          props: {
            ids: fileId,
            limitTo
          }
        })
      })
        .then((resp: WSResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            resolve();
          } else {
            reject(new Error('documents.accessLevel.error.server'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('documents.accessLevel.error.server'));
        })
    });
  }

  async mergeFiles (filesIds: string[], name: string, parent: string, overWrite: boolean): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      makeDocumentCall('mergeFiles', {
        params: {
          filesIdToMerge: filesIds.join('~'),
          mergedFileName: name,
          destFolderId: parent,
          isFileOverwriteAccepted: String(overWrite)
        },
        type: PostMethod
      })
        .then((resp: WSResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode) && resp.entity) {
            const item: FolderItems = resp.entity.properties;
            const fileType: CrooglooFileTypes = ViewService.cardTypeToIcon('file', item.fileName);
            const documentCard: DocumentCard = folderItemToDocumentCard(item, fileType, false);
            dispatch(addDocumentCard({ documentCard }));
            resolve();
          } else if (resp.responseCode === '-1') {
            reject(new Error(resp.responseMessage));
          } else {
            reject(new Error('documents.merge.error.server'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('documents.merge.error.server'));
        })
    });
  }

  async changeParentFolder (oldParentFolderId: string, newParentFolderId: string, fileIds: string[]): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      makeDocumentCall('changeParentFolder', {
        params: {
          oldParentFolderId,
          newParentFolderId,
          filesId: fileIds.join(',')
        },
        type: PatchMethod
      })
        .then(() => {
          if (oldParentFolderId !== newParentFolderId) {
            dispatch(removeDocumentCards({ documentIds: fileIds }));
          }
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('documents.moveDocument.error.server'));
        })
    });
  }

  async saveDocument (fileName: string, file: FileToUpload, page: PageMenus): Promise<WSResponse> {
    const apiMethod: string = (!fileName.endsWith('.zip')) ? 'saveDocument' : 'addZipAsFolder';
    return await makeDocumentCall(apiMethod, {
      params: {
        page
      },
      body: JSON.stringify(file),
      type: PostMethod
    })
  }

  async isTempDocDownloadReady (taskId: string): Promise<WSResponse> {
    return await makeDocumentCall('isTempDocDownloadReady', {
      params: {
        fileId: taskId
      }
    })
  }

  async fetchTempDocDownloadLink (taskId: string): Promise<WSResponse> {
    return await makeDocumentCall('fetchTempDocDownloadLink', {
      params: {
        fileId: taskId
      }
    })
  }

  /**
   * get data of watermarked file to download
   * @param documentId
   */
  async handleGettingWatermarkFile (documentId: string): Promise<string> {
    const response = await this.watermarkFile(documentId);
    let data: string = '';
    if (response.data.type === 'text/plain' || response.data.type === 'text/json') {
      const reader: FileReader = new FileReader();
      reader.onloadend = async function () {
        data = reader.result as string;
      }
      reader.readAsText(response);
    } else {
      data = window.URL.createObjectURL(response.data);
    }
    return await Promise.resolve(data);
  }

  /**
   * common function to download file in browser
   */
  handleDownloadFile (url: string, documentName: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', documentName);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  }

  /**
   * common function to open a file in a file viewer modal
   */
  async openFile (file: FileAttachment): Promise<void> {
    let resp: string | undefined = '';
    resp = await this.fetchFile(file);
    if (resp) {
      ModalService.openCustomModal(FileViewerModal, {
        content: resp
      });
    }
  }

  /**
   * handle opening of move file / folder modal
   * header of modal depends on if just files and/or folders being moved
   */
  handleMoveFiles (option: string, documents: DocumentCard[], type: CrooglooFileTypes): void {
    dispatch(setDocumentToEdit({ documents }));
    const documentTypes: CrooglooFileTypes[] = [
      CrooglooFileTypes.File, CrooglooFileTypes.Image, CrooglooFileTypes.Excel, CrooglooFileTypes.PDF
    ];
    const folders: number = documents.filter(doc => doc.type === CrooglooFileTypes.Folder).length;
    const files: number = documents.filter(doc => documentTypes.includes(doc.type)).length;
    let dialogHeading: string = OptionPopups[option].heading;
    if (folders > 0 && files === 0) {
      // Folders only
      dialogHeading = 'documents.moveDocument.headingFoldersOnly';
    } else if (files > 0 && folders === 0) {
      // Files only
      dialogHeading = 'documents.moveDocument.headingFilesOnly';
    }
    ModalService.openCustomModal(
      option,
      {
        ...OptionPopups[option],
        heading: dialogHeading,
        contentValues: { name: String(type) }
      }
    );
  }

  async fetchPdfFromLink (linkId: string): Promise<PdfLinkResponse> {
    return await new Promise((resolve, reject) => {
      let result: PdfLinkResponse = {
        data: '',
        pdfFileName: '',
        isDownloadAllowed: false,
        isPrintAllowed: false
      };
      AuthService.fetchPdfFromLink(linkId)
        .then((response: PdfLinkResponse) => {
          if (['text/plain', 'text/html', 'text/json'].includes(response.data.type)) {
            const reader: FileReader = new FileReader();
            reader.onload = async function () {
              const result = reader.result as string;
              if (result.trim().length && result.length < 30) {
                reject(new Error(result.trim()));
              } else {
                reject(new Error('Invalid Link'));
              }
            }
            reader.readAsText(response.data);
          } else {
            result = {
              ...response,
              data: window.URL.createObjectURL(response.data)
            };
            resolve(result);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        })
    })
  }

  openGoogleFile (id: string): void {
    GoogleService.getGoogleFile(id)
      .then((link: string) => {
        if (link) {
          window.open(link, '_blank');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleSelectedFilesAndFolders (selectedFiles: string[], selectedFolders: string[]): void {
    dispatch(handleSelectedFolder({ folders: selectedFolders }));
    dispatch(handleSelectedFile({ files: selectedFiles }));
  }

  importToCroogloo (file: DocumentCard): void {
    let uploadURL: string = '';
    let fileType: CrooglooFileTypes | null = null;
    let fileId: string = UtilityService.generateUniqueId(file.name);
    if (file.type === CrooglooFileTypes.DropboxFolder) {
      uploadURL = this.getDropboxUploadUrl('', true);
      fileType = CrooglooFileTypes.DropboxFolder;
      fileId = 'id:' + file.id;
      dispatch(setPredefinedFileType({ type: FileTypes.DocumentFolder }));
    } else if (file.type === CrooglooFileTypes.BoxFolder) {
      // TODO: add code for import a box folder
    } else if (file.type === CrooglooFileTypes.GDriveFolder) {
      // TODO: add code for import a gDrive folder
    } else if (file.type === CrooglooFileTypes.DropboxFile) {
      const params: string[] = store.getState().documents.documentParams;
      const pathBuilder: string[] = [];
      if (params.length > 1) {
        for (let i = 1; i < params.length; i++) {
          pathBuilder.push(params[i]);
        }
      }
      const path: string = pathBuilder.length > 0 ? '/' + pathBuilder.join('/') + '/' : '/';
      uploadURL = this.getDropboxUploadUrl(path + file.name, false);
      fileType = CrooglooFileTypes.DropboxFile;
    } else if (file.type === CrooglooFileTypes.BoxFile) {
      // TODO: add code for import a box file
    } else if (file.type === CrooglooFileTypes.GDriveFile) {
      uploadURL = this.getGDriveImportUrl(file.id, fileId, file.name);
      fileType = CrooglooFileTypes.GDriveFile;
    }

    if (fileType) {
      const fileToImport: OtherFileAttachment = {
        id: fileId,
        name: file.name,
        uploadURL,
        size: file.size,
        type: fileType
      };
      UploadService.startImportUploadProcess([fileToImport]);
    }
  }

  getDropboxUploadUrl (path: string, folder: boolean): string {
    const authState = store.getState().auth;
    let url: string = `/DropboxImport?token=${authState.userToken}&tenantId=${authState.tenantId}&dropbox_accesstoken=${authState.crooglooauth.dropboxToken}`;
    if (!folder) {
      url += `&filepath=${path}`;
    }
    return url;
  }

  getGDriveImportUrl (fileId: string, crooglooFileId: string, fileName: string) {
    const authState = store.getState().auth;
    return `/GDriveImport?token=${authState.userToken}&tenantId=${authState.tenantId}&googlefileid=${fileId}&croogloofileid=${crooglooFileId}&filename=${fileName}&access_token=${authState.crooglooauth.gDriveAccessToken ?? ''}`
  }
}

/**
 * change a file of type script/side/fileAttachment to a ViewDocument to be able to download the file
 */
const getDocument = (file: Script | Side | FileAttachment): ViewableDocument => {
  return {
    id: file.id,
    name: file.fileName,
    isWatermarked: (UtilityService.isWatermarked(file.isWatermarked))
  }
}

const getSharedText = (shared: string): string => {
  let sharedText: string = 'documents.sharedWith.everyone';
  if (shared) {
    if (shared.includes('@')) {
      sharedText = 'documents.sharedWith.email';
    } else {
      sharedText = (typeof shared === 'string') ? shared : (shared ?? '');
    }
  }
  return sharedText;
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */

const makeDocumentCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(DocumentsApi, method, data);
  return result;
}

function buildHierarchy (folders: ShortenedFolder[]) {
  const folderMap: any = {};

  folders.forEach((folder: ShortenedFolder) => {
    folderMap[folder.id] = { ...folder, subDirectories: [] };
  });

  let hierarchy: DirectoryDestination[] = [];

  folders.forEach((folder: ShortenedFolder) => {
    if (folder.parentFolder) {
      const parentFolder = folderMap[folder.parentFolder];
      if (parentFolder) {
        parentFolder.subDirectories.push(folderMap[folder.id]);
      }
    } else {
      if (folder.id === RootFolderName) {
        hierarchy = [folderMap[folder.id]].concat(hierarchy);
      } else {
        hierarchy.push(folderMap[folder.id]);
      }
    }
  });

  return hierarchy;
}

function addChildrenRecursively (folder: DirectoryDestination) {
  if (folder.subDirectories.length > 0) {
    folder.subDirectories.forEach((child: DirectoryDestination) => {
      addChildrenRecursively(child);
    });
  }
}

function buildRecursiveHierarchy (folders: ShortenedFolder[]) {
  const hierarchy: DirectoryDestination[] = buildHierarchy(folders);
  hierarchy.forEach((folder: DirectoryDestination) => {
    addChildrenRecursively(folder);
  });
  return hierarchy;
}

function folderItemToDocumentCard (item: FolderItems, type: CrooglooFileTypes, isSmartFolder: boolean): DocumentCard {
  const documentCard: DocumentCard = {
    itemid: item.id,
    id: item.id,
    type,
    subType: '',
    data: item.id,
    name: item.fileName,
    datemod: item.timeCreated ?? '',
    shared: '',
    category: '',
    watermark: false,
    isEncrypted: false,
    archived: false,
    isPublishedToStudio: false,
    cloudProvider: '',
    size: (item.fileSize) ? parseInt(item.fileSize) : 0,
    sortValue: (isSmartFolder) ? 1 : (type === 'folder' ? 2 : 3)
  };
  return documentCard;
}

const documentService: IDocumentService = new DocumentService();
export default documentService;
