import React, { lazy, Suspense } from 'react';

const LazyFileAlreadyExist = lazy(async () => await import('./FileAlreadyExist'));

const FileAlreadyExist = () => (
  <Suspense fallback={null}>
    <LazyFileAlreadyExist />
  </Suspense>
);

export default FileAlreadyExist;
