import { Box, Checkbox, Grid } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import type { GenerateScripts } from '../../../models/Script';
import styles from './ScriptListDrawerTabs.module.scss';
import ScriptService from '../../../services/ScriptService';
import { BlackGrey, CrooglooGreen } from '../../../theme/colors';

interface Props {
  script: GenerateScripts
  handleSelect: (script: GenerateScripts, isSelected: boolean) => void
  isSelected: boolean
  currentlySelected: boolean
}

/**
 * Item for the drawer's script list.
 */
const ScriptListTabItem: FC<Props> = (props: Props) => {
  const { script } = props;

  const handleCheckboxSelect = (event: SyntheticEvent) => {
    event.preventDefault();
    props.handleSelect(script, props.isSelected);
  }

  return (
    <Grid
      container
      alignItems={'center'}
      className={
        `${styles.listItem} ${(props.currentlySelected) ? styles.currentListItem : (props.isSelected) ? styles.selectedListItem : ''}`
      }
    >
      <Grid item xs={2}>
        <Checkbox
          size='small'
          onClick={handleCheckboxSelect}
          checked={props.isSelected}
          sx={{
            '& .MuiSvgIcon-root': {
              color: (props.currentlySelected) ? CrooglooGreen : (props.isSelected) ? BlackGrey : ''
            }
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Box
          className={`${styles.itemText} ${(props.isSelected) ? styles.selectedItemText : ''}`}
        >
          {ScriptService.getScriptDisplayName(script.episode, script.color)}
        </Box>
      </Grid>
    </Grid>
  )
}

export default ScriptListTabItem;
