import React, { lazy, Suspense } from 'react';

const LazyAccessLevel = lazy(async () => await import('./AccessLevel'));

const AccessLevel = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyAccessLevel {...props} />
  </Suspense>
);

export default AccessLevel;
