import { store } from '../state/store';
import AuthService from './auth/AuthService';
import type { AuthUser } from './auth/constants';
import { setGDriveToken } from '../state/slices/AuthReducer';
import type { GoogleDriveResponse } from '../models/Google';

const { dispatch } = store;

const googleAPIKey: string = process.env.REACT_APP_GOOGLE_API ?? '';
const googleRootUrl: string = process.env.REACT_APP_GOOGLE_DRIVE_URL ?? '';
const GoogleRoot: string = 'root';

interface IGoogleService {
  importContacts: (token: string) => Promise<void>
  unlinkGDrive: () => void
  setGDriveToken: (crooglooAuth: AuthUser, token: string) => void
  fetchGoogleFiles: (params: string[]) => Promise<GoogleDriveResponse>
  buildFilesUrl: (params: string[]) => string
  getGoogleFile: (id: string) => Promise<string>
}

class GoogleService implements IGoogleService {
  async importContacts (token: string): Promise<void> {
    await AuthService.authenticatedCall('/googleContact', {
      params: {
        access_token: token
      }
    }).then(() => {})
      .catch((err) => {
        console.error(err);
        throw new Error('import.google.error');
      })
  }

  /**
   * Unlink GDrive token from croogloo auth object
   */
  unlinkGDrive (): void {
    const authState = store.getState().auth;
    this.setGDriveToken(authState.crooglooauth, '');
  }

  /**
   * set google drive token to access drive files in docuemnts
   */
  setGDriveToken (crooglooAuth: AuthUser, token: string): void {
    const newCrooglooAuth: AuthUser = { ...crooglooAuth, gDriveAccessToken: token };
    const authState = store.getState().auth;
    dispatch(setGDriveToken({ token }));
    // reset gapi client token to stop googleLogin automatically over writng it
    AuthService.setGapiToken(authState.accessToken);
    AuthService.setStorageAuth(newCrooglooAuth);
  }

  /**
   * fetch gDrive files depending on params
   */
  async fetchGoogleFiles (params: string[]): Promise<GoogleDriveResponse> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    const requestUrl: string = this.buildFilesUrl(params);
    const gDriveToken: string = crooglooAuth.gDriveAccessToken ?? '';
    return await new Promise((resolve, reject) => {
      fetch(requestUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${gDriveToken}`
        }
      })
        .then(response => {
          resolve(response.json());
        })
        .catch(error => reject(error));
    });
  }

  /**
   * build url from params to get files from google Drive
   */
  buildFilesUrl (params: string[]): string {
    const folderId: string = (params.length === 1) ? GoogleRoot : params[params.length - 1];
    return (params.length === 1) ? googleRootUrl : `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&orderBy=name&key=${googleAPIKey}`;
  }

  /**
   * fetch gDrive file weblink to view google file using the files id
   */
  async getGoogleFile (id: string): Promise<string> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    const gDriveToken: string = crooglooAuth.gDriveAccessToken ?? '';
    const requestUrl: string = `https://www.googleapis.com/drive/v3/files/${id}?fields=*`;
    return await new Promise((resolve, reject) => {
      fetch(requestUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${gDriveToken}`
        }
      })
        .then(async (response: Response) => {
          const res = await response.json();
          let link: string = '';
          if (res.webViewLink) {
            link = res.webViewLink;
          }
          resolve(link);
        })
        .catch(error => reject(error));
    });
  }
}

const googleService: IGoogleService = new GoogleService();
export default googleService;
