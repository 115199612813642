import type { FC } from 'react';
import Grid from '@mui/material/Grid';

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

/**
 * Tab Panel Component for the Slide Drawer Component containing lists or departemnts tabs
 * Also called for the Scripts/Sides pages in the DocumentLists component &
 * From EmailDrawerTab as an example for Distribution Report
 * Children passed in are a list mui componet with a tab item child component displaying the tab contents
 */
const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ flex: 1 }}
    >
      {value === index && (
        <Grid container direction="column" alignItems="stretch" sx={{ height: '100%' }}>
          {children}
        </Grid>
      )}
    </div>
  );
}

export default TabPanel;
