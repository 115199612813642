import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import type { Anchor } from '../../models/Anchor';
import type { Script } from '../../models/Script';

import styles from './Scripts.module.scss';
import MainSection from '../common/MainSection';
import DocumentViewer from '../DocumentViewer/DocumentViewer.lazy';
import DocumentLists from '../DocumentLists/DocumentLists.lazy';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import DocumentService from '../../services/documents/DocumentService';
import { DefaultListOrder, sortFiles } from '../DocumentLists/DocumentLists';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import { drawerWidth } from '../../theme/styles';

export enum ScriptsPage {
  Title = 'Scripts'
}

export interface ScriptsProps {
  pageTitle: ScriptsPage
  children?: React.ReactNode
}

const anchor: Anchor = 'left';

/**
 * Scripts component for Scripts page
 * Calls the document viewer component to display the file selected
 * And DocumentLists to display the scripts in the Slide Drawer
 */
const Scripts: FC<ScriptsProps> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle]);

  const buttonText: string = 'scripts.slideDrawer.button';
  const tabs: string[] = ['scripts.slideDrawer.tabHeader'];

  const [open, setOpen] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<Script[]>([]);
  const [selectedFile, setSelectedFile] = useState<Script | null>(null);
  const [currentFile, setCurrentFile] = useState<Script | null>(null);

  useEffect(() => {
    DocumentService.fetchDocumentsBySubcategory('script')
      .then((files: Script[]) => {
        files = sortFiles(files, DefaultListOrder);
        setFiles(files);
        files.length && setSelectedFile(files[0]);
      })
      .catch((e: any) => console.debug(e));
  }, []);

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const handleFileChange = (fileId: string): void => {
    if (!selectedFile || selectedFile.id !== fileId) {
      const newFile = files.find((file: Script) => file.id === fileId);
      if (newFile !== undefined) {
        setSelectedFile(newFile);
      }
    }
  };

  const handleDeleteSuccess = (id: string) => {
    setFiles(files => files.filter(f => f.id !== id));
  };

  return (
    <div className={styles.Scripts} data-testid="Scripts">
      <SlideDrawer
        drawerWidth={drawerWidth} open={open} anchor={anchor}
        toggleDrawer={toggleDrawer}
        buttonText={buttonText}
      >
        <DocumentLists // displays the scripts in the slide drawer - this is the child of the slide drawer
          file={selectedFile}
          files={files}
          handleFileChange={handleFileChange}
          tabs={tabs}
          documentType="script"
          setCurrentFile={setCurrentFile}
        />
      </SlideDrawer>
      <MainSection open={open} drawerwidth={drawerWidth}>
          <DocumentViewer
            file={selectedFile}
            actionsFile={currentFile}
            watermark={false}
            onDeleteSuccess={handleDeleteSuccess}
            isLoading={isLoading}
            setLoading={setLoading}
          />
      </MainSection>
    </div>
  )
};

export default Scripts;
