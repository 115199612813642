export const IMG_DIRECTORY: string = '/templates/images/';
export const GeneralDocumentImage: string = '/templates/images/documentGeneral.png';

export interface TemplateDashboardDetails {
  name?: string
  relativeOrder: number
}

export interface TemplateItem {
  imageUrl: string
  xlFileUrl: string
  docFileUrl: string
  name: string
  googleForm: boolean
  dashboard?: TemplateDashboardDetails
}

const templates: TemplateItem[] = [
  {
    imageUrl: IMG_DIRECTORY + 'crewIntake.png',
    xlFileUrl: '',
    docFileUrl: '',
    name: 'templates.crew-intake',
    googleForm: true,
    dashboard: {
      name: 'templates.allergyReport',
      relativeOrder: 2
    }
  },
  {
    imageUrl: IMG_DIRECTORY + 'accidentInvestReport.png',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Accident Report.docx',
    name: 'templates.accident-rpt',
    googleForm: false,
    dashboard: {
      relativeOrder: 1
    }
  },
  {
    imageUrl: IMG_DIRECTORY + 'callSheet.png',
    xlFileUrl: 'Croogloo Call Sheet.xlsm',
    docFileUrl: 'Croogloo Call Sheet.docx',
    name: 'templates.call-sheet',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'PEAR.png',
    xlFileUrl: 'Croogloo Carbon Calculator - PEAR.xlsm',
    docFileUrl: '',
    name: 'templates.carbon-calc',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'chequeRequest.png',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Cheque Request.doc',
    name: 'templates.cheque-req',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'crewList.png',
    xlFileUrl: 'Croogloo Contact Import.xlsx',
    docFileUrl: '',
    name: 'templates.contact-imp',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'dpr.png',
    xlFileUrl: '',
    docFileUrl: 'Croogloo DPR.docx',
    name: 'templates.dpr',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'equipmentOrder.png',
    xlFileUrl: 'Croogloo Equipment Order.xlsx',
    docFileUrl: '',
    name: 'templates.equip-ordr',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'gasReceipts.png',
    xlFileUrl: 'Croogloo Gas Receipts.xlsx',
    docFileUrl: 'Croogloo Gas Receipts.docx',
    name: 'templates.gas-receipts',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'greenSeal.png',
    xlFileUrl: 'GPG_PEACH_v6.xlsb',
    docFileUrl: '',
    name: 'templates.green-seal',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'lossAndDamage.png',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Loss and Damage.docx',
    name: 'templates.loss-dmg',
    googleForm: false,
    dashboard: {
      relativeOrder: 3
    }
  },
  {
    imageUrl: IMG_DIRECTORY + 'mastercardReceipts.png',
    xlFileUrl: 'Croogloo Mastercard Receipts.xlsm',
    docFileUrl: '',
    name: 'templates.mastercard-recpt',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'mileageForm.jpeg',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Mileage Form.docx',
    name: 'templates.mileage',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'pettyCashAdvanceReq.png',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Petty Cash Advance Request.doc',
    name: 'templates.petty-req',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'pettyCashEnvelope.png',
    xlFileUrl: 'Croogloo Petty Cash Envelope.xlsm',
    docFileUrl: '',
    name: 'templates.petty-env',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'productionChecklist.png',
    xlFileUrl: 'PEACH - Checklist & EMA Green Seal.xlsb',
    docFileUrl: '',
    name: 'templates.prod-chcklist',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'PLUM.png',
    xlFileUrl: 'Croogloo PLUM.xlsx',
    docFileUrl: '',
    name: 'templates.lumber',
    googleForm: false
  },
  {
    imageUrl: IMG_DIRECTORY + 'travelMemo.jpg',
    xlFileUrl: '',
    docFileUrl: 'Croogloo Travel Memo.doc',
    name: 'templates.travel-memo',
    googleForm: false,
    dashboard: {
      relativeOrder: 4
    }
  },
  {
    imageUrl: IMG_DIRECTORY + 'visaReceipts.png',
    xlFileUrl: 'Croogloo VISA Receipts.xlsm',
    docFileUrl: '',
    name: 'templates.visa-recpt',
    googleForm: false
  }
];
export const DashboardTemplates: TemplateItem[] = templates.filter(
  (template: TemplateItem) => template.dashboard
).sort((a: TemplateItem, b: TemplateItem) => (a.dashboard?.relativeOrder ?? 0) - (b.dashboard?.relativeOrder ?? 0));

export default templates;
