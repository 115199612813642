import type { FC, DragEvent, ReactNode, ChangeEvent, RefObject } from 'react';
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import styles from './FileUpload.module.scss';
import type { UploadFileLocation } from '../../models/Upload';
import SecurityAdminService from '../../services/SecurityAdmin';
import type { FileTypes } from '../../models/FileTypes';
import UploadService from '../../services/UploadService';

interface UploadProps {
  children?: ReactNode
  fileInputRef?: RefObject<HTMLInputElement>
  uploadDestination?: UploadFileLocation
  disableDragging?: boolean
  fileType?: FileTypes
}

export const MaxAttachmentsSize: number = 20; // in Mb

// Reusable upload component
const FileUpload: FC<UploadProps> = ({
  children, fileInputRef, uploadDestination, disableDragging, fileType
}: UploadProps) => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    SecurityAdminService.fetchSecurityLists();
  }, []);

  const restartUploadProcess = (files: File[]): void => {
    UploadService.startUploadProcess(files, uploadDestination, fileType);
  }

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (event.target?.files && event.target.files.length) {
      const files: File[] = Array.prototype.slice.call(event.target.files);
      restartUploadProcess(files);
      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
    }
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (disableDragging) {
      return;
    }
    setDragging(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (disableDragging) {
      return;
    }
    setDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (disableDragging) {
      return;
    }
    setDragging(false);
    if (event.dataTransfer?.files !== null) {
      const files: File[] = Array.prototype.slice.call(event.dataTransfer.files);
      restartUploadProcess(files);
    }
  };

  return (
    <>
      <Box
        component={'div'}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`${styles.FileUpload} ${(dragging) ? styles.fileDraggingActive : ''}`}
      >
        <input type='file' hidden ref={fileInputRef} multiple onChange={handleFileUpload}/>
        {children}
      </Box>
    </>
  );
};

export default FileUpload;
