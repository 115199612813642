export enum LinkAccessOptions {
  ReadOnly = 0,
  Download = 1,
  DownloadPrint = 2
}

export enum LinkTimeOptions {
  NEVER = 0,
  HOURS24 = 1,
  CUSTOM = 2
}

export interface LinkOptionsStatus {
  linkValidityTime: LinkTimeOptions
  linkAccessType: LinkAccessOptions
  isDeepLink: boolean
  durationDays: number
  durationHours: number
}

export const OriginalDurationDays: number = 86400000;
export const OriginalDurationHours: number = 0;
