interface IGeocodeService {
  geocodeLatLngToCountry: (lat: number, lng: number) => Promise<string>
}

class GeocodeService implements IGeocodeService {
  async geocodeLatLngToCountry (lat: number, lng: number): Promise<string> {
    let country: string = '';
    const url: string = `https://maps.googleapis.com/maps/api/geocode/json?address=${String(lat)},${String(lng)}&key=${String(process.env.REACT_APP_GOOGLE_MAPS_KEY)}`;
    const response = await fetch(url);
    const res = await response.json();
    if (Array.isArray(res.results) && res.results.length > 0) {
      const results = res.results;
      country = results[results.length - 1].formatted_address;
    }
    return country;
  }
}

const geocodeService: IGeocodeService = new GeocodeService();
export default geocodeService;
