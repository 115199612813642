/*
 * Reducer file for the Distribution slice
 */

import { createSlice } from '@reduxjs/toolkit';
import type {
  DistributionList, ExtraEmail, DistributionListName, DistributionListIds,
  DistributionGroupLists, DistributionContact
} from '../../models/DistributionList';
import type { ProductionOfficeUser } from '../../models/Production';
import type { DistributionEmailTemplateName, DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';
import type { Person } from '../../models/Person';
import type { DistributionMessage } from '../../models/DistributionMessage'
import type { DistributionStatuses } from '../../models/DistributionReport';
import { DistributionMessagesKeys } from '../../services/distribution/constants';
import { DistributionSlice } from './constants';
import type {
  DistributionListMembers, DistributionListWithContacts, DistributionGroupMembersList,
  DistributionContactMembersList
} from '../../models/DistributionListMembers';

export interface DistributionState {
  allContactsList: DistributionList | null
  distributionList: DistributionList[]
  distributionListNames: DistributionListName[]
  distributionListIds: DistributionListIds[]
  productionOfficeUsers: ProductionOfficeUser[]
  replyToUsers: ProductionOfficeUser[]
  cachedReplyToEmail: string | null
  distributionEmailTemplateNames: DistributionEmailTemplateName[]
  distributionEmailTemplates: DistributionEmailTemplate[]
  personList: Person[]
  extraEmails: ExtraEmail[]
  distributionMessages: Record<string, DistributionMessage[]>
  currentDistributionReport: DistributionStatuses
  distributionGroupList: DistributionGroupLists
  distributionContactList: DistributionContact[]
  distributionListMembers: DistributionGroupMembersList
  distributionContactListMembers: DistributionContactMembersList
  distributionListToEdit: string | null
  distributionContactToEdit: Person | null
  cachedExtraEmails: string[]
  isSendingMessage: boolean
}

const initialState: DistributionState = {
  allContactsList: null,
  distributionList: [],
  distributionListNames: [],
  distributionListIds: [],
  productionOfficeUsers: [],
  replyToUsers: [],
  cachedReplyToEmail: null,
  distributionEmailTemplateNames: [],
  distributionEmailTemplates: [],
  personList: [],
  extraEmails: [],
  distributionMessages: { // the different types of distribution messages are store here (draft, outbox & sent)
    [DistributionMessagesKeys.Outbox]: [],
    [DistributionMessagesKeys.Drafts]: [],
    [DistributionMessagesKeys.Sent]: []
  },
  currentDistributionReport: { // the data for the current distribution report in the sent page
    bounced: [],
    deferred: [],
    delivered: [],
    dropped: [],
    opened: [],
    processed: []
  },
  distributionGroupList: {},
  distributionContactList: [],
  distributionListMembers: {},
  distributionContactListMembers: {},
  distributionListToEdit: null,
  distributionContactToEdit: null,
  cachedExtraEmails: [],
  isSendingMessage: false
}

/*
 * Create the reducer using createSlice
 * 3 properties -
 * name: Used in action types (String)
 * initialState: Initial state for the respective reducer
 * reducers: object of reducer functions. Key names will be used to generate actions
 */

export const distributionSlice = createSlice({
  name: DistributionSlice,
  initialState,
  reducers: {
    setDistributionList: (state, action) => {
      const distributionList: DistributionList[] = action.payload.distributionList;
      distributionList.sort((a: DistributionList, b: DistributionList) => a.name.localeCompare(b.name));
      state.distributionList = distributionList;
    },
    setProductionOfficeUsers: (state, action) => {
      state.productionOfficeUsers = action.payload.productionOfficeUsers;
    },
    setDistributionListIds: (state, action) => {
      const distributionListIds: DistributionListIds[] = action.payload.distributionLists;
      distributionListIds.sort((a: DistributionListIds, b: DistributionListIds) => a.distributionListName.localeCompare(b.distributionListName));
      state.distributionListIds = distributionListIds;
    },
    setDistributionEmailTemplateNames: (state, action) => {
      const distributionEmailTemplateNames = action.payload.distributionEmailTemplateNames;
      distributionEmailTemplateNames.sort((a: string[], b: string[]) => a[0].localeCompare(b[0]));
      state.distributionEmailTemplateNames = distributionEmailTemplateNames;
    },
    addDistributionEmailTemplateName: (state, action) => {
      const templateName = action.payload.templateName;
      let distributionEmailTemplateNames = [...state.distributionEmailTemplateNames].filter(
        template => template[0] !== templateName[0] && template[1] !== templateName[1]
      );
      distributionEmailTemplateNames = [...distributionEmailTemplateNames, templateName];
      distributionEmailTemplateNames.sort((a: string[], b: string[]) => a[0].localeCompare(b[0]));
      state.distributionEmailTemplateNames = distributionEmailTemplateNames;
    },
    addDistributionEmailTemplate: (state, action) => {
      const emailTemplate = action.payload.distributionEmailTemplate;
      const existingTemplate = state.distributionEmailTemplates.find(template => template.templateName === emailTemplate.templateName);
      if (existingTemplate !== undefined) {
        state.distributionEmailTemplates = state.distributionEmailTemplates.map(template => template.templateName === emailTemplate.templateName ? emailTemplate : template)
      } else {
        state.distributionEmailTemplates.push(emailTemplate);
      }
    },
    setPersonList: (state, action) => {
      state.personList = action.payload.personList;
    },
    setExtraEmails: (state, action) => {
      state.extraEmails = action.payload.extraEmails;
    },
    setDistributionListNames: (state, action) => {
      state.distributionListNames = action.payload.distributionListNames;
    },
    setDistributionMessages: (state, action) => {
      state.distributionMessages[action.payload.key] = action.payload.distributionMessages;
    },
    setCurrentDistributionReport: (state, action) => {
      state.currentDistributionReport = action.payload.distributionReport;
    },
    addDistributionList: (state, action) => {
      const currentDistributionList = [...state.distributionList].filter(list => list.id !== action.payload.distributionList.id);
      currentDistributionList.push(action.payload.distributionList);
      currentDistributionList.sort((a: DistributionList, b: DistributionList) => a.name.localeCompare(b.name));
      state.distributionList = currentDistributionList;
    },
    addExtraEmail: (state, action) => {
      state.extraEmails.push(action.payload.extraEmail);
    },
    removeDistributionMessage: (state, action) => {
      const distrubtutionMessage = state.distributionMessages[action.payload.key].filter(message => message.id !== action.payload.msgId);
      state.distributionMessages[action.payload.key] = distrubtutionMessage;
    },
    removeAllDistributionMessages: (state, action) => {
      state.distributionMessages[action.payload.key] = [];
    },
    setDistributionGroupList: (state, action) => {
      const distributionGroupList: DistributionGroupLists = action.payload.list;
      state.distributionGroupList = distributionGroupList;
    },
    setDistributionContactList: (state, action) => {
      const distributionContactList: DistributionContact[] = action.payload.list;
      state.distributionContactList = distributionContactList;
    },
    setDistributionListMembers: (state, action) => {
      const distributionGroupList: string = action.payload.distributionGroupList;
      const members: DistributionListMembers[] = action.payload.members;
      const nonMembers: DistributionListMembers[] = action.payload.nonMembers;
      state.distributionListMembers[distributionGroupList] = {
        id: distributionGroupList,
        members,
        nonMembers
      }
    },
    setDistributionContactMembers: (state, action) => {
      const distributionContactList: string = action.payload.id;
      const members: DistributionListWithContacts[] = action.payload.members;
      const nonMembers: DistributionListWithContacts[] = action.payload.nonMembers;
      state.distributionContactListMembers[distributionContactList] = {
        id: distributionContactList,
        members,
        nonMembers
      }
    },
    setDistributionListToEdit: (state, action) => {
      const listId: string = action.payload.listId;
      const distributionGroupList: DistributionGroupLists = state.distributionGroupList;
      if (distributionGroupList[listId]) {
        state.distributionListToEdit = listId;
      }
    },
    resetDistributionToEdit: (state, action) => {
      state.distributionListToEdit = null;
    },
    removeDistributionListMembers: (state, action) => {
      const { distributionGroupList, ...copyDistributionContactListMembers } = state.distributionContactListMembers;
      state.distributionContactListMembers = { ...copyDistributionContactListMembers };
    },
    setDistributionContactToEdit: (state, action) => {
      const person: Person = action.payload.person;
      state.distributionContactToEdit = person;
    },
    resetDistributionContactToEdit: (state, action) => {
      state.distributionContactToEdit = null;
    },
    setReplyToUsers: (state, action) => {
      state.replyToUsers = action.payload.users;
    },
    setCachedReplyToEmail: (state, action) => {
      state.cachedReplyToEmail = action.payload.email;
    },
    addCachedExtraEmail: (state, action) => {
      let cachedExtraEmails: string[] = state.cachedExtraEmails;
      const cachedEmail: string = action.payload.email;
      if (!cachedExtraEmails.some((email: string) => email === cachedEmail)) {
        cachedExtraEmails = [...cachedExtraEmails, cachedEmail];
      }
      state.cachedExtraEmails = cachedExtraEmails;
    },
    updateDistributionMessageTime: (state, action) => {
      const messages: DistributionMessage[] = [...state.distributionMessages[action.payload.key]];
      const timeLeft: string = action.payload.timeLeft;
      const messageIndex: number = messages.findIndex((m: DistributionMessage) => m.id === action.payload.messageId);
      if (messageIndex > -1) {
        messages[messageIndex] = { ...messages[messageIndex], timeLeft };
        state.distributionMessages[action.payload.key] = messages;
      }
    },
    setIsSendingMessage: (state, action) => {
      state.isSendingMessage = action.payload.isSendingMessage;
    },
    setAllContactsList: (state, action) => {
      state.allContactsList = action.payload.list;
    }
  }
});

// export the reducer functions/actions to be used throughout the app
export const {
  setDistributionList, setDistributionListIds, setProductionOfficeUsers,
  setDistributionEmailTemplateNames, setPersonList, addDistributionEmailTemplate,
  setExtraEmails, setDistributionListNames, addDistributionList, addExtraEmail,
  setDistributionMessages, setCurrentDistributionReport, removeDistributionMessage,
  setDistributionContactList, setDistributionGroupList, setDistributionListMembers,
  setDistributionContactMembers, removeDistributionListMembers, setDistributionListToEdit,
  resetDistributionToEdit, setDistributionContactToEdit, resetDistributionContactToEdit,
  setReplyToUsers, setCachedReplyToEmail, addDistributionEmailTemplateName, removeAllDistributionMessages,
  addCachedExtraEmail, updateDistributionMessageTime, setIsSendingMessage, setAllContactsList
} = distributionSlice.actions;
export default distributionSlice.reducer;
