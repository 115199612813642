import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { drawerButtonStyles } from '../../theme/styles';

/**
 * drawer button style for open and closed slide drawer
 */
const DrawerButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sideBarOpen' })<{
  open: boolean
  width: number
  sideBarOpen: boolean
  showRight?: boolean
}>(drawerButtonStyles);

export default DrawerButton;
