import { createSlice } from '@reduxjs/toolkit';
import { SideBarSlice } from './constants';
import type { SideNavItem } from '../../models/SideNav';
import { DashboardSideNav } from '../../models/SideNav';

interface SideNavSlice {
  selectedItem: SideNavItem
  expandedItemName: string
  isSideBarOpen: boolean
}

const initialState: SideNavSlice = {
  selectedItem: DashboardSideNav,
  expandedItemName: '',
  isSideBarOpen: false
}

export const sidebarSlice = createSlice({
  name: SideBarSlice,
  initialState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.selectedItem;
    },
    setExpandedMenu: (state, action) => {
      state.expandedItemName = action.payload.expandedItemName;
    },
    setSideBarState: (state, action) => {
      state.isSideBarOpen = action.payload.state;
    }
  }
});

export const { setSelectedItem, setExpandedMenu, setSideBarState } = sidebarSlice.actions;
export default sidebarSlice.reducer;
