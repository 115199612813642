import React, { lazy, Suspense } from 'react';
import { type ScriptsProps } from './Scripts';

const LazyScripts = lazy(async () => await import('./Scripts'));

const Scripts = (props: ScriptsProps) => (
  <Suspense fallback={null}>
    <LazyScripts {...props} />
  </Suspense>
);

export default Scripts;
