import { store } from '../../state/store';
import { Currencies, CountryCurrencyMap } from '../../models/Subscription';
import type { Countries } from '../../models/Subscription';
import { setCurrency } from '../../state/slices/Subscription';
import GeocodeService from '../GeocodeService';

const { dispatch } = store;

interface ICurrencyService {
  getCurrency: () => Promise<Currencies>
}

/**
 * Currency service with functions related to currencies for the application
 */
class CurrencyService implements ICurrencyService {
  /**
   * get currency based on users location
   */
  async getCurrency (): Promise<Currencies> {
    const currency: Currencies = await new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const lat: number = position.coords.latitude;
          const lng: number = position.coords.longitude;
          GeocodeService.geocodeLatLngToCountry(lat, lng)
            .then((country: string) => {
              resolve(getCurrencyFromMap(country))
            })
            .catch((err) => {
              console.error(err.message);
              resolve(Currencies.USDollars);
            });
        }, (err) => {
          console.debug(err.message);
          resolve(Currencies.USDollars);
        });
      } else {
        console.debug('This browser does not support geolocation. US store will be used');
        resolve(Currencies.USDollars);
      }
    });
    dispatch(setCurrency({ currency }))
    return currency;
  }
}

/**
 * Get the currency from the selected country
 * default to usd if not in map
 * @param country
 */
function getCurrencyFromMap (country: string): Currencies {
  const CrooglooCountry: string = country.toLowerCase().replace(/\s/g, '-');
  let currency: Currencies = Currencies.USDollars;
  if (CountryCurrencyMap[CrooglooCountry as Countries]) {
    currency = CountryCurrencyMap[CrooglooCountry as Countries];
  }
  return currency;
}

const currencyService: ICurrencyService = new CurrencyService();
export default currencyService;
