import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';

import CustomTooltip from '../CustomToolTip';
import { BorderBlack, WhiteGrey, MainRed, LightBlack, CrooglooGreen } from '../../theme/colors';

interface Props {
  id: string
  name: string
  label: string
  placeholder?: string
  passwordCallback?: any
  sx?: any
}

/**
 * Reusable Input Password Field Component for forms
 * name - name of select field
 */

const FormInputPasswordField: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const sendPassword = () => {
    props.passwordCallback(password);
  };

  return (
    <>
      <Grid sx={{ display: 'flex', flexDirection: 'column', ...props.sx }}>
        <InputLabel
          sx={{
            float: 'left',
            padding: '10px 0px'
          }}
          htmlFor="outlined-adornment-password">
          {props.label}
        </InputLabel>
        <FormControl
          sx={{
            width: '100%',
            height: '40px',
            '& .MuiOutlinedInput-root': {
              height: 'inherit',
              borderRadius: '12px',
              border: `1px solid ${LightBlack} !important`,
              '&:hover': {
                background: WhiteGrey,
                border: `1px solid ${BorderBlack}`
              },
              '&:hover:before': {
                color: MainRed
              },
              '& fieldset': {
                border: 'none',
                height: 'inherit'
              },
              '&.Mui-focused': {
                border: `1px solid ${BorderBlack}`,
                boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
              }
            }
          }}
          variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={event => setPassword(event.target.value)}
            onBlur={event => sendPassword()}
            endAdornment={
              <InputAdornment position="end">
                <CustomTooltip
                  title={showPassword ? t('userSettings.tabs.passwordSettings.hidePassword') : t('userSettings.tabs.passwordSettings.showPassword')}
                  placement={'right'}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{
                      '&:hover': {
                        color: CrooglooGreen
                      }
                    }}
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </CustomTooltip>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </Grid>
    </>
  )
};

export default FormInputPasswordField;
