import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { BorderGrey } from '../../theme/colors';
import styles from '../../theme/sidebar.module.scss';
import { makeStyles } from '@mui/styles';
import type { Control } from 'react-hook-form';
import type { MessageForm, MessageFormTypes } from '../../models/Message';

// TODO: same css as for scroll on ComposeTo section & side bar - how to put this as a gloabal css
const useStyles = makeStyles({
  textArea: {
    width: '100%',
    border: `1px solid ${BorderGrey} !important`,
    borderRadius: '10px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
        height: 'inherit'
      }
    }
  }
});

interface Props {
  id: string
  name: MessageFormTypes
  formItemProps: { control: Control<MessageForm> }
  rows: number
  placeholder?: string
}

/**
 * Text Area Input Reusable Component
 */
const FormTextArea: FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <Controller
        name={props.name}
        {...props.formItemProps}
        render={({ field: { onChange, value } }) => (
          <TextField
            placeholder={props.placeholder}
            onChange={onChange} value={value}
            multiline
            rows={props.rows}
            inputProps={{
              className: styles.scrollbar
            }}
            classes={{
              root: classes.textArea
            }}
          />
        )}
      />
    </>
  )
}

export default FormTextArea;
