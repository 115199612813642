import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Grid, IconButton } from '@mui/material';
import type { AlertColor } from '@mui/material';
import styles from './Documents.module.scss';
import DocumentsTable from './DocumentsTable/DocumentsTable.lazy';
import DocumentButtons from './DocumentButtons/DocumentButtons.lazy';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import type { RootState } from '../../state/store';
import ViewService from '../../services/ViewService';
import NewFolder from '../modals/NewFolder/NewFolder.lazy';
import RemoveWatermark from '../modals/RemoveWatermark/RemoveWatermark.lazy';
import ESignature from '../modals/ESignature/ESignature.lazy';
import MoveDocument from '../modals/MoveDocument/MoveDocument.lazy';
import DeleteDocument from '../modals/DeleteDocument/DeleteDocument.lazy';
import ParseFile from '../DocumentViewer/ParseFile/ParseFile.lazy';
import ApplyWatermark from '../modals/ApplyWatermark/ApplyWatermark.lazy';
import RenameFile from '../modals/RenameFile/RenameFile.lazy';
import DocumentService from '../../services/documents/DocumentService';
import Toast from '../common/toast/Toast';
import DropboxAuthenticate from '../searchFiles/DropboxAuthenticate';
import { WindowWidth, WindowHeight, WindowLeft, WindowTop } from '../searchFiles/StepsModel';
import EditFileCategory from '../modals/EditFileCategory/EditFileCategory.lazy';
import AccessLevel from '../modals/AccessLevel/AccessLevel.lazy';
import MergeFiles from '../modals/MergeFiles/MergeFiles.lazy';
import { InitialToastValues } from '../../models/Toast';
import type { ToastProps, ToastIcons } from '../../models/Toast';
import FileViewerPopup from '../FileViewerPopup/FileViewerPopup.lazy';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import { GoogleRequestType } from '../../models/Google';
import BoxAuthenticate from '../BoxAuthenticate/BoxAuthenticate.lazy';
import type { DocumentCard } from '../../models/Document';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

const forwardPath: string = '>';

export enum DocumentsPage {
  Title = 'Documents'
}

export interface DocumentsProps {
  pageTitle: DocumentsPage
}

/**
 * Document Component used for displaying documents and folders from backend
 * Calls table component to display items & buttons component to handle the buttons for page
 * Contains components for modals here as they may be called in both the buttons or table
 */
const Documents: FC<DocumentsProps> = ({ pageTitle }: DocumentsProps) => {
  const dispatch = useAppDispatch();

  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const documentsPath: string[] = useAppSelector((state: RootState) => state.documents.documentsPath);
  const documentParams: string[] = useAppSelector((state: RootState) => state.documents.documentParams);
  const currentDocuments: DocumentCard[] = useAppSelector((state: RootState) => state.documents.documentCards);

  const [isFetching, setFetching] = useState<boolean>(true);
  const [dropboxPopup, setDropboxPopup] = useState<any>(null);
  const [boxPopup, setBoxPopup] = useState<any>(null);
  const [search, setSearch] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);
  const [documentsToShow, setDocumentsToShow] = useState<DocumentCard[]>([]);
  const [originalLoad, setOriginalLoad] = useState<boolean>(true);

  const fetchDocuments = (documentParams: string[]): void => {
    setFetching(true);
    ViewService.fetchCardViewDocuments(documentParams);
  }

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    fetchDocuments([...documentParams]);
    setOriginalLoad(false);
  }, [pageTitle]);

  useEffect(() => {
    if (!originalLoad) {
      setDocumentsToShow(currentDocuments);
    }
  }, [currentDocuments]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleBack = (event: React.SyntheticEvent, index: number): void => {
    event.stopPropagation();
    setFetching(true);
    ViewService.goBackFilePath(index);
  }

  const handleShowToast = (content: ToastProps): void => {
    let message: string = t(content.message);
    if (content.content) {
      const messageVariables: Record<string, string> = content.content;
      Object.keys(messageVariables).forEach((key: string) => {
        message = message.replace(`{{${key}}}`, messageVariables[key]);
      })
    }
    const status: ToastProps = {
      ...content,
      message
    };
    setToastStatus(status);
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleSuccess = (message: string) => {
    handleShowToast({
      message,
      type: 'success',
      isShown: true
    });
  }

  const handleGoogleMessage = (message: string, type: AlertColor, icon: ToastIcons) => {
    handleShowToast({
      message,
      type,
      icon,
      isShown: true
    });
  }

  const handleError = (message: string) => {
    handleShowToast({
      message,
      type: 'error',
      isShown: true
    });
  };

  const showDropboxPopup = (): void => {
    const popup: any = window.open('/#/dropbox', '', `width=${WindowWidth},height=${WindowHeight},left=${WindowLeft},top=${WindowTop}`);
    setDropboxPopup(popup);
  }

  const closeDropboxPopup = (): void => {
    setDropboxPopup(null);
    DocumentService.handleSelectedFilesAndFolders([], []);
    fetchDocuments([]);
  }

  const showBoxPopup = (): void => {
    const popup: any = window.open('/#/box', '', `width=${WindowWidth},height=${WindowHeight},left=${WindowLeft},top=${WindowTop}`);
    setBoxPopup(popup);
  }

  const closeBoxPopup = (): void => {
    setBoxPopup(null);
    DocumentService.handleSelectedFilesAndFolders([], []);
    fetchDocuments([]);
  }

  return (
    <div
      className={styles.Documents}
      data-testid="Documents"
    >
      <Grid container item className={styles.backButtonRow}>
        {(documentsPath.length > 0) && <Grid container item md={10} xs={12} className={styles.backButtonContainer}>
          <>
          {
            documentsPath.map((path: string, index: number) => {
              const linkButton: boolean = index < documentsPath.length - 1;
              return (
                <div key={`back-button-${index}`}>
                  {path !== '' && <>
                    <IconButton
                      aria-label='close'
                      className={styles.backButton}
                      disableRipple
                      onClick={(event: React.SyntheticEvent) => handleBack(event, index)}
                    >
                      <div
                        className={(linkButton) ? styles.backButtonHover : ''}
                      >
                        {path}
                      </div>
                    </IconButton>
                    {(linkButton) &&
                      <IconButton
                        aria-label='close'
                        className={styles.backButton}
                        disableRipple
                      >
                        {forwardPath}
                      </IconButton>
                    }
                  </>
                  }
                </div>
              )
            })
          }
          </>
        </Grid>
        }
      </Grid>
      <Grid item className={styles.documentButtons}>
        <DocumentButtons
          handleShowToast={handleShowToast}
          showDropboxPopup={showDropboxPopup}
          handleSearch={handleSearch}
          showBoxPopup={showBoxPopup}
        />
      </Grid>
      <Grid item className={styles.documentTable}>
        <DocumentsTable
          isFetching={isFetching}
          setFetching={setFetching}
          handleShowToast={handleShowToast}
          search={search}
          showDropboxPopup={showDropboxPopup}
          showBoxPopup={showBoxPopup}
          currentDocuments={documentsToShow}
        />
      </Grid>
      <NewFolder />
      <RemoveWatermark />
      <ESignature />
      <DeleteDocument />
      <MoveDocument />
      <ParseFile onSuccess={handleSuccess} onError={handleError} />
      <ApplyWatermark onSuccess={handleSuccess} onError={handleError} />
      <RenameFile />
      <EditFileCategory />
      <AccessLevel />
      <MergeFiles />
      <FileViewerPopup />
      <Toast open={toastStatus.isShown} onClose={handleCloseToast} type={toastStatus.type} title={toastStatus.message} icon={toastStatus.icon} />
      <DropboxAuthenticate dropboxPopup={dropboxPopup} closeDropboxPopup={closeDropboxPopup} initiateDropbox={false} />
      <GoogleLogin handleShowToast={handleGoogleMessage} requestType={GoogleRequestType.Files}><></></GoogleLogin>
      <BoxAuthenticate boxPopup={boxPopup} closeBoxPopup={closeBoxPopup} />
    </div>
  )
};

export default Documents;
