import type { FC } from 'react';
import { Grid, Box, Stepper, Step, StepLabel } from '@mui/material';
import type { StepModel } from './StepsModel';
import steps from './StepsModel';
import TemplateOptions from './steps/TemplateOptions';
import AttachmentOptions from './steps/AttachmentOptions';
import TemplateNames from './steps/TemplateName';
import type { TemplateForm, AttachmentItem } from './SaveTemplateFlow';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { LightBlack, ErrorRed } from '../../theme/colors';
import type { Control, UseFormSetValue } from 'react-hook-form';
import { stepperStyle } from '../../theme/styles';

export const MinAttachmentNumber: number = 1;
export const MaxAttachmentNumber: number = 30;

const getCurrentStep = (currentStepIndex: number): StepModel => steps[currentStepIndex] ?? steps[0];

interface Props {
  currentStepIndex: number
  control: Control<TemplateForm>
  setValue: UseFormSetValue<TemplateForm>
  selectedItems: AttachmentItem[]
  handleSelectedItems: (selectedItems: AttachmentItem[]) => void
  errorValue: string
}

/**
 * Save Template Steps Component for the save template flow
 * Contains a material ui stepper component with the step icons
 * shows the component for each step and other information (title & message)
 */
const saveTemplateSteps: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  // gets the current step to be shown in the component
  const currentStep: StepModel = getCurrentStep(props.currentStepIndex);

  return (
    <>
      <Grid item>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={props.currentStepIndex} alternativeLabel>
            {steps.map((step: StepModel, i: number) => {
              return (
                <Step
                  key={i}
                  sx={{
                    ...stepperStyle
                  }}
                >
                  <StepLabel/>
                </Step>
              )
            })}
          </Stepper>
        </Box>
      </Grid>
      <Grid item>
        <Box
          component={'h2'}
          sx={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '1.5rem',
            lineHeight: '29px'
          }}
        >
            {t(currentStep.title)}
        </Box>
      </Grid>
      <Grid item>
        <Box
          component={'span'}
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '150%',
            letterSpacing: '0.15px',
            color: LightBlack
          }}
        >
          {t(currentStep.message)}
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          padding: '10px 0px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
          {
            (props.currentStepIndex === 0) &&
            <TemplateOptions
              key={'step-0'}
              control={props.control}
              setValue={props.setValue}
            />
          }
          {
            (props.currentStepIndex === 1) &&
            <AttachmentOptions
              selectedItems={props.selectedItems}
              handleSelectedItems={props.handleSelectedItems}
            />
          }
          {
            (props.currentStepIndex === 2) &&
            <TemplateNames
              key={'step-2'}
              control={props.control}
            />
          }
          {
            (!!props.errorValue) &&
            <Box
              component={'span'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: ErrorRed
              }}
            >
              {t(props.errorValue, { min: MinAttachmentNumber, max: MaxAttachmentNumber })}
            </Box>
          }
      </Grid>
    </>
  )
}

export default saveTemplateSteps;
