import React, { lazy, Suspense } from 'react';

const LazyActivateSubscriptionCancel = lazy(async () => await import('./ActivateSubscriptionCancel'));

const ActivateSubscriptionCancel = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyActivateSubscriptionCancel {...props} />
  </Suspense>
);

export default ActivateSubscriptionCancel;
