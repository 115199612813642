export const MainBlack = '#000000';
export const LightBlack = 'rgba(0, 0, 0, 0.54)';
export const BlackGrey = 'rgba(0, 0, 0, 0.64)';
export const BorderGrey = 'rgba(108, 109, 107, 0.34)';
export const BorderBlack = '#222222';
export const TempBlack = '#1A2027';
export const Backdrop = 'rgba(88, 88, 88, 0.08)';
export const ChipColor = '#444444';
export const Silver = '#CCCCCC';
export const MainWhite = '#FFFFFF';
export const WhiteGrey = '#F2F0F0';
export const LightGrey = '#CDCDCD';
export const WhiteGreen = '#DFF7DF';
export const CrooglooGreen = '#01AD25';
export const DarkerCroolooGreen = '#008D1E';
export const LimeGreen = '#24C250';
export const LightGreen = '#DFF7DF';
export const ToastPink = '#FD0055';
export const MainRed = '#FF0000';
export const ToastHover = '#E3024D';
export const ErrorRed = '#F04937';
export const Blue = '#0061FD';
export const TertiaryGrey = 'rgba(68, 68, 68, 1)';
export const MenuHover = '#F6DAC4';
export const SideBarColor = '#FFF7F1';
