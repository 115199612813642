import React, { lazy, Suspense } from 'react';
import type { AddToDistributionProps } from './AddToDistribution';

const LazyAddToDistribution = lazy(async () => await import('./AddToDistribution'));

const AddToDistribution = (props: AddToDistributionProps) => (
  <Suspense fallback={null}>
    <LazyAddToDistribution {...props} />
  </Suspense>
);

export default AddToDistribution;
