import { type FC } from 'react';
import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Grid';

// Colors of divider, can add more or change them dynamically
const colorList = [
  '#222222',
  '#0B24F8',
  '#F04937',
  '#DD75F2',
  '#01AA25',
  '#4DF1F1',
  '#EACE00'
];

const StyledDividerItem = styled(Grid)<{
  color: string
}>(({ color }) => ({
  backgroundColor: color
}));

// This component is the divider between the side bar and the main area.
// It maps over each color in the colorList and creates a grid item with
const SideBarDivider: FC = () => {
  return (
    <Grid container direction={'column'} columns={7} spacing={0} sx={{ width: '8px' }}>
      {colorList.map((color) => (
        <StyledDividerItem item key={color} xs={1} color={color}/>
      ))}
    </Grid>
  )
}

export default SideBarDivider;
