import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store'; // provide the redux store to the application
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';
import { LicenseInfo } from '@mui/x-license-pro';

// Set your Material-UI license key
LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY ?? '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID ?? ''}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
