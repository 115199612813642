import React, { type FC, useState } from 'react';
import styles from './ManagePermissions.module.scss';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { CrooglooGreen } from '../../theme/colors';
import type { AccessPermissionsForm, SecurityGroupForm } from '../../models/SecurityGroups';
import type { OptionProps } from '../../models/OptionProps';
import {
  type AlertColor,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material';
import SuccessButton from '../common/buttons/SuccessButton';
import FormInputSelect from '../forms/FormInputSelect';
import Toast from '../common/toast/Toast';
import ManagePermissionsService from '../../services/ManagePermissionsService';
import PermissionService, { AccessLevel } from '../../services/auth/PermissionService';
import { ToastIcons } from '../../models/Toast';
import { scrollStyles, headerHeight, tabHeaderHeight } from '../../theme/styles';
import progressStyles from '../../theme/loadingProgress.module.scss';
import { DHDisplayName, SecurityListIds } from '../../models/SecurityList';
import type { Control } from 'react-hook-form';

const unauthorizedError: string = 'Error: Unauthorized';

export interface ManagePermissionsProps {
  control: Control<SecurityGroupForm>
  groupID: string
  groupsAccessPermissions: any[]
}

// Temporary
const drawMatrix: boolean = true;

const ManagePermissions: FC<ManagePermissionsProps> = ({ control, groupID, groupsAccessPermissions }) => {
  const i18nPrefix = 'managePermissions.';
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>(String(t(`${i18nPrefix}allSaved`)));
  const [toastIcon] = useState<ToastIcons>(ToastIcons.Success);
  const [toastStatus, setToastStatus] = useState<AlertColor>('success');

  let permissionsPayload: AccessPermissionsForm[] = [];

  // Translation prefix for the production settings tab

  const handleShowToast = (): void => {
    setShowToast(!showToast);
  }

  /**
   * function for handling permissions
  */
  const handlePermissionsNew = (value: string, fieldName: string): void => {
    const data = fieldName.split('$$');
    const field = data[0];
    const groupId = data[1];

    const initialValue: number = control._formValues[fieldName] ?? 0;
    const permission: AccessPermissionsForm = {
      groupId,
      menuName: field,
      initialValue,
      newValue: parseInt(value)
    };
    permissionsPayload.push(permission);
  }

  const savePermissions = (): void => {
    setIsLoading(true);
    ManagePermissionsService.saveSecurityGroups(permissionsPayload)
      .then((response: string) => {
        if (response.toString().indexOf(unauthorizedError) > 0) {
          setToastStatus('error');
          setToastMessage('Unauthorized');
          handleShowToast();
        } else {
          setToastStatus('success');
          setToastMessage(String(t(`${i18nPrefix}allSaved`)));
          handleShowToast();
        }

        permissionsPayload = [];
        setIsLoading(false);
      }).catch((err) => {
        console.debug(err);
        permissionsPayload = [];
        setIsLoading(false);
      });
  }

  const handleDisplayName = (name: string): string => {
    return (name === SecurityListIds.DH) ? DHDisplayName : name;
  }

  // Outbox delays options - to replace with real options from datastore
  const outboxDelayOptions: OptionProps[] = [
    {
      key: '2',
      value: '2',
      label: `${i18nPrefix}permissionOptions.rw`
    },
    {
      key: '1',
      value: '1',
      label: `${i18nPrefix}permissionOptions.ro`
    },
    {
      key: '0',
      value: '0',
      label: `${i18nPrefix}permissionOptions.no`
    }
  ];

  return (
    <>
    { groupsAccessPermissions.length === 0
      ? <CircularProgress className={progressStyles.circularProgress} />
      : <div className={styles.ManagePermissions} data-testid="ManagePermissions">
        {PermissionService.hasPermission(AccessLevel.READ_WRITE) &&
            <Grid container direction={'row'} justifyContent={'flex-end'} alignItems={'flex-start'} spacing={2} my={1}>
                <Grid item px={3}>
                  {isLoading
                    ? <div style={{ color: CrooglooGreen }}>{t(`${i18nPrefix}savingPermissions`)}</div>
                    : <SuccessButton onClick={savePermissions} style={{ width: '150px', left: '25px' }}>
                      {t('action.save')}
                    </SuccessButton>}
                </Grid>
            </Grid>
        }

        <Toast open={showToast} icon={toastIcon} onClose={handleShowToast} type={toastStatus}
               title={toastMessage}/>

        {drawMatrix &&
            <Paper>
              <TableContainer
                sx={{
                  maxHeight: `calc(100vh - ${headerHeight}px - ${tabHeaderHeight}px - 100px)`,
                  ...scrollStyles
                }}
              >
                <Table className={styles.table} aria-label="simple table"
                       sx={{ minWidth: 750 }}
                       aria-labelledby="permissionMatrix"
                       size={'medium'}
                       stickyHeader
                >
                    <TableHead>
                        <TableRow className={styles.tableHeader}>
                            <TableCell></TableCell>
                          {groupsAccessPermissions.map(group =>
                            <TableCell
                              style={{ textAlign: 'center' }}
                              key={`cellHeader_${Object.keys(group)[0]}`}
                            >
                              <b>{handleDisplayName(Object.keys(group)[0])}</b>
                            </TableCell>
                          )
                          }

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}contacts`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_contacts`} >
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_crew$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}dailyTimeReports`))}</TableCell>

                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_reports`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_dtr$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>

                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}dashboard`))}</TableCell>

                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_dashboard`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                                 name={`menu_dashboard$$${Object.keys(group)[0]}`}
                                                 placeholder={String(t(`${i18nPrefix}viewer`))}
                                                 isLabelBold={true}
                                                 formItemProps={{
                                                   control
                                                 }}
                                                 width={'140px'}
                                                 onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                                 options={outboxDelayOptions.map((template: OptionProps) => ({
                                                   key: template.key,
                                                   label: t(template.label),
                                                   value: template.value
                                                 }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>

                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}distribution`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_distribution`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_distribution$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>

                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}documents`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_documents`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_documents$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}scripts`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_scripts`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_scripts$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}security`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_security`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_security$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.categoryTitle}>{String(t(`${i18nPrefix}templates`))}</TableCell>
                          {groupsAccessPermissions.map((group, index) =>
                            <TableCell key={`cell_${index}_templates`}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormInputSelect centered
                                  name={`menu_templates$$${Object.keys(group)[0]}`}
                                  placeholder={String(t(`${i18nPrefix}viewer`))}
                                  isLabelBold={true}
                                  formItemProps={{
                                    control
                                  }}
                                  width={'140px'}
                                  onItemClick={(value: string, fieldName: string) => handlePermissionsNew(value, fieldName)}
                                  options={outboxDelayOptions.map((template: OptionProps) => ({
                                    key: template.key,
                                    label: t(template.label),
                                    value: template.value
                                  }))}
                                />
                              </div>
                            </TableCell>
                          )
                          }
                        </TableRow>
                    </TableBody>
                </Table>
              </TableContainer>
            </Paper>
        }
      </div>
    }
    </>
  )
};

export default ManagePermissions;
