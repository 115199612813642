import React, { lazy, Suspense } from 'react';
import type { ScriptInformationProps } from './ScriptInformation';

const LazyScriptInformation = lazy(async () => await import('./ScriptInformation'));

const ScriptInformation = (props: ScriptInformationProps) => (
  <Suspense fallback={null}>
    <LazyScriptInformation {...props} />
  </Suspense>
);

export default ScriptInformation;
