import React, { lazy, Suspense } from 'react';
import type { FileDestinationProps } from './FileDestination';

const LazyFileDestination = lazy(async () => await import('./FileDestination'));

const FileDestination = (props: FileDestinationProps) => (
  <Suspense fallback={null}>
    <LazyFileDestination {...props} />
  </Suspense>
);

export default FileDestination;
