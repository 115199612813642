import React, { lazy, Suspense } from 'react';
import type { FileWatermarkProps } from './FileWatermark';

const LazyFileWatermark = lazy(async () => await import('./FileWatermark'));

const FileWatermark = (props: FileWatermarkProps) => (
  <Suspense fallback={null}>
    <LazyFileWatermark {...props} />
  </Suspense>
);

export default FileWatermark;
