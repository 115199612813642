import React, { lazy, Suspense } from 'react';

const LazyFileTooLarge = lazy(async () => await import('./FileTooLarge'));

const FileTooLarge = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyFileTooLarge {...props} />
  </Suspense>
);

export default FileTooLarge;
