import styled from '@mui/material/styles/styled';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '50px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    padding: '0 16px',
    lineHeight: '20px'
  }
}));

export default CustomTooltip;
