/**
 * Reducer file for the Person slice
 */

import { createSlice } from '@reduxjs/toolkit';
import type { DepartmentResponse, DepartmentName } from '../../models/Department';
import { PersonSlice } from './constants';

export interface PersonState {
  departments: DepartmentResponse[]
  departmentNames: DepartmentName[]
}

const initialState: PersonState = {
  departments: [],
  departmentNames: []
}

export const personSlice = createSlice({
  name: PersonSlice,
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.departments = action.payload.departments;
    },
    setDepartmentNames: (state, action) => {
      state.departmentNames = action.payload.departmentNames;
    }
  }
});

export const {
  setDepartments, setDepartmentNames
} = personSlice.actions;
export default personSlice.reducer;
