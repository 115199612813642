import type { ApiDataType } from './auth/constants';
import { ScriptApi } from './auth/constants';
import AuthService from './auth/AuthService';
import DocumentService from './documents/DocumentService';
import type { Document, FileAttachment } from '../models/Document';
import type {
  FetchAllScripts, FetchAllScriptsResponse, GenerateScripts, WatermarkData
} from '../models/Script';
import type {
  Character,
  Location,
  LocationMap,
  Scene,
  ScriptScene,
  ScriptSceneResponse,
  ScriptScenesResult
} from '../models/Scene';
import { LocationGroup } from '../models/Scene';
import type { WSResponse, WSCalenderResponse } from '../models/WSResponse';
import { store } from '../state/store';
import {
  setAllScripts, setCallSheets, setScriptScenes, setSelectionData, setSidesRequest, addCallSheetsToSelectionData
} from '../state/slices/Sides';
import type { SelectionData, SideRequest } from '../models/Side';
import { PostMethod } from './constants';
import type { OutputMode } from '../components/Watermark/config';
import { defaultOutputMode, FirstLine, maxNbOfSelectedFiles, SecondLine } from '../components/Watermark/config';
import type { DistributionListName } from '../models/DistributionList';
import type { Person } from '../models/Person';
import type { ChipDropdownData } from '../components/common/ChipsDropdown';
import WatermarkNotifications from './notifications/WatermarkNotifications';
import NotificationUtility from './notifications/NotificationUtility';
import DownloadNotifications from './notifications/DownloadNotifications';
import UtilityService from './UtilityService';
import NotificationService from './notifications/NotificationService';
import type { CalenderEntities } from '../models/CalenderEntity';
import moment from 'moment';

interface FileData {
  fileName: string
  episode: string
  color: string
  fileId: string
  description: string
  version: string
}

const { dispatch } = store;

interface IScriptService {
  getGenerateSidesMeta: () => Promise<void>
  fetchAllScripts: () => Promise<GenerateScripts[]>
  fetchScriptScenes: (script: GenerateScripts, index: number) => Promise<ScriptScenesResult>
  addSpaceToColor: (color: string) => string
  checkSideFormatOptions: (getValues: any) => string | null
  buildSidesRequest: (getValues: any, scriptsForSides: string[], fileIdsForSides: string[], allSelectedScenes: Scene[]) => void
  submitSidesRequest: () => Promise<WSResponse>
  addCallSheetToSidesRequest: (callSheetId: string, callSheetPages: string) => void
  createAndStoreSidesSync: (sidesRequest: SideRequest, selectionData: SelectionData) => Promise<WSResponse>
  parseScript: (data: FileData) => Promise<WSResponse>
  processWatermarkingJob: (
    uploadFiles: FileAttachment[],
    firstLine: FirstLine | undefined,
    firstLineLabel: string,
    secondLine: SecondLine | undefined,
    secondLineLabel: string,
    selectedLists: DistributionListName[],
    selectedContacts: Person[],
    extraContacts: ChipDropdownData[],
    outputMode: OutputMode,
    opacity: number,
    size: number,
    orientation: number
  ) => Promise<void>
  processWatermarkPrinter: (watermarkData: any) => Promise<WSResponse>
  fetchWatermarkPrinterStatus: (taskId: string) => Promise<WSResponse>
  getScriptDisplayName: (episode: string, color: string) => string
  getCalendarEntities: (calenderEntity: CalenderEntities) => Promise<WSCalenderResponse>
}

const MaxLatestVersions: number = 5;
const Pattern = /(^\s*[^a-zA-Z\d]*\s*)|(\s*[^a-zA-Z\d]*\s*)$/;
const TrimPattern = new RegExp(Pattern, 'g');
const IntExtDotExt = /\./;
const IntExtWhiteSpaceExt = /(\s+)|(\s*$)/;
const DayNightExt = /\(|,|--|DAY|NIGHT/i;
const DayNightWhiteSpaceExt = /\s*(\(|,|--|DAY|NIGHT)\s*/i;
const SceneWhiteSpaceExt = /(\s*-\s*)|(\s*$)/;
const ColorMatchRegex = /[a-z][A-Z][a-z]+/g;
const ColorSplitRegex = /(?=[A-Z])/;
const ColorDashRegex = /^0\s-\s$/;

const locationGroups: LocationGroup[] = [LocationGroup.INT, LocationGroup.EXT, LocationGroup.OTHER];

class ScriptService implements IScriptService {
  async getGenerateSidesMeta (): Promise<void> {
    const callSheets: Document[] = await DocumentService.fetchCallSheets();
    dispatch(setCallSheets({ callSheets }));
    const scripts: GenerateScripts[] = await this.fetchAllScripts();
    let latestScripts: GenerateScripts[] = [];
    const otherScripts: GenerateScripts[] = [];
    if (scripts.length <= MaxLatestVersions) {
      latestScripts = [...scripts];
    } else {
      scripts.forEach((script: GenerateScripts) => {
        const scriptEpisode: string = script.episode;
        if (latestScripts.length < MaxLatestVersions) {
          if (episodic(scriptEpisode)) {
            const latestScriptEpisodes: string[] = latestScripts.map((latestScript: GenerateScripts) => latestScript.episode);
            if (latestScriptEpisodes.includes(scriptEpisode)) {
              otherScripts.push(script);
            } else {
              latestScripts.push(script);
            }
          } else {
            latestScripts.push(script);
          }
        } else {
          otherScripts.push(script);
        }
      })
    }
    dispatch(setAllScripts({ latest: latestScripts, other: otherScripts }));
  }

  async fetchAllScripts (): Promise<GenerateScripts[]> {
    return await makeScriptCall('fetchAllScripts', {})
      .then((result: FetchAllScriptsResponse) => {
        const items: FetchAllScripts[] = result.items;
        const scripts: GenerateScripts[] = items.map((item: FetchAllScripts) => ({
          id: item[5],
          color: item[1],
          episode: item[0],
          fileName: item[2],
          timeCreated: item[3],
          isWatermarked: item[4],
          scenes: [],
          characters: [],
          locations: {
            [LocationGroup.INT]: [],
            [LocationGroup.EXT]: [],
            [LocationGroup.OTHER]: []
          }
        }));
        return scripts;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  async fetchScriptScenes (script: GenerateScripts, index: number): Promise<ScriptScenesResult> {
    if (script.scenes?.length) {
      const intLocations: Location[] = script.locations?.INT ?? [];
      const extLocations: Location[] = script.locations?.EXT ?? [];
      const otherLocations: Location[] = script.locations?.OTHER ?? [];
      return {
        scenes: script.scenes,
        characters: script.characters ?? [],
        locations: {
          [LocationGroup.INT]: intLocations,
          [LocationGroup.EXT]: extLocations,
          [LocationGroup.OTHER]: otherLocations
        }
      };
    }
    return await makeScriptCall('fetchScriptScenes', {
      params: {
        script: script.fileName,
        episode: script.episode,
        color: script.color,
        fileId: script.id ?? '-'
      }
    })
      .then((resp: ScriptSceneResponse) => {
        // set the scenes, characters and locations for the selected script
        const scenes: Scene[] = [];
        let characters: Character[] = [];
        const locations: LocationMap = {
          [LocationGroup.INT]: [],
          [LocationGroup.EXT]: [],
          [LocationGroup.OTHER]: []
        };
        const scriptColor = (script.episode + ' - ').replace(ColorDashRegex, '') + script.color;
        if (resp.items) {
          const items: ScriptScene[] = resp.items;
          items.forEach((item: string[]) => {
            const sceneDesc: string = item[0];
            const sceneNumber: string = sceneDesc.split(',')[0].trim();
            const sceneCharacter: string = item[1];
            const charactersInScene: string[] = [];
            const sceneId: string = sceneNumber + '_' + script.id;
            sceneCharacter.split(',').forEach((name: string) => {
              if (name.trim()) {
                let character: Character | undefined = characters.find((character: Character) => character.name === name);
                const newCharacters: Character[] = characters.filter((character: Character) => character.name !== name);
                if (character) {
                  character.scenes = [...character.scenes, sceneId];
                } else {
                  character = {
                    name,
                    scenes: [sceneId]
                  };
                }
                newCharacters.push(character);
                characters = [...newCharacters];
                charactersInScene.push(character.name);
              }
            });
            let truncScene: string = sceneDesc.substring(sceneNumber.length + 1).replace(TrimPattern, '');
            try {
              const intExt: string = truncScene.split(truncScene.includes('.') ? IntExtDotExt : IntExtWhiteSpaceExt)[0].replace(TrimPattern, '');
              truncScene = truncScene.substring(intExt.length).replace(TrimPattern, '');
              const location: string = truncScene.split(truncScene.search(DayNightExt) >= 0
                ? (DayNightWhiteSpaceExt)
                : SceneWhiteSpaceExt)[0].replace(TrimPattern, '');
              if (sceneNumber && intExt && location) {
                const locationGroup: LocationGroup = (intExt === LocationGroup.INT || intExt === LocationGroup.EXT) ? intExt : LocationGroup.OTHER;
                const locationMap: Location[] = locations[locationGroup];
                let currentLocation: Location | undefined = locationMap.find((l: Location) => l.name === location);
                const newLocationMap: Location[] = locationMap.filter((l: Location) => l.name !== location);
                if (currentLocation) {
                  currentLocation.scenes = [...currentLocation.scenes, sceneId];
                } else {
                  currentLocation = { name: location, scenes: [sceneId] };
                }
                newLocationMap.push(currentLocation);
                locations[locationGroup] = newLocationMap;
              }
            } catch (e) {
              console.warn('failed to find location for scene "' + sceneDesc + '"');
              console.debug(e);
            }
            const scene: Scene = {
              id: sceneId,
              sceneNumber,
              description: sceneDesc.split(',')[1],
              scriptColor,
              scriptId: script.id,
              characters: charactersInScene
            }
            scenes.push(scene);
          });
          characters.sort((a: Character, b: Character) => {
            return a.name > b.name ? 1 : -1;
          });
          locationGroups.forEach((locationGroup: LocationGroup) => {
            locations[locationGroup].sort((a: Location, b: Location) => {
              return a.name > b.name ? 1 : -1;
            })
          });
          if (scenes.length > 0) {
            dispatch(setScriptScenes({
              scenes,
              characters,
              locations,
              scriptId: script.id,
              isLatest: (index === 0)
            }));
          }
        }
        const result: ScriptScenesResult = {
          scenes,
          characters,
          locations
        };
        return result;
      })
      .catch((err) => {
        console.error(err);
        const result: ScriptScenesResult = {
          scenes: [],
          characters: [],
          locations: {
            [LocationGroup.INT]: [],
            [LocationGroup.EXT]: [],
            [LocationGroup.OTHER]: []
          }
        };
        return result;
      })
  }

  addSpaceToColor (color: string): string {
    if (color.match(ColorMatchRegex) != null) {
      const colors: string[] = color.split(ColorSplitRegex);
      return colors[0] + ' ' + colors[1].charAt(0).toLowerCase() + colors[1].substring(1, colors[1].length);
    }
    return color;
  }

  checkSideFormatOptions (getValues: any): string | null {
    let errorMessage = null;
    const name: string = getValues('name').trim();
    const upOptions: string[] = getValues('upOptions');
    const invalidChars: string[] = findUnsupportedChars(name);
    if (upOptions.length === 0) {
      errorMessage = 'generateSides.formatOptions.errors.format';
    } else if (!name) {
      errorMessage = 'generateSides.formatOptions.errors.fileName';
    } else if (invalidChars.length > 0) {
      errorMessage = 'generateSides.formatOptions.errors.characters';
    }
    return errorMessage;
  }

  buildSidesRequest (getValues: any, scriptsForSides: string[], fileIdsForSides: string[], allSelectedScenes: Scene[]): void {
    const upOptions: string[] = getValues('upOptions');
    const name: string = getValues('name').trim();
    let fileNames: string = '';
    let printOptions: string = '';
    if (upOptions.includes('1-UP')) {
      fileNames = name;
      printOptions = '1-UP';
    }
    if (upOptions.includes('2-UP')) {
      if (getValues('formatOption') === 0) {
        fileNames += (fileNames === '' ? '' : ',') + name + ' (2-UP)';
        printOptions += (printOptions === '' ? '' : ',') + '2-UP';
      } else {
        fileNames += (fileNames === '' ? '' : ',') + name + ' (S2-UP)';
        printOptions += (printOptions === '' ? '' : ',') + 'S2-UP';
      }
    }

    const selectionData: SelectionData = {
      scenes: allSelectedScenes.map((scene: Scene) => scene.sceneNumber).join(','),
      scripts: scriptsForSides.join(','),
      filesId: fileIdsForSides.join(',')
    }

    const tmp: SideRequest = {
      isRevisions: false,
      printOptions,
      fileNames,
      unselScenesRemovalMode: getValues('unselectScOpt'),
      withContinuityArrows: getValues('addArrow'),
      withCircledScenes: getValues('circleSceneNumber'),
      contArrowsColor: getValues('contArrowColor'),
      sceneCirclesColor: getValues('circleScColor')
    };

    dispatch(setSidesRequest({ tmp }));
    dispatch(setSelectionData({ data: selectionData }));
  }

  async submitSidesRequest (): Promise<WSResponse> {
    const sidesRequest: SideRequest = store.getState().sides.sidesRequest;
    const selectionData: SelectionData = store.getState().sides.selectionData;
    return await this.createAndStoreSidesSync(sidesRequest, selectionData)
      .then((resp: WSResponse) => {
        if (UtilityService.isSuccessResponse(resp.responseCode)) {
          if (resp.responseMessage) {
            const responseMessage: string[] = resp.responseMessage.split(',');
            const referenceId: string = responseMessage[0];
            DownloadNotifications.add(
              false, true, referenceId, responseMessage[1] ?? null, moment().valueOf()
            );
            // don't show the browser download for gen sides anymore
            void NotificationService.updateBrowserNotificationStatus(referenceId, true);
          }
        }
        return resp;
      })
      .catch((err) => {
        console.error(err);
        return { responseCode: '-1' };
      })
  }

  addCallSheetToSidesRequest (callSheetId: string, callSheetPages: string): void {
    dispatch(addCallSheetsToSelectionData({
      callSheetId,
      callSheetPages
    }));
  }

  async createAndStoreSidesSync (sidesRequest: SideRequest, selectionData: SelectionData): Promise<WSResponse> {
    return await makeScriptCall('createAndStoreSidesSync', {
      params: {
        isRevisions: String(sidesRequest.isRevisions),
        printOptions: sidesRequest.printOptions,
        fileNames: sidesRequest.fileNames,
        unselScenesRemovalMode: String(sidesRequest.unselScenesRemovalMode),
        withContinuityArrows: String(sidesRequest.withContinuityArrows),
        withCircledScenes: String(sidesRequest.withCircledScenes),
        contArrowsColor: sidesRequest.contArrowsColor,
        sceneCirclesColor: sidesRequest.sceneCirclesColor
      },
      body: JSON.stringify(selectionData),
      type: PostMethod
    });
  }

  async parseScript (data: FileData): Promise<WSResponse> {
    return await makeScriptCall('parseScript', {
      params: {
        scriptName: data.fileName,
        episode: data.episode,
        color: data.color,
        fileId: data.fileId,
        description: data.description,
        version: data.version
      },
      type: PostMethod
    });
  }

  async processWatermarkingJob (
    uploadFiles: FileAttachment[],
    firstLine: FirstLine | undefined,
    firstLineLabel: string,
    secondLine: SecondLine | undefined,
    secondLineLabel: string,
    selectedLists: DistributionListName[],
    selectedContacts: Person[],
    extraContacts: ChipDropdownData[],
    outputMode: OutputMode,
    opacity: number,
    size: number,
    orientation: number
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      if (uploadFiles.length === 0) {
        reject(new Error('watermark.submitPopup.error.empty'));
        return;
      }

      if (uploadFiles.length > maxNbOfSelectedFiles) {
        reject(new Error('watermark.submitPopup.error.max'));
        return;
      }

      if (!firstLine || !secondLine) {
        reject(new Error('watermark.submitPopup.error.selection'));
        return;
      }

      if (firstLine === FirstLine.Label && !firstLineLabel) {
        reject(new Error('watermark.submitPopup.error.specifyFirst'));
        return;
      }

      if (secondLine === SecondLine.Label && !secondLineLabel) {
        reject(new Error('watermark.submitPopup.error.specifySecond'));
        return;
      }

      if (selectedLists.length === 0 && selectedContacts.length === 0 &&
        extraContacts.length === 0 && isRecipientNeeded(firstLine, secondLine)
      ) {
        reject(new Error('watermark.submitPopup.error.noContact'));
        return;
      }

      const ConstantString = '~';
      const selectedListsString: string = (selectedLists.length > 0) ? selectedLists.map((list: DistributionListName) => list.id).join(',') : ConstantString;
      const files: string = uploadFiles.map((file: FileAttachment) => file.id).join(',');
      let extraPersons: string = ConstantString;
      if (selectedContacts.length > 0) {
        extraPersons = selectedContacts.map((contact: Person) => contact.id).join(',');
      }
      let extraNames: string = ConstantString;
      if (extraContacts.length > 0) {
        extraNames = extraContacts.map((contact: ChipDropdownData) => contact.id).join(',');
      } else if (!isRecipientNeeded(firstLine, secondLine) && extraContacts.length === 0) {
        extraNames = 'default';
      }

      let isBulk: string = 'true';
      if (outputMode !== defaultOutputMode) {
        isBulk = 'false';
      }
      let fistLabel: string = ConstantString;
      if (firstLine === FirstLine.Label) {
        fistLabel = firstLineLabel;
      }
      let secondLabel: string = ConstantString;
      if (secondLine === SecondLine.Label) {
        secondLabel = secondLineLabel;
      }
      const watermarkData: WatermarkData = {
        distributionListId: selectedListsString,
        fileId: files,
        extraNames,
        extraPersonsId: extraPersons,
        firstLine: fistLabel,
        secondLine: secondLabel,
        firstLineType: firstLine,
        secondLineType: secondLine,
        isPasswordProtected: 'false',
        wmPassword: ConstantString,
        isBulk,
        rotation: String(orientation),
        opacity: String(opacity / 100),
        textWidth: String(size / 100)
      };

      this.processWatermarkPrinter(watermarkData)
        .then((resp: WSResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            if (resp.responseMessage && resp.contentName) {
              const fileName: string = (files.includes(',')) ? resp.contentName.substring(3) : resp.contentName;
              NotificationUtility.addCounter();
              WatermarkNotifications.add(false, resp.responseMessage, fileName, moment().valueOf())
            }
            resolve();
          } else {
            reject(new Error('watermark.submitPopup.error.failure'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('watermark.submitPopup.error.failure'));
        })
    });
  }

  async processWatermarkPrinter (watermarkData: WatermarkData): Promise<WSResponse> {
    return await makeScriptCall('processWatermarkPrinter', {
      params: {
        ...watermarkData
      },
      type: PostMethod
    });
  }

  async fetchWatermarkPrinterStatus (taskId: string): Promise<WSResponse> {
    return await makeScriptCall('fetchWatermarkPrinterStatus', {
      params: {
        taskId
      }
    });
  }

  getScriptDisplayName (episode: string, color: string): string {
    return (episode !== '0' ? episode + ' - ' : '') + this.addSpaceToColor(color);
  }

  async getCalendarEntities (calenderEntity: CalenderEntities): Promise<WSCalenderResponse> {
    return await makeScriptCall('getCalendarEntities', {
      params: {
        kind: String(calenderEntity)
      }
    })
  }
}

function findUnsupportedChars (docName: string): string[] {
  const invalidChars = ['<', '>', ':', '\\', '"', '/', '|', '?', '!', '%', '~', ',', '*', '#', '$'];
  const invalidCharsFound: string[] = [];
  for (let i = 0; i < docName.length; i++) {
    const car: string = docName.charAt(i);
    if (invalidChars.includes(car)) {
      invalidCharsFound.push(car);
    }
  }
  return invalidCharsFound;
}

// The script has an episode value
function episodic (episodeNumber: string) {
  return (episodeNumber !== '' && episodeNumber !== '0');
}

function isRecipientNeeded (lineOneType: FirstLine, lineTwoType: SecondLine): boolean {
  return !(lineOneType === FirstLine.Label &&
    [SecondLine.Label, SecondLine.Blank, SecondLine.IpAddress, SecondLine.Date].includes(lineTwoType)
  );
}

const makeScriptCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(ScriptApi, method, data);
  return result;
}

export const scriptService: IScriptService = new ScriptService();
export default scriptService;
