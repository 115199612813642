export interface Scene {
  id: string
  sceneNumber: string
  description: string
  scriptColor?: string
  scriptId?: string
  characters: string[]
}

export interface Character {
  name: string
  scenes: string[]
}

export interface Location {
  name: string
  scenes: string[]
}

export enum LocationGroup {
  INT = 'INT',
  EXT = 'EXT',
  OTHER = 'OTHER'
}

export type LocationMap = Record<LocationGroup, Location[]>

export type ScriptScene = string[];

export interface ScriptSceneResponse {
  items: ScriptScene[]
}

export interface ScriptScenesResult {
  scenes: Scene[]
  characters: Character[]
  locations: LocationMap
}
