import React, { lazy, Suspense } from 'react';

const LazyRenameExistingFile = lazy(async () => await import('./RenameExistingFile'));

const RenameExistingFile = () => (
  <Suspense fallback={null}>
    <LazyRenameExistingFile />
  </Suspense>
);

export default RenameExistingFile;
