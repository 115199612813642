import { store } from '../../state/store';
import { gapi } from 'gapi-script';
import type { AxiosProgressEvent } from 'axios';
import axios from 'axios';
import type { ApiDataType, AuthenticatedDataType, AuthUser, LoginData, PdfLinkResponse } from './constants';
import { ApiVersions, BoxFoldersApi, DefaultVersion } from './constants';
import {
  decrementAPICalls,
  incrementAPICalls,
  login,
  setBoxTokens,
  setSubscriptionInfo,
  setTempLoginAuth
} from '../../state/slices/AuthReducer';
import { GetMethod, PostMethod, PutMethod } from '../constants';
import UtilityService from '../UtilityService';
import SecurityAdmin from '../SecurityAdmin';
import type { SubscriptionInfo } from '../../models/SubscriptionInfo';
import type { Currencies, Subscription } from '../../models/Subscription';
import type { SwitchProductionResponse } from '../../models/Production';
import { TFAuthenticationLoginModal } from '../../components/modals/TFAuthenticationLogin/constants';
import ModalService from '../ModalService';
import type { BoxEntry } from '../../models/Box';
import { CrooglooFileTypes } from '../../models/FileTypes';
import DocumentTreeService from '../documents/DocumentTreeService';

const { dispatch } = store;

// TODO: Put these in an .env file or local storage
const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPES = process.env.REACT_APP_SCOPES;
const DISCOVERY_DOCS = [
  process.env.REACT_APP_DISCOVERY_DOC_DRIVE ?? '',
  process.env.REACT_APP_DISCOVERY_DOC_CALENDAR ?? ''
];

const endpoint = process.env.REACT_APP_ENDPOINT ?? 'https://build-dev-036e5ac-dot-dev-dot-movieprodmanager.appspot.com';
const devEndpoint = process.env.REACT_APP_DEV_ENDPOINT ?? 'https://build-dev-036e5ac-dot-dev-dot-movieprodmanager.appspot.com';
const BoxClientId: string = process.env.REACT_APP_BOX_CLIENT_ID ?? '';
const BoxClientSecret: string = process.env.REACT_APP_BOX_CLIENT_SECRET ?? '';

let ROOT: string = '';

interface IAuthService {
  init: (cb: () => void) => void
  login: (data: LoginData) => Promise<any>
  logout: (isInactivityTimeout?: boolean) => Promise<any>
  googleLogin: (jwtToken: string) => Promise<any>
  passwordRecovery: (email: string) => Promise<any>
  authentication: () => Promise<any>
  sms2FAuthentication: (code: string) => Promise<void>
  checkToken: () => boolean
  apiCall: (api: string, method: string, data: ApiDataType) => Promise<any>
  apiPost: (api: string, method: string, data: string) => Promise<any>
  authenticatedCall: (url: string, data: AuthenticatedDataType, progress?: (progress: number) => void) => Promise<any>
  getAPIVersion: (api: string) => string
  fetchBoxFiles: (path: string) => Promise<BoxEntry[]>
  fetchBoxEntry: (entry: any) => Promise<any>
  setStorageAuth: (data: AuthUser) => void
  setSubscriptionInfo: () => void
  fetchStoreProducts: (currency: Currencies, planId: string) => Promise<Subscription[]>
  switchProduction: (newCommunity: string) => Promise<SwitchProductionResponse>
  fetchPdfFromLink: (linkId: string) => Promise<PdfLinkResponse>
  setGapiToken: (accessToken: string) => void
  fetchBoxToken: (code: string, refresh: boolean) => Promise<void>
  setBoxAccessTokens: (crooglooAuth: AuthUser, token: string, refreshToken: string, expires: number) => void
}

class AuthService implements IAuthService {
  // This function loads the gapi client and sets the access_token
  init (callback: () => void): void {
    // get the auth redux states from the store
    const authState = store.getState().auth;
    const accessToken: string = (authState.accessToken !== '') ? authState.accessToken : '';
    const endPoint: string = (authState.urlEndpoint !== '') ? authState.urlEndpoint : '';

    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
        clientId: CLIENT_ID,
        scope: SCOPES
      }).then(() => {
        this.setGapiToken(accessToken);
        ROOT = endPoint + '_ah/api/';
        console.debug('Gapi Client Loaded');
        callback();
      }).catch((err) => {
        console.error('Caught error', err);
      })
    });
  }

  async login (data = {}): Promise<any> {
    const response = await fetch(endpoint + '/LoginServiceJSON', {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response) {
      const res = await response.json();
      if (res) {
        if (res.status === '0') {
          const crooglooAuth: AuthUser = res.crooglooAuth;
          if (crooglooAuth?.sms2fa === '1') {
            dispatch(setTempLoginAuth({ crooglooAuth }));
            const mobile: string = (crooglooAuth.mobile) ? `(${String(crooglooAuth.mobile)})` : '';
            ModalService.openCustomModal(TFAuthenticationLoginModal, {
              heading: 'login.twoFactorAuth.heading',
              content: 'login.twoFactorAuth.content',
              contentVariable: { '{{number}}': mobile },
              confirmButton: 'action.ok'
            })
          } else {
            this.setStorageAuth(crooglooAuth);
            window.location.replace('/');
          }
        } else {
          throw Error('Login failed');
        }
      }
    }
    return response;
  }

  /*
  async login (data = {}): Promise<any> {
    return await fetch(endpoint + '/LoginServiceBeta', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(data)
    });
  }
*/
  async logout (isInactivityTimeout: boolean | undefined): Promise<any> {
    const authState = store.getState().auth;
    const accessToken: string = (authState.accessToken !== '') ? authState.accessToken : '';
    const userToken: string = (authState.userToken !== '') ? authState.userToken : '';

    return await fetch(devEndpoint + '/logoutservice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        token: userToken,
        authToken: accessToken,
        isInactivityTimeout: isInactivityTimeout ? isInactivityTimeout?.toString() : 'false',
        // TODO move to env file
        xsrf: '8f869fd8b51bcdd4173168f17986d499f8e70ba9'
      })
    })
      .then(async (response: Response) => {
        localStorage.removeItem('crooglooAuth');
        return await response.text();
      });
  }

  async passwordRecovery (email: string): Promise<any> {
    return await fetch(devEndpoint + '/loginservice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        email,
        requestType: 'passwordRecovery'
      })
    }).then(async (response: Response) => {
      return await response.text();
    });
  }

  async googleLogin (jwtToken: string): Promise<any> {
    // TODO use google secret manager to fetch this key on the frontend and any other secret keys
    const apiExplorerKey = process.env.REACT_APP_API_EXPLORER_KEY ?? '';
    const myHeaders = new Headers();
    myHeaders.append('Authorization', '');

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders
    };

    return await fetch(`${devEndpoint}/_ah/api/personapi/v2/verifyJwt?apiExplorerKey=${apiExplorerKey}&jwtToken=${jwtToken}`, requestOptions)
      .then(async (response: Response) => {
        const result = await response.text();
        const res = JSON.parse(result);

        if (res?.responseCode && UtilityService.isSuccessResponse(res.responseCode)) {
          // The response looks like this: "userId@gmail.com,a824===1f42coiajfarfarJWis"
          const credentials = res.responseMessage.split(',');
          const email = credentials[0].trim();
          const hashedPassword = credentials[1].trim();

          return await this.login({
            username: email,
            password: hashedPassword
          });
        } else {
          throw new Error(res.responseMessage)
        }
      })
      .catch(err => {
        console.error(err)
      });
  }

  async authentication (): Promise<any> {
    const response = await fetch(endpoint + '/AuthenticationService', {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      }
    });

    const res = await new Promise((resolve) => {
      if (response) {
        response?.json().then((json: object) => {
          return resolve(json)
        }).catch(() => {
          resolve(null)
        })
      } else {
        resolve(null)
      }
    })
      .then(data => {
        this.setStorageAuth(data as AuthUser);
        return data;
      });

    return res;
  }

  async sms2FAuthentication (code: string): Promise<void> {
    const tempAuthState: AuthUser | null = store.getState().auth.tempLoginAuth;
    if (!tempAuthState) {
      throw new Error('login.twoFactorAuth.error.server');
    } else {
      return await fetch(`${endpoint}/sms2fa?authToken=${tempAuthState?.authenticationToken ?? ''}&authUserId=${tempAuthState?.userId ?? ''}&code=${code}`)
        .then(async (response: Response) => {
          const result = await response.text();
          const res = JSON.parse(result);
          if (res?.responseCode && UtilityService.isSuccessResponse(res.responseCode)) {
            this.setStorageAuth(tempAuthState);
            window.location.replace('/');
          } else {
            throw new Error('login.twoFactorAuth.error.code');
          }
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err.message)
        })
    }
  }

  checkToken (): boolean {
    let authorized: boolean = false;
    const storedCrooglooAuth = localStorage.getItem('crooglooAuth');
    if (storedCrooglooAuth) {
      const crooglooAuth = JSON.parse(decodeURI(storedCrooglooAuth));
      if (crooglooAuth) {
        dispatch(login({ authUser: crooglooAuth }));
        authorized = true;
      }
    }
    return authorized
  }

  /*
     * apiCall using the gapi client
     * First loads the api if not already present in the client, then calls the method
     * @param {string} api - the api to be loaded in the gapi client
     * @param {string} method - the method to be called using the gapi client
     * @param {API_DATA_TYPE} data - data to be used in api request, includes:
     *      params and body for the request
     */
  async apiCall (api: string, method: string, data: ApiDataType): Promise<any> {
    // get the auth redux states from the store
    let authState = store.getState().auth;
    const allowedAPIMethods: string[] = authState.allowedAPIMethods;
    let runningGDriveCalls: number = authState.runningGDriveCalls;

    if (allowedAPIMethods.length > 0 && !allowedAPIMethods.includes(method)) {
      throw new Error(`Method ${method} not currently allowed`);
    }

    while (runningGDriveCalls > 0) {
      console.debug('Waiting for google drive request to complete for: ' + method);
      await new Promise(resolve => setTimeout(resolve, 50));
      authState = store.getState().auth;
      runningGDriveCalls = authState.runningGDriveCalls;
    }

    const version: string = this.getAPIVersion(api); // get api version for the api
    const body = (data.body != null) ? data.body : null;
    const type: string = (data.type != null) ? data.type.toUpperCase() : GetMethod;
    let params: Record<string, string | string[]> = (data.params != null) ? data.params : {};
    params = handleAPIParams(params); // set params for the request

    dispatch(incrementAPICalls());

    return await new Promise((resolve, reject) => {
      if (!Object.prototype.hasOwnProperty.call(gapi.client, api)) { // check if api already loaded
        dispatch(incrementAPICalls());
        gapi.client.load(api, version, async () => {
          dispatch(decrementAPICalls());
          if (!Object.prototype.hasOwnProperty.call(gapi.client, api)) {
            reject(new Error('Unable to load API: ' + api));
          }
          resolve(`Loaded API ${api} Successfully`);
        }, ROOT);
      } else {
        resolve(`Api ${api} Already Loaded`)
      }
    })
      .then(async () => {
        // TODO: saveSystemActivities
        const response = await gapi.client.request({
          path: ROOT + api + `/${version}/${method}`,
          params,
          body,
          method: type
        });
        dispatch(decrementAPICalls());
        return response.result;
      }).catch(err => {
        dispatch(decrementAPICalls());
        if (err.status === 403) {
          this.logout(false)
            .then(() => {
              window.location.href = '#/login';
            })
            .catch(() => {
              window.location.href = '#/login';
            });
        } else {
          const error = (err.body !== null) ? err.body : err;
          throw new Error(error);
        }
      })
  }

  /*
    * apiCall using the gapi client
    * First loads the api if not already present in the client, then calls the method
    * @param {string} api - the api to be loaded in the gapi client
    * @param {string} method - the method to be called using the gapi client
    * @param {API_DATA_TYPE} data - data to be used in api request, includes:
    *      params and body for the request
    */
  async apiPost (api: string, method: string, data: string): Promise<any> {
    // get the auth redux states from the store
    const authState = store.getState().auth;
    const token = authState.userToken;
    const authToken = authState.accessToken;
    const tenantId = authState.tenantId;

    const version: string = this.getAPIVersion(api); // get api version for the api
    const url = ROOT + api + `/${version}/${method}?token=${token}&tenantId=${tenantId}`;

    console.log('&&&&& callFetchExclusionsByInactivity2 data: ' + data);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        accept: '*/*',
        authorization: 'Bearer ' + authToken,
        'x-goog-encode-response-if-executable': 'base64',
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*
     * authenticated calls using the fetch method
     * First sets the url properties and headers
     * @param {string} url - the url to be called
     * @param {AuthenticatedDataType} data - data to be used in api request, includes:
     *      params, mehtod (GET, POST...) and body for the request
     */
  async authenticatedCall (url: string, data: AuthenticatedDataType, progress?: (progress: number) => void): Promise<any> {
    // get the auth redux states from the store
    const authState = store.getState().auth;
    const accessToken: string = (authState.accessToken !== '') ? authState.accessToken : '';
    const endPoint: string = (authState.urlEndpoint !== '') ? authState.urlEndpoint : '';
    if (accessToken === '') {
      throw new Error('Not Authenticated');
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = (url.startsWith('/')) ? url.substring(1) : url;
      url = endPoint + url;
    }

    const method: string = (data.method !== null && data.method !== undefined) ? data.method.toUpperCase() : GetMethod;
    const body = (data.body !== null) ? data.body : null;
    let params: Record<string, string> = (data.params !== undefined && data.params !== null) ? data.params : {};
    params = handleAuthParams(params); // set params for the request

    // set the headers for the request
    const requestHeaders: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`
    };
    if ([PostMethod, PutMethod].includes(method)) {
      const JsonType: string = 'application/json';
      if (!data.type || data.type === JsonType) {
        requestHeaders['Content-Type'] = JsonType;
      }
    }

    const config: Record<string, any> = {
      url,
      method,
      params,
      data: body,
      headers: requestHeaders
    }
    if (progress) {
      config.onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted: number = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          progress(percentCompleted);
        }
      }
    }
    if (data.responseType) {
      config.responseType = data.responseType;
    }

    return await axios(config).then(response => {
      return response;
    }).catch(err => {
      if (err.status === 403) {
        this.logout(false)
          .then(() => {
            window.location.href = '#/login';
          })
          .catch(() => {
            window.location.href = '#/login';
          });
      } else {
        const error = (err.body !== null) ? err.body : err;
        throw new Error(error);
      }
    })
  }

  getAPIVersion (api: string): string {
    let apiVersion: string = DefaultVersion;
    if (typeof ApiVersions[api] === 'string' && ApiVersions[api] !== null) {
      apiVersion = ApiVersions[api];
    }
    return apiVersion;
  }

  /**
   * gets the box entries for the path being passed in
   * @param path
   */
  async fetchBoxFiles (path: string): Promise<BoxEntry[]> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    const token: string = crooglooAuth.boxToken ?? '';
    const url: string = `${BoxFoldersApi}${path === '' ? '0' : path}/items`;
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(async (response: Response) => {
        const res = await response.text();
        const result = JSON.parse(res);
        const entries: BoxEntry[] = [];
        result.entries.forEach((entry: any) => {
          const tag: CrooglooFileTypes = (entry.type === 'folder') ? CrooglooFileTypes.BoxFolder : CrooglooFileTypes.BoxFile;
          const entryId: string = entry.id ?? '';
          if (entryId) {
            const data: string = (tag === CrooglooFileTypes.BoxFolder) ? entry.id : encodeURI('https://app.box.com/file/' + entryId);
            entries.push({
              id: entryId,
              type: tag,
              name: entry.name,
              data
            })
          }
        });
        return entries;
      })
      .catch((err) => {
        console.error(err);
        DocumentTreeService.unlinkBoxTokens();
        return [];
      })
  }

  /**
   * get box entry details and return the size for the entry
   * @param entry
   */
  async fetchBoxEntry (entry: any): Promise<any> {
    // const id: string = entry.id;
    // const url: string = `${BoxFilesApi}${id}`;
    // use url to get the file entry and return the size value
    return await new Promise((resolve) => {
      return entry;
    })
  }

  setStorageAuth (data: AuthUser): void {
    localStorage.setItem('crooglooAuth', encodeURI(JSON.stringify(data)));
  }

  setSubscriptionInfo (): void {
    SecurityAdmin.fetchSubscriptionInfo()
      .then((res: SubscriptionInfo) => {
        dispatch(setSubscriptionInfo({ subscriptionInfo: res }));
      })
      .catch((err) => {
        console.debug('Error getting subscription info');
        console.error(err);
      })
  }

  /**
   * get all subscription plans available to user based on the currency provided
   * @param currency Currencies
   */
  async fetchStoreProducts (currency: Currencies, planId: string): Promise<Subscription[]> {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;

    if (planId === '') planId = crooglooAuth.planId ?? '';

    return await fetch(`${devEndpoint}/fetchStoreProducts?currency=${String(currency)}&activePlanId=${planId}`)
      .then(async (response: Response) => {
        const result = await response.text();
        const productList: Subscription[] = JSON.parse(result);
        return productList;
      })
      .catch(err => {
        console.error(err)
        return [];
      })
  }

  async switchProduction (newCommunity: string): Promise<SwitchProductionResponse> {
    const authUser: AuthUser = store.getState().auth.crooglooauth;
    return await fetch(devEndpoint + '/CommunitySwitchServlet', {
      method: PostMethod,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        token: authUser.token ?? '',
        tenantId: authUser.defaultCommunity ?? '',
        toCommunity: newCommunity
      })
    })
      .then(async (response: Response) => {
        const result = await response.text();
        return JSON.parse(result);
      })
      .catch((err) => {
        console.error(err);
        throw new Error('server error');
      })
  }

  async fetchPdfFromLink (linkId: string): Promise<PdfLinkResponse> {
    return await new Promise((resolve, reject) => {
      const response: PdfLinkResponse = {
        pdfFileName: '',
        isDownloadAllowed: false,
        isPrintAllowed: false,
        data: null
      }

      const req = {
        method: PostMethod,
        body: JSON.stringify('')
      };

      const xhrMain = new XMLHttpRequest();
      xhrMain.open(req.method, `${devEndpoint}/PdfLink?linkId=${linkId}`);
      xhrMain.responseType = 'blob';
      xhrMain.onload = () => {
        if (xhrMain.status >= 200 && xhrMain.status < 300) {
          try {
            const contentData = xhrMain.getResponseHeader('Content-Disposition');
            if (contentData) {
              const pdfFileName = contentData.match(/filename=([^;]*);/);
              const fileAccess = contentData.match(/access=([^;]*);/);
              response.pdfFileName = (pdfFileName) ? pdfFileName[1] : '';
              if (fileAccess) {
                response.isDownloadAllowed = parseInt(fileAccess[1]) > 0;
                response.isPrintAllowed = parseInt(fileAccess[1]) > 1;
              }
            }
          } catch (e) {
            console.error(e);
            console.error('ignore previous error if running localhost');
          }
          response.data = xhrMain.response;
          resolve(response);
        } else {
          reject(new Error('Error getting file'));
        }
      }
      xhrMain.onerror = () => {
        reject(xhrMain);
      }
      xhrMain.send(req.body);
    });
  }

  // set gapi.client token
  setGapiToken (accessToken: string): void {
    gapi.client.setToken({ access_token: accessToken });
  }

  /**
   * Gets the box tokens gotten from the new access code from sign in to be able to fetch box files
   */
  async fetchBoxToken (code: string, refresh: boolean): Promise<void> {
    const urlParams = new URLSearchParams({
      client_id: BoxClientId,
      client_secret: BoxClientSecret,
      redirect: 'follow'
    });
    if (refresh) {
      urlParams.append('refresh_token', code);
      urlParams.append('grant_type', 'refresh_token');
    } else {
      urlParams.append('code', code);
      urlParams.append('grant_type', 'authorization_code');
    }
    return await fetch('https://api.box.com/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: urlParams,
      redirect: 'follow'
    })
      .then(async (response: Response) => {
        const res = await response.text();
        const result = JSON.parse(res);
        if (!result.error) {
          const authState = store.getState().auth;
          this.setBoxAccessTokens(authState.crooglooauth, result.access_token, result.refresh_token, result.expires_in);
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('server error');
      })
  }

  /**
   * sets box tokens in front end of application to be able to access box files
   */
  setBoxAccessTokens (crooglooAuth: AuthUser, token: string, refreshToken: string, expires: number): void {
    crooglooAuth = {
      ...crooglooAuth,
      boxToken: token,
      boxRefreshToken: refreshToken,
      boxTokenExpiry: expires
    };
    this.setStorageAuth(crooglooAuth);
    dispatch(setBoxTokens({
      boxToken: token,
      boxRefreshToken: refreshToken,
      boxTokenExpiry: expires
    }));
  }
}

// Adds token and tenantId to the params if they don't exist already
const handleAPIParams = (params: Record<string, string | string[]>): Record<string, string | string[]> => {
  if (params.token !== null) params.token = getUserToken();
  if (params.tenantId !== null) params.tenantId = getTenantId();
  return params;
}

const handleAuthParams = (params: Record<string, string>): Record<string, string> => {
  if (params.token !== null) params.token = getUserToken();
  if (params.tenantId !== null) params.tenantId = getTenantId();
  return params;
}

const getUserToken = (): string => {
  // get the auth redux states from the store
  const authState = store.getState().auth;
  return (authState.userToken !== '') ? authState.userToken : '';
}

const getTenantId = (): string => {
  // get the auth redux states from the store
  const authState = store.getState().auth;
  return (authState.tenantId !== '') ? authState.tenantId : '';
}

const service: IAuthService = new AuthService();
export default service;
