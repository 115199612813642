import type { FC } from 'react';
import MaterialTooltip from '@mui/material/Tooltip';
import { MainBlack, MainWhite } from '../../theme/colors';

type Placement = 'top' | 'left' | 'bottom' | 'right' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined;

const DefaultPlacement: Placement = 'top';

interface Props {
  title: string
  placement?: Placement
  children: React.ReactElement
}

/**
 * Reusable Tooltip component
 */
const Tooltip: FC<Props> = (props: Props) => {
  const placement: Placement = props.placement ?? DefaultPlacement;
  return (
     <MaterialTooltip
       title={props.title}
       placement={placement}
       componentsProps={{
         tooltip: {
           sx: {
             bgcolor: MainBlack,
             color: MainWhite,
             borderRadius: '50px',
             boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
             fontFamily: 'Arial',
             fontStyle: 'normal',
             fontWeight: 400,
             fontSize: '0.9rem',
             lineHeight: '150%',
             marginRight: '2px !important'
           }
         }
       }}
     >
       { props.children }
     </MaterialTooltip>
  )
}

export default Tooltip;
