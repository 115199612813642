import React, { lazy, Suspense } from 'react';

const LazyActivateSubscriptionFail = lazy(async () => await import('./ActivateSubscriptionFail'));

const ActivateSubscriptionFail = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyActivateSubscriptionFail {...props} />
  </Suspense>
);

export default ActivateSubscriptionFail;
