import { store } from '../../state/store';
import type { ApiDataType, AuthUser } from '../auth/constants';
import { DistributionApi } from '../auth/constants';
import AuthService from '../auth/AuthService';
import {
  addDistributionEmailTemplate,
  removeDistributionListMembers,
  setCachedReplyToEmail,
  setCurrentDistributionReport,
  setDistributionContactList,
  setDistributionContactMembers,
  setDistributionEmailTemplateNames,
  setDistributionGroupList,
  setDistributionList,
  setDistributionListIds,
  setDistributionListMembers,
  setDistributionListNames,
  setDistributionMessages,
  updateDistributionMessageTime,
  setExtraEmails,
  setPersonList,
  setProductionOfficeUsers,
  setReplyToUsers,
  setIsSendingMessage,
  setAllContactsList
} from '../../state/slices/DistributionReducer';
import type {
  WSDepartmentNameResponse,
  WSDistributionListIdsResponse,
  WSDistributionListMembersResponse,
  WSDistributionListNameResponse,
  WSDistributionListResponse,
  WSDistributionListWithContactsResponse,
  WSDistributionMessageResponse,
  WSExtraEmailResponse,
  WSItemResponse,
  WSPersonResponse,
  WSResponse
} from '../../models/WSResponse';
import type { DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';
import type {
  DistributionGroupLists,
  DistributionList,
  DistributionListIds,
  DistributionListIdsResponse,
  DistributionListItem,
  DistributionListName,
  DistributionListNameResponse,
  DistributionListResponse,
  ExtraEmail,
  ExtraEmailResponse,
  SelectedGroup,
  SelectedMember
} from '../../models/DistributionList';
import type { Message } from '../../models/Message';
import { MessageStatus, TestMessageResponses } from '../../models/Message';
import type { DepartmentName, DepartmentNameResponse } from '../../models/Department';
import { setDepartmentNames } from '../../state/slices/PersonReducer';
import { DistributionHistoryPage } from '../../components/DistributionHistory/DistributionHistory';
import { DistributionMessagesKeys, DraftNumOfTotalItems, SentNumOfTotalItems } from './constants';
import { DeleteMethod, GetMethod, PostMethod } from '../constants';
import type { Person, PersonResponse } from '../../models/Person';
import type { DistributionMessage, DistributionMessageResponse } from '../../models/DistributionMessage';
import type { DistributionStatuses, RecipientData } from '../../models/DistributionReport';
import type { ProductionOfficeUser, ProductionOfficeUsersResponse } from '../../models/Production';
import SettingsService from '../SettingsService';
import PersonService from '../person/PersonService';
import type { ComboValue, ComboValueParameters, ComboValues } from '../../models/ComboValues';
import UtilityService from '../UtilityService';
import { DefaultComboParamsLists, DistributionGroupList } from '../../components/DistributionLists/Index';
import ViewService from '../ViewService';
import DocumentService from '../documents/DocumentService';
import ImportExportService from '../ImportExportService';
import type {
  DistributionGroupMembers,
  DistributionListMembers,
  DistributionListWithContacts
} from '../../models/DistributionListMembers';
import type { AddContactForm, ModifyContactValidation } from '../../models/Contacts';
import { Categories } from '../../models/Contacts';
import MessageService from '../message/MessageService';
import { LinkTimeOptions, OriginalDurationDays, OriginalDurationHours } from '../../models/LinkOptions';
import { setDraft, setLinkOptions, setMessageToSend } from '../../state/slices/DraftReducer';
import type { FileAttachment } from '../../models/Document';
import ModalService from '../ModalService';
import { MessageSentModal } from '../../components/modals/MessageSent/constants';
import type { FileToUpload } from '../../models/Upload';
import UploadService from '../UploadService';
import moment from 'moment';

const AllListID: string = '__all';
const { dispatch } = store;

interface IDistributionService {
  loadDistributionGroupAndMembers: () => void
  fetchReplyToOptions: () => void
  getProductionOfficeUsers: () => Promise<ProductionOfficeUser[]>
  loadDistributionEmailTemplates: () => void
  fetchBasicContactInfo: () => void
  fetchEmailList: () => void
  fetchDistributionTemplateContent: (template: string) => Promise<DistributionEmailTemplate>
  findAllAffectedDistGroups: (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]) => SelectedMember[]
  fetchCompleteGroups: (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]) => string
  fetchPartialGroupsEmails: (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]) => string[]
  fetchPartialGroupsIds: (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]) => string[]
  sendMessage: (status: MessageStatus, showModal: boolean | null, messageKey: DistributionMessagesKeys) => Promise<boolean>
  requestTemplate: (requestMessageBody: string) => Promise<WSResponse>
  fetchDistributionListNames: () => void
  fetchDepartmentNames: () => void
  fetchMessageHistory: (pageTitle: string, messageStatus: number, pageNumber?: number) => void
  fetchMessageEntities: (pageTitle: string) => void
  fetchPageEntities: (pageTitle: string, messageStatus: number) => void
  generateDistroReports: (msgId: string) => void
  deleteDraft: (msgId: string) => Promise<any>
  deleteAllDrafts: () => Promise<any>
  convertToDraft: (msgId: string) => Promise<WSResponse>
  addDistributionListMembersByContact: (distributionListIds: string, personId: string) => Promise<WSResponse>
  removeDistributionListMemberByContact: (distributionListIds: string, personId: string) => Promise<WSResponse>
  fetchDistributionLists: () => void
  fetchDistributionListsAlt: () => Promise<DistributionListItem[]>
  getComboValues: (params: ComboValueParameters, listId: string) => Promise<void>
  fetchDistributionListMembers: (distributionListId: string) => Promise<DistributionGroupMembers>
  fetchDistributionListsWithContact: (personId: string) => Promise<void>
  addDistributionList: (newListName: string) => Promise<string>
  removeDistributionList: (listId: string) => Promise<string>
  editDistributionListName: (listName: string, listId: string) => Promise<string>
  duplicateDistributionList: (listName: string, clonedListId: string) => Promise<string>
  addDistributionListFromMsg: (listName: string, msgId: string) => Promise<any>
  mergeDistributionLists: (option: number, newListName: string, listId: string, selectedListIDs: string) => Promise<string>
  exportDistributionList: (type: string, listIds: string[]) => Promise<void>
  updateDistroListMembers: (persons: string[], listId: string, listTransaction: string, method: string, ignoreUpdate?: boolean) => Promise<void>
  addDistributionListMembersByContactCombo: (listIds: string[], person: string) => Promise<void>
  removeDistributionListMemberByContactCombo: (listIds: string[], person: string) => Promise<void>
  getMemberCount: (listId: string) => number
  fetchDistributionContact: (contactId: string) => Promise<Person>
  validateDistributionContact: (getValues: any) => ModifyContactValidation
  editDistributionContact: (person: Person, getValues: any, listId: string) => Promise<void>
  sendTestMessage: (getValues: any) => Promise<TestMessageResponses>
  saveDistributionEmailTemplate: (template: DistributionEmailTemplate) => Promise<WSResponse>
  handleLinkOptions: (linkAccessType: number, linkValidityTime: number, durationDays: number, durationHours: number) => Promise<void>
  cacheExtraEmail: (email: string) => Promise<WSResponse>
  fetchSignature: () => Promise<WSResponse | null>
  saveSignature: (signature: string) => Promise<WSResponse>
  saveAttachment: (file: FileToUpload) => Promise<any>
  sendMassSms: (messageContent: string, recipientIds: string[], listIds: string[], extraPhoneNumbers: string[]) => Promise<WSResponse>
}

const PersonChunkSize: number = 50;

class DistributionService implements IDistributionService {
  /**
   * loads distribution lists and their members
   */
  loadDistributionGroupAndMembers (): void {
    const distributionList = store.getState().distribution.distributionList;
    if (Array.isArray(distributionList) && distributionList !== null && distributionList.length > 0) {
      return;
    }
    makeDistributionCall('fetchDistributionGroupAndMembers', {})
      .then((result: WSDistributionListResponse) => {
        if (result.items !== null) {
          const lists: DistributionList[] = [];
          const items: DistributionListResponse[] = result.items;
          items.forEach((list: DistributionListResponse) => {
            const distrubtionListNew: DistributionList = {
              id: list.distributionListId,
              name: list.distributionListName,
              members: list.distributionListMembers
            };
            lists.push(distrubtionListNew);
          });
          dispatch(setDistributionList({ distributionList: lists }));
        } else {
          console.debug('Distribution List Not Found');
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * fetch reply to options for compose page
   */
  fetchReplyToOptions (): void {
    const crooglooAuth: AuthUser = store.getState().auth.crooglooauth;
    const userEmail: string = crooglooAuth.email ?? '';
    const userFirstName: string = (typeof crooglooAuth.firstName === 'string') ? crooglooAuth.firstName.trim() : '';
    const userLastName: string = (typeof crooglooAuth.lastName === 'string') ? crooglooAuth.lastName.trim() : '';
    let replyToUsers: any[] = [];
    let cachedReplyToEmail: string | null = null;
    // get users & cached reply to user
    const promises: any[] = [this.getProductionOfficeUsers(), SettingsService.fetchCachedData()];
    Promise.all(promises)
      .then((resp: any) => {
        const productionUsers: ProductionOfficeUser[] = resp[0];
        const cachedData: WSResponse = resp[1];

        if (productionUsers.length > 0) {
          replyToUsers.push(...productionUsers);
        }
        replyToUsers.sort((u1, u2) => u1.name.localeCompare(u2.name));
        // include auth user in list
        if (!replyToUsers.map((u: ProductionOfficeUser) => u.email).filter((email: string) => email).includes(userEmail)) {
          replyToUsers = [...replyToUsers, {
            name: (userFirstName + ' ' + userLastName).trim(),
            email: userEmail,
            firstName: userFirstName,
            lastName: userLastName
          }];
        }
        if (UtilityService.isSuccessResponse(cachedData.responseCode) && cachedData.responseMessage && cachedData.responseMessage.length > 0) {
          cachedReplyToEmail = cachedData.responseMessage;
        }
        dispatch(setReplyToUsers({ users: replyToUsers }));
        // if cached email - set in dispatch to pick it up in the dropdown - otherwise set it to null
        if (cachedReplyToEmail !== null && replyToUsers.find(u => u.email === cachedReplyToEmail)) {
          dispatch(setCachedReplyToEmail({ email: cachedReplyToEmail }));
        } else if (replyToUsers.length > 0) {
          dispatch(setCachedReplyToEmail({ email: replyToUsers[0].email }));
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  async fetchSignature (): Promise<WSResponse | null> {
    return await makeDistributionCall('fetchSignatureFromDatastore',
      { type: GetMethod })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        console.error(err);
        return null;
      });
  }

  async saveSignature (signature: string): Promise<WSResponse> {
    return await makeDistributionCall('saveSignatureToDatastore',
      {
        type: PostMethod,
        body: JSON.stringify({ value: signature })
      })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        console.error(err);
        throw new Error('userSettings.userSettings.signature.error');
      });
  }

  /**
   * loads production office users
   */
  async getProductionOfficeUsers (): Promise<ProductionOfficeUser[]> {
    return await makeDistributionCall('getProductionOfficeUsers', {})
      .then((result: ProductionOfficeUsersResponse) => {
        if (result.items !== null) {
          const items: ProductionOfficeUser[] = result.items;
          dispatch(setProductionOfficeUsers({ productionOfficeUsers: items }));
          return items;
        } else {
          return [];
        }
      });
  }

  /**
   * load distribtion email template names
   */
  loadDistributionEmailTemplates (): void {
    const distributionEmailTemplateNames = store.getState().distribution.distributionEmailTemplateNames;
    if (Array.isArray(distributionEmailTemplateNames) && distributionEmailTemplateNames !== null && distributionEmailTemplateNames.length > 0) {
      return;
    }
    makeDistributionCall('fetchDistributionEmailTemplates', {})
      .then((result: WSItemResponse) => {
        if (result.items !== null) {
          dispatch(setDistributionEmailTemplateNames({ distributionEmailTemplateNames: result.items }));
        } else {
          console.debug('Distribution Email Templates Not Found');
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * fetch basic contact info of persons in production
   */
  fetchBasicContactInfo (): void {
    makeDistributionCall('fetchBasicContactInfo', {})
      .then((result: WSPersonResponse) => {
        if (result.items !== null) {
          const items: PersonResponse[] = result.items;
          const persons: Person[] = PersonService.personResponseToPersons(items);
          if (persons.length > 0) {
            const allList: DistributionList = {
              id: AllListID,
              name: 'All Contacts',
              members: persons.map((person: Person) => ({ ...person, id: `${AllListID}_${String(person.id)}` }))
            };
            dispatch(setPersonList({ personList: persons }));
            dispatch(setAllContactsList({ list: allList }))
          }
        } else {
          console.debug('basic contact info Not Found');
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * get the extra emails from backend
   */
  fetchEmailList (): void {
    makeDistributionCall('fetchEmailList', {})
      .then((result: WSExtraEmailResponse) => {
        if (result.items !== null) {
          const items: ExtraEmailResponse[] = result.items;
          const extraEmails: ExtraEmail[] = items.map((email: ExtraEmailResponse) => {
            return {
              email: email[0],
              label: email[1],
              primaryEmail: email[2],
              name: email[1].substring(0, email[1].indexOf('<')).trim(),
              castNumber: email[3],
              jobTitle: email[4],
              department: email[5],
              distroLists: email[6]
            }
          });
          // sort by cast # first then name
          extraEmails.sort((a: ExtraEmail, b: ExtraEmail) => {
            const aRawNameString: string = a.label;
            const bRawNameString: string = b.label;
            const aIsNumbered = isNumberedEntry(aRawNameString);
            const bIsNumbered = isNumberedEntry(bRawNameString);
            if (aIsNumbered && !bIsNumbered) {
              return -1;
            } else if (!aIsNumbered && bIsNumbered) {
              return 1;
            } else {
              const aKey = sortKey(aRawNameString);
              const bKey = sortKey(bRawNameString);
              if (aKey < bKey) {
                return -1;
              } else if (aKey > bKey) {
                return 1;
              } else {
                const aName = nameKey(aRawNameString);
                const bName = nameKey(bRawNameString);
                if (aName < bName) {
                  return -1;
                } else if (aName > bName) {
                  return 1;
                } else {
                  return 0;
                }
              }
            }
          })
          dispatch(setExtraEmails({ extraEmails }));
        } else {
          console.debug('Extra Emails List not found');
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * get content of distribtion tempalte
   * @param template {string} name of template
   * @return template content
   */
  async fetchDistributionTemplateContent (template: string): Promise<DistributionEmailTemplate> {
    const templateList: DistributionEmailTemplate[] = store.getState().distribution.distributionEmailTemplates;
    if (Array.isArray(templateList) && templateList !== null && templateList.length > 0) {
      const templateExists: DistributionEmailTemplate | undefined = templateList.find(t => t.templateName === template);
      if (templateExists !== undefined) {
        dispatch(addDistributionEmailTemplate({ distributionEmailTemplate: templateExists }));
        return await Promise.resolve(templateExists);
      }
    }
    const result: DistributionEmailTemplate | boolean = await makeDistributionCall('fetchDistributionTemplateContent', {
      params: {
        templateName: template
      }
    });
    if (typeof result !== 'boolean' && result?.templateName !== null) {
      dispatch(addDistributionEmailTemplate({ distributionEmailTemplate: result }));
      return result;
    } else {
      throw new Error(`Template Content not found for ${template}`);
    }
  }

  /**
   * find all affected lists and departments to send message to
   * @param groupList
   * @param groupDepartment
   * @return affectedGroups {SelectedMember[]}
   */
  findAllAffectedDistGroups (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]): SelectedMember[] {
    const affectedGroups: SelectedMember[] = [];
    groupList.forEach((list: SelectedGroup) => {
      if (list.members.length > 0) {
        list.members.forEach((member: SelectedMember) => {
          affectedGroups.push(member);
        });
      }
    });
    groupDepartment.forEach((department: SelectedGroup) => {
      if (department.members.length > 0) {
        department.members.forEach((member: SelectedMember) => {
          affectedGroups.push(member);
        });
      }
    });
    return affectedGroups;
  }

  /**
   * find all groups of lists and departments where all members are included in message
   * @param groupList
   * @param groupDepartment
   * @return string containing all complete group ids
   */
  fetchCompleteGroups (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]): string {
    let completeGroupsString: string = '-';
    const completeGroups: string[] = [];
    groupList.forEach((list: SelectedGroup) => {
      if (list.total === list.members.length) {
        completeGroups.push(list.id);
      }
    });

    groupDepartment.forEach((department: SelectedGroup) => {
      if (department.total === department.members.length) {
        completeGroups.push(department.id);
      }
    });
    if (completeGroups.length > 0) {
      completeGroupsString = completeGroups.join(',');
    }
    return completeGroupsString;
  }

  /**
   * find all members of lists and departments where member is included in message
   * but all members of list / department aren't
   * @param groupList
   * @param groupDepartment
   * @return string containing all member ids
   */
  fetchPartialGroupsEmails (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]): string[] {
    const partialGroupMembers: string[] = [];
    groupList.forEach((list: SelectedGroup) => {
      if (list.members.length > 0 && list.total !== list.members.length) {
        list.members.forEach((member: SelectedMember) => {
          partialGroupMembers.push(member.email);
        });
      }
    });
    groupDepartment.forEach((department: SelectedGroup) => {
      if (department.members.length > 0 && department.total !== department.members.length) {
        department.members.forEach((member: SelectedMember) => {
          partialGroupMembers.push(member.email);
        });
      }
    });
    return partialGroupMembers;
  }

  fetchPartialGroupsIds (groupList: SelectedGroup[], groupDepartment: SelectedGroup[]): string[] {
    const partialGroupMembers: string[] = [];
    groupList.forEach((list: SelectedGroup) => {
      if (list.members.length > 0 && list.total !== list.members.length) {
        list.members.forEach((member: SelectedMember) => {
          // in old application it gets ids with _INPUT at end - quick work around here
          const id: string = (member.id.endsWith('_INPUT')) ? member.id : `${member.id}_INPUT`;
          partialGroupMembers.push(id);
        });
      }
    });
    groupDepartment.forEach((department: SelectedGroup) => {
      if (department.members.length > 0 && department.total !== department.members.length) {
        department.members.forEach((member: SelectedMember) => {
          const id: string = (member.id.endsWith('_INPUT')) ? member.id : `${member.id}_INPUT`;
          partialGroupMembers.push(id);
        });
      }
    });
    return partialGroupMembers;
  }

  /**
   * send message to backend
   * @param message
   */
  async sendMessage (status: MessageStatus, showModal: boolean | null, messageKey: DistributionMessagesKeys): Promise<boolean> {
    const message: Message | null = store.getState().draft.messageToSend;
    let msgId: string = '-';
    if (!message) {
      return false;
    }
    return await makeDistributionCall('sendToDistributionList', {
      body: JSON.stringify(message),
      type: PostMethod
    })
      .then((result: WSResponse) => {
        setSendingMessage(false);
        if (status !== MessageStatus.READY && result.entityId) {
          msgId = result.entityId;
        }
        if (status === MessageStatus.DRAFT) {
          dispatch(setDraft({
            key: messageKey,
            draft: {
              id: msgId,
              unsignedMessage: message.unsignedMessage,
              subject: message.subject,
              smsmessage: message.smsmessage,
              replyTo: message.replyToEmail,
              restoreContent: true
            }
          }))
        }
        dispatch(setMessageToSend({ message: null }))
        if (showModal) {
          const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
          const unknownEmails: string[] = MessageService.getUnknownRecipientsEmails(selectedExtraEmails);
          ModalService.openCustomModal(
            MessageSentModal,
            {
              heading: 'compose.form.message.success',
              content: 'compose.form.message.willBeSentShortly',
              metaData: unknownEmails
            }
          );
        }
        return true;
      }).catch((err) => {
        console.error(err);
        return false;
      });
  }

  async requestTemplate (requestMessageBody: string): Promise<WSResponse> {
    return await makeDistributionCall('sendTemplateRequest', {
      type: PostMethod,
      params: { requestMessageBody }
    })
      .then((result: WSResponse) => {
        return result;
      })
      .catch((err) => {
        console.error(err);
        throw new Error('Error requesting new message');
      });
  }

  fetchDistributionListNames (): void {
    makeDistributionCall('fetchDistributionListNames', {})
      .then((result: WSDistributionListNameResponse) => {
        const distributionListNames: DistributionListName[] = [];
        const items: DistributionListNameResponse[] = result.items;
        items.forEach((listName: DistributionListNameResponse) => {
          const distributionListName: DistributionListName = {
            id: listName.key.name,
            name: listName.properties.distributionListName
          }
          distributionListNames.push(distributionListName);
        });
        distributionListNames.push({
          id: AllListID,
          name: 'All Contacts'
        });
        distributionListNames.sort((a: DistributionListName, b: DistributionListName) => {
          return UtilityService.compareStrings(a.name, b.name)
        });
        dispatch(setDistributionListNames({ distributionListNames }));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchDepartmentNames (): void {
    const departmentNames = store.getState().person.departmentNames;
    if (Array.isArray(departmentNames) && departmentNames !== null && departmentNames.length > 0) {
      return;
    }
    makeDistributionCall('fetchDepartmentNames', {})
      .then((result: WSDepartmentNameResponse) => {
        const newDepartmentNames: DepartmentName[] = [];
        const items: DepartmentNameResponse[] = result.items;
        items.forEach((departmentName: DepartmentNameResponse) => {
          const newDepartmentName: DepartmentName = {
            [departmentName.key.name]: departmentName.properties.departmentName
          }
          newDepartmentNames.push(newDepartmentName);
        });
        dispatch(setDepartmentNames({ departmentNames: newDepartmentNames }));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchMessageHistory (pageTitle: string, messageStatus: number, pageNumber?: number): void {
    if (pageTitle === DistributionHistoryPage.Outbox) {
      this.fetchMessageEntities(pageTitle);
    } else {
      this.fetchPageEntities(pageTitle, messageStatus, pageNumber);
    }
  }

  fetchMessageEntities (pageTitle: string): void {
    makeDistributionCall('fetchOutboxMessages', {})
      .then((result: WSDistributionMessageResponse) => {
        const items: DistributionMessageResponse[] = result.items;
        const newOutboxMessages: DistributionMessage[] = handleNewDistributionMessages(items);
        dispatch(setDistributionMessages({ key: pageTitle, distributionMessages: newOutboxMessages }));
      }).catch((err) => {
        console.error(err);
      });
  }

  fetchPageEntities (pageTitle: string, messageStatus: number, pageNumber?: number): void {
    const pageNo = pageNumber ?? '1';
    const numOfTotalItems: number = (pageTitle === DistributionHistoryPage.Sent) ? SentNumOfTotalItems : DraftNumOfTotalItems;
    makeDistributionCall('fetchPageEntites', {
      params: {
        status: String(messageStatus),
        limit: String(numOfTotalItems),
        page: pageNo.toString()
      }
    }).then((result: WSDistributionMessageResponse) => {
      const items: DistributionMessageResponse[] = result.items;
      const newDistributionMessages: DistributionMessage[] = handleNewDistributionMessages(items);
      dispatch(setDistributionMessages({ key: pageTitle, distributionMessages: newDistributionMessages }));
    }).catch((err) => {
      console.error(err);
    });
  }

  generateDistroReports (msgId: string): void {
    makeDistributionCall('generateDistroReports', {
      params: { msgId },
      type: PostMethod
    }).then((result: DistributionStatuses) => {
      dispatch(setCurrentDistributionReport({ distributionReport: result }));
    }).catch((err) => {
      console.error(err);
    });
  }

  async deleteDraft (msgId: string) {
    return await new Promise((resolve, reject) => {
      makeDistributionCall('deleteDraft', {
        params: { messageId: msgId },
        type: DeleteMethod
      })
        .then((resp: WSResponse) => {
          if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
            const entityId: string = resp.entityId ?? '';
            resolve(entityId);
          } else if (resp?.responseCode && resp.responseCode === '-1') {
            reject(new Error(resp.responseMessage ?? 'Error deleting draft'));
          } else {
            reject(new Error('Server Error. Couldn\'t delete draft'))
          }
        })
        .catch((err) => {
          console.debug(err);
          reject(new Error('Server Error. Couldn\'t delete draft'))
        });
    });
  }

  async deleteAllDrafts () {
    return await new Promise((resolve, reject) => {
      makeDistributionCall('deleteAllDrafts', {
        type: DeleteMethod
      })
        .then((resp: WSResponse) => {
          if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
            const entityId: string = resp.entityId ?? '';
            resolve(entityId);
          } else if (resp?.responseCode && resp.responseCode === '-1') {
            reject(new Error(resp.responseMessage ?? 'Error deleting all drafts'));
          } else {
            reject(new Error('Server Error. Couldn\'t delete all drafts'))
          }
        })
        .catch((err) => {
          console.debug(err);
          reject(new Error('Server Error. Couldn\'t delete all drafts'))
        });
    });
  }

  async convertToDraft (msgId: string): Promise<WSResponse> {
    return await makeDistributionCall('convertToDraft', {
      params: { msgId },
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error converting to draft');
    });
  }

  async addDistributionListMembersByContact (distributionListIds: string, personId: string): Promise<WSResponse> {
    return await makeDistributionCall('addDistributionListMembersByContact', {
      params: { distributionListIds },
      body: JSON.stringify({
        personId
      }),
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error adding distribution list member');
    });
  }

  async removeDistributionListMemberByContact (distributionListIds: string, personId: string): Promise<WSResponse> {
    return await makeDistributionCall('removeDistributionListMemberByContact', {
      params: { distributionListIds },
      body: JSON.stringify({
        personId
      }),
      type: DeleteMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error removing distribution list member');
    });
  }

  fetchDistributionLists (): void {
    makeDistributionCall('fetchDistributionLists', {})
      .then((result: WSDistributionListIdsResponse) => {
        if (result.items !== null) {
          const lists: DistributionListIds[] = [];
          const items: DistributionListIdsResponse[] = result.items;
          items.forEach((item: DistributionListIdsResponse) => {
            const list: DistributionListIds = {
              category: item.properties.category ?? '',
              distributionListName: item.properties.distributionListName ?? '',
              distributionListId: item.properties.distributionListId ?? '',
              id: item.properties.id ?? '',
              includesAgent: item.properties.includesAgent ?? false
            }
            lists.push(list);
          });
          dispatch(setDistributionListIds({ distributionLists: lists }));
        } else {
          console.debug('Distribution Lists Not Found');
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  async fetchDistributionListsAlt (): Promise<DistributionListItem[]> {
    const lists: DistributionListItem[] = [];

    return await makeDistributionCall('fetchDistributionLists', {})
      .then((result: WSDistributionListIdsResponse) => {
        if (result.items !== null) {
          const items: DistributionListIdsResponse[] = result.items;
          items.forEach((item: DistributionListIdsResponse) => {
            const list: DistributionListItem = {
              name: item.properties.distributionListName ?? '',
              id: item.properties.id ?? ''
            }
            lists.push(list);
          });
        } else {
          console.debug('Distribution Lists Not Found');
        }
        return lists;
      }).catch((err) => {
        console.error(err);
        throw new Error(err);
      });
  }

  async getComboValues (params: ComboValueParameters, listId: string): Promise<void> {
    const response: ComboValues = await makeDistributionCall('getComboValues', {
      params: {
        entityName: params.entityName ?? '',
        filterAttribute: params.filterAttribute ?? '',
        filterValue: params.filterValue ?? '',
        valueAttrib: params.valueAttrib ?? '',
        displayAttrib: params.displayAttrib ?? '',
        sortAttrib: params.sortAttrib ?? ''
      }
    })
    if (response.items?.length > 0) {
      let items: ComboValue[] = response.items;
      const allIndexToRemove: number = items.findIndex((item: ComboValue) => item.value === AllListID);
      let allItemRemoved: ComboValue | null = null;
      if (allIndexToRemove >= 0) {
        allItemRemoved = items.splice(allIndexToRemove, 1)[0];
      }
      items.sort((a: ComboValue, b: ComboValue) => {
        return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
      });
      if (allIndexToRemove >= 0 && allItemRemoved) {
        items = [allItemRemoved, ...items];
      }
      const distributionGroupList: DistributionGroupLists = {};
      switch (listId) {
        case 'distributionGroupList':
          items.forEach((item: ComboValue) => {
            distributionGroupList[item.value] = item.label;
          });
          dispatch(setDistributionGroupList({ list: distributionGroupList }));
          break;
        case 'distributionContactList':
          items.forEach(item => {
            item.label = item.label.toUpperCase();
          });
          dispatch(setDistributionContactList({ list: items }));
          break;
        default:
          console.error('select element id changed without updating loader reference');
      }
    }
  }

  async fetchDistributionListMembers (distributionListId: string): Promise<DistributionGroupMembers> {
    const distGroupMembers: DistributionGroupMembers = {
      id: '',
      members: [],
      nonMembers: []
    }
    const response: WSDistributionListMembersResponse = await makeDistributionCall('fetchDistributionListMembers', {
      params: {
        distributionListId
      }
    });
    if (response.items?.length > 0) {
      const items: DistributionListMembers[] = response.items;
      const groupMembers: DistributionListMembers[] = [];
      const nonGroupMembers: DistributionListMembers[] = [];
      items.forEach((item: DistributionListMembers, index: number) => {
        const sortingValue: number = index;
        const departmentId: string = UtilityService.cleanForKey(item.departmentId ?? 'DEPARTMENT_NONE');
        const department: string = item.department ?? 'NONE';
        if (item.selected) {
          groupMembers.push(
            {
              ...item,
              departmentId,
              department,
              sortingValue
            }
          );
        } else {
          nonGroupMembers.push(
            {
              ...item,
              departmentId,
              department,
              sortingValue
            }
          );
        }
      });
      groupMembers.sort(compareDeptWithDefaults);
      nonGroupMembers.sort(compareDeptWithDefaults);

      dispatch(setDistributionListMembers({
        distributionGroupList: distributionListId,
        members: groupMembers,
        nonMembers: nonGroupMembers
      }))

      distGroupMembers.id = distributionListId;
      distGroupMembers.members = groupMembers;
      distGroupMembers.nonMembers = nonGroupMembers;
    }
    return distGroupMembers;
  }

  async fetchDistributionListsWithContact (personId: string): Promise<void> {
    const response: WSDistributionListWithContactsResponse = await makeDistributionCall('fetchDistributionListsWithContact', {
      params: {
        personId
      }
    });
    if (response.items?.length > 0) {
      const items: DistributionListWithContacts[] = response.items;
      const groupMembers: DistributionListWithContacts[] = [];
      const nonGroupMembers: DistributionListWithContacts[] = [];
      items.forEach((item: DistributionListWithContacts) => {
        if (item.selected) {
          groupMembers.push(item);
        } else {
          nonGroupMembers.push(item);
        }
      });

      dispatch(setDistributionContactMembers({
        id: personId,
        members: groupMembers,
        nonMembers: nonGroupMembers
      }))
    }
  }

  async addDistributionList (newListName: string): Promise<string> {
    newListName = newListName.trim();
    return await new Promise((resolve, reject) => {
      if (!validateListName(newListName)) {
        reject(new Error('distributionList.addList.error.name'));
      } else {
        makeDistributionCall('addDistributionList', {
          params: {
            distributionListName: newListName
          },
          type: PostMethod
        })
          .then(async (resp: WSResponse) => {
            if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
              await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
                .then(async () => {
                  const entityId: string = resp.entityId ?? '';
                  await this.fetchDistributionListMembers(entityId);
                  resolve(entityId);
                })
            } else if (resp?.responseCode && resp.responseCode === '-1') {
              reject(new Error(resp.responseMessage));
            } else {
              reject(new Error('distributionList.addList.error.server'))
            }
          })
          .catch((err) => {
            console.error(err);
            reject(new Error('distributionList.addList.error.server'))
          })
      }
    })
  }

  async removeDistributionList (listId: string): Promise<string> {
    return await new Promise((resolve, reject) => {
      makeDistributionCall('removeDistributionList', {
        params: {
          distributionListId: listId
        },
        type: DeleteMethod
      })
        .then(async (resp: WSResponse) => {
          await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
            .then(() => {
              dispatch(removeDistributionListMembers({ distributionGroupList: listId }));
              resolve('distributionList.deleteList.success');
            })
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.deleteList.error.server'))
        })
    })
  }

  async editDistributionListName (listName: string, listId: string): Promise<string> {
    listName = listName.trim();
    return await new Promise((resolve, reject) => {
      if (!validateListName(listName)) {
        reject(new Error('distributionList.addList.error.name'));
      } else {
        makeDistributionCall('editDistributionListName', {
          params: {
            newDistributionListName: listName,
            currentDistributionListId: listId
          },
          type: PostMethod
        })
          .then(async (resp: WSResponse) => {
            if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
              await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
                .then(() => {
                  resolve('distributionList.editListName.success');
                })
            } else if (resp?.responseCode && resp.responseCode === '-1') {
              reject(new Error(resp.responseMessage));
            } else {
              reject(new Error('distributionList.editListName.error.server'))
            }
          })
          .catch((err) => {
            console.error(err);
            reject(new Error('distributionList.editListName.error.server'))
          })
      }
    })
  }

  async duplicateDistributionList (listName: string, clonedListId: string): Promise<string> {
    listName = listName.trim();
    return await new Promise((resolve, reject) => {
      if (!validateListName(listName)) {
        reject(new Error('distributionList.addList.error.name'));
      } else {
        makeDistributionCall('duplicateDistributionList', {
          params: {
            newDistributionListName: listName,
            clonedDistributionListId: clonedListId
          },
          type: PostMethod
        })
          .then(async (resp: WSResponse) => {
            if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
              await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
                .then(async () => {
                  const entityId: string = resp.entityId ?? '';
                  await this.fetchDistributionListMembers(entityId);
                  resolve(entityId);
                })
            } else if (resp?.responseCode && resp.responseCode === '-1') {
              reject(new Error(resp.responseMessage));
            } else {
              reject(new Error('distributionList.duplicate.error.server'))
            }
          })
          .catch((err) => {
            console.error(err);
            reject(new Error('distributionList.duplicate.error.server'))
          })
      }
    })
  }

  async addDistributionListFromMsg (listName: string, msgId: string) {
    listName = listName.trim();
    return await new Promise((resolve, reject) => {
      if (!validateListName(listName)) {
        reject(new Error('distributionList.addList.error.name'));
      } else {
        makeDistributionCall('addDistributionListFromMsg', {
          params: {
            distributionListName: listName,
            msgId
          },
          type: PostMethod
        })
          .then(async (resp: WSResponse) => {
            if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
              const entityId: string = resp.entityId ?? '';
              resolve(entityId);
            } else if (resp?.responseCode && resp.responseCode === '-1') {
              reject(new Error(resp.responseMessage));
            } else {
              reject(new Error('distributionList.duplicate.error.server'))
            }
          })
          .catch((err) => {
            console.error(err);
            reject(new Error('distributionList.duplicate.error.server'))
          });
      }
    });
  }

  async mergeDistributionLists (option: number, newListName: string, listId: string, selectedListIDs: string): Promise<string> {
    newListName = newListName.trim();
    return await new Promise((resolve, reject) => {
      if (option > 0) {
        if (!validateListName(newListName)) {
          reject(new Error('distributionList.addList.error.name'));
          return;
        }
      }
      if (!selectedListIDs || selectedListIDs === '') {
        reject(new Error('distributionList.mergeList.error.empty'));
        return;
      }
      const payload: any = {};
      const selectedLists: string[] = [selectedListIDs];
      if (option > 0) {
        selectedLists.push(listId);
        payload.name = newListName;
      } else {
        payload.listId = listId;
      }
      payload.listIds = selectedLists;
      makeDistributionCall('mergeDistributionLists', {
        body: JSON.stringify(payload),
        type: PostMethod
      })
        .then(async (resp: WSResponse) => {
          if (resp?.responseCode && UtilityService.isSuccessResponse(resp.responseCode)) {
            await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
              .then(async () => {
                const entityId: string = resp.entityId ?? '';
                await this.fetchDistributionListMembers(entityId);
                resolve(entityId);
              })
          } else {
            reject(new Error('distributionList.mergeList.error.server'))
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.mergeList.error.server'))
        })
    })
  }

  async exportDistributionList (type: string, listIds: string[]): Promise<void> {
    return await new Promise((resolve, reject) => {
      if (type === '') {
        reject(new Error('distributionList.exportOptions.error.empty'));
        return;
      }
      if (listIds.length === 0) {
        reject(new Error('distributionList.exportList.error.empty'));
        return;
      }
      let promise = null;
      if (type === 'excel') {
        promise = ImportExportService.exportDistributionListToExcel(listIds);
      } else if (type === 'vcard') {
        promise = ViewService.generateVCardFromDistributionList(listIds);
      } else if (type === 'csv') {
        promise = DocumentService.exportDistroListsToGoogleCsv(listIds);
      }
      Promise.resolve(promise)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.exportList.error.server'));
        })
    });
  }

  async updateDistroListMembers (persons: string[], listId: string, listTransaction: string, method: string, ignoreUpdate?: boolean): Promise<void> {
    const personIdChunk: any = [];
    while (persons.length) {
      const chunkSize = Math.min(persons.length, PersonChunkSize)
      personIdChunk.push(persons.splice(0, chunkSize));
    }
    let nbOfChunksLeft = personIdChunk.length;
    return await new Promise((resolve, reject) => {
      personIdChunk.forEach((chunk: string[]) => {
        makeDistributionCall(listTransaction, {
          params: {
            distributionListId: listId,
            personId: chunk.join(',')
          },
          type: method
        })
          .then(async (res: WSResponse) => {
            if (--nbOfChunksLeft === 0) {
              if (ignoreUpdate) {
                resolve();
              } else {
                await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
                  .then(async () => {
                    await this.fetchDistributionListMembers(listId);
                    resolve();
                  });
              }
            }
          }).catch((err) => {
            console.error(err);
            reject(new Error('distributionList.update.error'));
          })
      })
    })
  }

  async addDistributionListMembersByContactCombo (listIds: string[], person: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      makeDistributionCall('addDistributionListMembersByContact', {
        params: {
          distributionListIds: listIds.join(','),
          personId: person
        },
        type: PostMethod
      })
        .then(async (res: WSResponse) => {
          await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
            .then(async () => {
              await this.fetchDistributionListsWithContact(person);
              resolve();
            })
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.update.error'));
        })
    });
  }

  async removeDistributionListMemberByContactCombo (listIds: string[], person: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      makeDistributionCall('removeDistributionListMemberByContact', {
        params: {
          distributionListIds: listIds.join(','),
          personId: person
        },
        type: DeleteMethod
      })
        .then(async (res: WSResponse) => {
          await this.getComboValues(DefaultComboParamsLists, DistributionGroupList)
            .then(async () => {
              await this.fetchDistributionListsWithContact(person);
              resolve();
            })
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.update.error'));
        })
    });
  }

  getMemberCount (listId: string): number {
    let count: number = 0;
    const distributionGroupList = store.getState().distribution.distributionGroupList;
    const listName: string = distributionGroupList[listId];
    const memberCount = /\((\d+)\)$/.exec(listName);
    if (memberCount) {
      count = parseInt(memberCount[1]);
    }
    return count;
  }

  /**
   * build message object to send a test message
   * @param getValues - get values from compose form
   */
  async sendTestMessage (getValues: any): Promise<TestMessageResponses> {
    const files: FileAttachment[] = store.getState().uploadFiles.files;
    if (!await MessageService.checkAttachmentSize(MessageStatus.READY, files)) {
      return TestMessageResponses.Fail;
    }
    return await new Promise((resolve) => {
      // build test message to send to backend
      MessageService.buildTestMessage(getValues)
        .then(() => {
          const message: Message | null = store.getState().draft.messageToSend;
          if (message) {
            makeDistributionCall('sendTestMessage', {
              body: JSON.stringify(message),
              type: 'POST'
            })
              .then((result: WSResponse) => {
                dispatch(setMessageToSend({ message: null }));
                if (result.responseCode && UtilityService.isSuccessResponse(result.responseCode)) {
                  resolve(TestMessageResponses.NoPhone);
                } else if (result.responseCode && result.responseCode === '-1') {
                  resolve(TestMessageResponses.NoPhone);
                } else {
                  resolve(TestMessageResponses.Fail);
                }
              }).catch((err) => {
                console.error(err);
                resolve(TestMessageResponses.Fail);
              });
          } else {
            resolve(TestMessageResponses.Fail);
          }
        })
        .catch((err) => {
          console.error(err);
          resolve(TestMessageResponses.Fail);
        })
    });
  }

  async saveDistributionEmailTemplate (template: DistributionEmailTemplate): Promise<WSResponse> {
    return await makeDistributionCall('saveDistributionEmailTemplate', {
      type: PostMethod,
      body: JSON.stringify(template)
    });
  }

  async handleLinkOptions (
    linkAccessType: number,
    linkValidityTime: number,
    durationDays: number,
    durationHours: number
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      const customLinkTime: boolean = linkValidityTime === LinkTimeOptions.CUSTOM;
      if (customLinkTime) {
        const daysInvalid = UtilityService.hasInvalidNumber(durationDays);

        if (daysInvalid) {
          reject(new Error('compose.linkOptions.error.days'));
          return;
        }

        if (durationDays >= 0 && durationHours !== 0) {
          if (UtilityService.hasInvalidNumber(durationHours)) {
            reject(new Error('compose.linkOptions.error.hours'));
            return;
          }
        }
      }
      // if custom link time set the days and hours in milliseconds
      if (customLinkTime) {
        durationDays = UtilityService.daysToMilliseconds(durationDays);
        durationHours = UtilityService.hoursToMilliseconds(durationHours);
      } else {
        // else set to origin days and hours in milliseconds
        durationDays = OriginalDurationDays;
        durationHours = OriginalDurationHours;
      }
      if (durationDays + durationHours === 0) {
        reject(new Error('compose.linkOptions.error.total'));
        return;
      }
      dispatch(setLinkOptions({
        time: linkValidityTime,
        accessType: linkAccessType,
        isDeepLink: false,
        days: durationDays,
        hours: durationHours
      }));
      resolve();
    });
  }

  /**
   * gets the person object for modify contact modal popup
   * @param contactId {string}
   */
  async fetchDistributionContact (contactId: string): Promise<Person> {
    return await PersonService.fetchContact(contactId)
      .then((result: Person) => {
        return result;
      });
  }

  validateDistributionContact (getValues: any): ModifyContactValidation {
    const validationErrors: ModifyContactValidation = {
      errorFields: [],
      error: ''
    };
    const firstName: string = getValues('firstName').trim();
    const lastName: string = getValues('lastName').trim();
    const email: string = getValues('email').trim();
    const email2: string = getValues('email2').trim();
    const email3: string = getValues('email3').trim();
    if (firstName === '') {
      validationErrors.errorFields.push('firstName');
    }
    if (lastName === '') {
      validationErrors.errorFields.push('lastName');
    }
    if (email === '') {
      validationErrors.errorFields.push('email');
    }
    // check if mandatory fields are empty
    if (validationErrors.errorFields.length > 0) {
      validationErrors.error = 'distributionList.modifyContact.error.empty';
      return validationErrors;
    }
    if (!UtilityService.isValidName(firstName)) {
      validationErrors.errorFields.push('firstName');
    }
    if (!UtilityService.isValidName(lastName)) {
      validationErrors.errorFields.push('lastName');
    }
    if (UtilityService.isInValidEmailAddress(email)) {
      validationErrors.errorFields.push('email');
    }
    if (email2 !== '' && UtilityService.isInValidEmailAddress(email2)) {
      validationErrors.errorFields.push('email2');
    }
    if (email3 !== '' && UtilityService.isInValidEmailAddress(email3)) {
      validationErrors.errorFields.push('email3');
    }
    // if fields are not valid
    if (validationErrors.errorFields.length > 0) {
      validationErrors.error = 'distributionList.modifyContact.error.valid';
      return validationErrors;
    }
    return validationErrors;
  }

  /**
   * function to edit a contact in the modify contact modal popup in distribution lists
   * calls the personService editPerson to call the api
   * handles the validation of the form and converting of person to addContactForm object to send to api
   * @param person {Person}
   * @param getValues {any}
   * @param listId {string}
   */
  async editDistributionContact (person: Person, getValues: any, listId: string): Promise<void> {
    const firstName: string = getValues('firstName').trim();
    const lastName: string = getValues('lastName').trim();
    const email: string = getValues('email').trim();
    const email2: string = getValues('email2').trim();
    const email3: string = getValues('email3').trim();
    const mobile: string = getValues('mobile').trim();
    await new Promise((resolve, reject) => {
      const data: AddContactForm = convertPersonToFormData(person, firstName, lastName, email, email2, email3, mobile);
      PersonService.editPerson(data)
        .then(async (r: WSResponse) => {
          if (r.responseMessage && r.responseMessage === 'success') {
            await this.fetchDistributionListMembers(listId);
            resolve(r.responseMessage);
          } else if (r.responseMessage && typeof r.responseMessage === 'string') {
            reject(new Error(r.responseMessage));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('distributionList.modifyContact.error.server'));
        })
    });
  }

  async cacheExtraEmail (email: string): Promise<WSResponse> {
    return await makeDistributionCall('cacheExtraEmail', {
      params: {
        email
      },
      type: PostMethod
    });
  }

  async saveAttachment (file: FileToUpload): Promise<WSResponse> {
    return await makeDistributionCall('saveAttachment', {
      type: PostMethod,
      params: {
        tags: 'test',
        replaceEntity: 'false'
      },
      body: JSON.stringify(file)
    })
  }

  async sendMassSms (messageContent: string, recipientIds: string[], listIds: string[], extraPhoneNumbers: string[]): Promise<WSResponse> {
    return await makeDistributionCall('sendMassSms', {
      type: PostMethod,
      body: JSON.stringify({
        smsmessage: messageContent,
        extraListMembersInputVal: extraPhoneNumbers.join(','),
        extraemail: recipientIds.join(','),
        distributionListId: listIds.join(',')
      })
    })
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeDistributionCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(DistributionApi, method, data);
  return result;
}

const handleNewDistributionMessages = (items: DistributionMessageResponse[]): DistributionMessage[] => {
  const newDistributionMessages: DistributionMessage[] = [];
  items.forEach((distributionMessage: DistributionMessageResponse) => {
    const status: MessageStatus = getMessageStatus(distributionMessage.properties.status);
    const dateSent: string = distributionMessage.properties.dateSent ?? '';
    if (checkFailedMessage(status, dateSent)) {
      const id: string = distributionMessage.key.name;
      const recipients: string = distributionMessage.properties?.recipientListJSON?.value ?? '';
      const nbOfRecipients: RecipientData[] = JSON.parse(recipients);
      const allEmails: string[] = nbOfRecipients.map((recipientData: RecipientData) => recipientData.email ?? '');
      const uploadedFilesJSON: string = distributionMessage.properties.uploadedFilesJSON.value;
      const signature: string = distributionMessage.properties.signature?.value ?? '';
      const timeLeft: string = distributionMessage.properties.timeLeft ?? '';
      const uploadedFiles: FileAttachment[] = UploadService.getFileAttachmentsFromUploadedFilesJSON(uploadedFilesJSON);
      newDistributionMessages.push({
        id,
        hasAttachment: uploadedFiles.length > 0,
        subject: distributionMessage.properties.subject ?? '',
        date: dateSent,
        nbOfRecipients: nbOfRecipients.length,
        recipientListJSON: recipients,
        unsignedMessage: distributionMessage.properties.unsignedMessage.value,
        smsmessage: distributionMessage.properties.smsmessage,
        signature,
        replyToEmail: distributionMessage.properties.replyToEmail,
        uploadedFilesJSON: uploadedFiles,
        distributionListId: distributionMessage.properties.distributionListId,
        departmentId: distributionMessage.properties.departmentId,
        extraEmailsForSelect: distributionMessage.properties.extraEmailsForSelect.value,
        extraListMembersInputVal: distributionMessage.properties.extraListMembersInputVal.value,
        timeLeft,
        status,
        allRecipientEmails: allEmails.filter((email: string) => email !== '')
      });
      if (status === MessageStatus.READY && timeLeft) {
        trackTimeLeft(id, timeLeft);
      }
    }
  })
  return newDistributionMessages;
}

function getMessageStatus (status: string): MessageStatus {
  const statusValue: number = parseInt(status);
  const statuses: MessageStatus[] = [MessageStatus.SENT, MessageStatus.DRAFT, MessageStatus.READY, MessageStatus.RECEIVED, MessageStatus.FAILED];
  return statuses.includes(statusValue) ? statusValue : MessageStatus.SENT;
}

function checkFailedMessage (status: MessageStatus, dateSent: string): boolean {
  const DaysToExpire: number = 3;
  const DayMs: number = 1000 * 3600 * 24;
  return (status !== MessageStatus.FAILED) || (moment(dateSent).valueOf() > moment().valueOf() - DaysToExpire * DayMs);
}

function trackTimeLeft (id: string, value: string): void {
  let timeLeft: number = parseInt(value);
  const timeInterval = setInterval(() => {
    timeLeft -= 5;
    dispatch(updateDistributionMessageTime({
      key: DistributionHistoryPage.Outbox,
      messageId: id,
      timeLeft: String(timeLeft)
    }));
    if (timeLeft <= 0) {
      clearInterval(timeInterval);
    }
  }, 5000);
}

/**
 * returns true if string starts with a pound sign and a number, and false otherwise
 * @param {String} string
 * @returns boolean
 */
function isNumberedEntry (string: string): boolean {
  return string.match(/^#\d+/) !== null;
}

/**
 * takes a string and extracts the number following the pound sign if it exists, and returns Infinity otherwise.
 * @param {String} string
 * @returns number
 */
function sortKey (string: string): number {
  const match = string.match(/^#(\d+)/);
  return match !== null ? parseInt(match[1]) : Infinity;
}

/**
 * takes a string and removes the pound sign and number at the beginning of the string if it exists
 * @param {String} string
 * @returns string
 */
function nameKey (string: string): string {
  return string.replace(/^#\d+ /, '');
}

function compareDeptWithDefaults (a: DistributionListMembers, b: DistributionListMembers): number {
  const aName: string = (a.firstName + ' ' + a.lastName);
  const bName: string = (b.firstName + ' ' + b.lastName);
  const result = (Number)(aName.localeCompare(bName, undefined, { sensitivity: 'base' }));
  return result;
}

function validateListName (listName: string): boolean {
  let valid: boolean = true;
  listName = listName.trim();
  if (listName === '') {
    valid = false;
  }
  return valid;
}

function convertPersonToFormData (
  person: Person, firstName: string, lastName: string,
  email: string, email2: string, email3: string, mobile: string
): AddContactForm {
  const NValue: string = 'N';
  const emptyString: string = '~';
  const data: AddContactForm = {
    subcategory: person.subcategory ?? Categories.Crew,
    firstName,
    lastName,
    email,
    email2: email2 ?? emptyString,
    email3: email3 ?? emptyString,
    mobile: mobile ?? emptyString,
    departmentId: person.departmentId ?? emptyString,
    departmentName: person.departmentName ?? emptyString,
    jobTitle: person.jobTitle,
    castNumber: emptyString,
    characterName: emptyString,
    agency: person.agency ?? emptyString,
    office: person.officePhone ?? emptyString,
    home: person.homePhone ?? emptyString,
    address1: person.address1 ?? emptyString,
    locality: person.locality ?? emptyString,
    state: person.state ?? emptyString,
    postalcode: person.postalcode ?? emptyString,
    creditName: person.creditName ?? emptyString,
    contactName: person.contactName ?? emptyString,
    contactNumber: person.contactNumber ?? emptyString,
    allergies: person.allergies ?? emptyString,
    gift: person.gift ?? emptyString,
    startDate: person.startDate ?? emptyString,
    endDate: person.endDate ?? emptyString,
    welcomeToCroogloo: NValue,
    inviteToCroogloo: person.inviteToCroogloo ?? NValue,
    securitygroup: person.securityGroup ?? '',
    id: person.id ?? emptyString,
    isNewContact: false,
    includeInDTR: true,
    receivedSafeSender: person.receivedSafeSender ?? NValue
  }
  return data;
}

const setSendingMessage = (isSending: boolean): void => {
  dispatch(setIsSendingMessage({ isSendingMessage: isSending }));
}

const distributionService: IDistributionService = new DistributionService();
export default distributionService;
