import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleService from '../../services/GoogleService';
import { ToastIcons } from '../../models/Toast';
import { setGContactsToken } from '../../state/slices/AuthReducer';
import AuthService from '../../services/auth/AuthService';
import type { AuthUser } from '../../services/auth/constants';
import type { RootState } from '../../state/store';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { editDocumentCard } from '../../state/slices/Documents';
import { GDriveFolderId } from '../../services/ViewService';
import type { GoogleLoginResponse } from '../../models/Google';
import { GoogleRequestType } from '../../models/Google';
import DocumentService from '../../services/documents/DocumentService';
import DocumentTreeService from '../../services/documents/DocumentTreeService';
import type { AlertColor } from '@mui/material';

const googleError: string = 'import.google.error';
export const googleButtonId: string = 'google-signIn-button';

export interface GoogleLoginProps {
  children: React.ReactNode
  handleShowToast: (message: string, type: AlertColor, icon: ToastIcons) => void
  requestType: GoogleRequestType
}

const GoogleLogin: FC<GoogleLoginProps> = ({ children, handleShowToast, requestType }: GoogleLoginProps) => {
  const dispatch = useAppDispatch();
  const crooglooAuth: AuthUser = useAppSelector((state: RootState) => state.auth.crooglooauth);

  const [googleScopes, setGoogleScopes] = useState<string>('');

  useEffect(() => {
    const scope = (requestType === GoogleRequestType.Files)
      ? (process.env.REACT_APP_GOOGLE_DRIVE_SCOPE ?? '')
      : (process.env.REACT_APP_GOOGLE_CONTACT_SCOPES ?? '');
    setGoogleScopes(scope);
  }, [requestType]);

  const showGoogleError = (message: string): void => {
    handleShowToast(message, 'error', ToastIcons.Info);
  }

  const onSuccess = (response: GoogleLoginResponse): void => {
    if (response?.access_token) {
      const accessToken: string = response.access_token;
      if (requestType === GoogleRequestType.Contacts) {
        const newCrooglooAuth: AuthUser = { ...crooglooAuth, gContactsAccessToken: accessToken };
        dispatch(setGContactsToken({ accessToken }));
        AuthService.setStorageAuth(newCrooglooAuth);
        GoogleService.importContacts(accessToken)
          .then(() => {
            handleShowToast('import.google.success', 'success', ToastIcons.Success);
          })
          .catch((err) => {
            showGoogleError(err.message);
          })
      } else if (requestType === GoogleRequestType.Files) {
        GoogleService.setGDriveToken(crooglooAuth, accessToken);
        DocumentService.handleSelectedFilesAndFolders([], []);
        dispatch(editDocumentCard({ data: { id: GDriveFolderId, linked: true } }));
      } else if (requestType === GoogleRequestType.Compose) {
        GoogleService.setGDriveToken(crooglooAuth, accessToken);
        void DocumentTreeService.initGDriveAfterConnect();
      }
    } else {
      showGoogleError(googleError);
    }
  };

  const onError = (): void => {
    showGoogleError(googleError);
  };

  const login = useGoogleLogin({
    onSuccess: codeResponse => onSuccess(codeResponse as GoogleLoginResponse),
    onError,
    scope: googleScopes
  });

  return (
    <>
      <div id={googleButtonId} onClick={() => login()}>
        {children}
      </div>
    </>
  )
};

export default GoogleLogin;
