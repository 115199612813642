/*
 * File for the redux store
 */
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './slices/AuthReducer';
import distributionReducer from './slices/DistributionReducer';
import personReducer from './slices/PersonReducer';
import draftReducer from './slices/DraftReducer';
import modalReducer from './slices/modals';
import pageTitleReducer from './slices/pageTitle';
import contactsReducer from './slices/Contacts';
import securityAdminReducer from './slices/SecurityAdmin';
import documentsReducer from './slices/Documents';
import templatesReducer from './slices/Templates';
import documentTreeReducer from './slices/DocumentTree';
import uploadFilesReducer from './slices/UploadFiles';
import sidesReducer from './slices/Sides';
import notificationsReducer from './slices/Notifications';
import sidebarReducer from './slices/SideBar';
import dashboardReducer from './slices/DashboardReducer';
import subscriptionReducer from './slices/Subscription';
import generateCrewListReducer from './slices/GenerateCrewList';

/*
 * import any reducers, combine and add them into the store to be available throughout the app
 */
const rootReducer = combineReducers({
  auth: authReducer,
  distribution: distributionReducer,
  person: personReducer,
  draft: draftReducer,
  modals: modalReducer,
  pageTitle: pageTitleReducer,
  contacts: contactsReducer,
  securityAdmin: securityAdminReducer,
  documents: documentsReducer,
  templates: templatesReducer,
  documentTree: documentTreeReducer,
  uploadFiles: uploadFilesReducer,
  sides: sidesReducer,
  notifications: notificationsReducer,
  sideBar: sidebarReducer,
  dashboard: dashboardReducer,
  subscription: subscriptionReducer,
  generateCrewList: generateCrewListReducer
})

// create and export the store
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
