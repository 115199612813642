/*
 * Reducer file for the Documents slice
 */

import { createSlice } from '@reduxjs/toolkit';
import { DocumentSlice } from './constants';
import type { DocumentCard, DirectoryDestination } from '../../models/Document';

// default path to get files & folders from backend for Documents page
export const DefaultDocumentPath: string = 'ROOT';
// default name to be shown Cookie trail for Documents page
export const DefaultDocumentName: string = 'Documents';

export interface DocumentsSlice {
  documentCards: DocumentCard[]
  documentParams: string[]
  documentsPath: string[]
  checkDocuments: DocumentCard[]
  selectedFolders: string[]
  selectedFiles: string[]
  allFolders: DirectoryDestination[]
  documentsToEdit: DocumentCard[]
  publishToStudioExists: boolean
}

const initialState: DocumentsSlice = {
  documentCards: [],
  documentParams: [],
  documentsPath: [DefaultDocumentName],
  checkDocuments: [],
  selectedFolders: [],
  selectedFiles: [],
  allFolders: [],
  documentsToEdit: [],
  publishToStudioExists: false
}

export const documentsSlice = createSlice({
  name: DocumentSlice,
  initialState,
  reducers: {
    handleSelectedFolder: (state, action) => {
      state.selectedFolders = action.payload.folders;
    },
    setAllFolders: (state, action) => {
      state.allFolders = action.payload.folders;
    },
    handleSelectedFile: (state, action) => {
      state.selectedFiles = action.payload.files;
    },
    resetSelected: (state, action) => {
      state.selectedFiles = [];
      state.selectedFolders = [];
    },
    setDocumentPath: (state, action) => {
      state.documentsPath = action.payload.path;
    },
    setDocumentParams: (state, action) => {
      state.documentParams = action.payload.params;
    },
    setCurrentDocumentCards: (state, action) => {
      const documentCards: DocumentCard[] = action.payload.documentCards;
      documentCards.sort((a: DocumentCard, b: DocumentCard) => a.sortValue - b.sortValue);
      state.documentCards = documentCards;
    },
    editDocumentCard: (state, action) => {
      state.documentCards = state.documentCards.map((card) =>
        card.id === action.payload.data.id
          ? { ...card, ...action.payload.data }
          : card
      );
    },
    setDocumentToEdit: (state, action) => {
      state.documentsToEdit = action.payload.documents;
    },
    removeDocumentCards: (state, action) => {
      const documentIds: string[] = action.payload.documentIds;
      let documentCards: DocumentCard[] = state.documentCards;
      documentCards = documentCards.filter((document: DocumentCard) => !documentIds.includes(document.id));
      state.documentCards = documentCards;
    },
    addDocumentCard: (state, action) => {
      const documentCards: DocumentCard[] = [...state.documentCards, action.payload.documentCard];
      const sortedCards: DocumentCard[] = documentCards.sort((a: DocumentCard, b: DocumentCard) => a.sortValue - b.sortValue);
      state.documentCards = sortedCards;
    },
    setPublishToStudioExists: (state, action) => {
      state.publishToStudioExists = action.payload.exists;
    },
    setDocumentsToCheck: (state, action) => {
      state.checkDocuments = action.payload.documents;
    }
  }
})

export const {
  handleSelectedFolder, handleSelectedFile, resetSelected,
  setDocumentPath, setCurrentDocumentCards, editDocumentCard, setDocumentParams, setAllFolders,
  setDocumentToEdit, removeDocumentCards, addDocumentCard, setPublishToStudioExists, setDocumentsToCheck
} = documentsSlice.actions;
export default documentsSlice.reducer;
