import React, { lazy, Suspense } from 'react';
import type { UploadFileTypeProps } from './UploadFileType';

const LazyUploadFileType = lazy(async () => await import('./UploadFileType'));

const UploadFileType = (props: UploadFileTypeProps) => (
  <Suspense fallback={null}>
    <LazyUploadFileType {...props} />
  </Suspense>
);

export default UploadFileType;
