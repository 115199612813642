import type { FC } from 'react';
import { Grid } from '@mui/material';
import FormInputTextField from '../../forms/FormInputTextField';
import type { TemplateForm } from '../SaveTemplateFlow';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { Control } from 'react-hook-form';

interface Props {
  control: Control<TemplateForm>
}

/**
 * Template Name Component for the save template flow - step 3
 */
const TemplateName: FC<Props> = ({ control }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        textAlign: 'left'
      }}
    >
      <FormInputTextField
        id={'template-name'}
        name={'name'}
        label={String(t('compose.saveTemplate.templateName.label'))}
        formItemProps={{
          control
        }}
      />
    </Grid>
  )
}

export default TemplateName;
