import React, { lazy, Suspense } from 'react';
import type { AttachCallSheetProps } from './AttachCallSheet';

const LazyAttachCallSheet = lazy(async () => await import('./AttachCallSheet'));

const AttachCallSheet = (props: AttachCallSheetProps) => (
  <Suspense fallback={null}>
    <LazyAttachCallSheet {...props} />
  </Suspense>
);

export default AttachCallSheet;
