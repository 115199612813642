import { NotificationApi } from '../auth/constants';
import type { ApiDataType } from '../auth/constants';
import AuthService from '../auth/AuthService';
import { GetMethod, PatchMethod } from '../constants';
import type { NotificationType, NotificationResponse } from '../../models/Notifications';
import type { WSResponse } from '../../models/WSResponse';
import UtilityService from '../UtilityService';

interface INotificationService {
  fetchVisibleNotifications: (type: NotificationType) => Promise<NotificationResponse>
  updateNotificationVisibility: (referenceId: string, isVisible: boolean) => Promise<void>
  updateNotificationStatus: (referenceId: string, isUserNotified: boolean) => Promise<any>
  updateBrowserNotificationStatus: (referenceId: string, isBrowserNotificationSent: boolean) => Promise<void>
}

class NotificationService implements INotificationService {
  async fetchVisibleNotifications (type: NotificationType): Promise<NotificationResponse> {
    return await makeNotificationCall('fetchVisibleNotifications', {
      params: { type },
      type: GetMethod
    }).then((result: NotificationResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error fetching visible notifications.');
    });
  }

  async updateNotificationVisibility (referenceId: string, isVisible: boolean): Promise<void> {
    return await makeNotificationCall('updateNotificationVisibility', {
      params: {
        referenceId,
        isVisible: String(isVisible)
      },
      type: PatchMethod
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('Notification visibility update failed');
        }
        console.debug('this notification will not be shown anymore');
      })
      .catch((err) => console.error(err));
  }

  async updateNotificationStatus (referenceId: string, isUserNotified: boolean): Promise<any> {
    return await makeNotificationCall('updateNotificationStatus', {
      params: {
        referenceId,
        isUserNotified: String(isUserNotified)
      }
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('Notification status update failed');
        } else if (isUserNotified) {
          // handle unseenReference.isSaved = true;
          console.debug('alerts won\'t be shown anymore for this notification');
        }
      })
      .catch((err) => {
        console.error(err);
        // handle error here with resp?
      })
  }

  async updateBrowserNotificationStatus (referenceId: string, isBrowserNotificationSent: boolean): Promise<void> {
    return await makeNotificationCall('updateBrowserNotificationStatus', {
      params: {
        referenceId,
        isBrowserNotifSent: String(isBrowserNotificationSent)
      },
      type: PatchMethod
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('browser notification status update failed');
        } else {
          console.debug('browser notification status updated');
        }
      })
      .catch((err) => console.error(err));
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeNotificationCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(NotificationApi, method, data);
  return result;
}

const dataMaintenanceService: INotificationService = new NotificationService();
export default dataMaintenanceService;
