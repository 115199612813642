import type { FC, MouseEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface Props {
  name: string
  formItemProps: any
  onClick?: MouseEventHandler<HTMLDivElement>
}

/**
 * Reusable Input Text Field Component for forms - without border and label
 * name - name of select field
 * formItemProps would have to contain control from react-hook-form's useForm
 */
const FormInputLongTextField: FC<Props> = (props: Props) => {
  return (
    <>
      <Controller
        name={props.name}
        {...props.formItemProps}
        render={({ field: { onChange, value } }) => (
            <TextField
              onClick={props.onClick}
              onChange={onChange} value={value}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none'
                  }
                }
              }} />
        )}
      />
    </>
  )
}

export default FormInputLongTextField;
