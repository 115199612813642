import React, { lazy, Suspense } from 'react';
import type { FormatOptionsProps } from './FormatOptions';

const LazyFormatOptions = lazy(async () => await import('./FormatOptions'));

const FormatOptions = (props: FormatOptionsProps) => (
  <Suspense fallback={null}>
    <LazyFormatOptions {...props} />
  </Suspense>
);

export default FormatOptions;
