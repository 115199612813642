/**
 * Reducer file for the Draft slice
 * Handles the setting of a draft from either a template or distribution message
 * Also stores the selected group lists, departments and emails selected for drafts
 */

import { createSlice } from '@reduxjs/toolkit';
import type { SelectedGroup } from '../../models/DistributionList';
import type { Draft } from '../../models/Draft';
import { DraftSlice } from './constants';
import type { LinkOptionsStatus } from '../../models/LinkOptions';
import { LinkAccessOptions, LinkTimeOptions, OriginalDurationDays, OriginalDurationHours } from '../../models/LinkOptions';
import type { Message } from '../../models/Message';
import { DistributionMessagesKeys } from '../../services/distribution/constants';

interface DraftMessage {
  draft: Draft
  groupList: SelectedGroup[]
  groupDepartment: SelectedGroup[]
  selectedExtraEmails: string[]
}

const initialDraft: DraftMessage = {
  draft: {
    id: '-',
    restoreRecipients: false,
    restoreContent: false,
    type: ''
  },
  groupList: [],
  groupDepartment: [],
  selectedExtraEmails: []
};

export interface DraftState {
  drafts: {
    [DistributionMessagesKeys.Compose]: DraftMessage
    [DistributionMessagesKeys.Drafts]: DraftMessage
    [DistributionMessagesKeys.Sent]: DraftMessage
    [DistributionMessagesKeys.Outbox]: DraftMessage
  }
  fieldsToOverwrite: string[]
  linkOptions: LinkOptionsStatus
  messageToSend: Message | null
}

const initialState: DraftState = {
  drafts: {
    [DistributionMessagesKeys.Compose]: initialDraft,
    [DistributionMessagesKeys.Drafts]: initialDraft,
    [DistributionMessagesKeys.Sent]: initialDraft,
    [DistributionMessagesKeys.Outbox]: initialDraft
  },
  fieldsToOverwrite: [],
  linkOptions: {
    linkValidityTime: LinkTimeOptions.NEVER,
    linkAccessType: LinkAccessOptions.DownloadPrint,
    isDeepLink: false,
    durationDays: OriginalDurationDays,
    durationHours: OriginalDurationHours
  },
  messageToSend: null
}

export const draftSlice = createSlice({
  name: DraftSlice,
  initialState,
  reducers: {
    setDraft: (state, action) => {
      const draftKey: DistributionMessagesKeys = action.payload.key ?? DistributionMessagesKeys.Compose;
      state.drafts[draftKey].draft = action.payload.draft;
    },
    setGroupList: (state, action) => {
      const draftKey: DistributionMessagesKeys = action.payload.key ?? DistributionMessagesKeys.Compose;
      state.drafts[draftKey].groupList = action.payload.groupList;
    },
    setGroupDepartment: (state, action) => {
      const draftKey: DistributionMessagesKeys = action.payload.key ?? DistributionMessagesKeys.Compose;
      state.drafts[draftKey].groupDepartment = action.payload.groupDepartment;
    },
    setSelectedExtraEmails: (state, action) => {
      const draftKey: DistributionMessagesKeys = action.payload.key ?? DistributionMessagesKeys.Compose;
      state.drafts[draftKey].selectedExtraEmails = action.payload.selectedExtraEmails;
    },
    resetDraft: (state, action) => {
      const draftKey: DistributionMessagesKeys = action.payload.key;
      state.drafts[draftKey] = initialDraft;
    },
    setFieldsToOverwrite: (state, action) => {
      state.fieldsToOverwrite = action.payload.fields;
    },
    resetFieldsToOverwrite: (state, action) => {
      state.fieldsToOverwrite = [];
    },
    setLinkOptions: (state, action) => {
      state.linkOptions = {
        linkValidityTime: action.payload.time,
        linkAccessType: action.payload.accessType,
        isDeepLink: action.payload.isDeepLink,
        durationDays: action.payload.days,
        durationHours: action.payload.hours
      }
    },
    resetLinkOptions: (state, action) => {
      state.linkOptions = {
        linkValidityTime: LinkTimeOptions.NEVER,
        linkAccessType: LinkAccessOptions.DownloadPrint,
        isDeepLink: false,
        durationDays: OriginalDurationDays,
        durationHours: OriginalDurationHours
      }
    },
    setMessageToSend: (state, action) => {
      state.messageToSend = action.payload.message;
    }
  }
});

export const {
  setDraft, setGroupList, setGroupDepartment, setSelectedExtraEmails, resetDraft,
  setFieldsToOverwrite, resetFieldsToOverwrite, setLinkOptions, resetLinkOptions, setMessageToSend
} = draftSlice.actions;
export default draftSlice.reducer;
