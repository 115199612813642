import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainWhite, ChipColor, WhiteGrey } from '../../../theme/colors';

/**
 * Custom White button component with custom style using material ui button component
 */
const WhiteButton = styled(Button)<{
  activecolor?: string
  hovercolor?: string
}>(({ activecolor, hovercolor }) => ({
  textTransform: 'capitalize',
  backgroundColor: MainWhite,
  color: ChipColor,
  fontWeight: 700,
  padding: '8px 22px',
  boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '50px',
  '&:hover': {
    background: hovercolor ?? WhiteGrey,
    color: hovercolor ? MainWhite : ChipColor
  },
  '&:active': {
    background: activecolor ?? WhiteGrey,
    color: (activecolor) ? MainWhite : ChipColor
  }
}));

export default WhiteButton;
