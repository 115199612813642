import React, { lazy, Suspense } from 'react';

const LazyInvalidRecipients = lazy(async () => await import('./InvalidRecipients'));

const InvalidRecipients = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyInvalidRecipients {...props} />
  </Suspense>
);

export default InvalidRecipients;
