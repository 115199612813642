import React, { lazy, Suspense } from 'react';
import { type Props } from './ManagePermissions';

const LazyManagePermissions = lazy(async () => await import('./ManagePermissions'));

const ManagePermissions = (props: Props) => (
  <Suspense fallback={null}>
    <LazyManagePermissions {...props} />
  </Suspense>
);

export default ManagePermissions;
