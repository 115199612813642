import { ListItem, Box, Grid, Chip } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { useRef } from 'react';
import type { FC } from 'react';
import styles from './SceneListItem.module.scss';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import Tooltip from '../../common/Tooltip';
import ScriptService from '../../../services/ScriptService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export interface SceneListItemProps {
  sceneDescription: string
  scriptColor: string | undefined
  id: string
  index: number
  moveScene: (dragIndex: number, hoverIndex: number) => void
  sx: SxProps<Theme> | undefined
  onDuplicate: () => void
  onRemove: () => void
  sceneNumber: string
}

interface DragItem {
  index: number
  id: string
  type: string
}

// Item type for dragging reference
const itemType = 'sceneListItem';

/**
 * Scene list item that can be reordered on the right panel.
 */
const SceneListItem: FC<SceneListItemProps> = ({
  sceneDescription,
  scriptColor,
  id,
  index,
  moveScene,
  sx = {},
  onDuplicate,
  onRemove,
  sceneNumber
}: SceneListItemProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const ref = useRef<HTMLDivElement>(null)

  // Consider using dragRef and previewRef if you want to add the reorder icon as an anchor
  // const dragRef = useRef<HTMLButtonElement>(null);
  // const previewRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: itemType,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover (item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Dragging downwards (cursor below 50%)
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards (cursor above 50%)
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveScene(dragIndex, hoverIndex);

      // Mutate monitor item to avoid expensive index search
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => {
      return { isDragging: monitor.isDragging() }
    }
  })

  const opacity = isDragging ? 0 : 1

  // Uncomment lines below if choosing to use an anchor
  // drag(dragRef)
  // drop(preview(previewRef))

  drag(drop(ref));

  return (
    <ListItem
      className={styles.SceneListItem}
      data-testid="SceneListItem"
      sx={{ ...sx, opacity }}
      data-handler-id={handlerId}
      ref={ref as any}
    >
      <Grid container className={styles.listItemGrid} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={1}>
          <DragHandleIcon className={styles.dragHandle} />
        </Grid>
        <Grid item xs={9} className={styles.sceneContent}>
          <Box className={styles.sceneDescription}>
            {sceneNumber} {sceneDescription}
          </Box>
          {(scriptColor) && <Chip className={styles.colorChip} size={'small'} label={ScriptService.addSpaceToColor(scriptColor)}/>}
        </Grid>
        <Grid item container xs={2} spacing={1} className={styles.iconColumn}>
          <Grid item>
            <Tooltip title={t('action.duplicate')} placement={'left'}>
              <ContentCopyIcon
                fontSize='small'
                className={styles.copyIcon}
                onClick={onDuplicate}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('action.delete')} placement={'left'}>
              <CloseIcon
                fontSize='small'
                onClick={onRemove}
                className={styles.deleteIcon}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
};

export default SceneListItem;
