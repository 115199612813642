/*
 * Reducer file for the DocumentTree slice
 * used for when searching files and storing the folders / files
 */

import { createSlice } from '@reduxjs/toolkit';
import { DocumentTreeSlice } from './constants';
import { TreeRoot, DropBoxRoot, GoogleDriveRoot } from '../../services/documents/constants';
import { FileType } from '../../models/Document';
import type { FileCollectionMap, CollectionFile, CollectionTreeFile } from '../../models/Document';

export interface DocumentTreeState {
  loadingFiles: boolean
  fileCollection: FileCollectionMap
  fileHierarchy: CollectionTreeFile[]
}

const initialFileCollection: FileCollectionMap = {
  [TreeRoot]: {
    id: TreeRoot,
    root: true,
    icon: 'folder',
    parent: '',
    text: 'Documents',
    state: {
      opened: false
    },
    li_attr: {
      id: TreeRoot,
      treeIndex: 0,
      isWatermark: '0',
      size: '0',
      type: 'Folder',
      fileType: FileType.Other,
      isPublishedToStudio: false,
      cloudProviderName: ''
    }
  },
  [DropBoxRoot]: {
    id: DropBoxRoot,
    root: true,
    icon: 'dropbox',
    parent: '',
    text: 'DropBox',
    state: {
      opened: false
    },
    li_attr: {
      id: DropBoxRoot,
      treeIndex: 0,
      isWatermark: '0',
      size: '0',
      type: 'dropbox-folder',
      fileType: FileType.Other,
      isPublishedToStudio: false,
      cloudProviderName: ''
    }
  },
  [GoogleDriveRoot]: {
    id: GoogleDriveRoot,
    root: true,
    icon: 'googleDrive',
    parent: '',
    text: 'Google Drive',
    state: {
      opened: false
    },
    li_attr: {
      id: GoogleDriveRoot,
      treeIndex: 0,
      isWatermark: '0',
      size: '0',
      type: 'gDrive-folder',
      fileType: FileType.Other,
      isPublishedToStudio: false,
      cloudProviderName: ''
    }
  }
}

const initialState: DocumentTreeState = {
  loadingFiles: false,
  // initial file collection with the roots for box, dropbox and croogloo
  fileCollection: initialFileCollection,
  fileHierarchy: []
}

export const documentTreeSlice = createSlice({
  name: DocumentTreeSlice,
  initialState,
  reducers: {
    addToFileCollection: (state, action) => {
      const node: CollectionFile = action.payload.node;
      let fileCollection: FileCollectionMap = { ...state.fileCollection };
      if (fileCollection[node.id]) {
        fileCollection[node.id] = { ...node };
      } else {
        fileCollection = {
          ...fileCollection,
          [node.id]: node
        }
      }
      state.fileCollection = {
        ...fileCollection
      }
    },
    setFileHierarchy: (state, action) => {
      state.fileHierarchy = action.payload.hierarchy;
    },
    setLoadingFiles: (state, action) => {
      state.loadingFiles = action.payload.status;
    },
    setFolderToFilled: (state, action) => {
      const nodeId: string = action.payload.nodeId;
      const fileCollection: FileCollectionMap = { ...state.fileCollection };
      const node: CollectionFile = fileCollection[nodeId];
      node.li_attr.isFilled = '1';
      fileCollection[node.id] = { ...node };
      state.fileCollection = {
        ...fileCollection
      }
    },
    resetFileCollection: (state, action) => {
      state.fileCollection = initialFileCollection;
    }
  }
})

export const {
  addToFileCollection, setFileHierarchy, setLoadingFiles, setFolderToFilled, resetFileCollection
} = documentTreeSlice.actions;
export default documentTreeSlice.reducer;
