import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainWhite, WhiteGrey } from '../../../theme/colors';

/**
 * Custom disabled button component with custom style using material ui button component
 */
const DisabledButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  backgroundColor: WhiteGrey,
  color: MainWhite,
  padding: '8px 22px',
  boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '50px'
}));

export default DisabledButton;
