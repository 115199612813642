import type { EmailAndName } from '../models/DistributionList';
import ModalService from './ModalService';
import DraftService from './draft/DraftService';
import type { ContactListingsPage } from '../components/ContactListings/Constants';
import { OptionPopups } from '../components/ContactListings/Constants';
import type { Person } from '../models/Person';
import type { OptionProps } from '../models/OptionProps';
import { FileTypes, PageMenus } from '../models/FileTypes';
import { setContactsToEdit, setFallbackPage } from '../state/slices/Contacts';
import { store } from '../state/store';
import MessageService from './message/MessageService';
import type { FileAttachment } from '../models/Document';
import { CrooglooUrl } from '../models/CrooglooUrl';
import moment from 'moment';
import type { Moment } from 'moment';
import uuid from 'react-uuid';
import React from 'react';
import type { ReactNode } from 'react';
const { dispatch } = store;

interface IUtilityService {
  checkValidEmailAddresses: (emails: EmailAndName[]) => string[]
  isInValidEmailAddress: (email: string) => boolean
  isValidName: (name: string) => boolean
  isValidCastNumber: (number: string) => boolean
  handleContactsMore: (option: string, navigate: any, pageTitle: ContactListingsPage, currentPage: CrooglooUrl, id: string | null) => void
  cleanForKey: (key: string) => string
  removeMemberCount: (name: string) => string
  getFileExtension: (filename: string) => string
  millisecondsToDays: (ms: number) => number
  millisecondsToHours: (ms: number) => number
  daysToMilliseconds: (days: number) => number
  hoursToMilliseconds: (hours: number) => number
  hasInvalidNumber: (numberInput: number) => boolean
  getFileCategories: (fileType: FileTypes) => OptionProps[]
  compareNumbers: (a: number, b: number) => number
  compareStrings: (a: string, b: string) => number
  generateUniqueId: (fileName: string) => string
  getPageMenu: (fileType: FileTypes) => PageMenus
  isSuccessResponse: (responseCode: string) => boolean
  isWatermarked: (value: string) => boolean
  getFormattedDate: (value: Moment) => string
  uniqueUuid: () => string
  getTotalFileSizes: (files: FileAttachment[]) => string
  breakText: (text: string) => ReactNode
}

const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,}([-.]\w+)*$/;
const NameRegex = /,|;|:|\./;
const CastNumberRegex = /^\d[A-Za-z\d]*$/;
const ExtensionRegex = /^[\s\S]*\.[^.]{2,5}$/;
const MinIdBaseLength: number = 10;
const MaxIdBaseLength: number = 25;
const WSSuccessCode: string = '0';
const isWatermarkedValue: string = '1';
export const MegabyteConverter: number = 1000000;

class UtilityService implements IUtilityService {
  /**
   * checks to see if any invalid emails in the list provided
   * @param emails {EmailAndName[]}
   */
  checkValidEmailAddresses (emails: EmailAndName[]): string[] {
    const invalidEmailsFormatted: string[] = [];
    emails.forEach((email: EmailAndName) => {
      const formattedString: string = (email.name) ? String(email.name) + ' (' + String(email.email) + ')' : email.email;
      if (!invalidEmailsFormatted.includes(formattedString)) {
        if (this.isInValidEmailAddress(email.email)) {
          invalidEmailsFormatted.push(formattedString);
        }
      }
    });
    return invalidEmailsFormatted;
  }

  /**
   * checks to see if an email is invalid
   * @param email {string}
   */
  isInValidEmailAddress (email: string): boolean {
    const match = email.match(EmailRegex);
    return (match === null || match.index !== 0);
  }

  /**
   * checks to see if a name is invalid
   * @param name {string}
   */
  isValidName (name: string): boolean {
    return !(NameRegex.test(name));
  }

  /**
   * checks to see if a cast number is invalid
   * @param number {string}
   */
  isValidCastNumber (number: string): boolean {
    return CastNumberRegex.test(number);
  }

  /**
   * handle selecting of dropdown options from the different contact tables
   */
  handleContactsMore (option: string, navigate: any, pageTitle: ContactListingsPage, currentPage: CrooglooUrl, id: string | null): void {
    const contacts: Person[] = store.getState().contacts.pageContacts[pageTitle];
    const contact: Person | undefined = contacts.find((c: Person) => c.id === id);

    if (id) {
      switch (option) {
        case 'edit':
          dispatch(setFallbackPage({ page: currentPage }));
          navigate(`${CrooglooUrl.EDITCONTACT}/${id}`);
          break;
        case 'text':
          if (contact) {
            MessageService.showTestSmsModal([contact]);
          }
          break;
        case 'email':
          if (contact?.email) {
            DraftService.handleAddExtraEmail(contact.email);
            navigate(CrooglooUrl.COMPOSE);
          }
          break;
        case 'editDistribution':
          navigate(CrooglooUrl.LISTS);
          break;
        case 'DeleteContactModal':
          dispatch(setContactsToEdit({ contacts: [contact] }));
          ModalService.openCustomModal(
            option,
            {
              ...OptionPopups[option]
            }
          );
          break;
        case 'InviteContactModal':
          if (contact) {
            ModalService.openCustomModal(
              option,
              {
                ...OptionPopups[option],
                metaData: [`${contact.firstName} ${contact.lastName}`, contact.id]
              }
            );
          }
          break;
        default:
          dispatch(setContactsToEdit({ contacts: [contact] }));
          ModalService.openCustomModal(
            option,
            {
              ...OptionPopups[option]
            }
          );
          break;
      }
    }
  }

  cleanForKey (key: string): string {
    return key.trim().replace(/[^a-zA-Z\d\-_]/g, '').replace(/^(\d)/, 'a$1');
  }

  removeMemberCount (name: string): string {
    return name.substring(0, name.lastIndexOf(' ('));
  }

  getFileExtension (filename: string): string {
    let ext: string = '';
    const fileNameArray: string[] = filename.split('.');
    if (fileNameArray.length > 0) {
      ext = fileNameArray[fileNameArray.length - 1];
    }
    return ext;
  }

  millisecondsToDays (ms: number): number {
    const duration = moment.duration(ms, 'milliseconds');
    const days = duration.asDays();
    return days;
  }

  millisecondsToHours (ms: number): number {
    const duration = moment.duration(ms, 'milliseconds');
    const hours = duration.asHours();
    return hours;
  }

  daysToMilliseconds (days: number): number {
    return Math.round(days * 24 * 60 * 60 * 1000);
  }

  hoursToMilliseconds (hours: number): number {
    return Math.round(hours * 60 * 60 * 1000);
  }

  hasInvalidNumber (numberInput: number): boolean {
    let invalidNumber = false;
    if (numberInput === undefined || numberInput < 0 || numberInput > 1000) {
      invalidNumber = true;
    }
    return invalidNumber;
  }

  getFileCategories (fileType: FileTypes): OptionProps[] {
    let uploadType: OptionProps[] = [];
    if (fileType === FileTypes.ReportsAndSchedules) {
      uploadType = [
        {
          key: 'callsheet',
          value: 'callsheet',
          label: 'common.fileTypes.callSheets'
        },
        {
          key: 'castList',
          value: 'castList',
          label: 'common.fileTypes.castList'
        },
        {
          key: 'chrono',
          value: 'chrono',
          label: 'common.fileTypes.chrono'
        },
        {
          key: 'crewList',
          value: 'crewList',
          label: 'common.fileTypes.crewList'
        },
        {
          key: 'oneliner',
          value: 'oneliner',
          label: 'common.fileTypes.oneliner'
        },
        {
          key: 'dood',
          value: 'dood',
          label: 'common.fileTypes.dood'
        },
        {
          key: 'maps',
          value: 'maps',
          label: 'common.fileTypes.maps'
        },
        {
          key: 'photo',
          value: 'photo',
          label: 'common.fileTypes.photo'
        },
        {
          key: 'production',
          value: 'production',
          label: 'common.fileTypes.prodSched'
        },
        {
          key: 'shooting',
          value: 'shooting',
          label: 'common.fileTypes.shootSched'
        },
        {
          key: 'prepschedule',
          value: 'prepschedule',
          label: 'common.fileTypes.prepschedule'
        },
        {
          key: 'dpr',
          value: 'dpr',
          label: 'common.fileTypes.dpr'
        },
        {
          key: 'breakdown',
          value: 'breakdown',
          label: 'common.fileTypes.breakdown'
        },
        {
          key: 'clearance',
          value: 'clearance',
          label: 'common.fileTypes.clearance'
        },
        {
          key: 'script',
          value: 'script',
          label: 'common.fileTypes.script'
        },
        {
          key: 'script timing',
          value: 'script timing',
          label: 'common.fileTypes.scriptTiming'
        },
        {
          key: 'revision',
          value: 'revision',
          label: 'common.fileTypes.revision'
        },
        {
          key: 'side',
          value: 'side',
          label: 'common.fileTypes.sides'
        },
        {
          key: 'other',
          value: 'other',
          label: 'common.fileTypes.other'
        }
      ];
    } else if (fileType === FileTypes.Report) {
      uploadType = [
        {
          key: 'callsheet',
          value: 'callsheet',
          label: 'common.fileTypes.callSheets'
        },
        {
          key: 'castList',
          value: 'castList',
          label: 'common.fileTypes.castList'
        },
        {
          key: 'chrono',
          value: 'chrono',
          label: 'common.fileTypes.chrono'
        },
        {
          key: 'crewList',
          value: 'crewList',
          label: 'common.fileTypes.crewList'
        },
        {
          key: 'oneliner',
          value: 'oneliner',
          label: 'common.fileTypes.oneliner'
        },
        {
          key: 'dood',
          value: 'dood',
          label: 'common.fileTypes.dood'
        },
        {
          key: 'dpr',
          value: 'dpr',
          label: 'common.fileTypes.dpr'
        },
        {
          key: 'breakdown',
          value: 'breakdown',
          label: 'common.fileTypes.breakdown'
        },
        {
          key: 'clearance',
          value: 'clearance',
          label: 'common.fileTypes.clearance'
        },
        {
          key: 'script',
          value: 'script',
          label: 'common.fileTypes.script'
        },
        {
          key: 'script timing',
          value: 'script timing',
          label: 'common.fileTypes.scriptTiming'
        },
        {
          key: 'revision',
          value: 'revision',
          label: 'common.fileTypes.revision'
        },
        {
          key: 'sides',
          value: 'sides',
          label: 'common.fileTypes.sides'
        },
        {
          key: 'other',
          value: 'other',
          label: 'common.fileTypes.other'
        }
      ];
    } else if (fileType === FileTypes.Schedules) {
      uploadType = [
        {
          key: 'maps',
          value: 'maps',
          label: 'common.fileTypes.maps'
        },
        {
          key: 'production',
          value: 'production',
          label: 'common.fileTypes.prodSched'
        },
        {
          key: 'shooting',
          value: 'shooting',
          label: 'common.fileTypes.shootSched'
        },
        {
          key: 'prepschedule',
          value: 'prepschedule',
          label: 'common.fileTypes.prepschedule'
        }
      ];
    } else if (fileType === FileTypes.Script) {
      uploadType = [
        {
          key: 'script',
          value: 'script',
          label: 'common.fileTypes.script'
        },
        {
          key: 'script timing',
          value: 'script timing',
          label: 'common.fileTypes.scriptTiming'
        },
        {
          key: 'revision',
          value: 'revision',
          label: 'common.fileTypes.revision'
        },
        {
          key: 'side',
          value: 'side',
          label: 'common.fileTypes.sides'
        }
      ];
    } else if (fileType === FileTypes.Photo) {
      uploadType = [
        {
          key: 'photo',
          value: 'photo',
          label: 'common.fileTypes.photo'
        }
      ];
    } else if (fileType === FileTypes.Document) {
      uploadType = [
        {
          key: 'breakdown',
          value: 'breakdown',
          label: 'common.fileTypes.breakdown'
        },
        {
          key: 'callsheet',
          value: 'callsheet',
          label: 'common.fileTypes.callSheets'
        },
        {
          key: 'chrono',
          value: 'chrono',
          label: 'common.fileTypes.chrono'
        },
        {
          key: 'clearance',
          value: 'clearance',
          label: 'common.fileTypes.clearance'
        },
        {
          key: 'dood',
          value: 'dood',
          label: 'common.fileTypes.dood'
        },
        {
          key: 'dpr',
          value: 'dpr',
          label: 'common.fileTypes.dpr'
        },
        {
          key: 'oneliner',
          value: 'oneliner',
          label: 'common.fileTypes.oneliner'
        },
        {
          key: 'maps',
          value: 'maps',
          label: 'common.fileTypes.maps'
        },
        {
          key: 'photo',
          value: 'photo',
          label: 'common.fileTypes.photo'
        },
        {
          key: 'prepschedule',
          value: 'prepschedule',
          label: 'common.fileTypes.prepschedule'
        },
        {
          key: 'production',
          value: 'production',
          label: 'common.fileTypes.prodSched'
        },
        {
          key: 'revision',
          value: 'revision',
          label: 'common.fileTypes.revision'
        },
        {
          key: 'script',
          value: 'script',
          label: 'common.fileTypes.script'
        },
        {
          key: 'shooting',
          value: 'shooting',
          label: 'common.fileTypes.shootSched'
        },
        {
          key: 'side',
          value: 'side',
          label: 'common.fileTypes.sides'
        },
        {
          key: 'techsurvey',
          value: 'techsurvey',
          label: 'common.fileTypes.techsurvey'
        },
        {
          key: 'other',
          value: 'other',
          label: 'common.fileTypes.other'
        }
      ];
    }
    return uploadType;
  }

  compareNumbers (a: number, b: number): number {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
  }

  compareStrings (a: string, b: string): number {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
  }

  generateUniqueId (fileName: string): string {
    fileName = fileName.trim();
    const fileExtension = (fileName.match(ExtensionRegex) === null)
      ? ''
      : fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
    let uniqueId: string = fileName.replace(/[^A-Za-z\d]/g, '').replace(/(^\d)/, 'f$1');
    if (uniqueId.length < MinIdBaseLength) {
      uniqueId = uniqueId.padEnd(MinIdBaseLength, (Math.random() * 10).toString().replace('.', ''));
    } else if (uniqueId.length > MaxIdBaseLength) {
      uniqueId = uniqueId.substring(0, MaxIdBaseLength);
    }
    uniqueId += '_' + new Date().getTime().toString() + '_';
    uniqueId = uniqueId.padEnd(uniqueId.length + 8,
      (Math.random() * 10).toString().replace('.', ''));
    uniqueId += (fileExtension === '' ? '' : '.') + fileExtension;
    return uniqueId;
  }

  getPageMenu (fileType: FileTypes): PageMenus {
    let page: PageMenus = PageMenus.Documents;
    switch (fileType) {
      case FileTypes.Template:
        page = PageMenus.Templates;
        break;
      case FileTypes.Script:
        page = PageMenus.Scripts;
        break;
      case FileTypes.Schedules:
        page = PageMenus.Schedules;
        break;
    }
    return page;
  }

  isSuccessResponse (responseCode: string): boolean {
    return responseCode === WSSuccessCode;
  }

  isWatermarked (value: string): boolean {
    return value === isWatermarkedValue;
  }

  getFormattedDate (value: Moment): string {
    const format: string = 'MMM DD YYYY h:mmA';
    return value.format(format);
  }

  uniqueUuid (): string {
    return uuid();
  }

  getTotalFileSizes (files: FileAttachment[]): string {
    let size: string = '0';
    if (files.length > 0) {
      let totalSize: number = files.reduce((acc: number, file: FileAttachment) => acc + file.size, 0);
      if (totalSize > 0) {
        totalSize = totalSize / MegabyteConverter;
        size = Number.isInteger(totalSize) ? String(totalSize) : totalSize.toFixed(2);
      }
    }
    return size;
  }

  breakText (text: string): ReactNode {
    return text.split('\n').map((word, index, array) => (
      <React.Fragment key={index}>
        {word}
        {index !== array.length - 1 && <br />}
      </React.Fragment>
    ))
  }
}

const utilityService: IUtilityService = new UtilityService();
export default utilityService;
