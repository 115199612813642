import React, { lazy, Suspense } from 'react';
import type { DocumentsProps } from './Documents';

const LazyDocuments = lazy(async () => await import('./Documents'));

const Documents = (props: DocumentsProps) => (
  <Suspense fallback={null}>
    <LazyDocuments {...props} />
  </Suspense>
);

export default Documents;
