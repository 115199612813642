import React, { lazy, Suspense } from 'react';

const LazyStripePayment = lazy(async () => await import('./StripePayment'));

const StripePayment = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyStripePayment {...props} />
  </Suspense>
);

export default StripePayment;
