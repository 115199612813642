export interface GoogleLoginResponse {
  access_token: string
  token_type: string
  expires_in: number
  scope: string
  authuser: string
  prompt: string
}

export enum GoogleRequestType {
  Contacts = 0,
  Files = 1,
  Compose = 2
}

export interface GoogleDriveEntry {
  id: string
  kind: string
  mimeType: string
  name: string
  modifiedTime?: string
}

interface GoogleDriveResponseError {
  code: number
  message: string
  status: string
  errors: any[]
}

export interface GoogleDriveResponse {
  incompleteSearch: boolean
  kind: string
  nextPageToken: string
  files: GoogleDriveEntry[]
  error?: GoogleDriveResponseError
}

export interface GoogleDriveFolderType {
  params: string
  nodeId: string
}
