import React, { lazy, Suspense } from 'react';

const LazySendingFilesError = lazy(async () => await import('./SendingFilesError'));

const SendingFilesError = () => (
  <Suspense fallback={null}>
    <LazySendingFilesError />
  </Suspense>
);

export default SendingFilesError;
