import type { FileTypes } from './FileTypes';

export interface WatermarkValues {
  line1: string
  line2: string
  typeline1: string
  typeline2: string
}

export const DefaultWatermarkValues = {
  line1: '',
  line2: '',
  typeline1: '',
  typeline2: ''
};

export enum UploadFileLocation {
  Compose = 'Compose',
  Documents = 'Documents',
  Scripts = 'Script',
  Sides = 'Sides',
  Photos = 'Photos'
}

export interface FileToUploadAttribute {
  id: string
  value?: string | number
}

export interface FileToUpload {
  _ID: string
  _NAME: string
  entityType: string
  pageName: string
  tenantId: string
  attributes: FileToUploadAttribute[]
  _fileType?: FileTypes
}

export const OriginalCurrentFileNumber: number = 0;
