import type { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { WhiteGrey, BorderBlack, LightGrey } from '../../../theme/colors';
import { styled } from '@mui/material/styles';

interface Props {
  isButton: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  initials?: string
  width?: number
  height?: number
  fontSize?: string
}

const StyledUserMenuButton = styled(IconButton)<{
  width: number
  height: number
}>(({ width, height }) => ({
  p: 0,
  width: (width > 0) ? width : 40,
  height: (height > 0) ? height : 40,
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

interface StyledIconProps {
  isButton: boolean
  width?: number
  height?: number
  fontSize?: string
}

const StyledAvatarIcon = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'isButton'
})<StyledIconProps>(({ isButton, width, height, fontSize }) => ({
  color: BorderBlack,
  backgroundColor: WhiteGrey,
  '&:hover': {
    backgroundColor: (isButton) ? LightGrey : WhiteGrey,
    cursor: (isButton) ? 'hover' : 'default'
  },
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  p: 0,
  width: width ?? 40,
  height: height ?? 40,
  fontSize: fontSize ?? '1.25rem'
}));

/**
 * OP Button Avatar component
 * Displayed in Header - can be used in other places too
 */
const OPButton: FC<Props> = (props: Props) => {
  const initials: string = props.initials ?? 'OP';
  const onClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    if (props.onClick) {
      props.onClick(event);
    }
  }

  return (
    <StyledUserMenuButton
      size="large"
      onClick={onClick}
      disableRipple
      width={props.width ?? 0}
      height={props.height ?? 0}
    >
      <StyledAvatarIcon
        isButton={props.isButton}
        width={props.width}
        height={props.height}
        fontSize={props.fontSize}
      >
        {initials}
      </StyledAvatarIcon>
    </StyledUserMenuButton>
  )
}

export default OPButton;
