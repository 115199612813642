import React, { lazy, Suspense } from 'react';

const LazyMessageSent = lazy(async () => await import('./MessageSent'));

const MessageSent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyMessageSent {...props} />
  </Suspense>
);

export default MessageSent;
