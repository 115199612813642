import type { AlertColor } from '@mui/material'

export interface ToastProps {
  message: string
  type: AlertColor
  isShown: boolean
  icon?: ToastIcons
  content?: Record<string, string>
  toastAction?: ToastAction
}

export const InitialToastValues: ToastProps = {
  message: '',
  type: 'success',
  isShown: false
}

export enum ToastIcons {
  Success = 'success',
  Info = 'info',
  Time = 'time'
}

export interface ToastAction {
  action: () => void
  content?: string
}
