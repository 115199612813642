import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { MainWhite } from '../../theme/colors';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { makeStyles } from '@mui/styles';

import EditorToolbar, { modules, formats, maxToolbarHeight } from '../ThirdParty/EditorToolbar';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '10px'
  },
  expanded: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 2000,
    background: MainWhite
  },
  editor: {
    height: '240px',
    '& .ql-container': {
      borderTop: '1px solid #ccc',
      borderBottom: 'none',
      borderRight: 'none',
      borderLeft: 'none'
    }
  },
  noToolbarEditor: {
    height: 'inherit',
    '& .ql-container': {
      border: 'none'
    }
  },
  expandedEditor: {
    height: `calc(100vh - ${maxToolbarHeight})`
  }
});

interface Props {
  value: string | undefined
  label?: string
  toolbar: boolean
  onChange?: (value: string) => void
  setInsertFunction?: any
  onFocus?: () => void
}

const TextEditor: FC<Props> = (props: Props) => {
  const label: string = props.label ?? '';
  const classes = useStyles();

  const quillRef = useRef<ReactQuill | null>(null);
  const [editorExpanded, setEditorExpanded] = useState<boolean>(false);

  /**
   * Note: For now just insert simple text {firstName}|{lastName} which is picked up later in building message
   * React Quill is difficult to insert html tags for some reason
   * To get it working, it only inserts it on new lines every time and not in the inline text
   * TODO: look into maybe replacing react quill with another component in the future
   */
  const insertText = (varName: string) => {
    const textToInsert: string = '{' + varName + '}';
    const quillEditor = quillRef.current?.getEditor();
    if (quillEditor) {
      const selection = quillEditor.getSelection();
      const cursorPosition: number = (selection) ? selection.index : 0;
      quillEditor.insertText(cursorPosition, textToInsert);
      const newPosition = cursorPosition + textToInsert.length;
      quillEditor.setSelection({ index: newPosition, length: 0 });
    }
  };

  if (props.setInsertFunction) { props.setInsertFunction(insertText); }

  return (
    <>
      <div className={`${(editorExpanded) ? classes.expanded : classes.wrapper}`}>
        {props.toolbar &&
          <EditorToolbar
            quillRef={quillRef}
            setEditorExpanded={setEditorExpanded}
            editorExpanded={editorExpanded}
          />
        }
        <ReactQuill
          id='message'
          className={`${(editorExpanded) ? classes.expandedEditor : (!props.toolbar ? classes.noToolbarEditor : classes.editor)}`}
          modules={(props.toolbar) ? modules : {}}
          formats={formats}
          readOnly={false}
          theme={(props.toolbar) ? 'snow' : ''}
          value={props.value}
          onChange={props.onChange}
          onFocus={() => {
            if (props.onFocus) props.onFocus();
          }}
          placeholder={label}
          ref={(el) => {
            if (el) quillRef.current = el; // Assign quillRef
          }}
        />
      </div>
    </>
  )
}

export default TextEditor;
