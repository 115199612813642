import { Box, Grid, List, Paper } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import type { FC } from 'react';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

import AutorenewIcon from '@mui/icons-material/Autorenew';

import { BorderGrey } from '../../theme/colors';
import styles from './GenerateSides.module.scss';
import SceneListItem from './SceneListItem/SceneListItem';
import SceneSelectionTabs from './sceneSelectionTabs/SceneSelectionTabs';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import ScriptListDrawerTabs from './sciptListDrawer/ScriptListDrawerTabs';
import SuccessButton from '../common/buttons/SuccessButton';
import WhiteButton from '../common/buttons/WhiteButton';
import type { GenerateScripts } from '../../models/Script';
import { LocationGroup } from '../../models/Scene';
import type { Scene, ScriptScenesResult, Character, Location } from '../../models/Scene';
import MainSection from '../common/MainSection';
import ScriptService from '../../services/ScriptService';
import { scrollStyles, drawerWidth } from '../../theme/styles';
import UtilityService from '../../services/UtilityService';
import FormatOptions from '../modals/FormatOptions/FormatOptions.lazy';
import { FormatOptionsModal } from '../modals/FormatOptions/constants';
import ModalService from '../../services/ModalService';
import Toast from '../common/toast/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { ToastProps } from '../../models/Toast';
import SceneSelectionTabHeaders from './sceneSelectionTabs/SceneSelectionTabHeaders';
import { setAllSelectedScenes } from '../../state/slices/Sides';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import ChipsDropdown from '../common/ChipsDropdown';
import type { ChipDropdownData } from '../common/ChipsDropdown';
import AttachCallSheet from '../modals/AttachCallSheet/AttachCallSheet.lazy';
import GenerateSidesSuccess from '../modals/GenerateSidesSuccess/GenerateSidesSuccess.lazy';
import { GenerateSidesSuccessModal } from '../modals/GenerateSidesSuccess/constants';
import type { AlertColor } from '@mui/material/Alert';

export const buttonHeight: string = '40px';
export const buttonMargin: string = '15px';
export const sceneTabHeight: string = '38px';

const paperStyle = {
  display: 'flex',
  alignItems: 'left',
  borderRadius: '5px',
  height: 'auto',
  boxShadow: 'none',
  border: `1px solid ${String(BorderGrey)}`
}

export enum GenerateSidesPage {
  Title = 'Generate Sides'
}

export interface Props {
  pageTitle: GenerateSidesPage
}

/**
 * Render sides generation page.
 */
const GenerateSides: FC<Props> = ({ pageTitle }: Props) => {
  const dispatch = useAppDispatch();

  // Translation fct
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  useEffect(() => {
    ScriptService.getGenerateSidesMeta().then(() => { setFetching(false) }).catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle]);

  // Force update required to ensure consistency between all tabs
  const [, updateState] = useState();
  const [isFetching, setFetching] = useState<boolean>(true);

  const forceUpdate = useCallback(() => { updateState({} as any); }, []);

  // Scripts for the drawer
  const [selectedScript, setSelectedScript] = useState<GenerateScripts | null>(null);
  const [scenesToShow, setScenesToShow] = useState<Scene[]>([]);
  const [charactersToShow, setCharactersToShow] = useState<Character[]>([]);
  const [locationsIntToShow, setLocationsIntToShow] = useState<Location[]>([]);
  const [locationsExtToShow, setLocationsExtToShow] = useState<Location[]>([]);
  const [locationsOtherToShow, setLocationsOtherToShow] = useState<Location[]>([]);

  // Scenes for the left scene list
  const [selectedScenes, setSelectedScenes] = useState<Scene[]>([]);
  // Ordered scenes for the right scene list
  const [orderedScenes, setOrderedScenes] = useState<Scene[]>([]);

  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);
  // Drawer state
  const [isOpen, setOpen] = useState<boolean>(true);

  const [sceneTab, setSceneTab] = useState<number>(0);

  const [selectedScripts, setSelectedScripts] = useState<GenerateScripts[]>([]);
  const [selectedScriptIndex, setSelectedTabIndex] = useState<number>(0);

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  // handling the tab change event
  const handleSceneTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setSceneTab(newTab);
  };

  const handleSelectedScripts = (script: GenerateScripts, removeIndex?: number): void => {
    let newIndex: number = 0;
    let currentSelectedScripts = [...selectedScripts];
    if (removeIndex !== undefined) {
      // get index of currently selected script
      const currentIndex: number = currentSelectedScripts.map((selectedScript: GenerateScripts) => selectedScript.id).indexOf(script.id);
      currentSelectedScripts = currentSelectedScripts.filter(
        (script: GenerateScripts, index: number) => index !== removeIndex
      );
      // set the new Index of the currently selected script
      if (removeIndex > currentIndex) {
        newIndex = currentIndex;
      } else if (removeIndex < currentIndex) {
        newIndex = currentIndex - 1;
      } else {
        const currentSelectedLastIndex = currentSelectedScripts.length - 1;
        newIndex = Math.min(removeIndex, currentSelectedLastIndex);
      }
    } else {
      if (!currentSelectedScripts.some(s => s.id === script.id)) {
        currentSelectedScripts.push(script);
        newIndex = currentSelectedScripts.length - 1;
      } else {
        newIndex = currentSelectedScripts.findIndex(s => s.id === script.id);
      }
    }
    setSelectedScripts(currentSelectedScripts);
    setSelectedTabIndex(newIndex);
  }

  const toggleDrawer = (): void => {
    setOpen(!isOpen);
  };

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  // Changes the script selection, clearing any selected scene
  const updateSelectedScript = (script: GenerateScripts, removeIndex?: number) => {
    if (selectedScript?.id !== script.id) {
      setFetching(true);
      setSelectedScript(script);
      ScriptService.fetchScriptScenes(script, tab).then((result: ScriptScenesResult) => {
        handleSelectedScripts(script, removeIndex);
        setScenesToShow(result.scenes);
        setCharactersToShow(result.characters);
        setLocationsIntToShow(result.locations[LocationGroup.INT])
        setLocationsExtToShow(result.locations[LocationGroup.EXT])
        setLocationsOtherToShow(result.locations[LocationGroup.OTHER])
        forceUpdate();
        setFetching(false);
      }).catch((err) => console.error(err));
    }
  }

  const resetSelectedScripts = (): void => {
    setSelectedScript(null);
    setSelectedScripts([]);
    setScenesToShow([]);
    setCharactersToShow([]);
    setLocationsIntToShow([]);
    setLocationsExtToShow([]);
    setLocationsOtherToShow([]);
    forceUpdate();
  }

  const removeSelectedScript = (scriptToRemove: GenerateScripts, index: number) => {
    const currentSelectedScripts: GenerateScripts[] = [...selectedScripts];
    if (currentSelectedScripts.length === 1) {
      resetSelectedScripts();
    } else if (selectedScript?.id === scriptToRemove.id) {
      let newScriptToShow: GenerateScripts;
      if (index === currentSelectedScripts.length - 1) {
        newScriptToShow = currentSelectedScripts[index - 1];
      } else {
        newScriptToShow = currentSelectedScripts[index + 1];
      }
      updateSelectedScript(newScriptToShow, index);
    } else if (selectedScript) {
      handleSelectedScripts(selectedScript, index);
    }
  }

  // Character selected with checkbox on left panel
  const selectCharacter = (character: Character) => {
    let newOrderedScenes: Scene[] = orderedScenes;
    let newSelectedScenes: Scene[] = selectedScenes;
    const characterScenes: string[] = character.scenes;
    characterScenes.forEach((characterScene: string) => {
      const scene: Scene | undefined = scenesToShow.find((s: Scene) => s.id === characterScene);
      if (scene) {
        if (!selectedScenes.some(s => s.id === scene.id)) {
          newOrderedScenes = [...newOrderedScenes, scene];
          newSelectedScenes = [...newSelectedScenes, scene];
        }
      }
    });
    setSelectedScenes(newSelectedScenes);
    setOrderedScenes(newOrderedScenes);
    forceUpdate();
  }

  // Location selected with checkbox on left panel
  const selectLocation = (location: Location) => {
    let newOrderedScenes: Scene[] = orderedScenes;
    let newSelectedScenes: Scene[] = selectedScenes;
    const locationScenes: string[] = location.scenes;
    locationScenes.forEach((locationScene: string) => {
      const scene: Scene | undefined = scenesToShow.find((s: Scene) => s.id === locationScene);
      if (scene) {
        if (!selectedScenes.some(s => s.id === scene.id)) {
          newOrderedScenes = [...newOrderedScenes, scene];
          newSelectedScenes = [...newSelectedScenes, scene];
        }
      }
    })
    setSelectedScenes(newSelectedScenes);
    setOrderedScenes(newOrderedScenes);
    forceUpdate();
  }

  // Scene selected with checkbox on left panel
  const selectScene = (scene: Scene) => {
    if (!selectedScenes.some(s => s.id === scene.id)) {
      if (!orderedScenes.some(s => s.id === scene.id)) {
        const newOrderScenes: Scene[] = [...orderedScenes, scene];
        setOrderedScenes(newOrderScenes);
      }
      const newSelectedScenes: Scene[] = [...selectedScenes, scene];
      setSelectedScenes(newSelectedScenes);
      forceUpdate();
    }
  }

  // character deselected with checkbox on left panel
  const deselectCharacter = (character: Character) => {
    const characterScenes: string[] = character.scenes;
    let newOrderedScenes: Scene[] = orderedScenes;
    let newSelectedScenes: Scene[] = selectedScenes;
    characterScenes.forEach((characterScene: string) => {
      const scene: Scene | undefined = scenesToShow.find((s: Scene) => s.id === characterScene);
      if (scene) {
        if (selectedScenes.some(s => s.id === scene.id)) {
          newSelectedScenes = newSelectedScenes.filter(s => s.id !== scene.id);
        }
        if (orderedScenes.some(s => s.id === scene.id)) {
          newOrderedScenes = newOrderedScenes.filter(s => s.id !== scene.id);
        }
      }
    });
    setOrderedScenes(newOrderedScenes);
    setSelectedScenes(newSelectedScenes);
    forceUpdate();
  }

  // Deselects a location from the left panel checkbox
  const deselectLocation = (location: Location) => {
    const locationScenes: string[] = location.scenes;
    let newOrderedScenes: Scene[] = orderedScenes;
    let newSelectedScenes: Scene[] = selectedScenes;
    locationScenes.forEach((locationScene: string) => {
      const scene: Scene | undefined = scenesToShow.find((s: Scene) => s.id === locationScene);
      if (scene) {
        if (selectedScenes.some(s => s.id === scene.id)) {
          newSelectedScenes = newSelectedScenes.filter(s => s.id !== scene.id);
        }
        if (orderedScenes.some(s => s.id === scene.id)) {
          newOrderedScenes = newOrderedScenes.filter(s => s.id !== scene.id);
        }
      }
    });
    setOrderedScenes(newOrderedScenes);
    setSelectedScenes(newSelectedScenes);
    forceUpdate();
  }

  // Deselects a scene from the left panel checkbox
  const deselectScene = (scene: Scene) => {
    const sceneIndex = selectedScenes.findIndex(s => s.id === scene.id)
    if (sceneIndex >= 0) {
      selectedScenes.splice(sceneIndex, 1);
      setSelectedScenes(selectedScenes);
      setOrderedScenes(orderedScenes.filter(s => s.id !== scene.id));
      forceUpdate();
    }
  }

  const closeScriptPanel = () => {
    if (isOpen) setOpen(false);
  }

  // Toggles a scene by clicking on a checkbox from the left panel
  const toggleScene = (scene: Scene, isSelected: boolean, closePanel: boolean) => {
    isSelected ? selectScene(scene) : deselectScene(scene);
    if (closePanel) closeScriptPanel();
  }

  // Toggles a character by clicking on a checkbox from the left panel
  const toggleCharacter = (character: Character, isSelected: boolean) => {
    isSelected ? selectCharacter(character) : deselectCharacter(character);
    closeScriptPanel();
  }

  // Toggles a location by clicking on a checkbox from the left panel
  const toggleLocation = (location: Location, isSelected: boolean) => {
    isSelected ? selectLocation(location) : deselectLocation(location);
    closeScriptPanel();
  }

  // Reorder a selected scene
  const moveScene = useCallback((dragIndex: number, hoverIndex: number) => {
    setOrderedScenes((prevCards: Scene[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
  }, [])

  // Create a duplicate of a given scene and place right next to the original
  const duplicateOrderedScene = (scene: Scene, index: number) => {
    orderedScenes.splice(index, 0, { ...scene });
    setOrderedScenes(orderedScenes);
    forceUpdate();
  }

  /**
   * Removes a scene from the ordered list, possibly checking off the corresponding
   * scene, character(s) and location(s) from the left panel. This is triggered
   * by clicking on the "x" close button next to a scene from the right panel.
   *
   * @param index The index of the scene to remove in the oredered list.
   * @param scene The scene to remove.
   */
  const removeOrderedScene = (index: number, scene: Scene) => {
    orderedScenes.splice(index, 1);
    setOrderedScenes(orderedScenes);
    // If no duplicate of the removed scene remove from selected
    if (!orderedScenes.some(s => s.id === scene.id)) {
      // Remove selected scene from scene tab
      if (selectedScenes.some(s => s.id === scene.id)) {
        setSelectedScenes(selectedScenes.filter(s => s.id !== scene.id));
      }
    }

    // Ensure consistent state across all tabs
    forceUpdate();
  }

  // Renders a scene list item for the ordered list on the right panel
  const renderSceneListItem = (scene: Scene, index: number) => {
    const { id, sceneNumber, description, scriptColor } = scene;
    return (
      <SceneListItem
        sceneDescription={description}
        scriptColor={scriptColor}
        index={index}
        id={id}
        moveScene={moveScene}
        key={`${id}_${index}`} // key must not be only ID because duplicates will have same ID
        sx={index === (orderedScenes.length - 1) ? undefined : { borderBottom: '1px solid rgba(0,0,0,0.1)' }}
        onDuplicate={() => { duplicateOrderedScene(scene, index); }}
        onRemove={() => { removeOrderedScene(index, scene); }}
        sceneNumber={sceneNumber}
      />
    )
  }

  // Unchecks all selected scenes
  const clearAllSceneSelection = () => {
    setSelectedScenes([]);
    setOrderedScenes([]);
  };

  const handleScriptOrder = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const currentSelectedScenes: Scene[] = [...orderedScenes];
    const orderedSelectedScenes: Scene[] = currentSelectedScenes.sort(function (a: Scene, b: Scene) {
      const aEpisode = Math.floor(parseInt(a.id));
      const bEpisode = Math.floor(parseInt(b.id));
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const aScene = parseInt((a.id + '').split('.')[1]);
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const bScene = parseInt((b.id + '').split('.')[1]);
      const aScriptColor: string = a.scriptColor ?? '';
      const bScriptColor: string = b.scriptColor ?? '';
      return UtilityService.compareStrings(aScriptColor, bScriptColor) || UtilityService.compareNumbers(aEpisode, bEpisode) || UtilityService.compareNumbers(aScene, bScene);
    });
    setOrderedScenes(orderedSelectedScenes);
  }

  const handleDropdownSelect = (value: ChipDropdownData): void => {
    const sceneToAdd: Scene | undefined = scenesToShow.find((scene: Scene) => scene.id === value.id);
    if (sceneToAdd) {
      toggleScene(sceneToAdd, !selectedScenes.some((s: Scene) => s.id === sceneToAdd.id), false);
    }
  }

  const handleSubmit = (): void => {
    if (orderedScenes.length > 0) {
      dispatch(setAllSelectedScenes({ scenes: orderedScenes }));
      ModalService.openCustomModal(FormatOptionsModal, {
        heading: 'generateSides.formatOptions.heading',
        confirmButton: 'action.next'
      });
    } else {
      handleShowToast('generateSides.formatOptions.noScene', 'error', ToastIcons.Info);
    }
  }

  const handleSuccess = (message: string) => {
    clearAllSceneSelection();
    resetSelectedScripts();
    ModalService.openCustomModal(GenerateSidesSuccessModal, {
      heading: 'generateSides.formatOptions.successHeader',
      content: message,
      confirmButton: 'action.ok'
    })
  };

  const handleError = (message: string) => {
    handleShowToast(message, 'error', ToastIcons.Info);
  };

  return (
    <Box className={styles.GenerateSides}>
      <SlideDrawer
        drawerWidth={drawerWidth}
        open={isOpen}
        toggleDrawer={toggleDrawer}
        anchor={'left'}
        buttonText={'generateSides.drawer.buttonText'}
      >
        <ScriptListDrawerTabs
          tab={tab}
          onChange={handleTabChange}
          selectedScript={selectedScript}
          setSelectedScript={updateSelectedScript}
          removeSelectedScript={removeSelectedScript}
          selectedScripts={selectedScripts}
        />
      </SlideDrawer>
      <MainSection open={isOpen} drawerwidth={drawerWidth}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <ChipsDropdown
              data={showScenesInDropdown(scenesToShow, selectedScripts, selectedScriptIndex)}
              readValue={showScenesInDropdown(selectedScenes)}
              setValue={handleDropdownSelect}
              placeholder={'generateSides.quickSceneSelect.placeholder'}
              showChips={false}
              wrapItems={true}
              sx={{
                display: 'flex'
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.mainSection} data-testid="GenerateSides">
          <Grid item xs={12} md={5}>
            <SceneSelectionTabHeaders
              selectedScripts={selectedScripts}
              tabIndex={selectedScriptIndex}
              handleChange={updateSelectedScript}
              remove={removeSelectedScript}
              isScriptPanelOpen={isOpen}
              setScriptPanelOpen={setOpen}
            />
            <Paper
              component="form"
              sx={paperStyle}
              onClick={closeScriptPanel}
            >
              <SceneSelectionTabs
                tab={sceneTab}
                onChange={handleSceneTabChange}
                scenes={scenesToShow}
                selectedScenes={selectedScenes}
                characters={charactersToShow}
                locationsInt={locationsIntToShow}
                locationsExt={locationsExtToShow}
                locationsOther={locationsOtherToShow}
                toggleScene={toggleScene}
                toggleCharacter={toggleCharacter}
                toggleLocation={toggleLocation}
                isFetching={isFetching}
                isDrawerOpen={isOpen}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={7} sx={{ marginTop: `${sceneTabHeight}`, paddingLeft: '5px' }}>
            <Paper
              component="form"
              sx={paperStyle}
            >
              <Box sx={{ width: '100%' }}>
                <Grid container>
                  <Grid
                    container
                    item
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{
                      padding: '15px 10px',
                      gap: '15px'
                    }}
                  >
                    <WhiteButton
                      disableRipple
                      onClick={(event) => handleScriptOrder(event)}
                    >
                      {t('generateSides.scriptOrder')}
                    </WhiteButton>
                    <div
                      className={styles.clearSceneSelectionBtn}
                      onClick={() => {
                        clearAllSceneSelection();
                        forceUpdate();
                      }}
                    >
                      <AutorenewIcon className={styles.autoRenewIcon} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      paddingX: '20px',
                      height: 'auto',
                      overflow: 'auto',
                      width: '100%',
                      ...scrollStyles
                    }}
                  >
                    <List sx={{ padding: 0 }}>
                      {orderedScenes.map((scene: Scene, index: number) => {
                        return renderSceneListItem(scene, index);
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Grid
              item
              xs={12}
              textAlign={'center'}
              sx={{
                marginTop: '15px',
                height: buttonHeight
              }}
            >
              <SuccessButton
                  sx={{ width: '100%', maxWidth: '553px' }}
                  onClick={handleSubmit}
              >
                {t('action.submit')}
              </SuccessButton>
            </Grid>
          </Grid>
        </Grid>
      </MainSection>
      <FormatOptions onSuccess={handleSuccess} onError={handleError} />
      <AttachCallSheet onSuccess={handleSuccess} onError={handleError} />
      <GenerateSidesSuccess />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={String(t(toastStatus.message))}
        icon={toastStatus.icon}
      />
    </Box>
  )
};

export default GenerateSides;

function showScenesInDropdown (
  scenesToShow: Scene[],
  selectedScripts?: GenerateScripts[],
  index?: number
): ChipDropdownData[] {
  let scenes: ChipDropdownData[] = [];
  if (selectedScripts) {
    const script: GenerateScripts = selectedScripts[index ?? 0];
    if (script) {
      scenes = [{
        id: script.id,
        name: ScriptService.getScriptDisplayName(script.episode, script.color).toLowerCase(),
        header: true
      }];
    }
  }
  scenes = [...scenes, ...scenesToShow.map(
    (scene: Scene) => {
      const characters: string = (scene.characters.length > 0) ? ' (' + scene.characters.join(', ') + ')' : '';
      const name: string = scene.sceneNumber + ', ' + scene.description + characters;
      return (
        {
          id: scene.id,
          name
        }
      )
    }
  )];
  return scenes;
}
