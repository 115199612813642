import { createTheme } from '@mui/material/styles';
import { CrooglooGreen, MainWhite } from './colors';

/**
 * The theme for the daterangepicker to overwrite styles
 */
export const dateRangeTheme = createTheme({
  palette: {
    primary: {
      main: CrooglooGreen
    }
  }
});

/**
 * The theme for the datepicker to overwrite styles
 */
export const datePickerTheme = createTheme({
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: {
          width: '320px',
          minWidth: '320px',
          filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          borderRadius: '10px',
          '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
            color: MainWhite,
            background: CrooglooGreen,
            border: `1px solid ${CrooglooGreen}`
          }
        }
      }
    }
  }
});
