import type { FC, ChangeEventHandler } from 'react';
import { Paper, IconButton, InputBase } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { BorderGrey } from '../../theme/colors';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

interface Props {
  key?: string
  margin?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  maxWidth?: string
  sx?: SxProps<Theme>
}

/**
 * Reusable SearchBar component
 */
const SearchBar: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const placeholder = (props.placeholder !== undefined && props.placeholder !== null) ? String(t(props.placeholder)) : String(t('slideDrawer.searchPlaceholder'));

  return (
    <Paper
      component="form"
      sx={{
        display: 'flex',
        alignItems: 'left',
        borderRadius: '50px',
        boxShadow: 'none',
        height: '35px',
        maxWidth: props.maxWidth ?? '100%',
        margin: props.margin ?? '20px 70px 20px 20px',
        border: `1px solid ${BorderGrey}`,
        ...props.sx
      }}
    >
      <IconButton sx={{ p: '10px' }} aria-label='search'>
        <SearchIcon />
      </IconButton>
      <InputBase
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
          }
        }}
        sx={{ flex: 1, fontSize: '14px' }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={props.onChange}
      />
    </Paper>
  )
};

export default SearchBar;
