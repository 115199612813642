import React, { lazy, Suspense } from 'react';
import type { ContactListingsProps } from './ContactListings';

const LazyContactListings = lazy(async () => await import('./ContactListings'));

const ContactListings = (props: ContactListingsProps) => (
  <Suspense fallback={null}>
    <LazyContactListings {...props} />
  </Suspense>
);

export default ContactListings;
