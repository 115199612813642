/**
 * Reducer file for the handling Notifications
 */

import { createSlice } from '@reduxjs/toolkit';
import { NotificationSlice } from './constants';
import type { Notification, NotificationUpdateData, UploadBox, UploadBoxUpdateData } from '../../models/Notifications';

interface NotificationState {
  count: number
  notifications: Notification[]
  uploadBoxes: UploadBox[]
}

const initialState: NotificationState = {
  count: 0,
  notifications: [],
  uploadBoxes: []
}

export const notificationsSlice = createSlice({
  name: NotificationSlice,
  initialState,
  reducers: {
    addCount: (state) => {
      state.count = state.count + 1;
    },
    removeCount: (state) => {
      state.count = (state.count === 0) ? 0 : state.count - 1;
    },
    resetCount: (state) => {
      state.count = 0;
    },
    addNotificationItem: (state, action) => {
      let notifications: Notification[] = state.notifications;
      const notificationToAdd: Notification = action.payload.notification;
      if (!notifications.some((notification: Notification) => notification.referenceId === notificationToAdd.referenceId)) {
        notifications = [...notifications, notificationToAdd];
        notifications.sort((a: Notification, b: Notification) => b.timeCreated - a.timeCreated); // sort by latest first
      }
      state.notifications = notifications;
    },
    removeNotification: (state, action) => {
      const notificationId: string = action.payload.referenceId;
      let notifications: Notification[] = state.notifications;
      notifications = notifications.filter((notification: Notification) => notification.referenceId !== notificationId);
      state.notifications = notifications;
    },
    updateNotification: (state, action) => {
      const notificationId: string = action.payload.referenceId;
      const data: NotificationUpdateData = action.payload.data;
      const notifications: Notification[] = state.notifications;
      const index: number = notifications.findIndex((notification: Notification) => notification.referenceId === notificationId);
      if (index >= 0 && index < notifications.length) {
        notifications[index] = { ...notifications[index], ...data };
      }
      state.notifications = [...notifications];
    },
    addUploadBox: (state, action) => {
      let uploadBoxes: UploadBox[] = state.uploadBoxes;
      const boxToAdd: UploadBox = action.payload.uploadBox;
      if (!uploadBoxes.some((uploadBox: UploadBox) => uploadBox.fileId === boxToAdd.fileId)) {
        uploadBoxes = [...uploadBoxes, boxToAdd];
      }
      state.uploadBoxes = uploadBoxes;
    },
    removeUploadBox: (state, action) => {
      const uploadBoxId: string = action.payload.fileId;
      let uploadBoxes: UploadBox[] = state.uploadBoxes;
      uploadBoxes = uploadBoxes.filter((uploadBox: UploadBox) => uploadBox.fileId !== uploadBoxId);
      state.uploadBoxes = uploadBoxes;
    },
    updateUploadBox: (state, action) => {
      const uploadBoxId: string = action.payload.fileId;
      const data: UploadBoxUpdateData = action.payload.data;
      const uploadBoxes: UploadBox[] = state.uploadBoxes;
      const index: number = uploadBoxes.findIndex((uploadBox: UploadBox) => uploadBox.fileId === uploadBoxId);
      if (index >= 0 && index < uploadBoxes.length) {
        uploadBoxes[index] = { ...uploadBoxes[index], ...data };
      }
      state.uploadBoxes = [...uploadBoxes];
    }
  }
});

export const {
  addCount, removeCount, resetCount, addNotificationItem, removeNotification, updateNotification,
  addUploadBox, removeUploadBox, updateUploadBox
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
