import React, { lazy, Suspense } from 'react';
import type { LoadingProgressProps } from './LoadingProgress';

const LazyLoadingProgress = lazy(async () => await import('./LoadingProgress'));

const LoadingProgress = (props: LoadingProgressProps) => (
  <Suspense fallback={null}>
    <LazyLoadingProgress {...props} />
  </Suspense>
);

export default LoadingProgress;
