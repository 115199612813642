import type { FC } from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, Grid, Box } from '@mui/material';
import styles from './EditContact.module.scss';
import { DepartmentIdString, emptyString, PersonIdString } from './constants';
import type { RootState } from '../../state/store';
import type { AddContactForm, AddContactValidation } from '../../models/Contacts';
import { Categories, defaultAddContactValues } from '../../models/Contacts';
import {
  setContactsForPage, setEditContactId, setPredefinedEmail, setPreselectedCategory, setResetForm, setFallbackPage
} from '../../state/slices/Contacts';
import type { Person, PersonUnavailabilities } from '../../models/Person';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import personService from '../../services/person/PersonService';
import Toast from '../common/toast/Toast';
import distributionService from '../../services/distribution/DistributionService';
import WhiteButton from '../common/buttons/WhiteButton';
import InviteContact from '../modals/InviteContact/InviteContact.lazy';
import ModalService from '../../services/ModalService';
import { InviteContactModal } from '../modals/InviteContact/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ExitWithoutSavingModal } from '../modals/ExitWithoutSaving/constants';
import ExitWithoutSaving from '../modals/ExitWithoutSaving/ExitWithoutSaving';
import type { ChipDropdownData } from '../common/ChipsDropdown';
import type { DepartmentResponse } from '../../models/Department';
import progressStyles from '../../theme/loadingProgress.module.scss';
import type { AlertColor } from '@mui/material/Alert';
import type { PersonSecurityListResponse } from '../../models/SecurityList';
import type { DistributionListIds } from '../../models/DistributionList';
import type { WSResponse } from '../../models/WSResponse';
import UtilityService from '../../services/UtilityService';
import { ToastIcons } from '../../models/Toast';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { CrooglooUrl } from '../../models/CrooglooUrl';
import BasicInfo from '../drawers/AddContact/BasicInfo/BasicInfo.lazy';
import AddToDistribution from '../drawers/AddContact/AddToDistribution/AddToDistribution.lazy';
import { setPageTitle } from '../../state/slices/pageTitle';
import TertiaryButton from '../common/buttons/TertiaryButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContactListingsPage } from '../ContactListings/Constants';
import SuccessButton from '../common/buttons/SuccessButton';
import { headerHeight } from '../../theme/styles';
import moment from 'moment';

const emailKeys: string[] = ['email', 'email2', 'email3'];
const EmailTakenError: string = 'This email address is already taken';

export interface EditContactProps {
  pageTitle: string
}

/**
 * Add Contact Component used for the adding/editing a contact on the contact pages
 */
const EditContact: FC<EditContactProps> = ({ pageTitle }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const navigate = useNavigate();

  let currentPage: ContactListingsPage | null = useSelector((state: RootState) => state.contacts.currentPage);
  if (!currentPage) currentPage = ContactListingsPage.All;

  const currentPageContacts = useSelector((state: RootState) => currentPage
    ? state.contacts.pageContacts[currentPage]
    : []);

  const preselectedCategory: Categories | null = useSelector((state: RootState) => state.contacts.preselectedCategory);
  const predefinedEmail: string | null = useSelector((state: RootState) => state.contacts.predefinedEmail);
  const resetForm: boolean = useSelector((state: RootState) => state.contacts.resetForm);
  const fallbackPage: CrooglooUrl | null = useSelector((state: RootState) => state.contacts.fallbackPage);

  const dispatch = useDispatch();

  const { contactEditId } = useParams();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle]);

  useEffect(() => {
    if (resetForm) {
      resetPredefined();
      clearForm();
      dispatch(setResetForm({ resetForm: false }));
    }
  }, [resetForm]);

  const basicInfoRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [isMoreInfoExpanded, setMoreInfoExpanded] = useState<boolean>(false);
  const [isToastOpen, setToastOpen] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<AlertColor>('success');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastIcon, setToastIcon] = useState<ToastIcons | undefined>(ToastIcons.Success);
  const [isContactSaving, setIsContactSaving] = useState<boolean>(false);
  const [addToDistributionHeight, setAddToDistributionHeight] = useState<number>(680);

  const [category, setCategory] = useState<Categories>(Categories.Crew);

  const [unavailabilities, setUnavailabilities] = useState<PersonUnavailabilities[]>([]);
  const [securityGroup, setSecurityGroup] = useState<string>('');
  const [securityGroupChanged, setSecurityGroupChanged] = useState<boolean>(false);

  const [contactAssociations, setContactAssociations] = useState<ChipDropdownData[]>([]);
  const [initialContactAssociations, setInitialContactAssociations] = useState<ChipDropdownData[]>([]);
  const [personDistributionLists, setPersonDistributionLists] = useState<string[]>([]);
  const [originalPersonDistributionLists, setOriginalPersonDistributionLists] = useState<string[]>([]);

  const departmentsFromGlobalState: DepartmentResponse[] = useSelector((state: RootState) => state.person.departments);
  const distributionLists: DistributionListIds[] = useSelector((state: RootState) => state.distribution.distributionListIds);

  const [formValidation, setFormValidation] = useState<AddContactValidation>(
    { firstNameSet: true, lastNameSet: true, emailSet: true, departmentSet: true, positionSet: true, characterNameSet: true })

  // react-hook-form functions
  const { control, reset, register, setValue, handleSubmit, formState } = useForm<AddContactForm>({
    defaultValues: defaultAddContactValues
  });

  const handleFormHeight = (): void => {
    const screenHeight = window.innerHeight - headerHeight - 60;
    if (basicInfoRef.current) {
      const formHeight: number = basicInfoRef.current.offsetHeight;
      const height: number = Math.max(formHeight, screenHeight);
      setAddToDistributionHeight(height);
    }
  }

  useEffect(() => {
    handleFormHeight();
  }, [category, isMoreInfoExpanded, distributionLists, contactAssociations]);

  useEffect(() => {
    if (contactEditId) return;
    let category: Categories = Categories.Crew;
    if (preselectedCategory) category = preselectedCategory;
    let email: string = '';
    if (predefinedEmail) email = predefinedEmail;
    setValue('subcategory', category);
    setValue('email', email);
    setCategory(category);
  }, [preselectedCategory, predefinedEmail]);

  // if editing a contact - pre-fill form with data
  useEffect(() => {
    if (!isLoading) setIsLoading(true);

    if (contactEditId) {
      personService.getContactToEdit(contactEditId)
        .then((contact: Person | undefined) => {
          if (contact) {
            const newForm = { ...defaultAddContactValues };
            newForm.subcategory = contact.subcategory ?? Categories.Crew;
            setCategory(contact.subcategory ?? Categories.Crew);
            newForm.id = contact.id ?? '';
            newForm.firstName = contact.firstName ?? '';
            newForm.lastName = contact.lastName ?? '';
            newForm.email = contact.email ?? '';
            newForm.mobile = contact.mobile ?? '';
            newForm.departmentId = contact.departmentId ?? '';
            newForm.jobTitle = (contact.subcategory !== Categories.Cast) ? (contact.jobTitle ?? '') : '';
            newForm.castNumber = contact.castNumber ?? '';
            newForm.characterName = (contact.subcategory === Categories.Cast) ? (contact.jobTitle ?? '') : '';
            newForm.agency = contact.agency ?? '';
            newForm.email2 = contact.email2 ?? '';
            newForm.email3 = contact.email3 ?? '';
            newForm.office = contact.officePhone ?? '';
            newForm.home = contact.homePhone ?? '';
            newForm.address1 = contact.address1 ?? '';
            newForm.locality = contact.locality ?? '';
            newForm.state = contact.state ?? '';
            newForm.postalcode = contact.postalcode ?? '';
            newForm.creditName = contact.creditName ?? '';
            newForm.contactName = contact.contactName ?? '';
            newForm.contactNumber = contact.contactNumber ?? '';
            newForm.allergies = contact.allergies ?? '';
            newForm.gift = contact.gift ?? '';
            newForm.startDate = contact.startDate ? dayjs(contact.startDate) : null;
            newForm.endDate = contact.endDate ? dayjs(contact.endDate) : null;
            newForm.securitygroup = contact.securityGroup ?? '';
            newForm.inviteToCroogloo = contact.inviteToCroogloo ?? '';
            newForm.isNewContact = false;
            let contactUnavailabilities: PersonUnavailabilities[] = [];
            if (contact.unavailabilities) {
              // get unavailabilities from backend and change to format wanted to display
              contactUnavailabilities = contact.unavailabilities.map((unavailability: PersonUnavailabilities) => ({
                start: moment(unavailability.start).format('MMMM DD, YYYY'),
                end: moment(unavailability.end).format('MMMM DD, YYYY')
              }))
            }
            setUnavailabilities(contactUnavailabilities);

            const originalLists: string[] = contact.distributionLists
              ? contact.distributionLists.split(',').map((item: string) => item.trim())
              : [];

            setPersonDistributionLists(originalLists);
            setOriginalPersonDistributionLists(originalLists);

            personService.fetchPersonSecurityLists(contactEditId ?? '').then((result: PersonSecurityListResponse | null) => {
              if (result?.items && result.items.length > 0) {
                newForm.securitygroup = result.items[0] ?? '';
                setSecurityGroup(newForm.securitygroup);
              }
              setSecurityGroupChanged(false);
              setIsLoading(false);
            }).catch((err) => {
              console.error(err);
            });

            reset(newForm);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setIsLoading(false);
    }
  }, [contactEditId]);

  const handleShowToast = (toastType: AlertColor, message: string, icon: ToastIcons | undefined = undefined): void => {
    setToastOpen(true);
    setToastMessage(message);
    setToastStatus(toastType);
    setToastIcon(icon);
  }

  const onSubmitHandler: SubmitHandler<AddContactForm> = async (data: AddContactForm) => {
    if (!validateForm(data)) return;

    const invalidEmails: string[] = areEmailsValid(data);
    if (invalidEmails.length > 0) {
      invalidEmails.forEach((email: string) => {
        const toastMessage: string = t('contacts.addContact.form.error.email');
        handleShowToast('error', toastMessage.replace('{{email}}', email));
      });
      return;
    }

    const fullName: string = data.firstName + ' ' + data.lastName;
    if (!UtilityService.isValidName(fullName)) {
      const toastMessage: string = t('contacts.addContact.form.error.name');
      handleShowToast('error', toastMessage.replace('{{name}}', fullName));
      return;
    }

    const castNumber: string = data.castNumber.trim();
    if (category === Categories.Cast && castNumber !== '') {
      if (typeof castNumber !== 'string' || !UtilityService.isValidCastNumber(castNumber)) {
        const toastMessage: string = t('contacts.addContact.form.error.castNumber');
        handleShowToast('error', toastMessage);
        return;
      }
    }

    setIsContactSaving(true);

    const formData = prepareFormData(data);

    personService.editPerson(formData)
      .then(async (result: WSResponse) => {
        if (result.responseMessage === EmailTakenError) {
          throw new Error('contacts.addContact.form.error.emailTaken');
        }

        data.id = result.responseCode;

        // Save contact associations
        const addedItems: ChipDropdownData[] = contactAssociations.filter((item: ChipDropdownData) => !initialContactAssociations.includes(item));
        const addedIds = addedItems.map((item: ChipDropdownData) => item.id);
        if (addedIds.length > 0) {
          await personService.createPersonAssociation(data.id, addedIds, category, category === Categories.Cast ? Categories.Agent : Categories.Cast);
        }

        const deletedItems: ChipDropdownData[] = initialContactAssociations.filter((item: ChipDropdownData) => !contactAssociations.includes(item));
        const deletedIds = deletedItems.map((item: ChipDropdownData) => item.id);

        if (deletedIds.length > 0) {
          await personService.removePersonAssociation(data.id, deletedIds);
        }

        // handle saving assigned distribution lists
        const addedLists: DistributionListIds[] = distributionLists.filter((list: DistributionListIds) => personDistributionLists.includes(list.distributionListName));
        const originalLists: DistributionListIds[] = distributionLists.filter((list: DistributionListIds) => originalPersonDistributionLists.includes(list.distributionListName));

        const addedListIds: string[] = addedLists.reduce((accum: string[], list: DistributionListIds) => {
          if (!originalPersonDistributionLists.includes(list.distributionListName)) {
            return [...accum, list.distributionListId]
          } else {
            return accum;
          }
        }, []);

        const removedListIds: string[] = originalLists.reduce((accum: string[], list: DistributionListIds) => {
          if (!personDistributionLists.includes(list.distributionListName)) {
            return [...accum, list.distributionListId]
          } else {
            return accum;
          }
        }, []);

        // save removed lists
        if (removedListIds.length > 0) {
          // Save in DB
          await distributionService.removeDistributionListMemberByContact(removedListIds.join(','), data.id);
        }

        // save added lists
        if (addedListIds.length > 0) {
          // Save in DB
          await distributionService.addDistributionListMembersByContact(addedListIds.join(','), data.id);
        }

        // Save unavailabilities
        if (unavailabilities.length > 0) {
          // Removed unavailabilities
          let removedUnavailabilities = unavailabilities.filter(item => (item.deleted ?? item.modified));
          removedUnavailabilities = JSON.parse(JSON.stringify(removedUnavailabilities));
          for (const item of removedUnavailabilities) {
            item.start = item.initialStart ? item.initialStart.toString() : item.start;
            item.end = item.initialEnd ? item.initialEnd.toString() : item.end;
          }
          const removedUnavailabilitiesDto = removedUnavailabilities.map(item => ({
            start: moment(item.start).format('YYYY-MM-DD'),
            end: moment(item.end).format('YYYY-MM-DD')
          }));

          const removedUnavailabilitiesPayload = {
            personId: data.id,
            unavailabilities: removedUnavailabilitiesDto
          };
          await personService.removeUnavailabilitiesByContact(removedUnavailabilitiesPayload);

          // Added unavailabilities
          let addedUnavailabilities = unavailabilities.filter(item => (!item.deleted || (item.modified && !item.deleted)));
          // Filter out "empty" items
          addedUnavailabilities = addedUnavailabilities.filter(item => !(item.start === '' && item.end === ''));
          const addedUnavailabilitiesDto = addedUnavailabilities.map(item =>
            ({
              start: moment(item.start).format('YYYY-MM-DD'),
              end: moment(item.end).format('YYYY-MM-DD')
            })
          );

          const addedUnavailabilitiesPayload = {
            personId: data.id,
            unavailabilities: addedUnavailabilitiesDto
          };
          await personService.addUnavailabilitiesByContact(addedUnavailabilitiesPayload);
        }

        reset(data);

        const message = securityGroupChanged ? 'contacts.addContact.saveAndInviteSuccess' : 'contacts.addContact.saveSuccess';

        handleShowToast('success', message, ToastIcons.Success);

        setIsContactSaving(false);

        // Update inner application state
        let itemToUpdate: Person | undefined = currentPageContacts.find((item: Person) => item.id === data.id);
        if (itemToUpdate) {
          itemToUpdate = convertFormDataToPerson({ ...itemToUpdate }, data);
          setSecurityGroup(data.securitygroup);
          setSecurityGroupChanged(false);
          dispatch(setContactsForPage({
            key: currentPage,
            contacts: [itemToUpdate, ...currentPageContacts.filter((item: Person) => item.id !== data.id)]
          }));
        }
      })
      .catch((err) => {
        console.error(err);
        handleShowToast('error', err.message);
        setIsContactSaving(false);
      })
  }

  const addNewContactToLocalStore = (newItem: Person): void => {
    dispatch(setContactsForPage({
      key: currentPage,
      contacts: [newItem, ...currentPageContacts.filter((item: Person) => item.id !== newItem.id)]
    }));
  }

  const validateForm = (data: AddContactForm): boolean => {
    const newFormValidation: AddContactValidation = {
      firstNameSet: !!data.firstName,
      lastNameSet: !!data.lastName,
      emailSet: !!data.email,
      departmentSet: category === Categories.Crew ? !!data.departmentId : true,
      positionSet: category === Categories.Crew ? !!data.jobTitle : true,
      characterNameSet: category === Categories.Cast ? !!data.characterName : true
    }

    setFormValidation(newFormValidation);

    const hasInvalidValue = Object.values(newFormValidation).some((value) => value === false);

    return !hasInvalidValue;
  };

  const resetPredefined = (): void => {
    dispatch(setPredefinedEmail({ predefinedEmail: null }));
    dispatch(setPreselectedCategory({ preselectedCategory: null }));
  }

  const handleClose = () => {
    const isDirty = Object.values(formState.dirtyFields).some((value) => value);
    if (isDirty) {
      ModalService.openCustomModal(ExitWithoutSavingModal, {
        heading: 'contacts.addContact.exitWithoutSaving',
        content: 'contacts.addContact.exitProceed',
        confirmButton: 'action.yes',
        callback: () => {
          // check here if set in state
          closeContactForm();
          ModalService.closeCustomModal(ExitWithoutSavingModal);
        }
      });
    } else {
      closeContactForm();
    }
  }

  const closeContactForm = (): void => {
    if (fallbackPage) {
      navigate(fallbackPage);
    } else {
      navigate(CrooglooUrl.ALLCONTACTS);
    }
    resetPredefined();
    clearForm();
    dispatch(setFallbackPage({ page: null }));
  }

  const clearForm = () => {
    dispatch(setEditContactId({ contactId: null }));
    const newForm = { ...defaultAddContactValues };
    newForm.subcategory = Categories.Crew;
    reset(newForm);

    setCategory(Categories.Crew);
    setUnavailabilities([]);
    setSecurityGroupChanged(false);
    setInitialContactAssociations([]);
    setContactAssociations([]);
    setPersonDistributionLists([]);

    const newFormValidation: AddContactValidation = {
      firstNameSet: true,
      lastNameSet: true,
      emailSet: true,
      departmentSet: true,
      positionSet: true,
      characterNameSet: true
    }

    setFormValidation(newFormValidation);
  }

  const inviteToClicked = () => {
    const firstName: string = control._formValues.firstName;
    const lastName: string = control._formValues.lastName;

    ModalService.openCustomModal(InviteContactModal, {
      heading: 'contacts.inviteContact.heading',
      content: 'contacts.inviteContact.content',
      confirmButton: 'action.confirm',
      metaData: [`${firstName} ${lastName}`],
      callback: addInviteToData
    });
  }

  const addInviteToData = (securityGroupId: string) => {
    setValue('inviteToCroogloo', 'Y');
    setValue('securitygroup', securityGroupId);
    setSecurityGroup(securityGroupId);
    setSecurityGroupChanged(true);
  }

  const prepareFormData = (data: AddContactForm): AddContactForm => {
    data.jobTitle = (category === Categories.Cast) ? data.characterName : data.jobTitle;

    if (!data.id) {
      data.isNewContact = true
      data.id = (PersonIdString + data.firstName + '_' + data.lastName + '_' + data.jobTitle).toUpperCase();
    }

    if (category === Categories.Crew) {
      const department = departmentsFromGlobalState.find(item => item.properties.id === data.departmentId);
      if (department) {
        data.departmentName = department.properties.departmentName;
      }
    } else {
      const categoryChosen: string = data.subcategory.toUpperCase();
      data.departmentId = DepartmentIdString + categoryChosen;
      data.departmentName = categoryChosen;
    }

    return {
      id: data.id,
      isNewContact: data.isNewContact,
      jobTitle: data.jobTitle,
      firstName: data.firstName,
      lastName: data.lastName,
      subcategory: data.subcategory,
      email: (data.email !== '') ? data.email : emptyString,
      email2: (data.email2 !== '') ? data.email2 : emptyString,
      email3: (data.email3 !== '') ? data.email3 : emptyString,
      home: (data.home !== '') ? data.home : emptyString,
      mobile: (data.mobile !== '') ? data.mobile : emptyString,
      office: (data.office !== '') ? data.office : emptyString,
      address1: (data.address1 !== '') ? data.address1 : emptyString,
      locality: (data.locality !== '') ? data.locality : emptyString,
      state: (data.state !== '') ? data.state : emptyString,
      postalcode: (data.postalcode !== '') ? data.postalcode : emptyString,
      securitygroup: (data.securitygroup !== '') ? data.securitygroup : emptyString,
      castNumber: (category === Categories.Cast && data.castNumber) ? data.castNumber : '',
      characterName: (data.characterName !== '') ? data.characterName : '',
      departmentId: data.departmentId,
      departmentName: data.departmentName,
      deptDisplayOrder: '0',
      inviteToCroogloo: data.inviteToCroogloo,
      includeInDTR: true,
      receivedSafeSender: 'N',
      creditName: (data.creditName !== '') ? data.creditName : emptyString,
      contactName: (data.contactName !== '') ? data.contactName : emptyString,
      contactNumber: (data.contactNumber !== '') ? data.contactNumber : emptyString,
      allergies: (data.allergies !== '') ? data.allergies : emptyString,
      gift: (data.gift !== '') ? data.gift : emptyString,
      agency: (data.agency !== '') ? data.agency : emptyString,
      startDate: (data.startDate !== null) ? (data.startDate as Dayjs).format('YYYY-MM-DD') : '',
      endDate: (data.endDate !== null) ? (data.endDate as Dayjs).format('YYYY-MM-DD') : ''
    }
  }

  const convertFormDataToPerson = (person: Person, data: AddContactForm): Person => {
    person.firstName = data.firstName;
    person.lastName = data.lastName;
    person.jobTitle = data.jobTitle;
    person.departmentId = data.departmentId;

    const department = departmentsFromGlobalState.find(item => item.properties.id === person.departmentId);
    if (department) {
      person.departmentName = department.properties.departmentName;
    }

    person.subcategory = data.subcategory;
    person.id = data.id;
    person.email = data.email;
    person.mobile = data.mobile;
    person.castNumber = data.castNumber;
    person.agency = data.agency;
    person.email2 = data.email2;
    person.email3 = data.email3;
    person.officePhone = data.office;
    person.homePhone = data.home;
    person.address1 = data.address1;
    person.locality = data.locality;
    person.state = data.state;
    person.postalcode = data.postalcode;
    person.creditName = data.creditName;
    person.contactName = data.contactName;
    person.contactNumber = data.contactNumber;
    person.allergies = data.allergies;
    person.gift = data.gift;
    person.startDate = data.startDate?.toString();
    person.endDate = data.endDate?.toString();
    person.securityGroup = data.securitygroup;
    person.inviteToCroogloo = data.inviteToCroogloo;
    person.distributionLists = personDistributionLists.join(',');

    return person;
  }

  let securityGroupButton = <></>;

  if (securityGroupChanged) { // Invite set, contact NOT YET saved
    securityGroupButton = <div className={styles.inviteToCroogloo}>
      <WhiteButton
        disableRipple
        onClick={inviteToClicked}
        className={styles.topButton}
      >
        { `${securityGroup} ${t('contacts.addContact.account')}` }
      </WhiteButton>
    </div>
  } else { // New contact, "Invite to Croogloo" button
    if (control._formValues.inviteToCroogloo === 'Y' /* && securityGroup !== '' */) { // Invite set, contact saved
      securityGroupButton = <div className={styles.inviteToCroogloo}>
        <WhiteButton className={`${styles.securityGroupsButton} ${styles.topButton}`}
                     disableRipple
                     onClick={() => {
                       navigate(CrooglooUrl.SECURITYGROUPS);
                     }}>
          {`${securityGroup} ${t('contacts.addContact.account')}`}
        </WhiteButton>
      </div>;
    } else if (control._formValues.inviteToCroogloo === 'N' ||
      control._formValues.inviteToCroogloo === '' ||
      contactEditId === null) {
      securityGroupButton = <div className={styles.inviteToCroogloo}>
        <WhiteButton
          disableRipple
          onClick={inviteToClicked}
          className={styles.topButton}
        >
          { t('contacts.addContact.basicInfo.inviteToCroogloo.label') }
        </WhiteButton>
      </div>
    }
  }

  return (
    <Grid
      className={styles.EditContact}
      data-testid="EditContact"
    >
      {(isLoading && contactEditId !== null)
        ? <CircularProgress className={progressStyles.circularProgress}/>
        : <>
            <form onSubmit={(...data) => {
              void handleSubmit(onSubmitHandler)(...data)
            }}>
            <Grid className={styles.contentWrapper}>
              <Grid className={styles.contentLeft}>
                <Grid className={styles.buttonRow}>
                  <Grid className={styles.backButton}>
                    <TertiaryButton
                        // style={{ margin: '10px 5px', float: 'left' }}
                        disableRipple
                        onClick={handleClose}
                        startIcon={<ArrowBackIcon/>}
                        className={styles.closeButton}
                    >
                    </TertiaryButton>
                  </Grid>
                  <Grid>
                    {(category === Categories.Crew || category === Categories.Cast || category === Categories.Agent) && securityGroupButton}
                  </Grid>
                  <Grid>
                    <SuccessButton
                      disableRipple
                      disabled={isContactSaving}
                      type={'submit'}
                      className={`${styles.smallSaveButton} ${styles.topButton}`}
                    >
                      {t('action.save')}
                    </SuccessButton>
                  </Grid>

                </Grid>
                <Grid ref={basicInfoRef}>
                  <input type={'hidden'} {...register('inviteToCroogloo')}/>
                  <BasicInfo
                    control={control} category={category} setCategory={setCategory}
                    handleShowToast={handleShowToast}
                    unavailabilities={unavailabilities} setFormValidation={setFormValidation}
                    setUnavailabilities={setUnavailabilities} formValidation={formValidation}
                    addItemToStore={addNewContactToLocalStore}
                    chipsContactAssociations={contactAssociations}
                    setChipsContactAssociations={setContactAssociations}
                    setInitialContactAssociations={setInitialContactAssociations}
                    isMoreInfoExpanded={isMoreInfoExpanded}
                    setMoreInfoExpanded={setMoreInfoExpanded}
                  />
                </Grid>
              </Grid>
              <Grid className={styles.contentRight}>
                <Grid item className={styles.headingBox}>
                  <Box component={'span'} className={styles.heading}>{t('contacts.addContact.addToDistributionLists')}</Box>
                </Grid>
                <AddToDistribution
                  distributionLists={distributionLists}
                  selectedDistributionLists={personDistributionLists}
                  setSelectedDistributionLists={setPersonDistributionLists}
                  customHeight={addToDistributionHeight}
                />
              </Grid>
            </Grid>
            </form>
          </>
      }
      <InviteContact />
      <ExitWithoutSaving/>
      <Toast onClose={() => setToastOpen(false)} open={isToastOpen} type={toastStatus} title={toastMessage} icon={toastIcon}/>
    </Grid>
  )
};

export default EditContact;

function areEmailsValid (data: AddContactForm): string[] {
  const invalidEmails: string[] = [];
  emailKeys.forEach((emailKey: string) => {
    const emailValue = data[emailKey as keyof AddContactForm];
    if (emailValue && typeof emailValue === 'string') {
      emailValue.split(',').forEach((email: string) => {
        email = email.trim();
        if (email && email !== emptyString) {
          if (UtilityService.isInValidEmailAddress(email)) {
            invalidEmails.push(email);
          }
        }
      })
    }
  });
  return invalidEmails;
}
