import React, { lazy, Suspense } from 'react';
import type { ListsDrawerProps } from './ListsDrawer';

const LazyListsDrawer = lazy(async () => await import('./ListsDrawer'));

const ListsDrawer = (props: ListsDrawerProps) => (
  <Suspense fallback={null}>
    <LazyListsDrawer {...props} />
  </Suspense>
);

export default ListsDrawer;
