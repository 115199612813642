import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import ManagePermissions from './Index';
import ManagePermissionsService from '../../services/ManagePermissionsService';
import type { FetchAccessPermissionsResponse, FetchAccessPermissionsResponseItem, SecurityGroupForm } from '../../models/SecurityGroups';
import MainSection from '../common/MainSection';
import { drawerWidth } from '../../theme/styles';

export enum ManagePermissionsPage {
  Title = 'Manage Permissions'
}

export interface Props {
  pageTitle: ManagePermissionsPage
}

const ManagePermissionsIndex: FC<Props> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle])

  // Groups for the drawer
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [groupID, setGroupID] = useState<string>('');
  // default values for form
  const defaultPermissionValues: SecurityGroupForm = {
    menu_crew: '0',
    menu_dtr: '0',
    menu_distribution: '0',
    menu_documents: '0',
    menu_scripts: '0',
    menu_security: '0',
    menu_templates: '0'
  };

  const [groupsAccessPermissions, setGroupsAccessPermissions] = useState([]);

  // const [accessPermissions, setAccessPermissions] = useState<any>();
  const { control, reset } = useForm({
    defaultValues: defaultPermissionValues
  });

  // Changes the group selection, clearing any selected scene
  const updateSelectedGroup = (group: string) => {
    if (selectedGroup !== group) {
      setSelectedGroup(group);
      getAccessPermissions(group);
    }
  }

  useEffect(() => {
    getSecurityGroups();
  }, []);

  // fetch access permissions for all groups
  const getAccessPermissions = (selectedGroup: string): void => {
    ManagePermissionsService.getAccessPermissions()
      .then((response: FetchAccessPermissionsResponse) => {
        const filteredItems = response.items.filter(item => {
          return !item.securityMenuName.includes('mobile')
        })
        createAccessPermissionsObject(filteredItems, selectedGroup);
      }).catch((err) => {
        console.debug(err, 'error');
      });
  }

  const createAccessPermissionsObject = (filteredGroups: FetchAccessPermissionsResponseItem[], selectedGroup: string): void => {
    const securityMenuTitleAndPermissions: any = {};
    const accessPermissions: any = [];
    let groupItem: any = {};

    let securityMenuTitleAndPermissionswithValues: any;

    if (filteredGroups.length > 0) {
      filteredGroups.forEach(securityMenu => {
        if (securityMenu.securityMenuName !== 'menu_dashboard') {
          securityMenuTitleAndPermissions[securityMenu.securityMenuName] = 0;
        }
      })

      filteredGroups[0].orderedGroups.forEach(group => {
        securityMenuTitleAndPermissionswithValues = JSON.parse(JSON.stringify(securityMenuTitleAndPermissions));
        groupItem[group] = securityMenuTitleAndPermissions;

        filteredGroups.forEach(securityMenu => {
          securityMenuTitleAndPermissionswithValues[securityMenu.securityMenuName] = securityMenu.groupsAccessValue[group];
          groupItem[group] = securityMenuTitleAndPermissionswithValues;

          if (group === selectedGroup && securityMenu.securityMenuName !== 'menu_dashboard') {
            setGroupID(selectedGroup);

            reset(securityMenuTitleAndPermissionswithValues);
          }
        })

        accessPermissions.push(groupItem);
        groupItem = {};
      })
    }

    if (accessPermissions.length > 0) {
      const groupSecurityValues: any = {};

      for (const group of accessPermissions) {
        const groupId = Object.keys(group)[0];

        const values = group[groupId];

        for (const key of Object.keys(values)) {
          groupSecurityValues[`${key}$$${groupId}`] = values[key]
        }
      }

      reset({ ...control._formValues, ...groupSecurityValues });
    }

    setGroupsAccessPermissions(accessPermissions)
  }

  const getSecurityGroups = (): void => {
    ManagePermissionsService.getAccessPermissions()
      .then((response: FetchAccessPermissionsResponse) => {
        if (response && response.items.length > 0) {
          const securityGroups = response.items[0].orderedGroups;
          updateSelectedGroup(securityGroups[0]);
        }
      }).catch((err) => {
        console.debug(err, 'error');
      });
  }

  return (
    <MainSection open={true} drawerwidth={drawerWidth} sx={{ height: '100vh' }}>
      <ManagePermissions
        groupsAccessPermissions={groupsAccessPermissions}
        control={control}
        groupID={groupID}/>
    </MainSection>
  )
};

export default ManagePermissionsIndex;
