import React, { lazy, Suspense } from 'react';

const LazySwitchProduction = lazy(async () => await import('./SwitchProduction'));

const SwitchProduction = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazySwitchProduction {...props} />
  </Suspense>
);

export default SwitchProduction;
