import type { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { BorderBlack, WhiteGrey, MainRed, LightBlack } from '../../theme/colors';
import styles from './FormInput.module.scss';

interface Props {
  id: string
  name: string
  label?: string | ReactNode
  formItemProps: any
  placeholder?: string
  width?: string
  error?: boolean
  onValueChanged?: any
  setError?: any
  setLocalError?: any
  fullWidth?: boolean
  adjustSize?: boolean
}

/**
 * Reusable Input Text Field Component for forms
 * name - name of select field
 * formItemProps would have to contain control from react-hook-form's useForm
 */

const FormInputTextField: FC<Props> = (props: Props) => {
  const borderColor: string = (props.error) ? MainRed : LightBlack;

  const onInputBlur = (event: any, onBlur: any) => {
    onBlur(event);

    if (props.onValueChanged) {
      props.onValueChanged(event.target.value);

      if (props.setLocalError) props.setLocalError(false);
    }
  }

  const onInputChange = (event: any, onChange: any) => {
    onChange(event);

    if (props.setError) {
      props.setError(event.target.value.trim() === '');

      if (props.setLocalError) props.setLocalError(true);
    }
  }

  return (
    <>
      <Controller
        name={props.name}
        {...props.formItemProps}
        render={({ field: { onChange, onBlur, value } }) => (
            <Grid sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: (props.fullWidth) ? 1 : ''
            }}
            >
              <InputLabel
                id={props.id}
                sx={{
                  float: 'left',
                  padding: (props.label) ? '10px 0px' : 'none',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '1rem',
                  lineHeight: '19px',
                  color: 'rgba(0, 0, 0, 0.54)'
                }}
              >
                {(props.label) ? props.label : ''}
              </InputLabel>
              <TextField
                onChange={(event) => onInputChange(event, onChange)} value={value}
                onBlur={(event) => onInputBlur(event, onBlur)}
                placeholder={(props.placeholder !== null && props.placeholder !== undefined) ? props.placeholder : ''}
                className={`${styles.textInput} ${(props.adjustSize) ? styles.adjustInput : ''}`}
                sx={{
                  width: props.width ?? '100%',
                  '& .MuiOutlinedInput-root': {
                    height: 'inherit',
                    fontSize: 'inherit',
                    borderRadius: '12px',
                    border: `1px solid ${borderColor} !important`,
                    '&:hover': {
                      background: WhiteGrey,
                      border: `1px solid ${BorderBlack}`
                    },
                    '&:hover:before': {
                      color: MainRed
                    },
                    '& fieldset': {
                      border: 'none',
                      height: 'inherit'
                    },
                    '&.Mui-focused': {
                      border: `1px solid ${BorderBlack}`,
                      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                    }
                  }
                }}
              />
            </Grid>
        )}
      />
    </>
  )
}

export default FormInputTextField;
