/**
 * Reducer file for the handling modals and popups
 */

import { createSlice } from '@reduxjs/toolkit';
import { ModalsSlice } from './constants';
import type { DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';

import { CopyRecipientsModal } from '../../components/modals/CopyRecipients/constants';
import { AbortMessageModal } from '../../components/modals/AbortMessage/constants';
import { AddDistributionModal } from '../../components/modals/AddDistributionList/constants';
import { MergeDistributionModal } from '../../components/modals/MergeDistributionList/constants';
import { ExportDistributionModal } from '../../components/modals/ExportDistributionList/constants';
import { ModifyContactModal } from '../../components/modals/ModifyContact/constants';
import { ClearEditorModal } from '../../components/modals/ClearModal';
import { NoSubjectModal } from '../../components/modals/NoSubject';
import { ConfirmModal } from '../../components/modals/ConfirmSend';
import { PreviewSendModal } from '../../components/modals/PreviewSend';
import { NoRecipientsModal } from '../../components/modals/NoRecipients';
import { InviteContactModal } from '../../components/modals/InviteContact/constants';
import { ChangeDepartmentModal } from '../../components/modals/ChangeDepartment/constants';
import { ChangeCategoryModal } from '../../components/modals/ChangeCategory/constants';
import { ImportContactsModal } from '../../components/modals/ImportContacts/constants';
import { DeleteContactModal } from '../../components/modals/DeleteContact/constants';
import { ContactAddToDistributionModal } from '../../components/modals/ContactAddToDistributionList/constants';
import { WatermarkedFileModal } from '../../components/modals/WatermarkedFile/constants';
import { AddDepartmentModal } from '../../components/modals/AddDepartment/constants';
import { NewContactModal } from '../../components/modals/NewContact/constants';
import { ExportContactModal } from '../../components/modals/ExportContact/constants';
import { EmailDooDsModal } from '../../components/modals/EmailDooDs/constants';
import { NewFolderModal } from '../../components/modals/NewFolder/constants';
import { RemoveWatermarkModal } from '../../components/modals/RemoveWatermark/constants';
import { ESignatureModal } from '../../components/modals/ESignature/constants';
import { DeleteDocumentModal } from '../../components/modals/DeleteDocument/constants';
import { MoveDocumentModal } from '../../components/modals/MoveDocument/constants';
import { ParseFileModal } from '../../components/DocumentViewer/ParseFile/constants';
import { ApplyWatermarkModal } from '../../components/modals/ApplyWatermark/constants';
import { RequestNewTemplateModalId } from '../../components/modals/RequestNewTemplate/constants';
import { TemplateDownloadModalId } from '../../components/modals/TemplateDownload/constants';
import { GoogleFormOptionsModalId } from '../../components/modals/GoogleFormOptions/constants';
import { NewGroupModal } from '../../components/modals/NewGroup/constants';
import { DeleteGroupModal } from '../../components/modals/DeleteGroup/constants';
import { ConfirmAccessLossModal } from '../../components/modals/ConfirmAccessLoss/constants';
import { DeleteListModal } from '../../components/modals/DeleteList/constants';
import { CompareListsModal } from '../../components/modals/CompareLists/constants';
import { ExportOptionsModal } from '../../components/modals/ExportOptions/constants';
import { TemplateWarningModal } from '../../components/modals/TemplateWarning/constants';
import { InvalidRecipientsModal } from '../../components/modals/InvalidRecipients/constants';
import { NoAttachmentsModal } from '../../components/modals/NoAttachment/constants';
import { AttachmentErrorModal } from '../../components/modals/AttachmentError/constants';
import { RemoveVariablesModal } from '../../components/modals/RemoveVariables/constants';
import { IncludeAgentsModal } from '../../components/modals/IncludeAgents/constants';
import { InactiveContactsModal } from '../../components/modals/InactiveContacts/constants';
import { SaveTemplateModal } from '../../components/saveTemplate/StepsModel';
import { SearchFilesModal } from '../../components/searchFiles/StepsModel';
import { SendingFilesErrorModal } from '../../components/modals/SendingFilesError/constants';
import { MissingFilesModal } from '../../components/modals/MissingFiles/constants';
import { LinkOptionsModal } from '../../components/modals/LinkOptions/constants';
import { ExitWithoutSavingModal } from '../../components/modals/ExitWithoutSaving/constants';
import { FormatOptionsModal } from '../../components/modals/FormatOptions/constants';
import { AttachCallSheetModal } from '../../components/modals/AttachCallSheet/constants';
import { DeleteFileModal } from '../../components/DocumentViewer/DeleteFile/constants';
import { DeleteDraftModal } from '../../components/modals/DeleteDraft/constants';
import { HandleInactivityModal } from '../../components/modals/HandleInactivity/constants';
import { TextSMSModal } from '../../components/modals/TextSMS/constants';
import { GenerateCrewListModal } from '../../components/modals/GenerateCrewList/constants';
import { RenameFileModal } from '../../components/modals/RenameFile/constants';
import { EditFileCategoryModal } from '../../components/modals/EditFileCategory/constants';
import { AccessLevelModal } from '../../components/modals/AccessLevel/constants';
import { MergeFilesModal } from '../../components/modals/MergeFiles/constants';
import { FileAlreadyExistModal } from '../../components/modals/FileAlreadyExist/constants';
import { MessageSentModal } from '../../components/modals/MessageSent/constants';
import { ScheduleSendModal } from '../../components/scheduleSend/constants';
import { PendingContactsModal } from '../../components/modals/PendingContacts/constants';
import { RenameExistingFileModal } from '../../components/modals/RenameExistingFile/constants';
import { ExpiredContactsModal } from '../../components/modals/ExpiredContacts/constants';
import { LinkCastModal } from '../../components/modals/LinkCast/constants';
import { LinkAgentModal } from '../../components/modals/LinkAgent/constants';
import { InvitationFailedModal } from '../../components/modals/InvitationFailed/constants';
import { FileViewerModal } from '../../components/FileViewerPopup/constants';
import { FileIsTooLargeModal } from '../../components/modals/FileTooLarge/constants';
import { ImportFileModal } from '../../components/modals/ImportFile/constants';
import { vCardDetailsModal } from '../../components/modals/vCardDetails/constants';
import { CrewListVersionModal } from '../../components/modals/CrewListVersion/constants';
import { ApplyToAllModal } from '../../components/modals/ApplyToAll/constants';
import { GenerateSidesSuccessModal } from '../../components/modals/GenerateSidesSuccess/constants';
import { ManageSubscriptionModal } from '../../components/modals/ManageSubscription/constants';
import { CancelNotAvailableModal } from '../../components/modals/CancelNotAvailable/constants';
import { ConfirmCancellationModal } from '../../components/modals/ConfirmCancellation/constants';
import { CancelSuccessModal } from '../../components/modals/CancelSuccess/constants';
import { SwitchProductionModal } from '../../components/modals/SwitchProduction/constants';
import { TwoFactorAuthenticationModal } from '../../components/modals/TwoFactorAuthentication/constants';
import { TFAuthenticationLoginModal } from '../../components/modals/TFAuthenticationLogin/constants';
import { ExpiredLinkModal } from '../../components/modals/ExpiredLink/constants';
import { DisclaimerLinkModal } from '../../components/modals/DisclaimerLink/constants';
import { ExpiredTrialModal } from '../../components/modals/ExpiredTrial/constants';
import { ActivateSubscriptionSuccessModal } from '../../components/modals/ActivateSubscriptionSuccess/constants';
import { ActivateSubscriptionFailModal } from '../../components/modals/ActivateSubscriptionFail/constants';
import { ActivateSubscriptionCancelModal } from '../../components/modals/ActivateSubscriptionCancel/constants';
import { StripePaymentModal } from '../../components/modals/StripePayment/constants';

export interface ModalContentValues {
  name?: string
  id?: string
}

export interface ModalData {
  title?: string
  heading?: string
  content?: string
  confirmButton?: string
  msgId?: string
  buttons?: string
  contactId?: string
  displayIcon?: boolean
  contentValues?: ModalContentValues
  metaData?: string[]
  callback?: any
  cancel?: any
  template?: DistributionEmailTemplate
  contentVariable?: Record<string, string>
}

interface ModalType {
  isVisible: boolean
  title: string
  heading: string
  content: string
  confirmButton: string
  msgId?: string
  buttons?: string
  contactId?: string
  displayIcon?: boolean
  contentValues?: ModalContentValues
  metaData?: string[]
  callback?: any
  cancel?: any
  template?: DistributionEmailTemplate
  contentVariable?: Record<string, string>
}

type modalItems = Record<string, ModalType>

export interface ModalState {
  custom: modalItems
}

const initialState: ModalState = {
  custom: {
    [ClearEditorModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NoSubjectModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ConfirmModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [PreviewSendModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NoRecipientsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [CopyRecipientsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [AbortMessageModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      msgId: ''
    },
    [AddDistributionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [InviteContactModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      contactId: ''
    },
    [WatermarkedFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      contactId: ''
    },
    [ChangeCategoryModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      displayIcon: false,
      contactId: ''
    },
    [MergeDistributionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      contactId: ''
    },
    [ChangeDepartmentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      displayIcon: false,
      contactId: ''
    },
    [ExportDistributionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      contactId: ''
    },
    [ImportContactsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      displayIcon: false
    },
    [ModifyContactModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteContactModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ContactAddToDistributionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: '',
      contentValues: undefined
    },
    [AddDepartmentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NewContactModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExportContactModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [EmailDooDsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NewFolderModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [RemoveWatermarkModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteDocumentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteDraftModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ESignatureModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [MoveDocumentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ParseFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [AttachCallSheetModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ApplyWatermarkModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [TemplateDownloadModalId]: {
      isVisible: false,
      title: 'Please select a format',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [RequestNewTemplateModalId]: {
      isVisible: false,
      title: 'Request New Template',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [GoogleFormOptionsModalId]: {
      isVisible: false,
      title: 'Please select an action',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NewGroupModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [HandleInactivityModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteGroupModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ConfirmAccessLossModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DeleteListModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [CompareListsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExportOptionsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [TemplateWarningModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [InvalidRecipientsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [NoAttachmentsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [AttachmentErrorModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [RemoveVariablesModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [IncludeAgentsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [InactiveContactsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [SaveTemplateModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [SearchFilesModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [SendingFilesErrorModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [MissingFilesModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [LinkOptionsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExitWithoutSavingModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [FormatOptionsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [TextSMSModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [GenerateCrewListModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [RenameFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [EditFileCategoryModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [AccessLevelModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [MergeFilesModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [FileAlreadyExistModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [FileIsTooLargeModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [MessageSentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ScheduleSendModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [PendingContactsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [RenameExistingFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExpiredContactsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [LinkCastModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [LinkAgentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [InvitationFailedModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [FileViewerModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ImportFileModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [vCardDetailsModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [CrewListVersionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ApplyToAllModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [GenerateSidesSuccessModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ManageSubscriptionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [CancelNotAvailableModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [CancelSuccessModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ConfirmCancellationModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [SwitchProductionModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [TwoFactorAuthenticationModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [TFAuthenticationLoginModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExpiredLinkModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [DisclaimerLinkModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ExpiredTrialModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ActivateSubscriptionSuccessModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ActivateSubscriptionFailModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [ActivateSubscriptionCancelModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    },
    [StripePaymentModal]: {
      isVisible: false,
      title: '',
      heading: '',
      content: '',
      confirmButton: ''
    }
  }
}

export const modalSlice = createSlice({
  name: ModalsSlice,
  initialState,
  reducers: {
    updateCustomModalState: (state, action) => {
      if (!action.payload.data.isVisible) {
        action.payload.data = {
          ...action.payload.data,
          title: '',
          heading: '',
          content: ''
        }
      }
      state.custom[action.payload.key] = {
        ...action.payload.data
      }
    }
  }
});

export const {
  updateCustomModalState
} = modalSlice.actions;
export default modalSlice.reducer;
