import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, CircularProgress } from '@mui/material';
import styles from './UploadFlow.module.scss';
import UploadSteps from './UploadSteps';
import dialogStyles from '../../../theme/dialog.module.scss';
import ModalTitle from '../../modals/ModalTitle/ModalTitle.lazy';
import { FileTypes } from '../../../models/FileTypes';
import type { WatermarkValues } from '../../../models/Upload';
import TertiaryButton from '../../common/buttons/TertiaryButton';
import SuccessButton from '../../common/buttons/SuccessButton';
import { OtherOption } from './steps/FileWatermark/FileWatermark'
import UploadService, { DefaultFileCategory } from '../../../services/UploadService';
import type { DirectoryDestination, OtherFileAttachment } from '../../../models/Document';
import { UploadStepIndex } from './StepsModel';
import { SecurityListIds } from '../../../models/SecurityList';
import type { ToastProps } from '../../../models/Toast';
import type { RootState } from '../../../state/store';
import progressStyles from '../../../theme/loadingProgress.module.scss';
import Toast from '../../common/toast/Toast';
import FileAlreadyExist from '../../modals/FileAlreadyExist/FileAlreadyExist.lazy';
import RenameExistingFile from '../../modals/RenameExistingFile/RenameExistingFile.lazy';
import FileTooLarge from '../../modals/FileTooLarge/FileTooLarge.lazy';
import ApplyToAll from '../../modals/ApplyToAll/ApplyToAll.lazy';
import UtilityService from '../../../services/UtilityService';
import { useForm } from 'react-hook-form';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NextStep: number = 1;
const SkipStep: number = 2;

export interface UploadFileForm {
  shared: SecurityListIds[]
  category: string
  episode: string
  wmFirstLine: string | null
  wmSecondLine: string | null
  wmFirstOtherField: string
  wmSecondOtherField: string
  wmWithEncryption: string
  watermark: boolean
  version: string
  color: string
  isParseChecked: boolean
}

export const DefaultFileFormValues: UploadFileForm = {
  shared: [SecurityListIds.ADMIN],
  category: '',
  episode: '',
  wmFirstLine: null,
  wmSecondLine: null,
  wmFirstOtherField: '',
  wmSecondOtherField: '',
  wmWithEncryption: '0',
  watermark: false,
  version: '',
  color: '',
  isParseChecked: true
}

/**
 * Upload component to handle the upload flow for upload file popup
 * This will change when a common component for the flows is created
 */
const UploadFlow: FC = () => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const showUploadFlow: boolean = useSelector((state: RootState) => state.uploadFiles.showUploadFlow);
  const currentFile: OtherFileAttachment | null = useSelector((state: RootState) => state.uploadFiles.currentFile);
  const watermarkAvailable: boolean = useSelector((state: RootState) => state.uploadFiles.isWatermarkAvailable);
  const applyToAll: boolean = useSelector((state: RootState) => state.uploadFiles.applyToAll);
  const preDefinedFileType: FileTypes | null = useSelector((state: RootState) => state.uploadFiles.predefinedFileType);
  const uploadToastStatus: ToastProps = useSelector((state: RootState) => state.uploadFiles.uploadToastStatus);
  const fetchingFile: boolean = useSelector((state: RootState) => state.uploadFiles.fetchingFile);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(UploadStepIndex.FileType);
  const [watermarkSubmitRequested, setWatermarkSubmitRequested] = useState(false);
  const [selectedDestinations, setSelectedDestinations] = useState<DirectoryDestination[]>([]);
  const [fileType, setFileType] = useState<FileTypes | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    if (showUploadFlow) {
      if (!applyToAll) {
        reset(DefaultFileFormValues);
        setWatermarkSubmitRequested(false);
        setSelectedDestinations([]);
        if (preDefinedFileType) {
          handleTypeSubmit();
        } else {
          setFileType(null);
          setCurrentStepIndex(UploadStepIndex.FileType);
        }
        // if apply to all isn't set - reset every field in upload flow
        setShowDialog(showUploadFlow);
      } else {
        handleConfirm();
      }
    } else {
      // closing dialog
      setShowDialog(showUploadFlow);
    }
  }, [showUploadFlow]);

  useEffect(() => {
    if (preDefinedFileType) {
      setFileType(preDefinedFileType);
    }
  }, [preDefinedFileType]);

  const { control, getValues, setValue, reset } = useForm<UploadFileForm>({
    defaultValues: DefaultFileFormValues
  });

  const handleSelectDestination = (directory: DirectoryDestination): void => {
    const directoryId: string = directory.id;
    let newSelected: DirectoryDestination[] = [];
    if (!selectedDestinations.some((destination: DirectoryDestination) => directoryId === destination.id)) {
      newSelected = [directory];
    }
    setSelectedDestinations(newSelected);
  }

  const handleNextStep = (): void => {
    if (currentStepIndex === UploadStepIndex.FileType) {
      handleTypeSubmit();
    } else if (currentStepIndex === UploadStepIndex.ScriptInformation) {
      handleScriptSubmit();
    } else if (currentStepIndex === UploadStepIndex.FileWatermark) {
      setWatermarkSubmitRequested(true);
    } else {
      let nextStepIndex = currentStepIndex + NextStep;
      if (nextStepIndex === UploadStepIndex.ScriptInformation && fileType !== FileTypes.Script) {
        nextStepIndex = currentStepIndex + SkipStep;
      }
      if (!watermarkAvailable && nextStepIndex === UploadStepIndex.FileWatermark) {
        nextStepIndex = nextStepIndex + NextStep;
      }
      setCurrentStepIndex(nextStepIndex);
    }
  }

  const handleSkip = (): void => {
    let nextStepIndex = currentStepIndex + NextStep;
    if (currentStepIndex === UploadStepIndex.FileWatermark) {
      setValue('wmFirstLine', null);
      setValue('wmSecondLine', null);
    } else if (currentStepIndex === UploadStepIndex.FileInformation) {
      setValue('category', DefaultFileCategory);
      nextStepIndex = currentStepIndex + SkipStep;
      if (!watermarkAvailable && nextStepIndex === UploadStepIndex.FileWatermark) {
        nextStepIndex = nextStepIndex + 1;
      }
    }
    setCurrentStepIndex(nextStepIndex);
  }

  const handleClose = (): void => {
    UploadService.handleNextFile();
  }

  const handleConfirm = (): void => {
    handleCloseToast();
    if (selectedDestinations.length === 0) {
      UploadService.showUploadError('upload.fileDestination.error.selection');
      return;
    }
    if (currentFile && fileType) {
      const sortedDestinations: DirectoryDestination[] = selectedDestinations.sort(
        (f1: DirectoryDestination, f2: DirectoryDestination) => { return f1.title.localeCompare(f2.title) }
      );
      if (fileType === FileTypes.DocumentFolder) {
        UploadService.processImportFolderUpload(sortedDestinations)
          .then(() => {
            UploadService.showUploadToast({
              message: t('upload.fileImport.success'),
              type: 'success',
              isShown: true
            })
            handleClose();
          })
          .catch((err) => {
            console.error(err);
            UploadService.showUploadError('upload.error.server');
          })
      } else {
        UploadService.processFileUpload(sortedDestinations, getValues, fileType)
          .then(() => {
            handleClose();
          })
          .catch((err) => {
            console.error(err);
            UploadService.showUploadError('upload.error.server');
          })
      }
    }
  }

  const handleWatermarkSubmit = (data: WatermarkValues) => {
    handleCloseToast();
    if (!data.typeline1 || !data.typeline2) {
      UploadService.showUploadError('scripts.viewer.watermark.error.selection');
      setWatermarkSubmitRequested(false);
      return;
    } else if ((data.typeline1 === OtherOption && !data.line1.trim()) || (data.typeline2 === OtherOption && !data.line2.trim())) {
      UploadService.showUploadError('upload.fileWatermark.error.other');
      setWatermarkSubmitRequested(false);
      return;
    }
    // set data for UploadFileForm
    setValue('wmFirstLine', data.typeline1);
    setValue('wmSecondLine', data.typeline2);
    setValue('wmFirstOtherField', data.line1);
    setValue('wmSecondOtherField', data.line2);
    setValue('watermark', true);
    const nextStepIndex = currentStepIndex + NextStep;
    setCurrentStepIndex(nextStepIndex);
  }

  const handleScriptSubmit = () => {
    handleCloseToast();
    const isParseChecked: boolean = getValues('isParseChecked');
    const version: string = getValues('version');
    const color: string = getValues('color');
    if (isParseChecked && !color) {
      UploadService.showUploadError('scripts.viewer.parseFile.error.color');
    } else if (isParseChecked && UtilityService.getFileExtension(currentFile?.name ?? '') !== 'pdf') {
      UploadService.showUploadError('scripts.viewer.parseFile.error.pdf');
    } else {
      const DraftVersionValue: string = 'draft';
      if (version === DraftVersionValue) {
        setValue('color', DraftVersionValue);
      } else if (version && color) {
        if (version !== 'single') {
          setValue('color', version + color[0].toUpperCase() + color.substring(1));
        }
      }
      if (!getValues('episode')) {
        setValue('episode', '0');
      }
      let nextStepIndex: number = currentStepIndex + NextStep
      if (!watermarkAvailable && nextStepIndex === UploadStepIndex.FileWatermark) nextStepIndex = currentStepIndex + SkipStep;
      setCurrentStepIndex(nextStepIndex);
    }
  }

  const handleTypeSubmit = () => {
    if (!fileType) {
      UploadService.showUploadError('upload.fileType.error');
      return;
    }
    let nextStepIndex = currentStepIndex + NextStep;
    if (fileType !== FileTypes.Script) {
      setValue('isParseChecked', false);
      setValue('episode', '0');
    }
    if (fileType === FileTypes.Photo) {
      nextStepIndex = UploadStepIndex.FileDestination;
      setValue('category', 'photo');
    } else if ([FileTypes.Template, FileTypes.DocumentFolder].includes(fileType)) {
      nextStepIndex = UploadStepIndex.FileDestination;
    } else if (fileType === FileTypes.Script) {
      nextStepIndex = UploadStepIndex.ScriptInformation;
    }
    setCurrentStepIndex(nextStepIndex);
  }

  const handleCloseToast = (): void => {
    UploadService.closeUploadToast();
  }

  return (
    <div className={styles.UploadFlow} data-testid="UploadFlow">
      {fetchingFile && <CircularProgress className={progressStyles.circularProgress}/> }
      <Dialog
        open={showDialog}
        onClose={handleClose}
        classes={{
          root: `${dialogStyles.dialogRoot} ${styles.UploadFlow}`,
          paper: `${dialogStyles.dialogPaper} ${styles.UploadFlowPaper}`
        }}
        BackdropProps={{
          className: dialogStyles.dialogBackdrop
        }}
      >
        <ModalTitle handleClose={handleClose} title={''} />
        <DialogContent sx={{ overflowY: (currentStepIndex === UploadStepIndex.FileDestination) ? 'hidden' : 'auto' }}>
          <Grid
              container
              direction='column'
              alignItems='stretch'
          >
            <UploadSteps
              currentStepIndex={currentStepIndex}
              stepper={true}
              control={control}
              getValues={getValues}
              setValue={setValue}
              submitRequested={watermarkSubmitRequested}
              onSubmit={handleWatermarkSubmit}
              selectedDestinations={selectedDestinations}
              selectDestination={handleSelectDestination}
              fileType={fileType}
              setSelectedFileType={setFileType}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            ([UploadStepIndex.FileWatermark, UploadStepIndex.FileInformation].includes(currentStepIndex))
              ? <TertiaryButton
                  disableRipple
                  onClick={handleSkip}
                  className={dialogStyles.cancelButton}
                >
                  {t('action.skip')}
                </TertiaryButton>
              : <TertiaryButton
                  disableRipple
                  onClick={handleClose}
                  className={dialogStyles.cancelButton}
                >
                  {t('action.cancel')}
                </TertiaryButton>
          }
          {
            ([UploadStepIndex.FileInformation, UploadStepIndex.ScriptInformation,
              UploadStepIndex.FileWatermark, UploadStepIndex.FileType].includes(currentStepIndex))
              ? <SuccessButton onClick={handleNextStep}>{[UploadStepIndex.FileType, UploadStepIndex.FileWatermark].includes(currentStepIndex)
                ? t('action.confirm')
                : t('action.next')}</SuccessButton>
              : <SuccessButton onClick={handleConfirm}>{t('action.upload')}</SuccessButton>
          }
        </DialogActions>
      </Dialog>
      <Toast
        open={uploadToastStatus.isShown}
        onClose={handleCloseToast}
        type={uploadToastStatus.type}
        title={uploadToastStatus.message}
      />
      <FileAlreadyExist />
      <FileTooLarge />
      <RenameExistingFile />
      <ApplyToAll />
    </div>
  );
};

export default UploadFlow;
