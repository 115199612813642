/**
 * Reducer file for the Dashboard slice
 * Handles the setting of the details to be displayed on the dashboard
 */

import { createSlice } from '@reduxjs/toolkit';
import { DashboardSlice } from './constants';
import type { ClosestCallSheet } from '../../models/CalenderEntity';
import { initialPaperWallDistro } from '../../models/Document';
import type { PaperWallDistro, PaperWallDistroCategories, DistroFile } from '../../models/Document';

interface DashboardState {
  closestCallSheet: ClosestCallSheet
  paperWallDistros: PaperWallDistro
  loadingData: boolean
  restrictedCommunity: boolean
  communityId: string
}

const initialState: DashboardState = {
  closestCallSheet: {
    date: '',
    callTime: ''
  },
  paperWallDistros: initialPaperWallDistro,
  loadingData: true,
  restrictedCommunity: false,
  communityId: ''
}

export const dashboardSlice = createSlice({
  name: DashboardSlice,
  initialState,
  reducers: {
    setClosestCallSheet: (state, action) => {
      state.closestCallSheet = action.payload.callSheet;
    },
    setPaperWallDistros: (state, action) => {
      const category: PaperWallDistroCategories = action.payload.category;
      const file: DistroFile = action.payload.file;
      state.paperWallDistros[category] = file;
    },
    setLoadingData: (state, action) => {
      state.loadingData = action.payload.loadingData;
    },
    setRestrictedCommunity: (state, action) => {
      state.restrictedCommunity = action.payload.restrictedCommunity;
      state.communityId = action.payload.communityId;
    }
  }
});

export const {
  setClosestCallSheet, setPaperWallDistros, setLoadingData, setRestrictedCommunity
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
