import { useRef, useState, useEffect } from 'react';
import type { FC, SyntheticEvent } from 'react';
import type { Department } from '../../models/Department';
import type { DistributionList, SelectedGroup } from '../../models/DistributionList';
import type { Person } from '../../models/Person';
import { List } from 'react-virtualized';
import { Box, Typography, Checkbox } from '@mui/material';
import { WhiteGrey, BlackGrey } from '../../theme/colors';
import { defaultCheckboxStyles } from '../../theme/styles';
import { textStyle } from './Header';

const rowHeight: number = 64;

interface Props {
  type: DistributionList | Department
  group: SelectedGroup[]
  handleSelect: (event: SyntheticEvent, member: Person, type: DistributionList | Department) => void
  isExpanded: boolean
}

/**
 * Member Component for the list or departemnt members
 * Contains a checkbox allowing the member to be added or removed from the template
 */
const Member: FC<Props> = ({ type, group, handleSelect, isExpanded }: Props) => {
  if (type.members !== undefined) {
    const parentDivRef = useRef<HTMLDivElement>(null);
    const [listWidth, setListWidth] = useState<number>(0);
    const [listHeight, setListHeight] = useState<number>(0);

    useEffect(() => {
      if (parentDivRef.current) {
        const parentDivWidth = parentDivRef.current.offsetWidth;
        setListWidth(parentDivWidth);
      }
      const totalHeight = type.members.length * rowHeight;
      setListHeight(totalHeight);
    }, []);

    const groups = group.find(g => g.id === type.id);
    return (
      <>
        <div ref={parentDivRef} style={{ width: '100%', height: '100%' }}>
            {(!isExpanded)
              ? <></>
              : <List
                  width={listWidth}
                  height={listHeight}
                  rowCount={type.members.length}
                  rowHeight={rowHeight}
                  rowRenderer={({ index }) => {
                    const member = type.members[index];
                    let checked = false;
                    if (groups !== undefined && member.id !== null && member.id !== undefined) {
                      const includesMemberId: boolean = (groups.members.map((member) => member.id).includes(member.id));
                      if (includesMemberId) {
                        checked = true;
                      }
                    }
                    return (
                      <Box
                        key={member.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '0px 16px 0px 25px',
                          textTransform: 'capitalize',
                          color: BlackGrey,
                          fontWeight: 400,
                          fontSize: '1rem',
                          border: `1px solid ${WhiteGrey}`,
                          minHeight: `${rowHeight}px`,
                          '&:hover': {
                            background: WhiteGrey,
                            cursor: 'pointer'
                          }
                        }}
                      >
                        <Checkbox
                          checked={checked}
                          color={'success'}
                          onClick={(e) => {
                            if (member.id !== null && member.id !== undefined) {
                              handleSelect(e, member, type)
                            }
                          }}
                          sx={{ ...defaultCheckboxStyles }}
                        />
                        <Box
                          component={'span'}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                          }}
                        >
                          <Typography noWrap sx={{ ...textStyle }}>
                            {`${String(member.jobTitle)} - ${String(member.firstName)} ${String(member.lastName)}`}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  }}
              />
            }
        </div>
      </>
    );
  }
  return null;
}

export default Member;
