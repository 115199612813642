import type { SyntheticEvent } from 'react';
import {
  addCount,
  addNotificationItem, addUploadBox,
  removeCount,
  removeNotification, removeUploadBox,
  resetCount,
  updateNotification, updateUploadBox
} from '../../state/slices/Notifications';
import type {
  Notification,
  NotificationType,
  NotificationUpdateData,
  UploadBox,
  UploadBoxUpdateData
} from '../../models/Notifications';
import { NotificationStatus, UploadStatus } from '../../models/Notifications';
import NotificationService from './NotificationService';
import { store } from '../../state/store';
import i18n from 'i18next';
import addNotification from 'react-push-notification';
import { ChipColor, MainWhite } from '../../theme/colors';

const { dispatch } = store;

interface INotificationUtility {
  addNotification: (referenceId: string, title: string, timeCreated: number, type: NotificationType, incrementCounter: boolean) => void
  removeNotification: (referenceId: string) => void
  updateNotificationData: (data: NotificationUpdateData) => void
  showBrowserNotification: (referenceId: string, title: string, contentUrl?: string) => void
  addCounter: () => void
  removeCounter: () => void
  resetCounter: () => void
  addUploadBox: (fileId: string, fileName: string) => void
  removeUploadBox: (fileId: string) => void
  updateUploadBox: (data: UploadBoxUpdateData) => void
}

class NotificationUtility implements INotificationUtility {
  addNotification (referenceId: string, title: string, timeCreated: number, type: NotificationType, incrementCounter: boolean): void {
    const notification: Notification = {
      referenceId,
      title,
      timeCreated,
      type,
      progress: 0,
      status: NotificationStatus.Initializing
    };
    if (incrementCounter) {
      this.addCounter();
    }
    dispatch(addNotificationItem({ notification }));
  }

  removeNotification (referenceId: string): void {
    NotificationService.updateNotificationVisibility(referenceId, false)
      .then(() => {
        dispatch(removeNotification({ referenceId }));
        this.removeCounter();
      })
      .catch((err) => {
        console.error(err);
      })
  }

  updateNotificationData (data: NotificationUpdateData): void {
    dispatch(updateNotification({
      referenceId: data.referenceId,
      data
    }));
  }

  showBrowserNotification (referenceId: string, title: string, contentUrl?: string): void {
    addNotification({
      title: String(i18n.t('notifications.download.pushTitle')),
      subtitle: String(i18n.t('notifications.download.pushSubtitle')),
      message: title,
      backgroundTop: MainWhite,
      backgroundBottom: MainWhite,
      colorTop: ChipColor,
      colorBottom: ChipColor,
      duration: 5000,
      onClick: (event: SyntheticEvent) => {
        event.stopPropagation();
        handleClick();
      },
      closeButton: 'X'
    });
    if (referenceId) {
      void NotificationService.updateBrowserNotificationStatus(referenceId, true);
    }

    const handleClick = (): void => {
      if (contentUrl) {
        window.location.href = contentUrl;
      }
    }
  }

  addCounter (): void {
    dispatch(addCount());
  }

  removeCounter (): void {
    dispatch(removeCount());
  }

  resetCounter (): void {
    dispatch(resetCount());
  }

  addUploadBox (fileId: string, fileName: string): void {
    const uploadBox: UploadBox = {
      fileId,
      fileName,
      progress: 0,
      status: UploadStatus.Initializing
    };
    dispatch(addUploadBox({ uploadBox }))
  }

  removeUploadBox (fileId: string): void {
    dispatch(removeUploadBox({ fileId }));
  }

  updateUploadBox (data: UploadBoxUpdateData): void {
    dispatch(updateUploadBox({
      fileId: data.fileId,
      data
    }));
  }
}

const notificationUtility: INotificationUtility = new NotificationUtility();
export default notificationUtility;
