import { createSlice } from '@reduxjs/toolkit';
import { GenerateCrewList } from './constants';

const initialState = {
  sortedDeptContacts: {},
  isCrewListMetadataSet: false
}

export const generateCrewListSlice = createSlice({
  name: GenerateCrewList,
  initialState,
  reducers: {
    setSortedDeptContacts: (state, action) => {
      state.sortedDeptContacts = action.payload.sortedDeptContacts;
    },
    setIsCrewListMetadataSet: (state, action) => {
      state.isCrewListMetadataSet = action.payload.isSet;
    }
  }
});

export const { setSortedDeptContacts, setIsCrewListMetadataSet } = generateCrewListSlice.actions;
export default generateCrewListSlice.reducer;
