import React from 'react';
import type { FC } from 'react';
import GeneralModal from './GeneralModal/GeneralModal.lazy';
import type { RootState } from '../../state/store';
import { ModalIcons } from './ModalIcon/ModalIcon';
import { useSelector } from 'react-redux';

interface ClearProps {
  clearEditor?: (event: React.SyntheticEvent) => Promise<void>
}

export const ClearEditorModal: string = 'ClearModal';

/**
 * Clear Modal component
 * Used in compose editor when clearing the editor
 * Calls generalModal to display data
 */
const ClearModal: FC<ClearProps> = (props: ClearProps) => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[ClearEditorModal]);
  return (
    <>
      <GeneralModal
        modalKey={ClearEditorModal}
        isVisible={modalsState.isVisible}
        title={modalsState.title}
        content={modalsState.content}
        heading={modalsState.heading}
        width={538}
        buttons={modalsState.buttons}
        confirmButton={modalsState.confirmButton}
        handleConfirm={props.clearEditor}
        iconType={ModalIcons.Question}
      />
    </>
  )
}

export default ClearModal;
