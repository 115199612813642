import React, { lazy, Suspense } from 'react';
import type { ImportFileProps } from './ImportFile';

const LazyImportFile = lazy(async () => await import('./ImportFile'));

const ImportFile = (props: ImportFileProps) => (
  <Suspense fallback={null}>
    <LazyImportFile {...props} />
  </Suspense>
);

export default ImportFile;
