import type { ReactNode } from 'react';
import type { OptionProps } from '../../models/OptionProps';
import { InviteContactModal } from '../modals/InviteContact/constants';
import { ChangeCategoryModal } from '../modals/ChangeCategory/constants';
import { ChangeDepartmentModal } from '../modals/ChangeDepartment/constants';
import { ContactAddToDistributionModal } from '../modals/ContactAddToDistributionList/constants';
import { DeleteContactModal } from '../modals/DeleteContact/constants';
import PermissionService, { AccessLevel } from '../../services/auth/PermissionService';
import { SecurityPageName } from '../../services/auth/constants';

export enum ContactListingsPage {
  All = 'All Contacts',
  Contacts = 'Contacts',
  Crew = 'Crew',
  Cast = 'Cast',
  Agent = 'Agent',
  Studio = 'Studio',
  Vendor = 'Vendor',
  Union = 'Union'
}

export enum EditContactPage {
  Title = 'Edit Contact'
}

export enum AddContactPage {
  Title = 'Add Contact'
}

export enum ContactButtonType {
  Success = 0,
  White = 1
}

export enum ContactButtonStyle {
  AlwaysShow = 0,
  Show = 1,
  Hide = 2,
  ShowForOnlyOne = 3
}

export interface ContactListingButton {
  button: string
  hidden: ContactButtonStyle
  type: ContactButtonType
  startIcon?: ReactNode
}

type ContactListingButtonsType = Record<ContactListingsPage, ContactListingButton[]>

export const ContactListingsButtons: ContactListingButtonsType = {
  [ContactListingsPage.All]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'email',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'text',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'setEmailDoods',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    }
    /*    {
      button: 'addContact',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    } */
    /*    {
      button: 'generateCrewList',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.Success
    } */
  ],
  // contacts page has different buttons to the rest - these are in the ContactEditButtons component
  [ContactListingsPage.Contacts]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'email',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'text',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'setEmailDoods',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    }
    /*    {
      button: 'addContact',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    } */
    /*    {
      button: 'generateCrewList',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.Success
    } */
  ],
  [ContactListingsPage.Crew]: [
    {
      button: 'email',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'text',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    /*    {
      button: 'addContact',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    }, */
    {
      button: 'setEmailDoods',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    },
    {
      button: 'generateCrewList',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.Success
    }
  ],
  [ContactListingsPage.Agent]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'setEmailDoods',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    },
    {
      button: 'linkCast',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    },
    {
      button: 'addAgent',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    }
    /*    {
      button: 'generateCrewList',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.Success
    } */
  ],
  [ContactListingsPage.Cast]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    },
    {
      button: 'setEmailDoods',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    },
    {
      button: 'linkAgent',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    },
    {
      button: 'addCast',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    }
    /*    {
      button: 'generateCrewList',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.Success
    } */
  ],
  [ContactListingsPage.Studio]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    }
    /*    {
      button: 'addStudio',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    } */
  ],
  [ContactListingsPage.Vendor]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    }
    /*    {
      button: 'addVendor',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    } */
  ],
  [ContactListingsPage.Union]: [
    {
      button: 'manageDistribution',
      hidden: ContactButtonStyle.Show,
      type: ContactButtonType.White
    },
    {
      button: 'addDistribution',
      hidden: ContactButtonStyle.Hide,
      type: ContactButtonType.White
    }
    /*    {
      button: 'addUnion',
      hidden: ContactButtonStyle.AlwaysShow,
      type: ContactButtonType.White
    } */
  ]
}

export const ContactOptions: OptionProps[] = [
  {
    key: 'option-send-invite',
    label: 'contacts.table.moreOptions.invite',
    value: InviteContactModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.AllContacts)
  },
  {
    key: 'option-edit-contact',
    label: 'contacts.table.moreOptions.edit',
    value: 'edit',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.AllContacts)
  },
  {
    key: 'option-email',
    label: 'contacts.table.moreOptions.email',
    value: 'email',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Compose)
  },
  {
    key: 'option-text',
    label: 'contacts.table.moreOptions.text',
    value: 'text',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Compose)
  },
  {
    key: 'option-change-category',
    label: 'contacts.table.moreOptions.category',
    value: ChangeCategoryModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.AllContacts)
  },
  {
    key: 'option-change-department',
    label: 'contacts.table.moreOptions.department',
    value: ChangeDepartmentModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.AllContacts),
    showOn: [ContactListingsPage.All, ContactListingsPage.Crew]
  },
  {
    key: 'option-add-distribution',
    label: 'contacts.table.moreOptions.addDistribution',
    value: ContactAddToDistributionModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.DistributionList)
  },
  {
    key: 'option-edit-distribution',
    label: 'contacts.table.moreOptions.editDistribution',
    value: 'editDistribution',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.DistributionList)
  },
  {
    key: 'option-delete',
    label: 'contacts.table.moreOptions.delete',
    value: DeleteContactModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.AllContacts)
  }
];

export const OptionPopups: any = {
  [InviteContactModal]: {
    heading: 'contacts.inviteContact.heading',
    content: 'contacts.inviteContact.content',
    confirmButton: 'action.confirm'
  },
  [ChangeCategoryModal]: {
    heading: 'contacts.changeCategory.heading',
    content: 'contacts.changeCategory.content',
    confirmButton: 'action.confirm'
  },
  [ChangeDepartmentModal]: {
    heading: 'contacts.changeDepartment.heading',
    content: 'contacts.changeDepartment.content',
    confirmButton: 'action.confirm'
  },
  [ContactAddToDistributionModal]: {
    heading: 'contacts.AddToDistribution.heading',
    content: 'contacts.AddToDistribution.content',
    confirmButton: 'action.confirm'
  },
  [DeleteContactModal]: {
    heading: 'contacts.deleteContact.heading',
    content: 'contacts.deleteContact.content'
  }
}
