import React from 'react';
import type { FC } from 'react';
import styles from './ExitWithoutSaving.module.scss';
import dialogStyles from '../../../theme/dialog.module.scss';
import ModalService from '../../../services/ModalService';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle/ModalTitle.lazy';
import TertiaryButton from '../../common/buttons/TertiaryButton';
import type { RootState } from '../../../state/store';
import { Dialog, DialogActions } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { ExitWithoutSavingModal } from './constants';
import SuccessButton from '../../common/buttons/SuccessButton';
import { ModalIcons } from '../ModalIcon/ModalIcon';

const ExitWithoutSaving: FC = () => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[ExitWithoutSavingModal]);
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const handleClose = (): void => {
    ModalService.closeCustomModal(ExitWithoutSavingModal);
  }

  return (
    <Dialog
      data-testid="ExitWithoutSaving"
      classes={{
        root: `${dialogStyles.dialogRoot} ${styles.DeleteDocument}`,
        paper: `${dialogStyles.dialogPaper} ${dialogStyles.dialogGeneralWidth}`
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
      open={modalsState.isVisible}
      onClose={handleClose}
    >
      <ModalTitle handleClose={handleClose} title={modalsState.title} />
      <ModalContent
        heading={String(t(modalsState.heading))}
        content={String(t(modalsState.content))}
        iconType={ModalIcons.Warning}
      />
      <DialogActions className={dialogStyles.dialogActionsEnd}>
        <TertiaryButton
          disableRipple
          onClick={handleClose}
          className={dialogStyles.cancelButton}
        >
          {t('action.cancel')}
        </TertiaryButton>
        <SuccessButton disableRipple onClick={modalsState.callback}>{t(modalsState.confirmButton)}</SuccessButton>

      </DialogActions>
    </Dialog>
  )
};

export default ExitWithoutSaving;
