import React, { lazy, Suspense } from 'react';

const LazyIncludeAgents = lazy(async () => await import('./IncludeAgents'));

const IncludeAgents = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyIncludeAgents {...props} />
  </Suspense>
);

export default IncludeAgents;
