import styled from '@mui/material/styles/styled';

/**
 * Main component style for main section of pages with open and close slide drawer
 */
const MainSection = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean
  drawerwidth: number
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(8),
  paddingBottom: theme.spacing(3),
  paddingRight: theme.spacing(8),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerwidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

export default MainSection;
