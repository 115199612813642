import React, { lazy, Suspense } from 'react';

const LazyConfirmCancellation = lazy(async () => await import('./ConfirmCancellation'));

const ConfirmCancellation = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyConfirmCancellation {...props} />
  </Suspense>
);

export default ConfirmCancellation;
