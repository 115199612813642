export enum FileTypes {
  ReportsAndSchedules = 'reportsAndSchedules',
  Report = 'report',
  Schedules = 'schedules',
  Script = 'script',
  Photo = 'photo',
  Document = 'document',
  Sides = 'sides',
  Template = 'template',
  DocumentFolder = 'documentFolder'
}

export enum PageMenus {
  Templates = 'menu_templates',
  Scripts = 'menu_scripts',
  Documents = 'menu_documents',
  Schedules = 'menu_schedules'
}

export enum CrooglooFileTypes {
  File = 'file',
  PDF = 'pdf',
  Excel = 'excel',
  Image = 'image',
  Folder = 'folder',
  DropboxFolder = 'dbfolder',
  DropboxLink = 'dblink',
  GDriveLink = 'gDriveLink',
  BoxLink = 'boxlink',
  GDriveFolder = 'gDriveFolder',
  BoxFolder = 'boxfolder',
  DropboxFile = 'dropbox-file',
  GDriveFile = 'gDriveFile',
  BoxFile = 'box-file'
}

export enum FileFolderNames {
  Scripts = 'Scripts',
  Photos = 'Photos',
  Templates = 'Templates'
}
