import React, { lazy, Suspense } from 'react';

const LazyExpiredTrial = lazy(async () => await import('./ExpiredTrial'));

const ExpiredTrial = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyExpiredTrial {...props} />
  </Suspense>
);

export default ExpiredTrial;
