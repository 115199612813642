import React from 'react';
import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Grid } from '@mui/material';
import dialogStyles from '../../../theme/dialog.module.scss';
import MegaphoneIcon from '../../../assets/icons/bitmap/megaphone2.png';
import PhonesIcon from '../../../assets/icons/bitmap/phones.png';
import ClapboardIcon from '../../../assets/icons/bitmap/clapboard.png';

export enum ModalIcons {
  Info = 0,
  Warning = 1,
  Question = 2,
  Success = 3
}

export interface ModalIconProps {
  iconType?: ModalIcons
}

/**
 * Reusable Dialog Popup Icon component
 * Called by any modal/dialog that has a icon to show
 */

const ModalIcon: FC<ModalIconProps> = ({ iconType }: ModalIconProps) => {
  return (
    <Grid item>
      {(iconType === ModalIcons.Info || !iconType) && <InfoIcon className={dialogStyles.dialogIcon} />}
      {(iconType === ModalIcons.Warning) && <img src={MegaphoneIcon} alt={'warning'} className={dialogStyles.warningIcon}/>}
      {(iconType === ModalIcons.Question) && <img src={PhonesIcon} alt={'question'} />}
      {(iconType === ModalIcons.Success) && <img src={ClapboardIcon} alt={'success'} />}
    </Grid>
  )
};

export default ModalIcon;
