import React, { lazy, Suspense } from 'react';

const LazyManageSubscription = lazy(async () => await import('./ManageSubscription'));

const ManageSubscription = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyManageSubscription {...props} />
  </Suspense>
);

export default ManageSubscription;
