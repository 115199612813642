import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './SecurityGroups.module.scss';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { ChipColor } from '../../theme/colors';
import type { SecurityGroup, SecurityGroupMembers } from '../../models/SecurityGroups';
import type {
  DepartmentMembers,
  DepartmentsSecurityListMembers,
  SecurityListMember
} from '../../models/SecurityListMembers';
import TabHeaders from '../slideDrawer/TabHeaders/TabHeaders.lazy';
import TabPanel from '../slideDrawer/TabPanel';
import GroupsTable from './GroupsTable';
import { Grid, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import SuccessButton from '../common/buttons/SuccessButton';
import DeleteButton from '../common/buttons/DeleteButton';
import DisabledButton from '../common/buttons/DisabledButton';
import NothingToSee, { NothingToSeeEntityType } from '../nothingToSee/NothingToSee';
import Toast from '../common/toast/Toast';
import type { DepartmentResponse } from '../../models/Department';
import type { RootState } from '../../state/store';
import SecurityGroupService from '../../services/SecurityGroupsService';
import ConfirmAccessLoss from '../modals/ConfirmAccessLoss/ConfirmAccessLoss.lazy';
import type { AlertColor } from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import PermissionService, { AccessLevel } from '../../services/auth/PermissionService';
import type { ToastProps } from '../../models/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import progressStyles from '../../theme/loadingProgress.module.scss';

export interface SecurityGroupsProps {
  groupID: string
  isFetching: boolean
  tableHeight: number
  tableWidth: number
}

export const ButtonRowHeight: string = '38px';

const SecurityGroupsIndex: FC<SecurityGroupsProps> = ({ groupID, isFetching, tableHeight, tableWidth }: SecurityGroupsProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  const [members, setMembers] = useState<DepartmentMembers>({});
  const [nonMembers, setNonMembers] = useState<DepartmentMembers>({});
  const [selected, setSelected] = useState<string[]>([]);
  const [search] = useState('');

  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);

  // Translation prefix for the production settings tab
  const i18nPrefix = 'securityGroup.';

  const tabs: string[] = [
    'distributionList.tabs.membersTab',
    'distributionList.tabs.nonMembersTab'
  ];

  const securityGroupMembers: SecurityGroupMembers = useSelector((state: RootState) => state.securityAdmin.securityGroupMembers[groupID]);
  const departments: DepartmentResponse[] = useSelector((state: RootState) => state.person.departments);
  const securityGroups: SecurityGroup[] = useSelector((state: RootState) => state.securityAdmin.securityGroups);

  useEffect(() => {
    if (securityGroupMembers && departments.length > 0) {
      const membersObject: DepartmentsSecurityListMembers = SecurityGroupService.formatSecurityListMembersToDepartments(securityGroupMembers, departments);
      setMembers(membersObject.groupMembers);
      setNonMembers(membersObject.nonMembers);
    }
  }, [groupID, securityGroupMembers, departments]);

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
    setSelected([]);
  };

  const handleSetSelected = (newSelected: string[]) => {
    setSelected(newSelected);
  }

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const removePermissions = (): void => {
    const allMembers: SecurityListMember[] = [...securityGroupMembers.members];
    const group: SecurityGroup | undefined = securityGroups.find((g: SecurityGroup) => g.value === groupID);
    if (group) {
      let contactName: string = '';
      // only get name if more than, otherwise it will show the message for more than one
      if (selected.length === 1) {
        const membersToRemove: string[] = allMembers.filter(
          (m: SecurityListMember) => selected.includes(m.personId)
        ).map((m: SecurityListMember) => m.firstName + ' ' + m.lastName);
        contactName = membersToRemove[0];
      }
      setIsLoading(true);
      SecurityGroupService.removeSecurityListMembers(selected, groupID)
        .then((result: string) => {
          handleShowToast(String(t(result, { name: group.label, contact: contactName })), 'error', ToastIcons.Success);
          setSelected([]);
        })
        .catch((err) => {
          console.error(err);
          if (err.message && err.message !== 'cancel') {
            handleShowToast(err.message, 'error', ToastIcons.Info);
          }
        }).finally(() => {
          setIsLoading(false);
        })
    } else {
      handleShowToast('securityGroup.confirmLoss.error', 'error', ToastIcons.Info);
    }
  }

  const savePermissions = (): void => {
    const allMembers: SecurityListMember[] = [...securityGroupMembers.members];
    const group: SecurityGroup | undefined = securityGroups.find((g: SecurityGroup) => g.value === groupID);
    if (group) {
      let contactName: string = '';
      // only get name if more than one contact, otherwise it will show the message for more than one
      if (selected.length === 1) {
        const membersToAdd: string[] = allMembers.filter(
          (m: SecurityListMember) => selected.includes(m.personId)
        ).map((m: SecurityListMember) => m.firstName + ' ' + m.lastName);
        contactName = membersToAdd[0];
      }
      setIsLoading(true);
      SecurityGroupService.addSecurityListMembers(selected, groupID)
        .then((result: string) => {
          handleShowToast(String(t(result, { name: group.label, contact: contactName })), 'success', ToastIcons.Success);
          setSelected([]);
        })
        .catch((err) => {
          console.error(err);
          if (err.message && err.message !== 'cancel') {
            handleShowToast(err.message, 'error', ToastIcons.Info);
          }
        }).finally(() => {
          setIsLoading(false);
        })
    } else {
      handleShowToast('securityGroup.confirmLoss.error', 'error', ToastIcons.Info);
    }
  };

  const filteredMembers = useMemo(() => filterMembers(members, search), [members, search]);
  const filteredNonMembers = useMemo(() => filterMembers(nonMembers, search), [nonMembers, search]);

  return (
    <div className={styles.ManagePermissions} data-testid="SecurityGroups">

      <Grid container direction={'row'} alignItems={'flex-start'} spacing={2} my={1} style={{
        marginTop: '0',
        marginBottom: '0',
        position: 'relative'
      }}>

          {PermissionService.hasPermission(AccessLevel.READ_WRITE) &&

              <div style={{
                flexGrow: 1,
                justifyContent: 'right',
                display: 'flex',
                height: '38px',
                position: 'absolute',
                right: '0',
                top: '0',
                zIndex: '1000'
              }}>
                {(tab === 0)
                  ? ((selected.length > 0 && !isLoading)
                      ? <DeleteButton disableRipple onClick={removePermissions}>{t(`${i18nPrefix}removeSelected`)}</DeleteButton>
                      : <DisabledButton disabled={true}>{t(`${i18nPrefix}removeSelected`)}</DisabledButton>)
                  : ((selected.length > 0 && !isLoading)
                      ? <SuccessButton disableRipple onClick={savePermissions}>{t(`${i18nPrefix}addSelected`)}</SuccessButton>
                      : <DisabledButton disabled={true}>{t(`${i18nPrefix}addSelected`)}</DisabledButton>)
                }
              </div>

          }

      </Grid>
      <Box className={styles.tabPanel} sx={{
        '& .tabHeader': {
          color: ChipColor,
          fontWeight: 700,
          fontStyle: 'bold'
        }
      }}>
        <TabHeaders tab={tab} onChange={handleTabChange} tabs={tabs} />
        {(isFetching)
          ? <CircularProgress className={progressStyles.circularProgress}/>
          : <>
              <TabPanel value={tab} index={0}>
                {(filteredMembers && Object.keys(filteredMembers).length > 0)
                  ? <GroupsTable members={filteredMembers} selected={selected} setSelected={handleSetSelected} tableHeight={tableHeight} tableWidth={tableWidth} />
                  : <NothingToSee entityType={NothingToSeeEntityType.Group} />
                }
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {(filteredNonMembers && Object.keys(filteredNonMembers).length > 0)
                  ? <GroupsTable members={filteredNonMembers} selected={selected} setSelected={handleSetSelected} tableHeight={tableHeight} tableWidth={tableWidth} />
                  : <NothingToSee entityType={NothingToSeeEntityType.Group} />
                }
              </TabPanel>
            </>
        }
      </Box>
      <ConfirmAccessLoss />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </div>
  )
};

export default SecurityGroupsIndex;

function filterMembers (members: DepartmentMembers, search: string | null) {
  if (!search || !members) {
    return members;
  }

  return Object.entries(members).reduce((acc, [key, users]) => {
    return {
      ...acc,
      [key]: users.filter(c => {
        return c.firstName.toLowerCase().includes(search.toLowerCase()) ||
          c.lastName.toLowerCase().includes(search.toLowerCase()) ||
          c.jobTitle.toString().toLowerCase().includes(search.toLowerCase());
      })
    };
  }, {});
}
