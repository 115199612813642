import React, { lazy, Suspense } from 'react';
import type { ModalIconProps } from './ModalIcon';

const LazyModalIcon = lazy(async () => await import('./ModalIcon'));

const ModalIcon = (props: ModalIconProps) => (
  <Suspense fallback={null}>
    <LazyModalIcon {...props} />
  </Suspense>
);

export default ModalIcon;
