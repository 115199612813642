import React from 'react';
import type { FC } from 'react';
import List from '@mui/material/List';
import ScriptListTabItem from './ScriptListTabItem';
import TabPanel from '../../slideDrawer/TabPanel';
import { Box } from '@mui/material';
import styles from './ScriptListDrawerTabs.module.scss';
import TabHeaders from '../../slideDrawer/TabHeaders/TabHeaders';
import type { GenerateScripts, AllScripts } from '../../../models/Script';
import type { RootState } from '../../../state/store';
import scrollStyles from '../../../theme/sidebar.module.scss';
import { useSelector } from 'react-redux';

interface Props {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  selectedScript: GenerateScripts | null
  setSelectedScript: (script: GenerateScripts) => void
  removeSelectedScript: (script: GenerateScripts, index: number) => void
  selectedScripts: GenerateScripts[]
}

// Script list tabs
const tabs: string[] = [
  'generateSides.tabs.latestVersions',
  'generateSides.tabs.otherScripts'
];

/**
 * Drawer tabs component for the script lists
 */
const DrawerTabs: FC<Props> = (props: Props) => {
  const scripts: AllScripts = useSelector((state: RootState) => state.sides.allScripts);

  const latestVersionScripts: GenerateScripts[] = scripts.latest;
  const otherScripts: GenerateScripts[] = scripts.other;

  const handleSelectScript = (script: GenerateScripts, isSelected: boolean): void => {
    if (isSelected) {
      // if script is already selected get script index and then remove
      const selectedIndex: number = props.selectedScripts.map((selectedScript: GenerateScripts) => selectedScript.id).indexOf(script.id);
      props.removeSelectedScript(script, selectedIndex);
    } else {
      props.setSelectedScript(script);
    }
  }

  return (
    <>
    <Box className={styles.ScriptListDrawerTabs}>
      <TabHeaders tab={props.tab} onChange={props.onChange} tabs={tabs} />
      <TabPanel value={props.tab} index={0}>
        <List className={`${scrollStyles.scrollbar} ${styles.scriptList}`}>
          {latestVersionScripts.map((script: GenerateScripts) => (
            <ScriptListTabItem
              key={script.id}
              script={script}
              handleSelect={handleSelectScript}
              isSelected={(props.selectedScripts.some((selected: GenerateScripts) => selected.id === script.id))}
              currentlySelected={script.id === props.selectedScript?.id}
            />
          ))}
        </List>
      </TabPanel>
      <TabPanel value={props.tab} index={1}>
        <List className={`${scrollStyles.scrollbar} ${styles.scriptList}`}>
          {otherScripts.map((script: GenerateScripts) => (
            <ScriptListTabItem
              key={script.id}
              script={script}
              handleSelect={handleSelectScript}
              isSelected={(props.selectedScripts.some((selected: GenerateScripts) => selected.id === script.id))}
              currentlySelected={script.id === props.selectedScript?.id}
            />
          ))}
        </List>
      </TabPanel>
    </Box>
    </>
  )
}

export default DrawerTabs;
