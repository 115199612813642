import { store } from '../../state/store';
import { ProductionApi } from '../auth/constants';
import type { ApiDataType, AuthUser } from '../auth/constants';
import AuthService from '../auth/AuthService';
import UtilityService from '../UtilityService';
import ImportExportService from '../ImportExportService';

import type { WSResponse } from '../../models/WSResponse';
import type { ProductionSettingResponse, SwitchProductionResponse } from '../../models/Production';
import { setIsCrewListMetadataSet } from '../../state/slices/GenerateCrewList';
import { setStripePublicKey } from '../../state/slices/Subscription';

import { GetMethod, PostMethod } from '../constants';

const { dispatch } = store;

interface IProductionService {
  fetchUserSetting: () => Promise<any>
  fetchProductionSettings: () => Promise<ProductionSettingResponse>
  updateProductionSettings: (payload: Record<string, string>) => Promise<WSResponse>
  createDepartment: (deptName: string, displayOrder: number) => Promise<WSResponse>
  wrapProduction: (convertToArchive: boolean) => Promise<void>
  switchProduction: (newCommunity: string) => Promise<void>
  validateNewProductionInfo: (communityId: string, newUserEmail: string) => Promise<WSResponse>
  fetchStripePublicKey: () => Promise<void>
}

class ProductionService implements IProductionService {
  async fetchProductionSettings (): Promise<ProductionSettingResponse> {
    return await makeProductionCall('fetchProductionSettings', {
      type: GetMethod
    }).then((result: ProductionSettingResponse) => {
      let isCrewListMetadataSet: boolean = false;
      const metadataSetItem = result.items.find(item => item.name === 'isCrewListMetadataSet')
      if (metadataSetItem) {
        isCrewListMetadataSet = metadataSetItem.value === 'true';
      }
      dispatch(setIsCrewListMetadataSet({ isSet: isCrewListMetadataSet }));
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error fetching production settings');
    });
  }

  async updateProductionSettings (payload: Record<string, string>): Promise<WSResponse> {
    return await makeProductionCall('updateProductionSettings', {
      type: PostMethod,
      body: JSON.stringify(payload)
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('userSettings.userSettings.savingError');
    });
  }

  async createDepartment (deptName: string, displayOrder: number): Promise<WSResponse> {
    return await makeProductionCall('createDepartment', {
      type: PostMethod,
      params: { deptName, displayOrder: displayOrder.toString() }
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('contacts.addDepartment.error.server');
    });
  }

  async fetchUserSetting (): Promise<any> {
    return await new Promise((resolve, reject) => {
      makeProductionCall('fetchUserSetting', {
        params: {
          settingName: 'isInactivityTimeoutEnabled'
        }
      }).then(resp => {
        if (UtilityService.isSuccessResponse(resp.responseCode)) {
          // true unless explicitly false for maximum security
          resolve(resp.responseMessage !== 'false');
        } else {
          reject(new Error('invalid server response'));
        }
      })
        .catch((err) => {
          console.debug(err);
          reject(new Error('invalid server response to inactivity timeout setting'));
        });
    })
  }

  /**
   * Wrap up subscription
   * Download files as zip if not convert to archive
   */
  async wrapProduction (convertToArchive: boolean): Promise<void> {
    await new Promise((resolve, reject) => {
      makeProductionCall('wrapProduction', {
        type: PostMethod,
        body: JSON.stringify({
          convertToArchive: String(convertToArchive)
        })
      })
        .then((resp: WSResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            if (convertToArchive) {
              resolve(true);
            } else {
              console.debug('wrap success, moving on to ZIP creation');
              ImportExportService.downloadAllFiles()
                .then(() => {
                  resolve(true);
                })
                .catch((err) => {
                  console.error(err.message);
                  reject(new Error('subscription.cancel.error.zip'));
                });
            }
          } else if (resp.responseCode === '-1') {
            reject(new Error('subscription.cancel.error.cancelled'));
          } else {
            reject(new Error('subscription.cancel.error.failed'));
          }
        })
        .catch((err) => {
          console.error(err.message)
          reject(new Error('subscription.cancel.error.server'));
        })
    });
  }

  /**
   * handle switching of production to the id of the community given
   * @param newCommunity: string
   */
  async switchProduction (newCommunity: string): Promise<void> {
    return await new Promise((resolve, reject) => {
      AuthService.switchProduction(newCommunity)
        .then((resp: SwitchProductionResponse) => {
          if (!UtilityService.isSuccessResponse(resp.responseCode)) {
            reject(new Error('production.switch.error.denied'));
          } else {
            const authState = store.getState().auth;
            const crooglooAuth: AuthUser = authState.crooglooauth;
            const newCrooglooAuth: AuthUser = {
              ...crooglooAuth,
              securityProfile: resp.securityProfile,
              sms2fa: resp.sms2fa,
              defaultCommunity: newCommunity
            };
            AuthService.setStorageAuth(newCrooglooAuth);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('production.switch.error.server'));
        })
    });
  }

  async validateNewProductionInfo (communityId: string, newUserEmail: string): Promise<WSResponse> {
    return await makeProductionCall('validateNewProductionInfo', {
      type: PostMethod,
      params: { communityId, newUserEmail }
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.debug(err);
      throw new Error('Error validating production info');
    });
  }

  async fetchStripePublicKey (): Promise<void> {
    return await makeProductionCall('fetchStripePublicKey', {})
      .then((result: WSResponse) => {
        if (UtilityService.isSuccessResponse(result.responseCode) && result.responseMessage) {
          const key: string = result.responseMessage;
          dispatch(setStripePublicKey({ key }));
        } else {
          throw new Error('error with stripe');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('error with stripe');
      })
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeProductionCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(ProductionApi, method, data);
  return result;
}

const productionService: IProductionService = new ProductionService();
export default productionService;
