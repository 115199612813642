import NotificationService from './NotificationService';
import type { NotificationResponse, NotificationUpdateData, ServerNotification } from '../../models/Notifications';
import { NotificationStatus, NotificationType } from '../../models/Notifications';
import NotificationUtility from './NotificationUtility';
import DocumentService from '../documents/DocumentService';
import type { WSResponse } from '../../models/WSResponse';
import UtilityService from '../UtilityService';

interface IDownloadNotifications {
  load: () => void
  add: (
    isUserNotified: boolean, isBrowserNotificationSent: boolean,
    referenceId: string, fileName: string | null, timeCreated: number
  ) => void
  build: (title: string, referenceId: string, timeCreated: number) => void
  trackProgress: (
    referenceId: string, builtNotification: boolean,
    isUserNotified: boolean, isBrowserNotificationSent: boolean, timeCreated: number
  ) => void
}

class DownloadNotifications implements IDownloadNotifications {
  load (): void {
    NotificationService.fetchVisibleNotifications(NotificationType.Download)
      .then((resp: NotificationResponse) => {
        if (Array.isArray(resp.items)) {
          const items: ServerNotification[] = resp.items;
          items.forEach((notification: ServerNotification) => {
            this.add(
              notification.is_user_notified, notification.is_browser_notif_sent ?? false,
              notification.reference_id, null, parseInt(notification.time_created)
            );
          });
        } else {
          throw new Error('No notifications gotten from server');
        }
      })
      .catch((err) => {
        console.error('Failed to load download notifications.', err);
      })
  }

  add (
    isUserNotified: boolean, isBrowserNotificationSent: boolean,
    referenceId: string, fileName: string | null, timeCreated: number
  ): void {
    const builtNotification: boolean = !!(fileName);
    if (fileName) {
      this.build(fileName, referenceId, timeCreated);
    }
    this.trackProgress(referenceId, builtNotification, isUserNotified, isBrowserNotificationSent, timeCreated);
  }

  build (title: string, referenceId: string, timeCreated: number): void {
    NotificationUtility.addNotification(referenceId, title, timeCreated, NotificationType.Download, false);
  }

  trackProgress (
    referenceId: string, builtNotification: boolean,
    isUserNotified: boolean, isBrowserNotificationSent: boolean, timeCreated: number
  ): void {
    let notificationHasBeenBuilt: boolean = builtNotification;
    let isTempDocDownloadReady: boolean = false;
    const progressTracker = setInterval(() => {
      checkProgress();
    }, 2500);

    const checkProgress = (): void => {
      console.debug('checking progress');
      const ServerError: string = 'server error';
      DocumentService.isTempDocDownloadReady(referenceId)
        .then((resp: WSResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            if (resp.responseMessage === 'true' && !isTempDocDownloadReady) {
              isTempDocDownloadReady = true;
              clearInterval(progressTracker);
              DocumentService.fetchTempDocDownloadLink(referenceId)
                .then((innerResp: WSResponse) => {
                  if (!notificationHasBeenBuilt && innerResp.contentName) {
                    notificationHasBeenBuilt = true;
                    this.build(innerResp.contentName, referenceId, timeCreated);
                  }
                  if (UtilityService.isSuccessResponse(innerResp.responseCode)) {
                    const data: NotificationUpdateData = {
                      referenceId,
                      progress: 100,
                      status: NotificationStatus.Complete
                    }
                    if (innerResp.contentURL) {
                      data.url = innerResp.contentURL;
                    }
                    if (!isUserNotified) {
                      NotificationUtility.addCounter();
                    }
                    if (!isBrowserNotificationSent && innerResp.contentName) {
                      NotificationUtility.showBrowserNotification(referenceId, innerResp.contentName, innerResp.contentURL);
                    }
                    NotificationUtility.updateNotificationData(data);
                  } else {
                    throw new Error(ServerError);
                  }
                })
                .catch((err) => {
                  throw new Error(err);
                })
            } else {
              console.debug('waiting for temp doc ' + referenceId + ' to be ready');
            }
          } else {
            throw new Error(ServerError);
          }
        })
        .catch((err) => {
          console.error(err);
          clearInterval(progressTracker);
          if (notificationHasBeenBuilt) {
            const data: NotificationUpdateData = {
              referenceId,
              status: NotificationStatus.Failed
            };
            NotificationUtility.updateNotificationData(data);
          }
        });
    }
  }
}

const downloadNotifications: IDownloadNotifications = new DownloadNotifications();
export default downloadNotifications;
