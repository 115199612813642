import { store } from '../../state/store';
import { BoxRoot, DropBoxRoot, GoogleDriveRoot, PreloadedFolderDepth, TreeRoot } from './constants';
import DocumentService from './DocumentService';
import type { WSFolderItemsResponse } from '../../models/WSResponse';
import type { FolderItems, FolderItemsResponse } from '../../models/FolderItems';
import {
  addToFileCollection,
  resetFileCollection,
  setFileHierarchy,
  setFolderToFilled,
  setLoadingFiles
} from '../../state/slices/DocumentTree';
import type { AuthUser } from '../auth/constants';
import { Response403 } from '../auth/constants';
import AuthService from '../auth/AuthService';
import UploadService from '../UploadService';
import UtilityService from '../UtilityService';
import uuid from 'react-uuid';
import type {
  CollectionFile,
  CollectionTreeFile,
  CollectionTreeMap,
  FileAttachment,
  FileCollectionMap,
  FolderType,
  OtherFileAttachment,
  UrlsToAdd
} from '../../models/Document';
import { FileType } from '../../models/Document';
import { CrooglooFileTypes } from '../../models/FileTypes';
import type { DropboxAccessObject, DropboxEntry, DropboxFolderType } from '../../models/Dropbox';
import { UploadFileLocation } from '../../models/Upload';
import { DeleteMethod, PostMethod } from '../constants';
import { setDropboxToken } from '../../state/slices/AuthReducer';
import { GDriveFolderName } from '../ViewService';
import GoogleService from '../GoogleService';
import type { GoogleDriveEntry, GoogleDriveResponse, GoogleDriveFolderType } from '../../models/Google';
import type { DropboxResponse } from 'dropbox';
import { Dropbox } from 'dropbox';
import type { files } from 'dropbox/types/dropbox_types';

const { dispatch } = store;

interface IDocumentTreeService {
  initAllDocuments: () => void
  handleOpenTreeNode: (node: CollectionTreeFile) => Promise<void>
  fetchCrooglooFiles: (parent: string, treeParent: string, recursions: number) => Promise<void>
  initBoxFiles: (crooglooAuth: AuthUser) => void
  fetchBoxFiles: (path: string, parent: string, recursions: number) => Promise<void>
  initDropbox: (crooglooAuth: AuthUser) => Promise<void>
  dropboxRefresh: (accessObject: DropboxAccessObject, initiateDropbox: boolean) => Promise<void>
  unlinkDropbox: () => Promise<void>
  handleOpenDropboxNode: (node: CollectionTreeFile) => Promise<void>
  fetchAllDropboxFiles: (path: string, parent: string, recursion: number, accessToken: string) => Promise<void>
  fetchDropboxFiles: (path: string, accessToken: string) => Promise<DropboxEntry[]>
  checkSelectedHierarchy: (selected: string[]) => string[]
  addFiles: (selected: string[]) => Promise<UrlsToAdd[]>
  addUploadedFiles: (urlsToAdd: UrlsToAdd[]) => Promise<boolean>
  getFileFromNode: (node: CollectionFile) => FileAttachment
  hasDropboxToken: () => boolean
  hasGoogleAccessToken: () => boolean
  hasBoxToken: () => boolean
  setDropboxAccessToken: (crooglooAuth: AuthUser, token: string) => AuthUser
  unlinkBoxTokens: () => void
  initGDrive: () => Promise<void>
  initGDriveAfterConnect: () => Promise<void>
  fetchAllGDriveFiles: (params: string, parent: string, recursion: number) => Promise<void>
  handleOpenGDriveNode: (node: CollectionTreeFile) => Promise<void>
}

class DocumentTreeService implements IDocumentTreeService {
  /**
   * loads documents to be displayed in the list accordion in the search files popup
   */
  initAllDocuments (): void {
    dispatch(resetFileCollection({}));
    const authState = store.getState().auth;
    dispatch(setLoadingFiles({ status: true }));
    this.fetchCrooglooFiles(TreeRoot, TreeRoot, PreloadedFolderDepth.CROOGLOO)
      .then(async () => await this.initDropbox(authState.crooglooauth))
      .then(async () => await this.initGDrive())
      // this.initBoxFiles(authState.crooglooauth);
      .then(() => {
        buildHierarchy();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleOpenTreeNode (node: CollectionTreeFile): Promise<void> {
    const foldersToFill: CollectionTreeFile[] = [];
    if (node.children.length > 0) {
      node.children.forEach((child: CollectionTreeFile) => {
        if (child.li_attr.type === 'Folder' && child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      })
      if (foldersToFill.length > 0) {
        let filledFolders = 0;
        dispatch(setLoadingFiles({ status: true }));
        foldersToFill.forEach((folderNode: CollectionTreeFile) => {
          this.fetchCrooglooFiles(folderNode.li_attr.id, folderNode.id, 1)
            .then(() => {
              dispatch(setFolderToFilled({ nodeId: folderNode.id }));
              if (++filledFolders === foldersToFill.length) {
                buildHierarchy();
                console.log('fetching croogloo files done');
              }
            })
            .catch((err) => {
              console.error(err);
            })
        });
      }
    }
  }

  /**
   * fetches the files and folders for the parent folder passed in
   * recursively calls the function to for all folders in the parent folder depending on the recursions
   * places all files and folders in the fileCollection object in the DocumentTreeSlice
   * @param parent
   * @param treeParent
   * @param recursions
   */
  async fetchCrooglooFiles (parent: string, treeParent: string, recursions: number): Promise<void> {
    return await new Promise((resolve) => {
      recursions--;
      const folders: FolderType[] = [];
      let completedFolders = 0;
      DocumentService.fetchItemsInFolder(parent)
        .then((result: WSFolderItemsResponse) => {
          if (result.items?.length > 0) {
            const items: FolderItemsResponse[] = result.items;
            items.sort(compareFiles);
            items.forEach((item: FolderItemsResponse, index: number) => {
              const obj: FolderItems = item.properties;
              obj.id = item.key.name ?? obj.id;
              try {
                obj.id = obj.id.replace(/%20/g, ' ');
                obj.parentFolder = obj.parentFolder.replace(/%20/g, ' ');
                obj.parents.value = obj.parents.value.replace(/%20/g, ' ');
              } catch (e) {
                console.debug(e);
              }
              if (obj.id && obj.fileName) {
                const nodeId: string = 'n' + uuid();
                const icon: string = (obj.subType !== 'Folder') ? 'file' : 'folder';
                const fileSize: string = obj.size ?? obj.fileSize ?? '0';
                // collectionFile object to add to fileCollection object in DocumentTreeSlice
                const node: CollectionFile = {
                  id: nodeId,
                  root: false,
                  parent: treeParent,
                  text: obj.fileName,
                  icon,
                  li_attr: {
                    id: obj.id,
                    url: obj.fileURL ?? '',
                    type: obj.subType ?? 'file',
                    isWatermark: obj.isWatermarked ?? '0',
                    size: fileSize,
                    subcategory: obj.subcategory ?? '',
                    isFilled: (recursions === 0) ? '0' : '1',
                    treeIndex: index,
                    fileType: (obj.subType !== 'Folder' && UtilityService.getFileExtension(obj.fileName) === 'pdf') ? FileType.PDF : FileType.Other,
                    isPublishedToStudio: false,
                    cloudProviderName: ''
                  }
                }
                dispatch(addToFileCollection({ node }));
                if (obj.subType === 'Folder') {
                  folders.push({
                    folderId: obj.id,
                    nodeId
                  });
                }
              }
            })
          }

          if (recursions > 0 && folders.length > 0) {
            folders.forEach((folderIdMap: FolderType) => {
              this.fetchCrooglooFiles(folderIdMap.folderId, folderIdMap.nodeId, recursions).then(() => {
                completedFolders++;
                checkCGCompletion();
              }).catch((err) => {
                console.error(err);
              })
            });
          } else {
            checkCGCompletion();
          }

          function checkCGCompletion () {
            if (completedFolders >= folders.length || recursions <= 0) {
              resolve();
            }
          }
        }).catch((err) => {
          console.error(err);
        })
    });
  }

  async initDropbox (crooglooAuth: AuthUser): Promise<void> {
    if (this.hasDropboxToken()) {
      await this.fetchAllDropboxFiles('', DropBoxRoot, PreloadedFolderDepth.DROPBOX, crooglooAuth.dropboxToken)
        .then(() => {
          console.debug('fetching dropbox files done');
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.debug('initDropbox : COULD NOT INIT!');
    }
  }

  async dropboxRefresh (accessObject: DropboxAccessObject, initiateDropbox: boolean): Promise<void> {
    const authState = store.getState().auth;
    const accessToken: string = accessObject.access_token;
    const resp = await AuthService.authenticatedCall('/DropboxAuth', {
      params: {
        token: authState.userToken,
        dropbox_accesstoken: accessToken,
        dropbox_tokentype: accessObject.token_type,
        dropbox_uid: accessObject.uid,
        dropbox_accountid: accessObject.account_id
      },
      method: PostMethod
    });
    console.debug('DropboxAuth resp', resp);
    const crooglooAuth: AuthUser = this.setDropboxAccessToken(authState.crooglooauth, accessToken);
    if (initiateDropbox) {
      await this.initDropbox(crooglooAuth).then(() => {
        buildHierarchy();
      });
    }
  }

  async unlinkDropbox (): Promise<void> {
    const authState = store.getState().auth;
    const resp = await AuthService.authenticatedCall('DropboxAuth', {
      params: {
        token: authState.userToken
      },
      method: DeleteMethod
    });
    console.debug('DropboxAuth resp', resp);
    const crooglooAuth: AuthUser = this.setDropboxAccessToken(authState.crooglooauth, '');
    console.debug('Croogloo Auth: ', crooglooAuth);
  }

  async handleOpenDropboxNode (node: CollectionTreeFile): Promise<void> {
    const authState = store.getState().auth;
    const foldersToFill: CollectionTreeFile[] = [];
    if (node.children.length > 0) {
      node.children.forEach((child: CollectionTreeFile) => {
        if (child.li_attr.type === 'dropbox-folder' && child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if (foldersToFill.length > 0) {
        let filledFolders = 0;
        dispatch(setLoadingFiles({ status: true }));
        foldersToFill.forEach((folderNode: CollectionFile) => {
          this.fetchAllDropboxFiles(folderNode.li_attr.path ?? '', folderNode.id, 1, authState.crooglooauth.dropboxToken)
            .then(() => {
              dispatch(setFolderToFilled({ nodeId: folderNode.id }));
              if (++filledFolders === foldersToFill.length) {
                buildHierarchy();
                console.log('fetching dropbox files done');
              }
            })
            .catch((err) => {
              console.error(err);
            })
        });
      }
    }
  }

  /**
   * fetches the dropbox files and folders for the parent folder passed in
   * recursively calls the function to for all folders in the parent folder depending on the recursions
   * places all files and folders in the fileCollection object in the DocumentTreeSlice
   * @param path
   * @param parent
   * @param recursions
   * @param accessToken
   */
  async fetchAllDropboxFiles (path: string, parent: string, recursions: number, accessToken: string): Promise<void> {
    return await new Promise((resolve) => {
      recursions--;
      const folders: DropboxFolderType[] = [];
      let completedFolders = 0;
      this.fetchDropboxFiles(path, accessToken)
        .then((entries: DropboxEntry[]) => {
          entries.forEach((entry: DropboxEntry, index: number) => {
            const nodeId: string = entry.id.split(':', 2)[1];
            const entryType: CrooglooFileTypes = entry.type;
            const icon: string = (entryType !== CrooglooFileTypes.DropboxFile) ? 'folder' : 'file';
            const node: CollectionFile = {
              id: nodeId,
              root: false,
              parent,
              text: entry.name,
              icon,
              li_attr: {
                id: entry.id,
                url: entry.data,
                type: 'dropbox-' + ((entryType !== CrooglooFileTypes.DropboxFolder) ? 'file' : 'folder'),
                isWatermark: '0',
                path: entry.path_lower,
                size: (entry.size) ? String(entry.size) : '0',
                subcategory: '',
                isFilled: recursions === 0 ? '0' : '1',
                treeIndex: index,
                fileType: (entryType !== CrooglooFileTypes.Folder && UtilityService.getFileExtension(entry.name) === 'pdf') ? FileType.PDF : FileType.Other,
                isPublishedToStudio: false,
                cloudProviderName: ''
              }
            };
            dispatch(addToFileCollection({ node }));
            if (entryType === 'folder') {
              folders.push({
                path: entry.path_lower,
                nodeId
              });
            }
          });
          if (recursions > 0 && folders.length > 0) {
            folders.forEach((dropboxFolder: DropboxFolderType) => {
              this.fetchAllDropboxFiles(dropboxFolder.path, dropboxFolder.nodeId, recursions, accessToken)
                .then(() => {
                  completedFolders++;
                  checkDbCompletion();
                })
                .catch((err) => {
                  console.error(err);
                })
            })
          } else {
            checkDbCompletion();
          }

          function checkDbCompletion () {
            if (completedFolders >= folders.length || recursions <= 0) {
              resolve();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
    });
  }

  async fetchDropboxFiles (path: string, accessToken: string): Promise<DropboxEntry[]> {
    const documentsPath: string[] = store.getState().documents.documentsPath;
    const dbx = new Dropbox({ accessToken });
    return await dbx.filesListFolder({ path })
      .then((response: DropboxResponse<files.ListFolderResult>) => {
        const entries: DropboxEntry[] = [];
        response.result.entries.forEach((entry: files.FileMetadataReference | files.FolderMetadataReference | files.DeletedMetadataReference) => {
          const tag: CrooglooFileTypes = (entry['.tag'] === 'folder') ? CrooglooFileTypes.DropboxFolder : CrooglooFileTypes.DropboxFile;
          const name: string = entry.name;
          let currentPath: string = documentsPath.slice(2).join('/');
          currentPath = (currentPath === '') ? currentPath : currentPath + '/';
          const data: string = (tag === CrooglooFileTypes.DropboxFolder) ? name : encodeURI('https://www.dropbox.com/preview/' + currentPath + name);
          entries.push({
            type: tag,
            id: ('id' in entry) ? entry.id : '',
            name,
            path_display: entry.path_display ?? '',
            path_lower: entry.path_lower ?? '',
            data,
            size: ('size' in entry) ? entry.size : 0
          })
        })
        return entries;
      })
      .catch(async (err) => {
        if (err.status >= 400 && err.status < 500) {
          await this.unlinkDropbox();
        } else if (err.status === 401) {
          await this.unlinkDropbox();
        }
        console.error(err);
        return [];
      });
  }

  initBoxFiles (crooglooAuth: AuthUser): void {
    if (crooglooAuth.boxToken && crooglooAuth.boxToken !== Response403) {
      this.fetchBoxFiles('', BoxRoot, PreloadedFolderDepth.BOX)
        .then(() => {
          console.log('fetching box files done');
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('initBox : COULD NOT INIT!');
    }
  }

  /**
   * fetches the box files and folders for the parent folder passed in
   * recursively calls the function to for all folders in the parent folder depending on the recursions
   * places all files and folders in the fileCollection object in the DocumentTreeSlice
   * @param path
   * @param parent
   * @param recursions
   */
  async fetchBoxFiles (path: string, parent: string, recursions: number): Promise<void> {
    return await new Promise((resolve) => {
      recursions--;
      AuthService.fetchBoxFiles(path)
        .then((entries: any[]) => {
          const folders: any = [];
          let completedFolders: number = 0;
          entries.map(async (entry: any, index: number) => {
            if (entry.type !== 'folder') {
              entry.size = await AuthService.fetchBoxEntry(entry);
            }
            const node: CollectionFile = {
              id: entry.id,
              root: false,
              parent,
              text: entry.name,
              icon: entry.type !== 'folder' ? 'jstree-file' : 'jstree-folder',
              li_attr: {
                id: entry.id,
                size: entry.size,
                isWatermark: '0',
                type: 'box-' + (entry.type !== 'folder' ? 'file' : 'folder'),
                isFilled: recursions === 0 ? '0' : '1',
                path: entry.id,
                fileType: (entry.type !== 'folder' && UtilityService.getFileExtension(entry.name) === 'pdf') ? FileType.PDF : FileType.Other,
                treeIndex: index,
                isPublishedToStudio: false,
                cloudProviderName: ''
              }
            };
            dispatch(addToFileCollection({ node }));
          });

          if (recursions > 0 && folders.length > 0) {
            folders.forEach((folderIdMap: any) => {
              this.fetchBoxFiles(folderIdMap.path, folderIdMap.id, recursions).then(() => {
                completedFolders++;
                checkDbCompletion();
              })
                .catch((err) => {
                  console.error(err);
                })
            });
          } else {
            checkDbCompletion();
          }

          function checkDbCompletion () {
            if (completedFolders >= folders.length || recursions <= 0) {
              resolve();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
    });
  }

  checkSelectedHierarchy (selected: string[]): string[] {
    const newSelected: string[] = [...selected];
    if (selected.length > 0) {
      const fileCollection: FileCollectionMap = store.getState().documentTree.fileCollection;
      Object.keys(fileCollection).forEach((id: string) => {
        const node: CollectionFile = fileCollection[id];
        if (node.parent && node.parent !== '' && newSelected.includes(node.parent)) {
          if (!newSelected.includes(id)) {
            newSelected.push(id);
          }
        }
      });
    }
    return newSelected;
  }

  async addFiles (selected: string[]): Promise<UrlsToAdd[]> {
    const fileCollection: FileCollectionMap = store.getState().documentTree.fileCollection;
    return await new Promise((resolve, reject) => {
      if (selected.length === 0) {
        void reject(new Error('upload.error.selectFile'))
      }
      // eslint-disable-next-line
      const urlsToAdd: UrlsToAdd[] = selected.reduce((accum: UrlsToAdd[], id: string) => {
        const node: CollectionFile = fileCollection[id];
        if (node) {
          if (node.li_attr.type === 'file') {
            const file: FileAttachment = this.getFileFromNode(node);
            accum = [...accum, file];
          } else if (node.li_attr.type === 'dropbox-file') {
            if (node.li_attr.path) {
              const uploadURL: string = DocumentService.getDropboxUploadUrl(node.li_attr.path, false);
              const file: OtherFileAttachment = {
                id: UtilityService.generateUniqueId(node.text),
                name: node.text,
                uploadURL,
                size: parseInt(node.li_attr.size),
                type: CrooglooFileTypes.DropboxFile
              }
              accum = [...accum, file];
            }
          } else if (node.li_attr.type === 'gDrive-file') {
            const fileId: string = UtilityService.generateUniqueId(node.text);
            const uploadURL: string = DocumentService.getGDriveImportUrl(node.id, fileId, node.text);
            const file: OtherFileAttachment = {
              id: fileId,
              name: node.text,
              uploadURL,
              size: parseInt(node.li_attr.size),
              type: CrooglooFileTypes.GDriveFile
            }
            accum = [...accum, file];
          } else if (node.li_attr.type === 'box-file') {
            // TODO: box file uploadUrl and add to urlsToAdd - copy dropbox
          }
        }
        return accum;
      }, []);

      if (urlsToAdd.length === 0) {
        void reject(new Error('upload.error.selectFile'))
      }
      const fileOrder: string[] = [CrooglooFileTypes.File, CrooglooFileTypes.DropboxFile, CrooglooFileTypes.BoxFile];
      urlsToAdd.sort((a, b) => fileOrder.indexOf(a.type) - fileOrder.indexOf(b.type));
      resolve(urlsToAdd);
    });
  }

  async addUploadedFiles (urlsToAdd: UrlsToAdd[]): Promise<boolean> {
    const importUploadFiles: OtherFileAttachment[] = [];
    let showToast: boolean = false;
    for (const file of urlsToAdd) {
      if (!file.size || isNaN(file.size)) {
        file.size = await DocumentService.getFileSize(file);
      }
      if ([CrooglooFileTypes.DropboxFile, CrooglooFileTypes.GDriveFile].includes(file.type)) {
        const dropboxFile = file as OtherFileAttachment;
        importUploadFiles.push(dropboxFile);
      } else if (file.type === CrooglooFileTypes.BoxFile) {
        // TODO: add to drop zone when upload and dropzone done
      } else {
        UploadService.addUploadedFile(file as FileAttachment);
        showToast = true;
      }
    }
    if (importUploadFiles.length > 0) {
      UploadService.startImportUploadProcess(importUploadFiles, UploadFileLocation.Compose);
    }
    return showToast;
  }

  getFileFromNode (node: CollectionFile): FileAttachment {
    return {
      id: node.li_attr.id,
      fileName: node.text,
      url: node.li_attr.url ?? '',
      isWatermarked: node.li_attr.isWatermark,
      size: parseInt(node.li_attr.size),
      subcategory: node.li_attr.subcategory ?? '',
      isPublishedToStudio: node.li_attr.isPublishedToStudio,
      cloudProvider: node.li_attr.cloudProviderName,
      fileExtension: node.li_attr.fileType,
      type: CrooglooFileTypes.File
    }
  }

  hasDropboxToken (): boolean {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    return !!(crooglooAuth.dropboxToken && crooglooAuth.dropboxToken !== Response403);
  }

  hasGoogleAccessToken (): boolean {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    return !!crooglooAuth.gDriveAccessToken;
  }

  hasBoxToken (): boolean {
    const authState = store.getState().auth;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    return !!crooglooAuth.boxToken;
  }

  setDropboxAccessToken (crooglooAuth: AuthUser, token: string): AuthUser {
    crooglooAuth = { ...crooglooAuth, dropboxToken: token };
    AuthService.setStorageAuth(crooglooAuth);
    dispatch(setDropboxToken({ accessToken: token }));
    return crooglooAuth;
  }

  /**
   * remove the box tokens from the applications
   */
  unlinkBoxTokens (): void {
    const authState = store.getState().auth;
    AuthService.setBoxAccessTokens(authState.crooglooauth, '', '', 0);
  }

  async initGDrive (): Promise<void> {
    if (this.hasGoogleAccessToken()) {
      await this.fetchAllGDriveFiles(GDriveFolderName, GoogleDriveRoot, PreloadedFolderDepth.GOOGLEDRIVE)
        .then(() => {
          console.debug('fetching dropbox files done');
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.debug('initGDrive : COULD NOT INIT!');
    }
  }

  async initGDriveAfterConnect (): Promise<void> {
    await this.initGDrive().then(() => {
      buildHierarchy();
    });
  }

  async fetchAllGDriveFiles (params: string, parent: string, recursion: number): Promise<void> {
    return await new Promise((resolve, reject) => {
      recursion--;
      const folders: GoogleDriveFolderType[] = [];
      let completedFolders = 0;
      const paramsToSearch: string[] = params.split(',');
      GoogleService.fetchGoogleFiles(paramsToSearch)
        .then((result: GoogleDriveResponse) => {
          if (result.error) {
            if (result.error.code === 401) {
              GoogleService.unlinkGDrive();
              throw new Error('invalid credentials');
            } else {
              throw new Error('error fetching files');
            }
          }
          const files: GoogleDriveEntry[] = result.files;
          if (files.length > 0) {
            files.forEach((entry: GoogleDriveEntry, index: number) => {
              const entryType: CrooglooFileTypes = entry.mimeType.endsWith('folder') ? CrooglooFileTypes.GDriveFolder : CrooglooFileTypes.GDriveFile;
              const icon: string = (entryType !== CrooglooFileTypes.GDriveFile) ? 'folder' : 'file';
              const newPath: string = params + ',' + entry.id;
              const node: CollectionFile = {
                id: entry.id,
                root: false,
                parent,
                text: entry.name,
                icon,
                li_attr: {
                  id: entry.id,
                  url: '',
                  type: 'gDrive-' + ((entryType !== CrooglooFileTypes.GDriveFolder) ? 'file' : 'folder'),
                  isWatermark: '0',
                  size: '0',
                  subcategory: '',
                  isFilled: recursion === 0 ? '0' : '1',
                  treeIndex: index,
                  fileType: (entryType !== CrooglooFileTypes.GDriveFolder && UtilityService.getFileExtension(entry.name) === 'pdf') ? FileType.PDF : FileType.Other,
                  isPublishedToStudio: false,
                  cloudProviderName: '',
                  path: newPath
                }
              };
              dispatch(addToFileCollection({ node }));
              if (entryType === CrooglooFileTypes.GDriveFolder) {
                folders.push({
                  params: newPath,
                  nodeId: entry.id
                })
              }
            });
            if (recursion > 0 && folders.length > 0) {
              folders.forEach((folder: GoogleDriveFolderType) => {
                this.fetchAllGDriveFiles(folder.params, folder.nodeId, recursion)
                  .then(() => {
                    completedFolders++;
                    checkGdCompletion();
                  })
                  .catch((err) => {
                    console.error(err);
                  })
              })
            } else {
              checkGdCompletion();
            }
          }
          function checkGdCompletion () {
            if (completedFolders >= folders.length || recursion <= 0) {
              resolve();
            }
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('error fetching gDrive files'));
        });
    });
  }

  async handleOpenGDriveNode (node: CollectionTreeFile): Promise<void> {
    const foldersToFill: CollectionTreeFile[] = [];
    if (node.children.length > 0) {
      node.children.forEach((child: CollectionTreeFile) => {
        if (child.li_attr.type === 'gDrive-folder' && child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if (foldersToFill.length > 0) {
        let filledFolders = 0;
        dispatch(setLoadingFiles({ status: true }));
        foldersToFill.forEach((folderNode: CollectionFile) => {
          this.fetchAllGDriveFiles(folderNode.li_attr.path ?? '', folderNode.id, 1)
            .then(() => {
              dispatch(setFolderToFilled({ nodeId: folderNode.id }));
              if (++filledFolders === foldersToFill.length) {
                buildHierarchy();
                console.log('fetching gDrive files done');
              }
            })
            .catch((err) => {
              console.error(err);
            })
        });
      }
    }
  }
}

function compareFiles (f1: FolderItemsResponse, f2: FolderItemsResponse): number {
  try {
    const a: FolderItems = f1.properties;
    const b: FolderItems = f2.properties;
    if (a.subType !== b.subType && (typeof a.subType === 'string' || typeof b.subType === 'string')) {
      return (typeof a.subType === 'string' && a.subType.toLowerCase() === 'folder') ? -1 : 1;
    }
    const aTimeCreated = (a.lastModifBaseTime)
      ? parseInt(a.lastModifBaseTime)
      : (typeof a.timeCreated === 'string' ? new Date(a.timeCreated).getTime() : 0);
    const bTimeCreated = (b.lastModifBaseTime)
      ? parseInt(b.lastModifBaseTime)
      : (typeof b.timeCreated === 'string' ? new Date(b.timeCreated).getTime() : 0);
    return bTimeCreated - aTimeCreated;
  } catch (e) {
    console.error(e);
    return 0;
  }
}

function buildHierarchy (): void {
  const hierarchy: CollectionTreeFile[] = [];
  const fileCollection: FileCollectionMap = store.getState().documentTree.fileCollection;
  const fileMap: CollectionTreeMap = {};

  Object.keys(fileCollection).forEach((id: string) => {
    fileMap[id] = { ...fileCollection[id], children: [] };
  });

  Object.keys(fileCollection).forEach((id: string) => {
    const node: CollectionTreeFile = fileMap[id];
    if (node.parent && node.parent !== '' && node.parent !== TreeRoot) {
      const parentNode: CollectionTreeFile = fileMap[node.parent];
      parentNode.children.push(node);
    } else {
      hierarchy.push(node);
    }
  });
  dispatch(setFileHierarchy({ hierarchy }));
  dispatch(setLoadingFiles({ status: false }));
}

const documentTreeService: IDocumentTreeService = new DocumentTreeService();
export default documentTreeService;
