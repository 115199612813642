import React, { lazy, Suspense } from 'react';

const LazyActivateSubscriptionSuccess = lazy(async () => await import('./ActivateSubscriptionSuccess'));

const ActivateSubscriptionSuccess = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyActivateSubscriptionSuccess {...props} />
  </Suspense>
);

export default ActivateSubscriptionSuccess;
