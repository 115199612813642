export enum CalenderEntities {
  CallSheet = 'CS_CALLSHEET',
  Scenes = 'CS_SCENES',
  Calender = 'CS_CALENDAR'
}

export interface CalenderResponse {
  properties: CalenderEntity
  key: {
    kind: string
    name: string
    id: string
  }
  kind: string
}

export interface CalenderEntity {
  callTime: string
  date: string
  documentType: string
  fileName: string
  lunchFrom: string
  lunchTo: string
  month: string
  year: string
}

export interface ClosestCallSheet {
  date: string
  callTime: string
}
