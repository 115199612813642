import { lazy, Suspense } from 'react';
import { type Props } from './DistributionHistory';

const LazyDistributionHistory = lazy(async () => await import('./DistributionHistory'));

const DistributionHistory = (props: Props) => (
  <Suspense fallback={null}>
    <LazyDistributionHistory {...props} />
  </Suspense>
);

export default DistributionHistory;
