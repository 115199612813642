import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import styles from './SecurityGroups.module.scss';
import tableStyles from '../ContactListings/ContactListingsTable/ContactListingsTable.module.scss';
import type { DepartmentMembers } from '../../models/SecurityListMembers';
import {
  Grid,
  Box,
  Checkbox
} from '@mui/material';
import { defaultCheckboxStyles } from '../../theme/styles';
import scrollStyles from '../../theme/sidebar.module.scss';
import { Table, Column } from 'react-virtualized';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const tableHeaderHeight: number = 50;
const rowHeight: number = 50;

export interface GroupsTableProps {
  members: DepartmentMembers
  selected: string[]
  setSelected: (selected: string[]) => void
  tableHeight: number
  tableWidth: number
}

interface DisplayMember {
  departmentName: string
  position: string
  firstName: string
  lastName: string
  personId: string
}

const GroupsTable: FC<GroupsTableProps> = ({ members, selected, setSelected, tableHeight, tableWidth }: GroupsTableProps) => {
  const [flatMembersList, setFlatMembersList] = React.useState<DisplayMember[]>([]);
  const [isAllSelected, setAllSelected] = React.useState<boolean>(false);
  const [finalTableHeight, setTableHeight] = useState<number>(0);
  const CheckboxColor = 'success';

  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  useEffect(() => {
    const flatMembers = [];

    for (const key of Object.keys(members)) {
      const items = members[key];

      for (const item of items) {
        flatMembers.push({
          departmentName: key,
          position: item.jobTitle,
          firstName: item.firstName,
          lastName: item.lastName,
          personId: item.personId
        });
      }
    }

    setFlatMembersList(flatMembers);
  }, [members]);

  useEffect(() => {
    let height: number = tableHeight;
    if (flatMembersList.length > 0) {
      const membersTableHeight: number = (flatMembersList.length * rowHeight) + tableHeaderHeight;
      if (membersTableHeight < height) {
        height = membersTableHeight;
      }
    }
    setTableHeight(height);
  }, [flatMembersList, tableHeight])

  const isSelected = (name: string) => selected.includes(name);

  const selectAll = () => {
    const newAllSelected = !isAllSelected;
    if (newAllSelected) {
      setSelected(flatMembersList.map(item => item.personId));
    } else {
      setSelected([]);
    }

    setAllSelected(newAllSelected);
  }

  const handleMemberClick = (event: React.MouseEvent<HTMLButtonElement>, personId: string) => {
    event.stopPropagation();
    const existingPersonId: string | undefined = selected.find((person: string) => person === personId);
    let newSelected: string[] = [];
    if (existingPersonId) {
      newSelected = selected.filter((person: string) => person !== personId);
    } else {
      newSelected = [...selected, personId];
    }
    setSelected(newSelected);
  }

  const rowRenderer = ({ index, key, style }: { index: number, key: string, style: any }) => {
    const member = flatMembersList[index];
    const isItemSelected = isSelected(member.personId);
    return (
      <div
        style={{
          ...style,
          width: '100%'
        }}
        className={tableStyles.tableRow}
        key={`list-${key}`}
      >
        <div
          style={{ width: '5%' }}
          className={tableStyles.checkBoxCellSmall}
        >
          <Checkbox
            size='small'
            checked={isItemSelected}
            color={CheckboxColor}
            onClick={(event) => {
              handleMemberClick(event, member.personId);
            }}
            sx={{ ...defaultCheckboxStyles }}
          />
        </div>
        <div
          style={{ width: '30%' }}
          className={tableStyles.tableCell}
        >
          <Box className={styles.cellContent}>{member.firstName} {member.lastName}</Box>
        </div>
        <div
            style={{ width: '20%' }}
            className={tableStyles.tableCell}
        >
          <Box className={styles.cellContent}>{member.departmentName}</Box>
        </div>
        <div
            style={{ width: '45%' }}
            className={tableStyles.tableCell}
        >
          <Box className={styles.cellContent}>{member.position}</Box>
        </div>
      </div>
    );
  }

  return (
    <Grid
      data-testid="SecurityGroups"
      sx={{
        maxHeight: finalTableHeight,
        width: tableWidth,
        marginTop: '15px'
      }}
    >
      {(flatMembersList && flatMembersList.length > 0)
        ? <Table
            rowHeight={rowHeight}
            headerHeight={tableHeaderHeight}
            height={finalTableHeight}
            width={tableWidth}
            rowCount={flatMembersList.length}
            rowGetter={({ index }) => flatMembersList[index]}
            rowClassName={tableStyles.tableRow}
            headerRowRenderer={({ columns, style }) => (
              <div style={{ ...style, paddingRight: 0 }} className={tableStyles.headerRow}>
                <div className={tableStyles.headerContainer}>
                  {columns}
                </div>
              </div>
            )}
            className={tableStyles.tableBox}
            style={{
              height: finalTableHeight
            }}
            gridClassName={scrollStyles.scrollbar}
            rowRenderer={rowRenderer}
          >
            <Column
              label={''}
              dataKey={'id'}
              width={tableWidth * 0.05}
              className={tableStyles.checkBoxCellSmall}
              headerClassName={`${tableStyles.headerCell} ${tableStyles.checkBoxCellSmall}`}
              headerRenderer={() => {
                return (
                  <div>
                    <Checkbox
                      size='small'
                      color={CheckboxColor}
                      checked={isAllSelected}
                      onClick={selectAll}
                      sx={{ ...defaultCheckboxStyles }}
                    />
                  </div>
                )
              }}
            />
            <Column
              width={tableWidth * 0.3}
              dataKey={'name'}
              label={t('securityGroups.headers.name')}
              className={tableStyles.tableCell}
              headerClassName={`${tableStyles.headerCell} ${tableStyles.detailsHeaderCell}`}
              headerRenderer={({ label }) => {
                return (
                  <div>
                    <Box component={'span'}>{label}</Box>
                  </div>
                );
              }}
            />
            <Column
              width={tableWidth * 0.20}
              dataKey={'department'}
              label={t('securityGroups.headers.department')}
              className={tableStyles.tableCell}
              headerClassName={`${tableStyles.headerCell} ${tableStyles.detailsHeaderCell}`}
              headerRenderer={({ label }) => {
                return (
                  <div>
                    <Box component={'span'}>{label}</Box>
                  </div>
                );
              }}
            />
            <Column
              width={tableWidth * 0.45}
              dataKey={'job'}
              label={t('securityGroups.headers.position')}
              className={tableStyles.tableCell}
              headerClassName={`${tableStyles.headerCell} ${tableStyles.detailsHeaderCell}`}
              headerRenderer={({ label }) => {
                return (
                    <div>
                      <Box component={'span'}>{label}</Box>
                    </div>
                );
              }}
            />
          </Table>
        : <></>
      }
    </Grid>
  )
};

export default GroupsTable;
