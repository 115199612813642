import type { FC } from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { ToastProps } from '../../models/Toast';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { ScheduleSendModal } from './constants';
import ModalService from '../../services/ModalService';
import ModalTitle from '../modals/ModalTitle/ModalTitle.lazy';
import type { RootState } from '../../state/store';
import dialogStyles from '../../theme/dialog.module.scss';
import ScheduleDatePicker from './steps/ScheduleDatePicker';
import ModalContent from '../modals/ModalContent';
import TertiaryButton from '../common/buttons/TertiaryButton';
import SuccessButton from '../common/buttons/SuccessButton';
import { ChipColor } from '../../theme/colors';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { useSelector } from 'react-redux';

const initialWidth: string = '700px';

interface Props {
  sendScheduledMessage?: any
  toastStatus: ToastProps
  setToastStatus: any
}

/**
 * The schedule send message component
 * Contains mui dialog component along with the date picker component and modal content to display second step
 */
const ScheduleSendFlow: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const modalsState = useSelector((state: RootState) => state.modals.custom[ScheduleSendModal]);

  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs());
  const [fullDate, setFullDate] = useState<Dayjs>(dayjs());
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [width, setWidth] = useState<string>(initialWidth);
  const [errorValue, setErrorValue] = useState<string>('');

  const handleClose = (): void => {
    ModalService.closeCustomModal(ScheduleSendModal);
    setCurrentStepIndex(0);
    setWidth(initialWidth);
  }

  const onSubmit = (): void => {
    setErrorValue('');
    if (!dateValue || !timeValue) {
      return;
    }

    if (!dateValue.isValid() || !timeValue.isValid()) {
      setErrorValue('compose.scheduleSend.error.reject');
      return;
    }

    const fullValue: Dayjs = dateValue.set('hour', timeValue.hour()).set('minute', timeValue.minute()).set('seconds', timeValue.second());
    const now: Dayjs = dayjs();
    if (fullValue.isBefore(now)) {
      setErrorValue('compose.scheduleSend.error.reject');
      return;
    }

    setFullDate(fullValue);

    setCurrentStepIndex(1);
    setWidth('538px');
  }

  const handleConfirm = (): void => {
    props.setToastStatus({ message: props.toastStatus.message, type: props.toastStatus.type, isShown: false });
    if (fullDate) {
      const dateToSend: number = fullDate.valueOf();
      if (props.sendScheduledMessage) props.sendScheduledMessage(dateToSend);
      handleClose();
    }
  }

  return (
    <>
      <Dialog
        open={modalsState.isVisible}
        onClose={handleClose}
        sx={{
          textAlign: 'center',
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width,
              maxHeight: '100%'
            }
          }
        }}
        classes={{
          root: `${dialogStyles.dialogRoot}`,
          paper: `${dialogStyles.dialogPaper}`
        }}
        BackdropProps={{
          className: dialogStyles.dialogBackdrop
        }}
      >
        <ModalTitle handleClose={handleClose} title={modalsState.title} />
        <DialogContent>
          {(currentStepIndex === 0)
            ? <ScheduleDatePicker
              dateValue={dateValue}
              timeValue={timeValue}
              setDateValue={setDateValue}
              setTimeValue={setTimeValue}
              onSubmit={onSubmit}
              errorValue={errorValue}
            />
            : <ModalContent
                  heading={String(t('compose.form.message.scheduleSend'))}
                  content={''}
              />
          }
        </DialogContent>
        {(currentStepIndex > 0)
          ? <DialogActions sx={{ justifyContent: 'center' }}>
            <TertiaryButton
              disableRipple onClick={handleClose}
              sx={{ fontWeight: 700, color: ChipColor }}
            >
                {t('action.cancel')}
            </TertiaryButton>
            <SuccessButton onClick={handleConfirm}>{t('compose.form.action.confirmScheduleSend')}</SuccessButton>
            </DialogActions>
          : <></>
        }
      </Dialog>
    </>
  )
}

export default ScheduleSendFlow;
