import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { PageTitleSlice } from './constants';

const initialState = {
  value: ''
};

export const pageTitleSetterSlice = createSlice({
  name: PageTitleSlice,
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { setPageTitle } = pageTitleSetterSlice.actions;

export default pageTitleSetterSlice.reducer;
