import type { FC } from 'react';
import GeneralModal from './GeneralModal/GeneralModal.lazy';
import type { RootState } from '../../state/store';
import { ModalIcons } from './ModalIcon/ModalIcon';
import { useSelector } from 'react-redux';

export const NoSubjectModal: string = 'NoSubjectModal';

/**
 * No Subject Modal component
 * Used in compose when sending message but no subject
 * Calls generalModal to display data
 */
const NoSubject: FC = () => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[NoSubjectModal]);

  const handleConfirm = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      if (modalsState.callback) {
        modalsState.callback();
      }
      resolve();
    });
  }

  const handleCancel = async (): Promise<void> => {
    return await new Promise((resolve) => {
      if (modalsState.cancel) {
        modalsState.cancel();
      }
      resolve();
    });
  }

  return (
    <>
      <GeneralModal
        modalKey={NoSubjectModal}
        isVisible={modalsState.isVisible}
        title={modalsState.title}
        content={modalsState.content}
        heading={modalsState.heading}
        width={538}
        buttons={modalsState.buttons}
        confirmButton={modalsState.confirmButton}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        iconType={ModalIcons.Warning}
      />
    </>
  )
}

export default NoSubject;
