import React, { lazy, Suspense } from 'react';
import type { UserSettingsTabPanelContentProps } from './UserSettingsTabPanelContent';
const LazyUserSettingsTabPanelContent = lazy(async () => await import('./UserSettingsTabPanelContent'));

const UserSettingsTabPanelContent = (props: UserSettingsTabPanelContentProps) => (
  <Suspense fallback={null}>
    <LazyUserSettingsTabPanelContent {...props} />
  </Suspense>
);

export default UserSettingsTabPanelContent;
