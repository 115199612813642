import React, { lazy, Suspense } from 'react';
import type { ProductionSettingsTabPanelContentProps } from './ProductionSettingsTabPanelContent';
const LazyProductionSettingsTabPanelContent = lazy(async () => await import('./ProductionSettingsTabPanelContent'));

const ProductionSettingsTabPanelContent = (props: ProductionSettingsTabPanelContentProps) => (
  <Suspense fallback={null}>
    <LazyProductionSettingsTabPanelContent {...props} />
  </Suspense>
);

export default ProductionSettingsTabPanelContent;
