import { RouterProvider } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import customRouter from './routes/customRouter';
import type { RootState } from './state/store';

// This is the main component that provides the custom router to the whole app
const App: React.FC = () => {
  const pageTitle = useSelector((state: RootState) => state.pageTitle.value);

  useEffect(() => {
    let windowTitle: string = 'Croogloo';
    if (pageTitle) {
      windowTitle += ' - ' + String(pageTitle);
    }

    document.title = windowTitle;
  }, [pageTitle]);

  return (
    <RouterProvider router={customRouter} />
  );
};

export default App;
