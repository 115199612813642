import type { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Controller } from 'react-hook-form';
import type { OptionProps } from '../../models/OptionProps';
import { MainWhite, WhiteGrey, Silver, BlackGrey, BorderGrey, BorderBlack } from '../../theme/colors';
import classes from './FormInput.module.scss';
import { makeStyles } from '@mui/styles';

interface Props {
  name: string
  label?: string
  labelDescription?: string
  placeholder?: string
  formItemProps: any
  onItemClick?: (item: string, name: string) => void
  options: OptionProps[]
  width?: string
  autoWidth?: boolean
  startAdornmentValue?: React.ReactElement
  isLabelBold?: boolean
  className?: string
  alwaysShowPlaceholder?: boolean
  isSelectedBold?: boolean
  centered?: boolean
  fullWidth?: boolean
  adjustSize?: boolean
}

const useStyles = makeStyles((theme) => ({
  select: {
    fontWeight: 'bold !important' // Set the font weight to bold
  }
}));

/**
 * Reusable Input Select Component for forms
 * name - name of select field
 * formItemProps would have to contain control from react-hook-form's useForm
 * options have to be in the model of {OptionProps}
 */
const FormInputSelect: FC<Props> = (props: Props) => {
  const styles = useStyles();

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: (props.fullWidth) ? 1 : ''
      }}
    >
      {(props.label && props.label !== '') &&
      <Box
        component={'span'}
        sx={{
          float: 'left',
          padding: '10px 0px',
          fontFamily: 'Arial',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: '19px',
          color: 'rgba(0, 0, 0, 0.54)'
        }}
      >
        <Grid
          container
          direction={'row'}
          textAlign={'start'}
          alignItems={'flex-start'}
          columnSpacing={2}>
          <Grid
            item
            sx={{
              fontWeight: props.isLabelBold ? 700 : 400,
              color: props.isLabelBold ? BorderBlack : 'inherit'
            }}>{props.label}</Grid>
          <Grid item>
            <div>{props.labelDescription}</div>
          </Grid>
        </Grid>
      </Box>
      }
      <FormControl
        size={'small'}
        sx={{
          width: !props.autoWidth ? (props.width ?? '200px') : 'auto',
          minWidth: props.width ?? '200px',
          '& .MuiFormLabel-root.Mui-focused': {
            display: 'none'
          },
          '& .MuiFormLabel-root.MuiFormLabel-filled': {
            display: 'none'
          }
        }}
      >
      <Controller
        name={props.name}
        {...props.formItemProps}
        render={({ field: { onChange, value } }) => (
          <>
              {(props.placeholder && props.placeholder !== '') &&
                <InputLabel id={`select-${props.name}`}
                  sx={{
                    fontSize: props.startAdornmentValue ? '21px' : '16px',
                    left: props.startAdornmentValue ? '30px' : '0',
                    top: props.startAdornmentValue ? '19px' : '0'
                  }}
                >
                  {props.placeholder}
                </InputLabel>
              }
             <Select
               value={props.alwaysShowPlaceholder ? '' : value}
               name={props.name}
               onChange={onChange}
               IconComponent={KeyboardArrowDownIcon}
               displayEmpty={true}
               startAdornment={props.startAdornmentValue}
               className={`
                 ${props.className ?? ''}
                 ${props.isSelectedBold ? styles.select : ''}
                 ${classes.textInput} ${(props.adjustSize) ? classes.adjustInput : ''}
               `}
               autoWidth={props.autoWidth}
               sx={{
                 textAlign: props.centered ? 'center' : 'left',
                 border: `1px solid ${BorderGrey}`,
                 borderRadius: '50px',
                 color: BlackGrey,
                 background: MainWhite,
                 '&:hover': {
                   background: WhiteGrey
                 },
                 '&:active': {
                   background: MainWhite,
                   boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                 },
                 '& .MuiOutlinedInput-notchedOutline': {
                   border: 'none'
                 }
               }}
               MenuProps={{
                 PaperProps: {
                   sx: {
                     '& .MuiMenuItem-root': {
                       color: BorderBlack,
                       background: MainWhite,
                       '&:hover': {
                         background: WhiteGrey
                       }
                     },
                     '& .MuiMenuItem-root.Mui-selected': {
                       color: Silver,
                       background: MainWhite,
                       '&:hover': {
                         background: MainWhite
                       },
                       '&:focus': {
                         background: MainWhite
                       }
                     }
                   }
                 }
               }}
             >
               {props.options.map((option: OptionProps) => (
                 <MenuItem sx={{ justifyContent: props.centered ? 'center' : 'flex-start' }}
                   key={option.key} value={option.value} disabled={(option.disabled) ?? false}
                   onClick={() => {
                     if (props.onItemClick !== undefined) {
                       props.onItemClick(option.value, props.name);
                     }
                   }}
                 >
                   {option.label}
                 </MenuItem>
               ))}
             </Select>
          </>
        )}
      />
    </FormControl>
    </Grid>
  )
}

export default FormInputSelect;
