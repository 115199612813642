export interface Message {
  CGSESSIONID?: string
  tags?: string
  includeAgent?: boolean
  timeSaved?: number
  distributionListNotify: string
  distributionListId: string
  departmentId: string
  subject: string
  watermark: string
  message: string
  unsignedMessage: string
  signature: string
  extraEmailsJSON?: string
  includedAgentsJSON?: string
  excludedContactsEmails?: string
  replyToEmail: string
  extraemail: string
  extraEmailsForSelect?: string
  extraListMembersInputVal?: string
  smsmessage: string
  uploadedFilesJSON: string
  areAttachmentsLinks: boolean
  linkValidityTime: number
  linkAccessType: number
  isdeeplink: boolean
  emailType?: number
  status?: number
  id?: string
}

export type MessageFormTypes = 'replyTo' | 'subject' | 'template' | 'unsignedMessage' | 'smsmessage' | 'sendAsLinkChk';

export interface MessageForm {
  msgId: string
  replyTo: string
  subject: string
  template: string
  unsignedMessage?: string
  smsmessage?: string
  sendAsLinkChk?: boolean
}

export enum TestMessageResponses {
  Success = 'Success',
  NoPhone = 'No Phone',
  Fail = 'Fail'
}

export enum MessageStatus {
  DRAFT = 0,
  READY = 1,
  SENT = 2,
  RECEIVED = 3,
  FAILED = -2,
  ANYSTATUS = -1
}
