import React, { lazy, Suspense } from 'react';
import type { ModalTitleProps } from './ModalTitle';

const LazyModalTitle = lazy(async () => await import('./ModalTitle'));

const ModalTitle = (props: ModalTitleProps) => (
  <Suspense fallback={null}>
    <LazyModalTitle {...props} />
  </Suspense>
);

export default ModalTitle;
