import React, { lazy, Suspense } from 'react';

const LazyInviteContact = lazy(async () => await import('./InviteContact'));

const InviteContact = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyInviteContact {...props} />
  </Suspense>
);

export default InviteContact;
