import React, { lazy, Suspense } from 'react';

const LazyDeleteDraft = lazy(async () => await import('./DeleteDraft'));

const DeleteDraft = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyDeleteDraft {...props} />
  </Suspense>
);

export default DeleteDraft;
