import { Box, Checkbox, Grid } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import styles from '../GenerateSides.module.scss';
import { defaultCheckboxStyles } from '../../../theme/styles';
import { useRef } from 'react';

interface Props {
  text: string
  sceneNo?: string
  handleSelect: (event: SyntheticEvent) => void
  isSelected: boolean
}

/**
 * Tab item for the left panel scene selection.
 */
const SceneTabItem: FC<Props> = (props: Props) => {
  const { text } = props;
  const checkBoxRef = useRef<any>(null);

  const checkboxGridSize: number = 2;
  const textGridSize: number = 10;

  return (
    <Grid
      container
      alignItems={'center'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      className={styles.sceneTabItem}
      onClick={() => checkBoxRef.current.firstElementChild.click()}
    >
      <Grid item xs={checkboxGridSize} className={styles.checkboxGrid}>
        <Checkbox
          ref={checkBoxRef}
          size='small'
          checked={props.isSelected}
          onClick={props.handleSelect}
          color={'success'}
          sx={{ ...defaultCheckboxStyles }}
        />
        {(props.sceneNo) && <Box className={styles.sceneTabItemText}>{props.sceneNo}</Box>}
      </Grid>
      <Grid item xs={textGridSize}>
        <Box
          className={styles.sceneTabItemText}
        >
          {text}
        </Box>
      </Grid>
    </Grid>
  )
}

export default SceneTabItem;
