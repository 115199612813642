import { useEffect, useState } from 'react';
import type { FC } from 'react';
import TabHeaders from '../../slideDrawer/TabHeaders/TabHeaders';
import TabPanel from '../../slideDrawer/TabPanel';
import SceneTabItem from './SceneTabItem';
import styles from '../GenerateSides.module.scss';
import progressStyles from '../../../theme/loadingProgress.module.scss';
import { Box, CircularProgress, Grid, List } from '@mui/material';
import type { Character, Location, LocationMap, Scene } from '../../../models/Scene';
import { LocationGroup } from '../../../models/Scene';
import { scrollStyles, tabHeaderHeight, ChipDropDownHeight, headerHeight, mainPadding } from '../../../theme/styles';
import { buttonHeight, buttonMargin, sceneTabHeight } from '../GenerateSides';
import type { RootState } from '../../../state/store';
import { useSelector } from 'react-redux';

const TabsKeyHeaderStart: string = 'tabs-key-';

interface Props {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  scenes: Scene[]
  characters: Character[]
  locationsInt: Location[]
  locationsExt: Location[]
  locationsOther: Location[]
  selectedScenes: Scene[]
  toggleScene: (scene: Scene, isSelected: boolean, closePanel: boolean) => void
  toggleCharacter: (character: Character, isSelected: boolean) => void
  toggleLocation: (location: Location, isSelected: boolean) => void
  isFetching: boolean
  isDrawerOpen: boolean
}

/**
 * Tabs for the left scene selection panel.
 */
const DrawerTabs: FC<Props> = (props: Props) => {
  const {
    scenes,
    characters,
    locationsExt,
    locationsInt,
    locationsOther,
    selectedScenes,
    toggleScene,
    toggleCharacter,
    toggleLocation,
    isFetching,
    isDrawerOpen
  } = props;

  const isSideBarOpen: boolean = useSelector((state: RootState) => state.sideBar.isSideBarOpen);

  const [tabsHeaderKey, setTabsHeaderKey] = useState<string>(`${TabsKeyHeaderStart}${String(isDrawerOpen)}${String(isSideBarOpen)}`);
  // reset tabs header key whenever side bar/slide drawer opens/closes - forces tab indicator to adjust accordingly
  useEffect(() => {
    setTabsHeaderKey(`${TabsKeyHeaderStart}${String(isDrawerOpen)}${String(isSideBarOpen)}`);
  }, [isDrawerOpen, isSideBarOpen]);

  // Available tabs for scene selection
  const tabs: string[] = [
    'generateSides.tabs.scene',
    'generateSides.tabs.character',
    'generateSides.tabs.location'
  ];

  const locationGroups: LocationGroup[] = [LocationGroup.INT, LocationGroup.EXT, LocationGroup.OTHER];

  const locationsMap: LocationMap = {
    [LocationGroup.INT]: locationsInt,
    [LocationGroup.EXT]: locationsExt,
    [LocationGroup.OTHER]: locationsOther
  }

  const isCharacterSelected = (character: Character): boolean => {
    const selectedIds: string[] = selectedScenes.map((selected: Scene) => selected.id);
    return character.scenes.every((s: string) => selectedIds.includes(s));
  }

  const isLocationSelected = (location: Location): boolean => {
    const selectedIds: string[] = selectedScenes.map((selected: Scene) => selected.id);
    return location.scenes.every((s: string) => selectedIds.includes(s));
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <TabHeaders key={tabsHeaderKey} tab={props.tab} onChange={props.onChange} tabs={tabs} spaceHeaders={true} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: `calc(
              100vh - ${tabHeaderHeight}px - ${sceneTabHeight} - ${ChipDropDownHeight}px
              - ${headerHeight}px - ${buttonHeight} - ${buttonMargin} - ${mainPadding}px
              )`,
            overflow: 'auto',
            ...scrollStyles
          }}
        >
          <TabPanel value={props.tab} index={0}>
            {isFetching
              ? <CircularProgress className={progressStyles.circularProgress}/>
              : <List sx={{ width: '100%' }}>
                  {scenes?.map((scene: Scene) => (
                    <SceneTabItem
                      key={scene.id}
                      sceneNo={scene.sceneNumber}
                      text={scene.description}
                      isSelected={selectedScenes.some(s => s.id === scene.id)}
                      handleSelect={(event) => {
                        event.stopPropagation();
                        toggleScene(scene, (event.target as any).checked, true);
                      }}
                    />
                  ))}
                </List>
            }
          </TabPanel>
          <TabPanel value={props.tab} index={1}>
            {isFetching
              ? <CircularProgress className={progressStyles.circularProgress} />
              : <List sx={{ width: '100%' }}>
                {characters?.map((character: Character) => (
                  <SceneTabItem
                    key={character.name}
                    text={character.name}
                    isSelected={isCharacterSelected(character)}
                    handleSelect={(event) => {
                      event.stopPropagation();
                      toggleCharacter(character, (event.target as any).checked);
                    }}
                  />
                ))}
              </List>
            }
          </TabPanel>
          <TabPanel value={props.tab} index={2}>
            {isFetching
              ? <CircularProgress className={progressStyles.circularProgress}/>
              : <List sx={{ width: '100%' }}>
                {locationGroups.map((locationGroup: LocationGroup) => {
                  const locations: Location[] = locationsMap[locationGroup];
                  return (
                    <Grid container key={`group-${locationGroup}`}>
                    <Grid item xs={12} className={styles.locationGroupHeader}>
                      <Box className={styles.locationGroupText}>
                        {locationGroup}
                      </Box>
                    </Grid>
                    <List sx={{ width: '100%' }}>
                      {locations?.map((location: Location) => (
                          <SceneTabItem
                            key={location.name}
                            text={location.name}
                            isSelected={isLocationSelected(location)}
                            handleSelect={(event) => {
                              event.stopPropagation();
                              toggleLocation(location, (event.target as any).checked);
                            }}
                          />
                      ))}
                    </List>
                    </Grid>
                  )
                })}
              </List>
            }
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DrawerTabs;
