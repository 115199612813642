import React, { lazy, Suspense } from 'react';
import type { BoldContentProps } from './BoldContent';

const LazyBoldContent = lazy(async () => await import('./BoldContent'));

const BoldContent = (props: BoldContentProps) => (
  <Suspense fallback={null}>
    <LazyBoldContent {...props} />
  </Suspense>
);

export default BoldContent;
