import React, { lazy, Suspense } from 'react';

const LazyFileViewerPopup = lazy(async () => await import('./FileViewerPopup'));

const FileViewerPopup = () => (
  <Suspense fallback={null}>
    <LazyFileViewerPopup />
  </Suspense>
);

export default FileViewerPopup;
