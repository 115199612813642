import React from 'react';
import type { RefObject, Dispatch, SetStateAction } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Quill } from 'react-quill';
import type ReactQuill from 'react-quill';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton
} from '@mui/material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { makeStyles } from '@mui/styles';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { CrooglooGreen, MainBlack } from '../../theme/colors';

export const maxToolbarHeight: string = '50px';
// Note: usually don't like using !important so much
// but it's the only way to override the react quill theme and have reusable classes
const useStyles = makeStyles({
  formControl: {
    border: 'none',
    '& fieldset': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      width: '35px'
    },
    '& .MuiInputLabel-root': {
      fontWeight: 'bold',
      color: MainBlack,
      '&.Mui-focused': {
        color: `${CrooglooGreen}`
      }
    },
    '&:hover': {
      '& .MuiInputLabel-root': {
        color: `${CrooglooGreen}`
      }
    }
  },
  openIcon: {
    '& path': {
      fill: MainBlack,
      stroke: MainBlack
    },
    '&:hover': {
      '& path': {
        fill: CrooglooGreen,
        stroke: CrooglooGreen
      }
    }
  },
  customSelect: {
    border: 'none'
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px !important',
    borderTop: '1px solid #ccc !important',
    borderBottom: 'none !important',
    borderRight: 'none !important',
    borderLeft: 'none !important',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'center'
  },
  formats: {
    marginRight: '5px !important'
  },
  backButton: {
    '& .ql-fill': {
      fill: `${MainBlack} !important`
    },
    '& .ql-stroke': {
      stroke: `${MainBlack} !important`
    },
    '&:hover, &.ql-active': {
      '& .ql-fill': {
        fill: `${CrooglooGreen} !important`
      },
      '& .ql-stroke': {
        stroke: `${CrooglooGreen} !important`
      }
    }
  },
  undoButton: {
    '& .ql-fill': {
      fill: `${MainBlack} !important`
    },
    '& .ql-stroke': {
      stroke: `${MainBlack} !important`
    },
    '&:hover': {
      '& .ql-fill': {
        fill: `${CrooglooGreen} !important`
      },
      '& .ql-stroke': {
        stroke: `${CrooglooGreen} !important`
      }
    }
  },
  pictureButton: {
    '& rect': {
      stroke: `${MainBlack} !important`
    },
    '& circle, polyline': {
      fill: `${MainBlack} !important`
    },
    '&:hover, &.ql-active': {
      '& rect': {
        stroke: `${CrooglooGreen} !important`
      },
      '& circle, polyline': {
        fill: `${CrooglooGreen} !important`
      }
    }
  },
  button: {
    '& path, polyline, polygone, circle, line, rect': {
      stroke: `${MainBlack} !important`
    },
    '&:hover, &.ql-active': {
      '& path, polyline, polygone, circle, line, rect': {
        stroke: `${CrooglooGreen} !important`
      },
      '& .ql-picker-item': {
        '& line': {
          stroke: `${MainBlack} !important`
        },
        '&:hover': {
          '& line': {
            stroke: `${CrooglooGreen} !important`
          }
        }
      }
    }
  }
});

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange () {
  // @ts-expect-error no error, required for quill editor
  this.quill.history.undo();
}
function redoChange () {
  // @ts-expect-error no error, required for quill editor
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['small', 'medium', 'large', 'huge'];
Quill.register(Size, true);

const Heading = Quill.import('attributors/style/font');
Heading.whitelist = ['1', '2', '3'];
Quill.register(Heading, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'Comic Sans MS',
  'Courier New',
  'georgia',
  'helvetica',
  'lucida'
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'link',
  'image',
  'color',
  'code-block',
  'custom-html'
];

interface Props {
  quillRef: RefObject<ReactQuill | null>
  setEditorExpanded: Dispatch<SetStateAction<boolean>>
  editorExpanded: boolean
}

// Quill Toolbar component
export const QuillToolbar = ({ quillRef, setEditorExpanded, editorExpanded }: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const classes = useStyles();

  const handleFormatChange = (format: string, value: string): void => {
    const quillEditor = quillRef.current?.getEditor();
    if (quillEditor) {
      if (quillEditor.getSelection()) {
        quillEditor.format(format, value);
      } else {
        const [line] = quillEditor.getLine(quillEditor.getSelection(true).index);
        quillEditor.formatLine(line, 0, format, value);
      }
    }
  }

  const handleSizeChange = (event: SelectChangeEvent): void => {
    const fontSize: string = event.target.value;
    handleFormatChange('size', fontSize);
  }

  const handleFontChange = (event: SelectChangeEvent): void => {
    const fontType: string = event.target.value;
    handleFormatChange('font', fontType);
  }

  const emptyIconComponent = () => {
    return null;
  }

  const handleExpandEditor = (): void => {
    setEditorExpanded(!editorExpanded);
  }

  return (
    <div id="toolbar" className={classes.toolbar}>
      <span className={`ql-formats ${classes.formats}`}>
       <FormControl className={classes.formControl}>
         <InputLabel shrink={false}>F</InputLabel>
         <Select
           onChange={handleFontChange}
           value={''}
           IconComponent={emptyIconComponent}
           displayEmpty
           inputProps={{
             id: 'font-select',
             className: `${classes.customSelect} ql-font`
           }}
         >
           <MenuItem value={'arial'}>{t('compose.editor.toolbar.font.arial')}</MenuItem>
           <MenuItem value={'Comic Sans MS'}>{t('compose.editor.toolbar.font.comicSans')}</MenuItem>
           <MenuItem value={'Courier New'}>{t('compose.editor.toolbar.font.courierNew')}</MenuItem>
           <MenuItem value={'georgia'}>{t('compose.editor.toolbar.font.georgia')}</MenuItem>
           <MenuItem value={'helvetica'}>{t('compose.editor.toolbar.font.helvetica')}</MenuItem>
           <MenuItem value={'lucida'}>{t('compose.editor.toolbar.font.lucida')}</MenuItem>
         </Select>
       </FormControl>
        <FormControl className={classes.formControl}>
        <InputLabel shrink={false}>S</InputLabel>
        <Select
          onChange={handleSizeChange}
          value={''}
          IconComponent={emptyIconComponent}
          displayEmpty
          inputProps={{
            id: 'font-size-select',
            className: `${classes.customSelect} ql-size`
          }}
        >
          <MenuItem value={'small'}>{t('compose.editor.toolbar.fontSize.small')}</MenuItem>
          <MenuItem value={'medium'}>{t('compose.editor.toolbar.fontSize.medium')}</MenuItem>
          <MenuItem value={'large'}>{t('compose.editor.toolbar.fontSize.large')}</MenuItem>
          <MenuItem value={'huge'}>{t('compose.editor.toolbar.fontSize.heading')}</MenuItem>
        </Select>
        </FormControl>
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <button className={`ql-bold ${classes.button}`} />
        <button className={`ql-italic ${classes.button}`} />
        <button className={`ql-underline ${classes.button}`} />
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <button className={`ql-list ${classes.button}`} value="ordered" />
        <button className={`ql-list ${classes.button}`} value="bullet" />
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <select className={`ql-align ${classes.button}`} />
        <select className={`ql-color ${classes.button}`} />
        <select className={`ql-background ${classes.backButton}`} />
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <button className={`ql-link ${classes.button}`} />
        <button className={`ql-image ${classes.pictureButton}`} />
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <button className={`ql-clean ${classes.button}`} />
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <button className={`ql-undo ${classes.undoButton}`}>
          <CustomUndo />
        </button>
        <button className={`ql-redo ${classes.undoButton}`}>
          <CustomRedo />
        </button>
      </span>
      <span className={`ql-formats ${classes.formats}`}>
        <IconButton onClick={handleExpandEditor} className={classes.openIcon}>
          <OpenWithIcon />
        </IconButton>
      </span>
    </div>
  )
};

export default QuillToolbar;
