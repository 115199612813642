/*
 * Reducer file for the UploadFiles slice
 * used for when searching and uploading files
 * adds to the files to array of files
 */

import { createSlice } from '@reduxjs/toolkit';
import { UploadFilesSlice } from './constants';
import type { FileAttachment, OtherFileAttachment } from '../../models/Document';
import type { UploadFileLocation } from '../../models/Upload';
import { OriginalCurrentFileNumber } from '../../models/Upload';
import type { FileTypes } from '../../models/FileTypes';
import type { ToastProps } from '../../models/Toast';
import { InitialToastValues } from '../../models/Toast';

export interface UploadFilesState {
  files: FileAttachment[]
  uploadFilesInProgress: File[]
  filesInProgress: OtherFileAttachment[]
  numberOfFilesInProgress: number
  showUploadFlow: boolean
  currentFile: OtherFileAttachment | null
  currentFileNumber: number
  changedFileName: string | null
  isWatermarkAvailable: boolean
  applyToAll: boolean
  applyToAllAsked: boolean
  fetchingFile: boolean
  uploadDestination: UploadFileLocation | null
  predefinedFileType: FileTypes | null
  uploadToastStatus: ToastProps
}

const initialState: UploadFilesState = {
  files: [],
  uploadFilesInProgress: [],
  filesInProgress: [],
  numberOfFilesInProgress: 0,
  showUploadFlow: false,
  currentFile: null,
  currentFileNumber: OriginalCurrentFileNumber,
  changedFileName: null,
  isWatermarkAvailable: false,
  applyToAll: false,
  applyToAllAsked: false,
  fetchingFile: false,
  uploadDestination: null,
  predefinedFileType: null,
  uploadToastStatus: InitialToastValues
}

export const uploadFilesSlice = createSlice({
  name: UploadFilesSlice,
  initialState,
  reducers: {
    addFiles: (state, action) => {
      let files: FileAttachment[] = state.files;
      const filesToAdd: FileAttachment[] = action.payload.files;
      filesToAdd.forEach((fileToAdd: FileAttachment) => {
        if (!files.some((file: FileAttachment) => file.id === fileToAdd.id)) {
          files = [...files, fileToAdd];
        }
      });
      state.files = files;
    },
    editUploadedFile: (state, action) => {
      state.files = state.files.map((file: FileAttachment) =>
        file.id === action.payload.data.id
          ? { ...file, ...action.payload.data }
          : file
      );
    },
    removeFiles: (state, action) => {
      let files: FileAttachment[] = state.files;
      const filesToRemove: string[] = action.payload.files;
      if (filesToRemove.length > 0) {
        files = files.filter((file: FileAttachment) => !filesToRemove.includes(file.id));
      }
      state.files = files;
    },
    clearFiles: (state, action) => {
      state.files = [];
    },
    setUploadFilesInProgress: (state, action) => {
      const files: File[] = action.payload.files;
      state.uploadFilesInProgress = files;
    },
    setFilesInProgress: (state, action) => {
      const files: OtherFileAttachment[] = action.payload.files;
      state.filesInProgress = files;
      state.numberOfFilesInProgress = files.length;
    },
    setShowUploadFlow: (state, action) => {
      state.showUploadFlow = action.payload.show;
    },
    setCurrentFileNumber: (state, action) => {
      state.currentFileNumber = action.payload.number;
    },
    setCurrentFile: (state, action) => {
      state.currentFile = action.payload.file;
    },
    setChangedFileName: (state, action) => {
      state.changedFileName = action.payload.name;
    },
    setIsWatermarkAvailable: (state, action) => {
      state.isWatermarkAvailable = action.payload.available;
    },
    setApplyToAll: (state, action) => {
      state.applyToAll = action.payload.applyToAll;
    },
    setApplyToAllAsked: (state, action) => {
      state.applyToAllAsked = action.payload.asked;
    },
    setUploadDestination: (state, action) => {
      state.uploadDestination = action.payload.destination;
    },
    setPredefinedFileType: (state, action) => {
      state.predefinedFileType = action.payload.type;
    },
    resetShowUploadFlow: (state, action) => {
      state.uploadFilesInProgress = [];
      state.filesInProgress = [];
      state.showUploadFlow = false;
      state.currentFileNumber = OriginalCurrentFileNumber;
      state.currentFile = null;
      state.changedFileName = null;
      state.isWatermarkAvailable = false;
      state.applyToAll = false;
      state.applyToAllAsked = false;
      state.uploadDestination = null;
      state.predefinedFileType = null;
    },
    setFetching: (state, action) => {
      state.fetchingFile = action.payload.fetchingFile;
    },
    setToastStatus: (state, action) => {
      state.uploadToastStatus = action.payload.status;
    },
    resetToastStatus: (state, action) => {
      state.uploadToastStatus = InitialToastValues;
    }
  }
})

export const {
  addFiles, editUploadedFile, removeFiles, clearFiles, setUploadFilesInProgress, setFilesInProgress,
  setShowUploadFlow, setCurrentFileNumber, resetShowUploadFlow, setApplyToAll, setApplyToAllAsked, setFetching,
  setUploadDestination, setPredefinedFileType, setCurrentFile, setChangedFileName, setIsWatermarkAvailable,
  setToastStatus, resetToastStatus
} = uploadFilesSlice.actions;
export default uploadFilesSlice.reducer;
