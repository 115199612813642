import { store } from '../state/store';
import type { ApiDataType, AuthUser } from './auth/constants';
import { ViewApi } from './auth/constants';
import AuthService from './auth/AuthService';
import {
  DefaultDocumentPath,
  setCurrentDocumentCards,
  setDocumentParams,
  setPublishToStudioExists,
  setDocumentPath,
  setDocumentsToCheck
} from '../state/slices/Documents';
import type { WSDocumentCardResponse, WSResponse } from '../models/WSResponse';
import type { DocumentCard, DocumentCardResponse } from '../models/Document';
import { PostMethod } from './constants';
import DocumentTreeService from './documents/DocumentTreeService';
import type { DropboxEntry } from '../models/Dropbox';
import { CrooglooFileTypes } from '../models/FileTypes';
import { PublishToStudioFolderName } from './documents/constants';
import GoogleService from './GoogleService';
import type { GoogleDriveEntry, GoogleDriveResponse } from '../models/Google';
import type { BoxEntry } from '../models/Box';

const { dispatch } = store;

const GetReportsOnly: boolean = false;
const DropboxFolderName: string = 'Dropbox';
export const GDriveFolderName: string = 'Google Drive';
const BoxFolderName: string = 'Box';
export const DropboxFolderId: string = 'dropboxfolder';
export const GDriveFolderId: string = 'gDriveFolder';
export const BoxFolderId: string = 'boxfolder';

interface IViewService {
  fetchCardViewDocuments: (params: string[], checkDocuments?: boolean) => void
  fetchDropboxDocuments: (crooglooAuth: AuthUser, params: string[]) => void
  createDocumentCard: (item: DocumentCardResponse) => DocumentCard
  dropboxEntryToDocumentCard: (entry: DropboxEntry) => DocumentCard
  googleEntryToDocumentCard: (entry: GoogleDriveEntry) => DocumentCard
  boxEntryToDocumentCard: (entry: BoxEntry) => DocumentCard
  replaceDHInShared: (shared: string) => string
  changeFilePath: (model: any, documentParams: string[]) => void
  goBackFilePath: (index: number) => void
  generateVCardFromDistributionList: (selected: string[]) => Promise<void>
  cardTypeToIcon: (type: string, name: string) => CrooglooFileTypes
  fetchGDriveFiles: (params: string[]) => void
  fetchBoxFiles: (params: string[]) => void
}

class ViewService implements IViewService {
  /**
   * Fetch documents to be viewed in documents table depending on params passed in
   */
  fetchCardViewDocuments (params: string[], checkDocuments?: boolean): void {
    const authState = store.getState().auth;
    if (params.length > 0 && params[0] === DropboxFolderName) {
      this.fetchDropboxDocuments(authState.crooglooauth, params)
    } else if (params.length && params[0] === GDriveFolderName) {
      this.fetchGDriveFiles(params);
    } else if (params.length && params[0] === BoxFolderName) {
      this.fetchBoxFiles(params);
    } else {
      const path: string = (params.length === 0) ? DefaultDocumentPath : params[params.length - 1];
      makeViewCall('fetchCardViewDocuments', {
        params: {
          parentFolder: path,
          getReportsOnly: String(GetReportsOnly)
        }
      }).then((result: WSDocumentCardResponse) => {
        const itemList: DocumentCard[] = [];
        if (result.items) {
          const items: DocumentCardResponse[] = result.items;
          if (items.length > 0) {
            items.forEach((item: DocumentCardResponse) => {
              itemList.push(this.createDocumentCard(item));
            });
          }
        }
        if (path === DefaultDocumentPath) {
          checkPublishToStudioExists(itemList);
          const dropboxLinked: boolean = DocumentTreeService.hasDropboxToken();
          const gDriveLinked: boolean = DocumentTreeService.hasGoogleAccessToken();
          const boxLinked: boolean = DocumentTreeService.hasBoxToken();
          itemList.push(linkFolderToDocumentCard(DropboxFolderId, CrooglooFileTypes.DropboxLink, DropboxFolderName, dropboxLinked));
          itemList.push(linkFolderToDocumentCard(GDriveFolderId, CrooglooFileTypes.GDriveLink, GDriveFolderName, gDriveLinked));
          itemList.push(linkFolderToDocumentCard(BoxFolderId, CrooglooFileTypes.BoxLink, BoxFolderName, boxLinked));
        }
        if (checkDocuments) {
          dispatch(setDocumentsToCheck({ documents: itemList }));
        } else {
          dispatch(setCurrentDocumentCards({ documentCards: itemList }));
        }
      }).catch((err) => {
        console.error(err);
      });
    }
  }

  fetchDropboxDocuments (crooglooAuth: AuthUser, params: string[]): void {
    const pathBuilder: string[] = [];
    if (params.length > 1) {
      for (let i = 1; i < params.length; i++) {
        pathBuilder.push(params[i]);
      }
    }
    const path: string = pathBuilder.length > 0 ? '/' + pathBuilder.join('/') : '';
    DocumentTreeService.fetchDropboxFiles(path, crooglooAuth.dropboxToken)
      .then((entries: DropboxEntry[]) => {
        const itemList: DocumentCard[] = [];
        if (entries.length > 0) {
          entries.forEach((entry: DropboxEntry) => {
            itemList.push(this.dropboxEntryToDocumentCard(entry));
          })
        }
        dispatch(setCurrentDocumentCards({ documentCards: itemList }));
      })
      .catch((err) => {
        console.error(err);
      })
  }

  /**
   * function to fetch the box files depending on the path
   * and then create the documentCard list to display in document tables
   */
  fetchBoxFiles (params: string[]): void {
    const pathBuilder: string[] = [];
    if (params.length > 1) {
      for (let i = 1; i < params.length; i++) {
        pathBuilder.push(params[i]);
      }
    }
    const path: string = pathBuilder.length > 0 ? '/' + pathBuilder[pathBuilder.length - 1] : '';
    AuthService.fetchBoxFiles(path)
      .then((entries: BoxEntry[]) => {
        const itemList: DocumentCard[] = [];
        entries.forEach((entry: BoxEntry) => {
          itemList.push(this.boxEntryToDocumentCard(entry));
        })
        dispatch(setCurrentDocumentCards({ documentCards: itemList }));
      })
      .catch((err) => {
        console.error(err);
      })
  }

  /**
   * function to fetch the google drive files depending on the path
   * and then create the documentCard list to display in document tables
   */
  fetchGDriveFiles (params: string[]): void {
    GoogleService.fetchGoogleFiles(params)
      .then((result: GoogleDriveResponse) => {
        if (result.error) {
          if (result.error.code === 401) {
            throw new Error('invalid credentials');
          } else {
            throw new Error('error fetching files');
          }
        }
        const itemList: DocumentCard[] = [];
        const files: GoogleDriveEntry[] = result.files;
        if (files.length > 0) {
          files.forEach((entry: GoogleDriveEntry) => {
            itemList.push(this.googleEntryToDocumentCard(entry));
          });
        }
        dispatch(setCurrentDocumentCards({ documentCards: itemList }));
      })
      .catch((err) => {
        console.error(err);
        dispatch(setCurrentDocumentCards({ documentCards: [] }));
        GoogleService.unlinkGDrive();
      })
  }

  /**
   * Create DocumentCard object from documentCardResponse object
   */
  createDocumentCard (item: DocumentCardResponse): DocumentCard {
    const type: string = (item.line05 === 'Folder') ? 'folder' : 'file';
    const subType: string = (item.isSmartFolder) ? 'smartFolder' : '';
    let sortValue: number = (subType === 'smartFolder') ? 2 : (type === 'folder' ? 3 : 4);
    if (item.fileName === PublishToStudioFolderName) {
      sortValue = 1; // if publish to studio folder push to top with dropbox and google
    }
    const fileItem: DocumentCard = {
      itemid: item.entityId,
      id: item.entityId, // validId
      type: this.cardTypeToIcon(type, item.fileName),
      subType,
      data: (item.line05 === 'Folder') ? item.entityId : '',
      name: item.fileName,
      datemod: item.line03 ?? '',
      shared: this.replaceDHInShared(item.line09),
      category: item.line10 ?? '',
      watermark: (item.line07 === '1'),
      isEncrypted: (item.line12 === '1'),
      archived: item.isArchived,
      isPublishedToStudio: item.isPublishedToStudio ?? false,
      cloudProvider: item.cloudProvider ?? '',
      size: (item.line02) ? parseInt(item.line02) : 0,
      sortValue
    }
    return fileItem;
  }

  dropboxEntryToDocumentCard (entry: DropboxEntry): DocumentCard {
    const entryType: CrooglooFileTypes = entry.type;
    const nodeId: string = entry.id.split(':', 2)[1];
    const fileItem: DocumentCard = {
      itemid: nodeId,
      id: nodeId,
      type: entryType,
      subType: '',
      data: entry.data ?? '',
      name: entry.name,
      datemod: '',
      shared: '',
      category: '',
      watermark: false,
      isEncrypted: false,
      archived: false,
      isPublishedToStudio: false,
      cloudProvider: '',
      size: entry.size ?? 0,
      sortValue: (entryType === CrooglooFileTypes.DropboxFolder) ? 1 : 2
    }
    return fileItem;
  }

  /**
   * convert box file entry to Document Card to display in documents
   */
  boxEntryToDocumentCard (entry: BoxEntry): DocumentCard {
    const entryType: CrooglooFileTypes = entry.type;
    const fileItem: DocumentCard = {
      itemid: entry.id,
      id: entry.id,
      type: entryType,
      subType: '',
      data: entry.data ?? '',
      name: entry.name,
      datemod: '',
      shared: '',
      category: '',
      watermark: false,
      isEncrypted: false,
      archived: false,
      isPublishedToStudio: false,
      cloudProvider: '',
      size: 0,
      sortValue: (entryType === CrooglooFileTypes.BoxFolder) ? 1 : 2
    }
    return fileItem;
  }

  /**
   * convert gDrive file entry to Document Card to display in documents
   */
  googleEntryToDocumentCard (entry: GoogleDriveEntry): DocumentCard {
    const entryType: CrooglooFileTypes = entry.mimeType.endsWith('folder') ? CrooglooFileTypes.GDriveFolder : CrooglooFileTypes.GDriveFile;
    const fileItem: DocumentCard = {
      itemid: entry.id,
      id: entry.id,
      type: entryType,
      subType: '',
      data: '',
      name: entry.name,
      datemod: entry.modifiedTime ?? '',
      shared: '',
      category: '',
      watermark: false,
      isEncrypted: false,
      archived: false,
      isPublishedToStudio: false,
      cloudProvider: '',
      size: 0,
      sortValue: (entryType === CrooglooFileTypes.GDriveFolder) ? 1 : 2

    }
    return fileItem;
  }

  /**
   * Function to replace dh with dhr for shared value in document cards
   */
  replaceDHInShared (shared: string): string {
    let replacedShared: string = shared;
    if (shared.includes('dh')) {
      replacedShared = shared.replace('dh', 'dtr');
    }
    if (shared.includes('DH')) {
      replacedShared = shared.replace('DH', 'DTR');
    }
    if (shared.includes('Dh')) {
      replacedShared = shared.replace('Dh', 'DTR');
    }
    return replacedShared;
  }

  /**
   * Changes the path and params when folder clicked on in documents table
   */
  changeFilePath (model: any, documentParams: string[]): void {
    const newPath: string[] = [...documentParams];
    const documentsPath: string[] = [...store.getState().documents.documentsPath];
    if ([CrooglooFileTypes.Folder, CrooglooFileTypes.GDriveFolder, CrooglooFileTypes.BoxFolder].includes(model.type)) {
      newPath.push(model.id);
      documentsPath.push(model.name);
    } else if (model.type === CrooglooFileTypes.DropboxLink) {
      newPath.push(DropboxFolderName);
      documentsPath.push(DropboxFolderName);
    } else if ([CrooglooFileTypes.DropboxFolder].includes(model.type)) {
      newPath.push(model.name.toLowerCase());
      documentsPath.push(model.name);
    } else if (model.type === CrooglooFileTypes.GDriveLink) {
      newPath.push(GDriveFolderName);
      documentsPath.push(GDriveFolderName);
    } else if (model.type === CrooglooFileTypes.BoxLink) {
      newPath.push(BoxFolderName);
      documentsPath.push(BoxFolderName);
    }

    // call dispatch to handle changing of params and getting in documents component
    dispatch(setDocumentParams({ params: newPath }));
    dispatch(setDocumentPath({ path: documentsPath }));
    this.fetchCardViewDocuments(newPath);
  }

  /**
   * after clicking on one of the cookies at the top of the documents page
   * change the params to go back to that folder
   * change the path to show the new cookie trail with the folders names
   * @param index
   */
  goBackFilePath (index: number): void {
    const documentsPath: string[] = [...store.getState().documents.documentsPath];
    const documentParams: string[] = [...store.getState().documents.documentParams];
    if (index === documentsPath.length - 1) {
      return;
    }
    const newDocumentParams: string[] = documentParams.slice(0, index);
    const newDocumentsPath: string[] = documentsPath.slice(0, index + 1);
    dispatch(setDocumentParams({ params: newDocumentParams }));
    dispatch(setDocumentPath({ path: newDocumentsPath }));
    this.fetchCardViewDocuments(newDocumentParams);
  }

  async generateVCardFromDistributionList (selected: string[]): Promise<void> {
    return await makeViewCall('generateVCardFromDistributionList', {
      params: {
        distributionList: selected.join(',')
      },
      type: PostMethod
    })
      .then((resp: WSResponse) => {
        if (resp) {
          setTimeout(function () {
            if (resp.responseMessage) {
              window.location.href = resp.responseMessage;
            }
          }, 500);
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('error');
      })
  }

  cardTypeToIcon (type: string, name: string): CrooglooFileTypes {
    name = name.toLowerCase();
    let iconType: CrooglooFileTypes = CrooglooFileTypes.Folder;
    if (type === 'file') {
      if (name.endsWith('.pdf')) {
        iconType = CrooglooFileTypes.PDF;
      } else if (name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.gif')) {
        iconType = CrooglooFileTypes.Image;
      } else if (name.endsWith('.xls') || name.endsWith('xlsm') || name.endsWith('.csv') || name.endsWith('.xlsx')) {
        iconType = CrooglooFileTypes.Excel;
      }
    }
    return iconType;
  }
}

/**
 * Create DocumentCard object for google / dropbox folders
 */
const linkFolderToDocumentCard = (id: string, type: CrooglooFileTypes, name: string, linked: boolean): DocumentCard => {
  const fileItem: DocumentCard = {
    itemid: id,
    id,
    type,
    subType: 'none',
    data: '',
    name,
    datemod: '',
    shared: 'documents.sharedWith.notShared',
    category: '',
    watermark: false,
    isEncrypted: false,
    archived: false,
    isPublishedToStudio: false,
    cloudProvider: '',
    sortValue: 0,
    size: 0,
    linked
  }
  return fileItem;
}

const checkPublishToStudioExists = (itemList: DocumentCard[]): void => {
  if (itemList.some((item: DocumentCard) => item.name === PublishToStudioFolderName)) {
    dispatch(setPublishToStudioExists({ exists: true }));
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeViewCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(ViewApi, method, data);
  return result;
}

export const viewService: IViewService = new ViewService();
export default viewService;
