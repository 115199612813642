import React, { lazy, Suspense } from 'react';
import type { TemplatesProps } from './Templates';
const LazyTemplates = lazy(async () => await import('./Templates'));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Templates = (props: TemplatesProps) => (
  <Suspense fallback={null}>
    <LazyTemplates {...props} />
  </Suspense>
);

export default Templates;
