import React from 'react';
import type { FC } from 'react';
import GeneralModal from './GeneralModal/GeneralModal.lazy';
import type { RootState } from '../../state/store';
import { ModalIcons } from './ModalIcon/ModalIcon';
import { useSelector } from 'react-redux';

export const NoRecipientsModal: string = 'NoRecipientsModal';

/**
 * No Recipients Modal component
 * Used in compose when sending message but no recipients
 * Calls generalModal to display data
 */
const NoRecipients: FC = () => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[NoRecipientsModal]);
  return (
    <>
      <GeneralModal
        modalKey={NoRecipientsModal}
        isVisible={modalsState.isVisible}
        title={modalsState.title}
        content={modalsState.content}
        heading={modalsState.heading}
        width={538}
        buttons={modalsState.buttons}
        confirmButton={modalsState.confirmButton}
        isCancelButton={false}
        iconType={ModalIcons.Warning}
      />
    </>
  )
}

export default NoRecipients;
