export interface StepModel {
  key: string
  title: string
  message: string
}

export const SaveTemplateModal: string = 'SaveTemplateModal';

const steps: StepModel[] = [
  {
    key: 'IncludeOptions',
    title: 'compose.saveTemplate.includeOptions.title',
    message: 'compose.saveTemplate.includeOptions.message'
  },
  {
    key: 'AttachmentOptions',
    title: 'compose.saveTemplate.attachmentOptions.title',
    message: 'compose.saveTemplate.attachmentOptions.message'
  },
  {
    key: 'TemplateName',
    title: 'compose.saveTemplate.templateName.title',
    message: ''
  }
]

export default steps;
