import { store } from '../state/store';
import { updateCustomModalState } from '../state/slices/modals';
import type { ModalData } from '../state/slices/modals';
const { dispatch } = store;

interface IModalService {
  openCustomModal: (key: string, data: ModalData) => void
  closeCustomModal: (key: string) => void
}

/**
 * Modal Service handling the opening and closing of modals in the modal redux slice
 */
class ModalService implements IModalService {
  /**
   * Function handling the showing/opening of a custom modal using the key
   * @param key - key of modal to show
   * @param data - data to be passed to redux to then display in modal
   */
  openCustomModal (key: string, data: ModalData): void {
    dispatch(updateCustomModalState({
      key,
      data: {
        isVisible: true,
        ...data
      }
    }))
  }

  /**
   * Function handling the closing of a custom modal using key
   * @param key - key of modal to close
   */
  closeCustomModal (key: string): void {
    dispatch(updateCustomModalState({
      key,
      data: {
        isVisible: false
      }
    }))
  }
}

const modalService: IModalService = new ModalService();
export default modalService;
