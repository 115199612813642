export const DraftNumOfTotalItems: number = 20;
export const SentNumOfTotalItems: number = 100;
export enum DistributionMessagesKeys {
  Drafts = 'Drafts',
  Outbox = 'Outbox',
  Sent = 'Sent',
  Compose = 'Compose'
}

// This is the ID of the list for ALL contacts
export const ALL_CREW_LIST_ID = '__all';
