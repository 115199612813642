import React, { lazy, Suspense } from 'react';

const LazyBoxLogin = lazy(async () => await import('./BoxLogin'));

const BoxLogin = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyBoxLogin {...props} />
  </Suspense>
);

export default BoxLogin;
