import React, { lazy, Suspense } from 'react';
import { type SidesProps } from './Sides';

const LazySides = lazy(async () => await import('./Sides'));

const Sides = (props: SidesProps) => (
  <Suspense fallback={null}>
    <LazySides {...props} />
  </Suspense>
);

export default Sides;
