import { useEffect } from 'react';
import type { FC } from 'react';
import DocumentTreeService from '../../services/documents/DocumentTreeService';
import type { DropboxAccessObject } from '../../models/Dropbox';

interface Props {
  dropboxPopup: any
  closeDropboxPopup: () => void
  initiateDropbox: boolean
}

const DropboxAuthenticate: FC<Props> = ({ dropboxPopup, closeDropboxPopup, initiateDropbox }: Props) => {
  const parseQueryString = (str: string): DropboxAccessObject => {
    const ret = Object.create(null);
    if (typeof str !== 'string') {
      return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');
    if (!str) {
      return ret;
    }

    str.split('&').forEach(function (param) {
      const parts = param.replace(/\+/g, ' ').split('=');
      let key = parts.shift();
      let val = parts.length > 0 ? parts.join('=') : undefined;

      key = (key) ? decodeURIComponent(key) : '';
      val = (val === undefined) ? undefined : decodeURIComponent(val);
      if (key) {
        if (ret[key] === undefined) {
          ret[key] = val;
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val);
        } else {
          ret[key] = [ret[key], val];
        }
      }
    });

    return ret;
  }

  const getAccessTokenFromUrl = (hash: string): string => {
    return parseQueryString(hash).access_token;
  }

  const getAccessObject = (hash: string): DropboxAccessObject => {
    return parseQueryString(hash);
  }

  const isAuthenticated = (hash: string): boolean => {
    return !!getAccessTokenFromUrl(hash);
  }

  useEffect(() => {
    if (!dropboxPopup) {
      return;
    }
    const timer = setInterval(() => {
      if (!dropboxPopup) {
        clearInterval(timer);
        return;
      }
      try {
        const currentUrl = dropboxPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const hash = new URL(currentUrl).hash;
        if (isAuthenticated(hash)) {
          DocumentTreeService.dropboxRefresh(getAccessObject(hash), initiateDropbox)
            .then(() => {})
            .catch((err) => {
              console.error(err)
            })
            .finally(() => {
              dropboxPopup.close();
              closeDropboxPopup();
              // clear timer at the end
              clearInterval(timer);
            });
        }
      } catch (e) {
        console.error(e);
      }
    }, 500);
  }, [dropboxPopup])
  return (
    <></>
  )
}

export default DropboxAuthenticate;
