import type { Theme } from '@mui/material';
import { MainWhite, BorderBlack, WhiteGrey, LightGrey, LightBlack, BorderGrey, CrooglooGreen, LimeGreen, BlackGrey } from './colors';

export const scrollStyles = {
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    minHeight: '0.5em',
    background: WhiteGrey,
    borderRadius: '50px',
    '&:hover': {
      background: LightGrey,
      cursor: 'pointer'
    },
    '&:active': {
      background: LightBlack,
      cursor: 'pointer'
    }
  },
  '&::-webkit-scrollbar-track-piece:end': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-track-piece:start': {
    background: 'transparent'
  }
};

export const drawerContainerStyles = (
  { theme, width }: { theme: Theme, width: number }
) => ({
  postion: 'relative',
  width,
  '& .MuiBackdrop-root': {
    display: 'none'
  },
  '& .MuiDrawer-paper': {
    zIndex: theme.zIndex.drawer - 1,
    flexShrink: 0,
    width,
    position: 'absolute',
    overflowY: 'hidden',
    top: `${headerHeight}px`, // app bar height
    left: 'auto',
    height: `calc(100vh - ${headerHeight}px)`,
    ...scrollStyles
  }
});

const sideBarClosedButtonPosition: number = 34;
const sideBarOpenedButtonPosition: number = 190;

export const drawerButtonStyles = (
  { open, width, sideBarOpen, showRight }: any
) => {
  const originalButtonPosition: number = (sideBarOpen) ? sideBarOpenedButtonPosition : sideBarClosedButtonPosition;
  const openButtonPosition: number = originalButtonPosition + Number(width);
  return ({
    position: 'absolute',
    left: `${originalButtonPosition}px`,
    color: MainWhite,
    background: BorderBlack,
    borderRadius: '15px 15px 0px 0px',
    width: '115.41px',
    height: '40px',
    transform: (showRight) ? 'rotate(270deg)' : 'rotate(90deg)',
    textTransform: 'capitalize',
    top: `calc(${headerHeight}px + 38px)`,
    '&:hover': {
      backgroundColor: BorderBlack
    },
    ...(open && {
      left: `${openButtonPosition}px`
    })
  })
};

export const paperStyle = {
  display: 'flex',
  alignItems: 'left',
  borderRadius: '5px',
  minHeight: '500px',
  boxShadow: 'none',
  margin: '20px',
  border: `1px solid ${String(BorderGrey)}`
}

export const sliderStyle = {
  '& .MuiSlider-thumb': {
    color: CrooglooGreen
  },
  '& .MuiSlider-track': {
    color: LimeGreen
  },
  '& .MuiSlider-rail': {
    color: '#bcbcbc'
  }
}

export const stepperStyle = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: CrooglooGreen
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: CrooglooGreen
  },
  '& .MuiStepLabel-root .Mui-disabled': {
    color: BlackGrey,
    '& .MuiSvgIcon-root.MuiStepIcon-root': {
      color: BlackGrey
    }
  }
}

export const drawerWidth: number = 332;
export const headerHeight: number = 70;
export const ChipDropDownHeight: number = 65;
export const tabHeaderHeight: number = 55;
export const mainPadding: number = 48;
export const sideBarClosedWidth: number = 64;
export const sideBarOpenedWidth: number = 220;
export const sideBarMarginWidth: number = 8;
export const ComposeToHeight: number = 75;
export const ComposeSubjectHeight: number = 50;
export const ComposeChipHeight: number = 100;

export const defaultCheckboxStyles = {
  '&.Mui-checked': {
    color: CrooglooGreen
  },
  '&.Mui-disabled.Mui-checked': {
    color: BlackGrey
  }
}
