export enum CrooglooUrl {
  DASHBOARD = '/',
  COMPOSE = '/compose',
  LISTS = '/lists',
  SECURITYGROUPS = '/security-groups',
  LOGIN = '/login',
  TEMPLATES = '/templates',
  WATERMARK = '/watermark',
  FILES = '/files',
  UPLOAD = '/upload',
  GENERATESIDES = '/generate-sides',
  CREW = '/crew',
  SENT = '/sent',
  ALLCONTACTS = '/all',
  CAST = '/cast',
  AGENT = '/agent',
  STUDIO = '/studio',
  VENDOR = '/vendor',
  UNION = '/union',
  OUTBOX = '/outbox',
  DRAFT = '/drafts',
  SCRIPTS = '/scripts',
  SIDES = '/sides',
  MANAGEPERMISSIONS = '/manage-permissions',
  ADDCONTACT = '/addContact',
  EDITCONTACT = '/editContact',
  USERSETTINGS = '/user-settings'
}

export const ContactCrooglooUrls: CrooglooUrl[] = [
  CrooglooUrl.ALLCONTACTS,
  CrooglooUrl.CAST,
  CrooglooUrl.AGENT,
  CrooglooUrl.CREW,
  CrooglooUrl.STUDIO,
  CrooglooUrl.VENDOR,
  CrooglooUrl.UNION
];
