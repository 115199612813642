import React, { lazy, Suspense } from 'react';
import type { BoxAuthenticateProps } from './BoxAuthenticate';

const LazyBoxAuthenticate = lazy(async () => await import('./BoxAuthenticate'));

const BoxAuthenticate = (props: BoxAuthenticateProps) => (
  <Suspense fallback={null}>
    <LazyBoxAuthenticate {...props} />
  </Suspense>
);

export default BoxAuthenticate;
