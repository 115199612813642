export const DistributionSlice: string = 'distribution';
export const ModalsSlice: string = 'modals';
export const ContactsSlice: string = 'contacts';
export const DraftSlice: string = 'draft';
export const PersonSlice: string = 'person';
export const PageTitleSlice: string = 'pageTitle';
export const DrawerSlice: string = 'drawers';
export const SecurityAdminSlice: string = 'securityAdmin';
export const DocumentSlice: string = 'documents';
export const TemplatesSlice: string = 'templates';
export const DocumentTreeSlice: string = 'documentTree';
export const UploadFilesSlice: string = 'uploadFiles';
export const SideSlice: string = 'sides';
export const NotificationSlice: string = 'notifications';
export const SideBarSlice: string = 'sidebar';
export const DashboardSlice: string = 'dashboard';
export const GenerateCrewList: string = 'generateCrewList';
export const SubscriptionSlice: string = 'subscription';
