import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Snackbar, AlertTitle, Button } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import type { AlertColor, AlertProps } from '@mui/material/Alert';
import type { SnackbarOrigin } from '@mui/material/Snackbar';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { ToastIcons, ToastAction } from '../../../models/Toast';
import { CrooglooGreen, ToastPink, BorderBlack, LightGrey } from '../../../theme/colors';

const ToastIconsMap: Record<string, React.ReactElement> = {
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  time: <AccessTimeIcon />
}

const ToastColorMap: Record<AlertColor, string> = {
  success: CrooglooGreen,
  info: LightGrey,
  warning: BorderBlack,
  error: ToastPink
}

const useStyles = makeStyles({
  toastAlert: {
    '& .MuiAlert-root': {
      height: 'auto',
      borderRadius: '50px'
    },
    '& .MuiAlertTitle-root': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '20px',
      margin: 0
    },
    '& .MuiAlert-message': {
      textAlign: 'left',
      '& .MuiBox-root': {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.875rem'
      }
    },
    '& .MuiAlert-action': {
      '& .MuiButton-root': {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '20px'
      }
    }
  }
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface Props {
  open: boolean
  onClose: () => void
  message?: string
  title?: string
  type: AlertColor
  icon?: ToastIcons
  toastAction?: ToastAction
}

// TODO: when css/ui mostly done create a toast service & state to handle showing/hiding toasts
const Toast: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [state] = useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'center'
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.open) {
      setIsOpen(props.open);
    }
  }, [props.open])

  const { vertical, horizontal } = state;
  const classes = useStyles();

  let icon: React.ReactElement = <InfoIcon />;
  if (props.icon) {
    icon = ToastIconsMap[props.icon];
  }
  const backgroundColor: string = ToastColorMap[props.type];

  const handleClose = (): void => {
    setIsOpen(false);
    // handle resetting of toast props outside component after closing so changes to content is unseen
    setTimeout(props.onClose, 500);
  }

  return (
    <>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} className={classes.toastAlert}>
        <Alert
          onClose={handleClose}
          icon={icon}
          sx={{
            backgroundColor,
            width: 'auto',
            maxWidth: '700px',
            minWidth: '400px'
          }}
          action={
            (props.toastAction)
              ? <Button
                  color='inherit'
                  size='small'
                  onClick={props.toastAction.action}
                >
                  {props.toastAction.content ? <span>{t(props.toastAction.content)}</span> : <CloseIcon />}
                </Button>
              : <IconButton
                color='inherit'
                size='small'
                onClick={handleClose}
               >
                 <CloseIcon />
               </IconButton>
          }
        >
          {(props.title !== undefined && props.title !== null)
            ? <AlertTitle>
                {t(props.title)}
              </AlertTitle>
            : <></>
          }
          {(props.message !== undefined && props.message !== null) ? t(props.message) : <></>}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Toast;
