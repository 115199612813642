import type { FC, SyntheticEvent } from 'react';
import type { DistributionList, SelectedGroup } from '../../models/DistributionList';
import type { Department } from '../../models/Department';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import { ChipColor, BlackGrey, CrooglooGreen } from '../../theme/colors';

type Color = 'success' | 'warning' | 'seconday' | 'default' | 'primary' | 'info' | undefined;

export const textStyle = {
  textTransform: 'capitalize',
  fontWeight: 400,
  fontSize: '0.95rem',
  whiteSpace: 'inherit'
};

interface Props {
  type: DistributionList | Department
  group: SelectedGroup[]
  handleSelect: (type: DistributionList | Department) => void
}

/**
 * Header Component for lists or departemnts
 * Contains a checkbox allowing the list/department to be added or removed from the template
 */
const Header: FC<Props> = ({ type, group, handleSelect }) => {
  let selectedCount = 0;
  let checked = false;
  let color: Color = 'default';
  let textColor: string = BlackGrey;

  const groups = group.filter(g => g.id === type.id);
  if (groups !== undefined && groups.length > 0) {
    checked = true;
    selectedCount = groups[0].members.length;
  }
  const totalCount = type.members.length;
  if (checked && selectedCount === totalCount) {
    color = 'success';
    textColor = ChipColor;
  }

  const handleClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    handleSelect(type);
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '44px' }}>
        <Checkbox
          checked={checked}
          color={color}
          onClick={handleClick}
          sx={{
            '&.Mui-checked': {
              color: (color === 'success') ? CrooglooGreen : ''
            }
          }}
        />
      </div>
      <Box
        component={'span'}
        sx={{
          color: textColor,
          display: 'flex',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
          <Typography noWrap sx={{ ...textStyle }}>
            {String(type.name)}
          </Typography>
          &nbsp;
          <Typography sx={{ ...textStyle }}>
            {`(${String(selectedCount)}/${String(totalCount)})`}
          </Typography>
      </Box>
    </Box>
  )
}

export default Header;
