import { type Dayjs } from 'dayjs';

export enum Categories {
  Crew = 'crew',
  Cast = 'cast',
  Agent = 'agent',
  Studio = 'studio',
  Union = 'union',
  Vendor = 'vendor'
}

export interface AddContactForm {
  subcategory: Categories
  firstName: string
  lastName: string
  email: string
  email2: string
  email3: string
  mobile: string
  departmentId: string
  departmentName: string
  jobTitle: string
  castNumber: string
  characterName: string
  agency: string
  office: string
  home: string
  address1: string
  locality: string
  state: string
  postalcode: string
  creditName: string
  contactName: string
  contactNumber: string
  allergies: string
  gift: string
  startDate: Dayjs | string | null
  endDate: Dayjs | string | null
  inviteToCroogloo: string
  securitygroup: string
  id: string
  isNewContact: boolean
  includeInDTR: boolean
  receivedSafeSender: string
  welcomeToCroogloo?: string
  deptDisplayOrder?: string
}

export const defaultAddContactValues: AddContactForm = {
  subcategory: Categories.Crew,
  firstName: '',
  lastName: '',
  email: '',
  email2: '',
  email3: '',
  mobile: '',
  departmentId: '',
  departmentName: '',
  jobTitle: '',
  castNumber: '',
  characterName: '',
  agency: '',
  office: '',
  home: '',
  address1: '',
  locality: '',
  state: '',
  postalcode: '',
  creditName: '',
  contactName: '',
  contactNumber: '',
  allergies: '',
  gift: '',
  startDate: null,
  endDate: null,
  inviteToCroogloo: '',
  securitygroup: '',
  id: '',
  isNewContact: false,
  includeInDTR: true,
  receivedSafeSender: 'N'
}

export interface AddContactValidation {
  firstNameSet: boolean
  lastNameSet: boolean
  emailSet: boolean
  departmentSet: boolean
  positionSet: boolean
  characterNameSet: boolean
}

export interface ModifyContactValidation {
  errorFields: string[]
  error: string
}

export interface TargetContact {
  address1: string
  agency: string
  agent: string
  agentEmail: string
  agentMobile: string
  assistant: string
  assistantEmail: string
  category: Categories
  createdBy: string
  createdByUserToken: string
  createdDate: string
  createdDateObj: string
  department: string
  departmentId: string
  departmentName: string
  deptDisplayOrder: string
  displayOrder: string
  displayOrderDepartment: string
  email: string
  email2: string
  email3: string
  endDate: string
  fax: string
  firstName: string
  home: string
  homePhone: string
  id: string
  importMethod: string
  includeInDTR: boolean
  inviteToCroogloo: string
  jobTitle: string
  lastModified: string
  lastName: string
  locality: string
  mobile: string
  officePhone: string
  postalcode: string
  receivedSafeSender: string
  securityGroups: string[]
  startDate: string
  state: string
  subcategory: Categories
  title: string
}

export interface TargetContactResponse {
  properties: TargetContact
  key: {
    kind: string
    name: string
    id: string
  }
  kind: string
}

export interface ExpiredContact {
  id: string
  firstName: string
  lastName: string
}
