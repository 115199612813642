import React, { lazy, Suspense } from 'react';
import type { TemplateWarningProps } from './TemplateWarning';

const LazyTemplateWarning = lazy(async () => await import('./TemplateWarning'));

const TemplateWarning = (props: TemplateWarningProps) => (
  <Suspense fallback={null}>
    <LazyTemplateWarning {...props} />
  </Suspense>
);

export default TemplateWarning;
