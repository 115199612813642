import React, { lazy, Suspense } from 'react';

const LazyTfAuthenticationLogin = lazy(async () => await import('./TFAuthenticationLogin'));

const TfAuthenticationLogin = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyTfAuthenticationLogin {...props} />
  </Suspense>
);

export default TfAuthenticationLogin;
