import { store } from '../../state/store';
import type { ApiDataType } from '../auth/constants';
import { PersonApi } from '../auth/constants';
import AuthService from '../auth/AuthService';
import type {
  WSDepartmentResponse,
  WSPersonAssociationResponse,
  WSPersonEntityResponse,
  WSPersonResponse,
  WSPersonUnavailabilitiesResponse,
  WSResponse,
  WSTargetContactResponse
} from '../../models/WSResponse';
import type { Department, DepartmentResponse } from '../../models/Department';
import type {
  DepartmentPerson,
  Person,
  PersonAssociation,
  PersonAssociationResponse,
  PersonResponse,
  PersonUnavailabilities,
  PersonUnavailabilitiesResponse
} from '../../models/Person';
import { setDepartments } from '../../state/slices/PersonReducer';
import {
  addExpiredContact,
  removeExpiredContact,
  setContactsForPage,
  setExtraMembers,
  setFetchingContacts
} from '../../state/slices/Contacts';
import type { ContactListingsPage } from '../../components/ContactListings/Constants';
import type { AddContactForm, TargetContact, TargetContactResponse } from '../../models/Contacts';
import { Categories } from '../../models/Contacts';
import { DeleteMethod, GetMethod, PatchMethod, PostMethod, PutMethod } from '../constants';
import UtilityService from '../UtilityService';
import NotificationUtility from '../notifications/NotificationUtility';
import type { Notification } from '../../models/Notifications';
import { NotificationType } from '../../models/Notifications';
import type { PersonSecurityListResponse } from '../../models/SecurityList';
import moment from 'moment';
import type { CrewListMetadata } from '../../components/modals/GenerateCrewList/GenerateCrewList';
import { setIsCrewListMetadataSet } from '../../state/slices/GenerateCrewList';

const { dispatch } = store;

export const enum ExpiryActions {
  ExpiryActionNone = 0,
  ExpiryActionDelete = 1,
  ExpiryActionDistributionListMemberships = 2,
  ExpiryActionDone = 3
}

interface IPersonService {
  fetchDepartments: () => void
  fetchPersons: (pageTitle: ContactListingsPage) => void
  fetchContactsBySubcategories: (subCategroies: string) => Promise<Person[]>
  fetchContactAssociations: () => Promise<PersonAssociation[]>
  fetchPersonSecurityLists: (personId: string) => Promise<PersonSecurityListResponse | null>
  addPersonToDepartments: (departments: any[], persons: Person[]) => Department[]
  personResponseToPersons: (items: PersonResponse[]) => Person[]
  getContactToEdit: (contactId: string) => Promise<Person | undefined>
  fetchUnavailabilitiesByContact: (personId: string) => Promise<PersonUnavailabilities[]>
  orderContactsByDepartment: (contacts: Person[]) => DepartmentPerson
  getPotentialAgentsToInclude: (castIds: string[], allRecipientEmails: string[]) => Promise<TargetContact[]>
  fetchContactsFromAssociation: (castIds: string[], personSubCategory: string, targetPersonSubcategory: string) => Promise<TargetContact[]>
  fetchExclusionsByInactivity: (personIds: string[], sendDate: string) => Promise<TargetContact[]>
  fetchExclusionsByInactivity2: (personIds: string[], sendDate: string) => Promise<TargetContact[]>
  editPerson: (personData: any) => Promise<WSResponse>
  saveCrewListMetadata: (crewListMetadata: CrewListMetadata) => Promise<WSResponse>
  generateCrewListPDF: (color: string) => Promise<WSResponse>
  generateCrewListExcel: (color: string) => Promise<WSResponse>
  fetchCrewListPDF: (color: string) => Promise<WSResponse>
  fetchContact: (personId: string) => Promise<Person>
  removeContacts: (contacts: string[]) => Promise<WSResponse | null>
  removeUnavailabilitiesByContact: (payload: any) => Promise<WSResponse | null>
  addUnavailabilitiesByContact: (payload: any) => Promise<WSResponse | null>
  updateCrewRelativeOrder: (payload: any) => Promise<WSResponse | null>
  updateDepartmentRelativeOrder: (payload: any) => Promise<WSResponse | null>
  removePersonAssociation: (personId: string, targetPersonIds: string[]) => Promise<WSResponse | null>
  createPersonAssociation: (personId: string, targetPersonIds: string[], personSubcategory: string, targetPersonSubcategory: string) => Promise<WSResponse | null>
  fetchContacts: () => void
  getExpiringContacts: () => void
  fetchExpiringContacts: (expiryDate: string, showExpired: boolean) => void
  registerExpiryAction: (personId: string, action: ExpiryActions) => Promise<void>
  changeSubcategory: (contacts: string[], category: string) => Promise<void>
  changeDepartment: (contacts: string[], department: string) => Promise<void>
  inviteToCroogloo: (personId: string[], securityListId: string) => Promise<void>
}

const TargetCategories: string[] = [Categories.Agent, Categories.Cast, Categories.Crew, Categories.Studio, Categories.Vendor, Categories.Union];

class PersonService implements IPersonService {
  /**
   * gets the departments from the backend
   */
  fetchDepartments (): void {
    makePersonCall('fetchDepartments', {})
      .then((result: WSDepartmentResponse) => {
        if (result.items !== null) {
          const items: DepartmentResponse[] = result.items;
          items.forEach((item: DepartmentResponse) => {
            item.properties = { ...item.properties, name: item.properties.departmentName };
          });
          dispatch(setDepartments({ departments: items }));
        } else {
          console.debug('Departments Not Found');
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  async editPerson (personData: AddContactForm): Promise<WSResponse> {
    return await makePersonCall('editPerson', {
      body: JSON.stringify(personData),
      type: PutMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err: Error) => {
      throw new Error(err.message);
    });
  }

  async saveCrewListMetadata (crewListMetadata: CrewListMetadata): Promise<WSResponse> {
    return await makePersonCall('saveCrewListMetadata', {
      body: JSON.stringify(crewListMetadata),
      type: PostMethod
    }).then((result: WSResponse) => {
      dispatch(setIsCrewListMetadataSet({ isSet: true }));
      return result;
    }).catch((err: Error) => {
      throw new Error(err.message);
    });
  }

  async generateCrewListPDF (color: string): Promise<WSResponse> {
    return await makePersonCall('generateCrewListPDF', {
      params: { color },
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err: Error) => {
      throw new Error(err.message);
    });
  }

  async generateCrewListExcel (color: string): Promise<WSResponse> {
    return await makePersonCall('generateCrewListExcel', {
      params: { color },
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err: Error) => {
      throw new Error(err.message);
    });
  }

  async fetchCrewListPDF (color: string): Promise<WSResponse> {
    return await makePersonCall('fetchCrewListPDF', {
      params: { color },
      type: GetMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err: Error) => {
      throw new Error(err.message);
    });
  }

  /**
   * fetch the persons from the backend for the contact pages
   */
  fetchPersons (pageTitle: ContactListingsPage): void {
    dispatch(setFetchingContacts({ fetching: true }));
    const pageTitleLower: string = pageTitle.toLowerCase();
    const subCategories: string[] = getSubCategories(pageTitleLower);
    const subCategoriesString: string = (checkAllContacts(pageTitleLower)) ? '*' : subCategories.join(',');
    const promises: any[] = [this.fetchContactsBySubcategories(subCategoriesString)];
    if (subCategories.includes('agent')) promises.push(this.fetchContactAssociations());
    Promise.all(promises)
      .then((resp: any) => {
        const contactEntities: Person[] = resp[0];
        // const relationshipEntities: PersonAssociation[] = resp[1] ?? []; TODO: use this if nescarry like the old code
        if (Array.isArray(contactEntities) && contactEntities.length > 0) {
          contactEntities.filter((contact: Person) => contact.firstName !== '');
          let contactEntitiesToDisplay: Person[] = contactEntities.filter(contactEntity => contactEntity.subcategory !== undefined && TargetCategories.includes(contactEntity.subcategory));

          if (pageTitle === 'Cast') {
            contactEntitiesToDisplay = contactEntitiesToDisplay.sort((a, b) => {
              return (a.castNumber ? a.castNumber : '').localeCompare(b.castNumber ? b.castNumber : '', 'en', { numeric: true });
            });
          } else {
            contactEntitiesToDisplay = contactEntitiesToDisplay.sort((a, b) => {
              return a.firstName.localeCompare(b.firstName);
            });
          }
          dispatch(setContactsForPage({
            key: pageTitle,
            contacts: contactEntitiesToDisplay
          }));
        } else {
          console.error('invalid server response retrieving contacts');
        }
        dispatch(setFetchingContacts({ fetching: false }));
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * fetch the persons/contacts from the backend for the contact pages passed in subcategories
   */
  async fetchContactsBySubcategories (subcategories: string): Promise<Person[]> {
    return await makePersonCall('fetchContactsBySubcategories', {
      params: {
        subcategories
      }
    }).then((result: WSPersonResponse) => {
      if (result.items !== null) {
        const items: PersonResponse[] = result.items;

        return this.personResponseToPersons(items);
      } else {
        return [];
      }
    })
  }

  /**
   * get the associoations for the persons from the backend for the contact pages
   */
  async fetchContactAssociations (): Promise<PersonAssociation[]> {
    return await makePersonCall('fetchContactAssociations', {})
      .then((result: WSPersonAssociationResponse) => {
        if (result.items !== null) {
          const items: PersonAssociationResponse[] = result.items;
          const associations: PersonAssociation[] = [];
          items.forEach((association: PersonAssociationResponse) => {
            const newAssociation: PersonAssociation = {
              personId1: association.properties.personId1,
              personId2: association.properties.personId2,
              personSubcategory1: association.properties.personSubcategory1,
              personSubcategory2: association.properties.personSubcategory2
            }
            associations.push(newAssociation);
          });
          return associations;
        } else {
          return [];
        }
      })
  }

  async fetchPersonSecurityLists (personId: string): Promise<PersonSecurityListResponse | null> {
    return await makePersonCall('fetchPersonSecurityLists', {
      params: { personId }
    })
      .then((result: PersonSecurityListResponse) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  addPersonToDepartments (departments: any[], persons: Person[]): Department[] {
    const departmentList: Department[] = [];
    // Loops through the deparments and gets their members in the form of a Person model
    departments.forEach((dept) => {
      if (typeof dept.key.name === 'string' && dept.key.name.trim() !== '') {
        const departmentPersons = persons.filter(p => p.departmentId === dept.key.name);
        const members: Person[] = [];
        departmentPersons.forEach(person => {
          if (person !== undefined && person !== null) {
            const id = (person.departmentId !== undefined && person.departmentId !== null && person.id !== undefined && person.id !== null) ? `${String(person.departmentId)}_${String(person.id)}` : '';
            members.push({
              id,
              firstName: (person.firstName !== null && person.firstName !== undefined) ? person.firstName : '',
              lastName: (person.lastName !== null && person.lastName !== undefined) ? person.lastName : '',
              name: person.name ?? '',
              jobTitle: (person.jobTitle !== null && person.jobTitle !== undefined) ? person.jobTitle : '',
              email: (person.email !== null && person.email !== undefined) ? person.email : '',
              inviteToCroogloo: (person.inviteToCroogloo !== null && person.inviteToCroogloo !== undefined) ? person.inviteToCroogloo : ''
            })
          }
        });
        if (members.length > 0) {
          departmentList.push({
            ...dept.properties,
            members
          });
        }
      }
    });
    return departmentList;
  }

  /**
   * covert a response from the backend getting persons properties to a frontend person object
   */
  personResponseToPersons (items: PersonResponse[]): Person[] {
    const persons: Person[] = [];
    if (items.length > 0) {
      items.forEach((person: PersonResponse) => {
        const newPerson: Person = {
          email: (person.properties.email !== undefined && person.properties.email !== null) ? person.properties.email : '',
          firstName: (person.properties.firstName !== undefined && person.properties.firstName !== null) ? person.properties.firstName : '',
          lastName: (person.properties.lastName !== undefined && person.properties.lastName !== null) ? person.properties.lastName : '',
          name: person.key.name ?? '',
          jobTitle: (person.properties.jobTitle !== undefined && person.properties.jobTitle !== null) ? person.properties.jobTitle : '',
          id: (person.key.name !== undefined && person.key.name !== null) ? person.key.name : '',
          personId: (person.key.name !== undefined && person.key.name !== null) ? person.key.name : '',
          departmentId: person.properties?.departmentId ?? '',
          departmentName: person.properties.departmentName ?? '',
          subcategory: person.properties?.subcategory ?? Categories.Crew,
          mobile: person.properties?.mobile ?? '',
          distributionLists: person.properties?.distributionLists ?? '',
          castNumber: person.properties.castNumber ?? '',
          agency: person.properties.agency ?? '',
          email2: person.properties.email2 ?? '',
          email3: person.properties.email3 ?? '',
          officePhone: person.properties.officePhone ?? '',
          homePhone: person.properties.homePhone ?? '',
          address1: person.properties.address1 ?? '',
          locality: person.properties.locality ?? '',
          state: person.properties.state ?? '',
          postalcode: person.properties.postalcode ?? '',
          creditName: person.properties.creditName ?? '',
          contactName: person.properties.contactName ?? '',
          contactNumber: person.properties.contactNumber ?? '',
          allergies: person.properties.allergies ?? '',
          gift: person.properties.gift ?? '',
          startDate: person.properties.startDate ?? '',
          endDate: person.properties.endDate ?? '',
          inviteToCroogloo: person.properties.inviteToCroogloo ?? '',
          createdByUserToken: person.properties.createdByUserToken ?? '',
          createdDateObj: person.properties.createdDateObj ?? '',
          emergencyContact_name: person.properties.emergencyContact_name ?? '',
          emergencyContactPhoneNb: person.properties.emergencyContactPhoneNb ?? '',
          foodAllergies: person.properties.foodAllergies ?? '',
          importMethod: person.properties.importMethod ?? '',
          nameOnCredit: person.properties.nameOnCredit ?? '',
          agent: person.properties.agent ?? '',
          relativeOrder: person.properties.relativeOrder ?? 0,
          securityGroup: person.properties.securityGroup ?? ''
        }
        persons.push(newPerson);
      });
    }
    return persons;
  }

  /**
   * Editing a contact on one of the contacts pages
   * Checks to see if the contact/person exists for the current page
   */
  async getContactToEdit (contactId: string): Promise<Person | undefined> {
    let contactToEdit: Person | undefined;
    const pageTitle: ContactListingsPage | null = store.getState().contacts.currentPage;
    if (pageTitle) {
      const contacts: Person[] = store.getState().contacts.pageContacts[pageTitle];
      const contact: Person | undefined = contacts.find((person: Person) => person.id === contactId);
      if (contact) {
        contactToEdit = { ...contact };
        contactToEdit.unavailabilities = await this.fetchUnavailabilitiesByContact(contactId);
      }
    }
    return contactToEdit;
  }

  async fetchUnavailabilitiesByContact (personId: string): Promise<PersonUnavailabilities[]> {
    const unvailabilities: PersonUnavailabilities[] = [];
    return await makePersonCall('fetchUnavailabilitiesByContact', {
      params: { personId },
      type: PostMethod
    }).then((result: WSPersonUnavailabilitiesResponse) => {
      if (result.items !== null) {
        const items: PersonUnavailabilitiesResponse[] = result.items;
        items.forEach((item: PersonUnavailabilitiesResponse) => {
          unvailabilities.push({
            end: item.properties.end,
            start: item.properties.start
          });
        });
      }
      return unvailabilities;
    });
  }

  async removeContacts (contacts: string[]): Promise<WSResponse | null> {
    return await makePersonCall('removeContacts', {
      body: JSON.stringify({
        value: contacts.join(',')
      }),
      type: DeleteMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.log(err);
      return null;
    })
  }

  async removeUnavailabilitiesByContact (payload: any): Promise<WSResponse | null> {
    return await makePersonCall('removeUnavailabilitiesByContact', {
      body: JSON.stringify(payload),
      type: DeleteMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.log(err);
      return null;
    })
  }

  async addUnavailabilitiesByContact (payload: any): Promise<WSResponse | null> {
    return await makePersonCall('addUnavailabilitiesByContact', {
      body: JSON.stringify(payload),
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch((err) => {
      console.log(err);
      return null;
    })
  }

  async updateCrewRelativeOrder (payload: any): Promise<WSResponse | null> {
    return await makePersonCall('updateCrewRelativeOrder', {
      body: JSON.stringify(payload),
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch(() => {
      throw new Error('Error saving crew relative order');
    })
  }

  async updateDepartmentRelativeOrder (payload: any): Promise<WSResponse | null> {
    return await makePersonCall('updateDepartmentRelativeOrder', {
      body: JSON.stringify(payload),
      type: PostMethod
    }).then((result: WSResponse) => {
      return result;
    }).catch(() => {
      throw new Error('Error saving department relative order');
    })
  }

  orderContactsByDepartment (contacts: Person[]): DepartmentPerson {
    let departmentContacts: DepartmentPerson = {};
    contacts.forEach((contact: Person) => {
      if (contact.departmentName) {
        if (contact.departmentName in departmentContacts) {
          departmentContacts[contact.departmentName].push(contact);
        } else {
          departmentContacts = {
            ...departmentContacts,
            [contact.departmentName]: [contact]
          };
        }
      }
    });
    return departmentContacts;
  }

  async fetchContact (personId: string): Promise<Person> {
    return await makePersonCall('fetchContact', {
      params: {
        personId
      }
    })
      .then((result: WSPersonEntityResponse) => {
        const personResponse: PersonResponse = result.entity
        const person: Person = personResponse.properties;
        person.personId = personResponse.key.name;
        return person;
      });
  }

  async getPotentialAgentsToInclude (castIds: string[], allRecipientEmails: string[]): Promise<TargetContact[]> {
    const potentialAgents: TargetContact[] = [];
    return await makePersonCall('fetchContactsFromAssociation', {
      params: {
        personIds: castIds.join(','),
        personSubcategory: Categories.Cast,
        targetPersonSubcategory: Categories.Agent
      }
    })
      .then((result: WSTargetContactResponse) => {
        if (result.items !== null) {
          const items: TargetContactResponse[] = result.items;
          const agentItems: TargetContactResponse[] = items.filter(
            (item: TargetContactResponse) => typeof item.properties.email === 'string' &&
            !allRecipientEmails.includes(item.properties.email)
          );
          if (agentItems.length > 0) {
            agentItems.forEach((agentItem: TargetContactResponse) => {
              const potentialAgent: TargetContact = getTargetContact(agentItem);
              potentialAgents.push(potentialAgent);
            })
          }
        }
        return potentialAgents;
      }).catch((err) => {
        console.error(err);
        return [];
      })
  }

  async fetchContactsFromAssociation (castIds: string[], personSubCategory: string, targetPersonSubCategory: string): Promise<TargetContact[]> {
    let resultItems: TargetContact[] = [];
    return await makePersonCall('fetchContactsFromAssociation', {
      params: {
        personIds: castIds.join(','),
        personSubcategory: personSubCategory,
        targetPersonSubcategory: targetPersonSubCategory
      }
    })
      .then((result: WSTargetContactResponse) => {
        if (result.items !== null) {
          resultItems = result.items.map(item => item.properties);
        }
        return resultItems;
      }).catch((err) => {
        console.error(err);
        return [];
      })
  }

  async removePersonAssociation (personId: string, targetPersonIds: string[]): Promise<WSResponse | null> {
    return await makePersonCall('removePersonAssociation', {
      type: DeleteMethod,
      params: {
        personId,
        targetPersonIds: targetPersonIds.join(',')
      }
    })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        console.error(err);
        return null;
      })
  }

  async createPersonAssociation (personId: string, targetPersonIds: string[], personSubcategory: string, targetPersonSubcategory: string): Promise<WSResponse | null> {
    return await makePersonCall('createPersonAssociation', {
      type: PostMethod,
      params: {
        personId,
        targetPersonIds: targetPersonIds.join(','),
        personSubcategory,
        targetPersonSubcategory
      }
    })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        console.error(err);
        return null;
      })
  }

  async fetchExclusionsByInactivity (personIds: string[], sendDate: string): Promise<TargetContact[]> {
    const inactives: TargetContact[] = [];
    return await makePersonCall('fetchExclusionsByInactivity', {
      params: {
        personIds,
        sendDate
      }
    }).then((result: WSTargetContactResponse) => {
      if (result?.items !== null) {
        const items: TargetContactResponse[] = result.items;
        items.forEach((contact: TargetContactResponse) => {
          const inactiveContact: TargetContact = getTargetContact(contact);
          inactives.push(inactiveContact);
        });
      }
      return inactives;
    }).catch((err) => {
      console.error(err);
      return [];
    });
  }

  async fetchExclusionsByInactivity2 (personIds: string[], sendDate: string): Promise<TargetContact[]> {
    const inactives: TargetContact[] = [];
    const body = JSON.stringify({
      personIds: personIds,
      sendDate: sendDate
    })
    return await AuthService.apiPost(PersonApi, 'fetchExclusionsByInactivity2', body
    ).then((result: WSTargetContactResponse) => {
      if (result?.items !== null) {
        const items: TargetContactResponse[] = result.items;
        items.forEach((contact: TargetContactResponse) => {
          const inactiveContact: TargetContact = getTargetContact(contact);
          inactives.push(inactiveContact);
        });
      }
      return inactives;
    }).catch((err) => {
      console.error(err);
      return [];
    });
  }

  fetchContacts (): void {
    makePersonCall('fetchContacts', {})
      .then((resp: WSPersonResponse) => {
        let contacts: Person[] = [];
        if (resp.items !== null) {
          const items: PersonResponse[] = resp.items;
          contacts = this.personResponseToPersons(items).filter((person: Person) => person.id !== '');
        }
        contacts.sort((a: Person, b: Person) => {
          return UtilityService.compareStrings(a.firstName, b.firstName) || UtilityService.compareStrings(a.lastName, b.lastName)
        });
        dispatch(setExtraMembers({ contacts }));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getExpiringContacts (): void {
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
    const threeWeeksLater = moment().add(3, 'weeks').format('YYYY-MM-DD');
    this.fetchExpiringContacts(tomorrow, true);
    this.fetchExpiringContacts(threeWeeksLater, false);
    this.fetchExpiringContacts(yesterday, true);
  }

  fetchExpiringContacts (expiryDate: string, showExpired: boolean): void {
    const today = moment().format('YYYY-MM-DD');
    makePersonCall('fetchExpiredContacts', {
      params: { expiryDate }
    })
      .then((resp: WSPersonResponse) => {
        const notifications: Notification[] = store.getState().notifications.notifications;
        if (resp.items.length > 0) {
          const uniqueId: string = UtilityService.generateUniqueId(expiryDate);
          let items: PersonResponse[] = resp.items;
          items = items.filter((contact: PersonResponse) => {
            const expiryAction: boolean = !!(contact.properties.expiryAction);
            const ActionAlreadyTaken: boolean = (expiryAction && contact.properties.expiryAction !== String(ExpiryActions.ExpiryActionNone));
            if (showExpired) {
              return !ActionAlreadyTaken;
            } else {
              return !moment(contact.properties.endDate).isBefore(today) && !ActionAlreadyTaken;
            }
          });
          if (items.length > 0) {
            items.forEach((contact: PersonResponse) => {
              dispatch(addExpiredContact({
                contact: {
                  id: contact.key.name,
                  firstName: contact.properties.firstName,
                  lastName: contact.properties.lastName
                }
              }));
            })
            if (!(notifications.some((notification: Notification) => notification.type === NotificationType.ExpiredContact))) {
              NotificationUtility.addNotification(uniqueId, '', moment().valueOf(), NotificationType.ExpiredContact, true)
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  async registerExpiryAction (personId: string, action: ExpiryActions): Promise<void> {
    await makePersonCall('registerExpiryAction', {
      params: {
        personIds: personId,
        expiryAction: String(action)
      },
      type: PostMethod
    })
      .then((resp: WSResponse) => {
        if (UtilityService.isSuccessResponse(resp.responseCode)) {
          dispatch(removeExpiredContact({ contactId: personId }))
        } else {
          throw new Error('notifications.contact.modal.error');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('notifications.contact.modal.error');
      });
  }

  async changeSubcategory (contacts: string[], category: string): Promise<void> {
    await makePersonCall('changeSubcategory', {
      body: JSON.stringify({
        personIDs: contacts.join(','),
        newSubcategory: category
      }),
      type: PatchMethod
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('contacts.changeCategory.error.server');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err.message);
      });
  }

  async changeDepartment (contacts: string[], department: string): Promise<void> {
    await makePersonCall('changeDepartment', {
      body: JSON.stringify({
        personIDs: contacts.join(','),
        newDepartmentID: department
      }),
      type: PostMethod
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('contacts.changeDepartment.error.server');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err.message);
      });
  }

  async inviteToCroogloo (personId: string[], securityListId: string): Promise<void> {
    await makePersonCall('inviteToCroogloo', {
      body: JSON.stringify({
        personIdList: personId,
        securityListId
      }),
      type: PostMethod
    })
      .then((resp: WSResponse) => {
        if (!UtilityService.isSuccessResponse(resp.responseCode)) {
          throw new Error('Error inviting contact');
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err.message);
      });
  }
}

/**
 * Get the person categories needed for current contacts page
 * @param pageTitle {string} page to get persons for
 */
const getSubCategories = (pageTitle: string): string[] => {
  let subCategories: string[] = TargetCategories;
  if (!checkAllContacts(pageTitle)) {
    subCategories = subCategories.filter((category: string) => category === pageTitle);
    if (subCategories.includes(Categories.Agent) && !!subCategories.includes(Categories.Cast)) {
      subCategories.push(Categories.Cast); // required for cast number mapping
    }
  }
  return subCategories;
}

/**
 * check if page is contacts or all contacts
 * @param pageTitle
 */
const checkAllContacts = (pageTitle: string): boolean => {
  return ['all contacts', 'contacts'].includes(pageTitle);
}

const getTargetContact = (item: TargetContactResponse): TargetContact => {
  return {
    address1: item.properties.address1 ?? '',
    agency: item.properties.agency ?? '',
    agent: item.properties.agent ?? '',
    agentEmail: item.properties.agentEmail ?? '',
    agentMobile: item.properties.agentMobile ?? '',
    assistant: item.properties.assistant ?? '',
    assistantEmail: item.properties.assistantEmail ?? '',
    category: item.properties.category ?? '',
    createdBy: item.properties.createdBy ?? '',
    createdByUserToken: item.properties.createdByUserToken ?? '',
    createdDate: item.properties.createdDate ?? '',
    createdDateObj: item.properties.createdDateObj ?? '',
    department: item.properties.department ?? '',
    departmentId: item.properties.departmentId ?? '',
    departmentName: item.properties.departmentName ?? '',
    deptDisplayOrder: item.properties.deptDisplayOrder ?? '',
    displayOrder: item.properties.displayOrder ?? '',
    displayOrderDepartment: item.properties.displayOrderDepartment ?? '',
    email: item.properties.email ?? '',
    email2: item.properties.email2 ?? '',
    email3: item.properties.email3 ?? '',
    endDate: item.properties.endDate ?? '',
    fax: item.properties.fax ?? '',
    firstName: item.properties.firstName ?? '',
    home: item.properties.home ?? '',
    homePhone: item.properties.homePhone ?? '',
    id: item.properties.id ?? '',
    importMethod: item.properties.importMethod ?? '',
    includeInDTR: item.properties.includeInDTR,
    inviteToCroogloo: item.properties.inviteToCroogloo ?? '',
    jobTitle: item.properties.jobTitle ?? '',
    lastModified: item.properties.lastModified ?? '',
    lastName: item.properties.lastName ?? '',
    locality: item.properties.locality ?? '',
    mobile: item.properties.mobile ?? '',
    officePhone: item.properties.officePhone ?? '',
    postalcode: item.properties.postalcode ?? '',
    receivedSafeSender: item.properties.receivedSafeSender ?? '',
    securityGroups: (item.properties.securityGroups?.length > 0) ? item.properties.securityGroups : [],
    startDate: item.properties.startDate ?? '',
    state: item.properties.state ?? '',
    subcategory: item.properties.subcategory ?? '',
    title: item.properties.title ?? ''
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makePersonCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(PersonApi, method, data);
  return result;
}

const personService: IPersonService = new PersonService();
export default personService;
