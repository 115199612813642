export enum NotificationType {
  Watermark = 'watermark',
  Download = 'download',
  Upload = 'upload',
  ExpiredContact = 'expiredContact',
  VCard = 'vCard'
}

export interface ServerNotification {
  is_browser_notif_sent?: boolean
  is_visible: boolean
  type: string
  reference_id: string
  time_created: string
  is_user_notified: false
}

export interface NotificationResponse {
  items: ServerNotification[]
}

export enum NotificationStatus {
  Failed = -1,
  Error = 0,
  Initializing = 1,
  Processing = 2,
  Finalizing = 3,
  Complete = 4,
}

export interface Notification {
  referenceId: string
  title: string
  timeCreated: number
  type: NotificationType
  url?: string
  progress: number
  status: NotificationStatus
}

export interface NotificationUpdateData {
  referenceId: string
  title?: string
  timeCreated?: number
  type?: NotificationType
  url?: string
  progress?: number
  status?: NotificationStatus
}

export enum UploadStatus {
  Initializing = 0,
  Complete = 1,
  Failed = 2
}

export interface UploadBox {
  fileId: string
  fileName: string
  progress: number
  status: UploadStatus
}

export interface UploadBoxUpdateData {
  fileId: string
  fileName?: string
  progress?: number
  status?: UploadStatus
}
