import NotificationService from './NotificationService';
import type { NotificationResponse, NotificationUpdateData, ServerNotification } from '../../models/Notifications';
import { NotificationStatus, NotificationType } from '../../models/Notifications';
import ScriptService from '../ScriptService';
import type { WSResponse } from '../../models/WSResponse';
import NotificationUtility from './NotificationUtility';
import UtilityService from '../UtilityService';

interface IWatermarkNotifications {
  load: () => void
  add: (isUserNotified: boolean, referenceId: string, fileName: string | null, timeCreated: number) => void
  build: (title: string, referenceId: string, timeCreated: number) => void
  trackProgress: (referenceId: string, builtNotification: boolean, isUserNotified: boolean, timeCreated: number) => void
}

class WatermarkNotifications implements IWatermarkNotifications {
  load (): void {
    NotificationService.fetchVisibleNotifications(NotificationType.Watermark)
      .then((resp: NotificationResponse) => {
        if (Array.isArray(resp.items)) {
          const items: ServerNotification[] = resp.items;
          items.forEach((notification: ServerNotification) => {
            this.add(notification.is_user_notified, notification.reference_id, null, parseInt(notification.time_created));
          });
        } else {
          throw new Error('No notifications gotten from server');
        }
      })
      .catch((err) => {
        console.error('Failed to load watermark notifications.', err);
      })
  }

  add (isUserNotified: boolean, referenceId: string, fileName: string | null, timeCreated: number): void {
    const builtNotification: boolean = !!(fileName);
    if (fileName) {
      this.build(fileName, referenceId, timeCreated);
    }
    this.trackProgress(referenceId, builtNotification, isUserNotified, timeCreated);
  }

  build (title: string, referenceId: string, timeCreated: number): void {
    NotificationUtility.addNotification(referenceId, title, timeCreated, NotificationType.Watermark, false);
  }

  trackProgress (referenceId: string, builtNotification: boolean, isUserNotified: boolean, timeCreated: number): void {
    let notificationHasBeenBuilt: boolean = builtNotification;
    const progressTracker = setInterval(() => {
      checkProgress();
    }, 2000);

    const checkProgress = (): void => {
      console.debug('checking progress');
      const FailedTaskErrMsg: string = 'failed task';
      ScriptService.fetchWatermarkPrinterStatus(referenceId)
        .then((resp: WSResponse) => {
          if (!notificationHasBeenBuilt && resp.contentName) {
            notificationHasBeenBuilt = true;
            const fileName: string = resp.contentName.substring(resp.contentName.startsWith('wp_') ? 3 : 0);
            this.build(fileName, referenceId, timeCreated);
          }
          if (resp.responseCode === '-1') {
            throw new Error(FailedTaskErrMsg);
          } else {
            const data: NotificationUpdateData = {
              referenceId,
              progress: Number(resp.responseCode),
              status: NotificationStatus.Processing
            };
            if (resp.responseCode === '100') {
              data.status = NotificationStatus.Complete;
              clearInterval(progressTracker);
              if (resp.contentURL) {
                data.url = resp.contentURL;
              }
              if (!isUserNotified) {
                NotificationUtility.addCounter();
              }
            } else {
              if (UtilityService.isSuccessResponse(resp.responseCode)) {
                data.status = NotificationStatus.Initializing;
              } else if (resp.responseCode === '99') {
                data.status = NotificationStatus.Finalizing;
              }
            }
            NotificationUtility.updateNotificationData(data);
          }
        })
        .catch((err) => {
          console.error(err);
          clearInterval(progressTracker);
          const isProcessFailed: boolean = err?.message === FailedTaskErrMsg;
          if (notificationHasBeenBuilt) {
            const data: NotificationUpdateData = {
              referenceId,
              status: (isProcessFailed) ? NotificationStatus.Failed : NotificationStatus.Error
            };
            NotificationUtility.updateNotificationData(data);
            if (!isUserNotified) {
              NotificationUtility.addCounter();
            }
          }
        })
    }
  }
}

const watermarkNotifications: IWatermarkNotifications = new WatermarkNotifications();
export default watermarkNotifications;
