import type { FC } from 'react';
import type { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Box, TextField, InputLabel } from '@mui/material';
import SuccessButton from '../../common/buttons/SuccessButton';
import { BorderBlack, LightBlack, WhiteGrey, ErrorRed } from '../../../theme/colors';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import dialogStyles from '../../../theme/dialog.module.scss';
import { datePickerTheme } from '../../../theme/datePickers';

const useStyles = makeStyles({
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      color: LightBlack,
      '&:hover': {
        background: WhiteGrey,
        border: `1px solid ${BorderBlack}`
      },
      '&.Mui-focused': {
        border: `1px solid ${BorderBlack}`,
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
      },
      '& fieldset': {
        border: 'none',
        height: 'inherit'
      }
    }
  },
  textFieldFilled: {
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${LightBlack} !important`
    }
  },
  textFieldEmpty: {
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${ErrorRed} !important`
    }
  }
});

interface Props {
  dateValue: Dayjs | null
  timeValue: Dayjs | null
  setDateValue: (date: Dayjs | null) => void
  setTimeValue: (time: Dayjs | null) => void
  onSubmit: () => void
  errorValue: string
}

/**
 * Scedule Date Picker Component - step 1
 * TODO: create a resuable common form component for the date picker if to be anywhere else
 */
const ScheduleDatePicker: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const classes = useStyles();

  return (
    <ThemeProvider theme={datePickerTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Box component={'h2'}>
              {t('common.labels.dateTime')}
            </Box>
          </Grid>
          {
            (!!props.errorValue) &&
            <Grid item>
              <Box
                component={'span'}
                className={dialogStyles.errorField}
              >
                {t(props.errorValue)}
              </Box>
            </Grid>
          }
          <Grid container item xs={12} sx={{ paddingLeft: '0px' }}>
            <Grid item xs={12} sm={8}>
              <StaticDatePicker
                displayStaticWrapperAs='desktop'
                value={props.dateValue}
                onChange={(newValue) => {
                  props.setDateValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid container item xs={12} sm={4} spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              <Grid item sx={{ textAlign: 'left' }}>
                <InputLabel id={'Date'}>
                  {t('common.fields.date')}
                </InputLabel>
                <DatePicker
                  value={props.dateValue}
                  onChange={(newValue) => {
                    props.setDateValue(newValue);
                  }}
                  disableOpenPicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={
                        `${classes.textField} ${(props.dateValue?.isValid() && !props.errorValue) ? classes.textFieldFilled : classes.textFieldEmpty}`
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ textAlign: 'left' }}>
                <InputLabel id={'Time'}>
                  {t('common.fields.time')}
                </InputLabel>
                <TimePicker
                  value={props.timeValue}
                  onChange={(newValue) => {
                    props.setTimeValue(newValue);
                  }}
                  ampm={false}
                  disableOpenPicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={
                        `${classes.textField} ${(props.timeValue?.isValid() && !props.errorValue) ? classes.textFieldFilled : classes.textFieldEmpty}`
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ textAlign: 'right' }}>
                <SuccessButton disableRipple onClick={props.onSubmit}>{t('compose.form.action.scheduleSend')}</SuccessButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default ScheduleDatePicker;
