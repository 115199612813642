import SecurityAdminService from './SecurityAdmin';
import type { ComboValue, ComboValues, ComboValueParameters } from '../models/ComboValues';
import type { SecurityListResponse } from '../models/SecurityList';
import type { SecurityGroup, RestrictredMember, SecurityGroupMembers } from '../models/SecurityGroups';
import type { SecurityListMembers, SecurityListMember, DepartmentsSecurityListMembers, DepartmentMembers } from '../models/SecurityListMembers';
import { setSecurityGroups, setSecurityGroupMembers } from '../state/slices/SecurityAdmin';
import type { DepartmentResponse, Department, DepartmentList } from '../models/Department';
import { defaultComboParams } from '../components/SecurityGroups/SecurityGroups';
import ModalService from './ModalService';
import { ConfirmAccessLossModal } from '../components/modals/ConfirmAccessLoss/constants';
import { store } from '../state/store';
import { SecurityListIds, DHDisplayName } from '../models/SecurityList';
import UtilityService from './UtilityService';
const { dispatch } = store;

const sortingList: string[] = [SecurityListIds.ADMIN, DHDisplayName, SecurityListIds.CREW, SecurityListIds.EXEC];

interface ISecurityGroupsService {
  getSecurityGroups: (params: ComboValueParameters) => Promise<void>
  fetchSecurityListMembers: (selectedGroup: string) => Promise<void>
  formatSecurityListMembersToDepartments: (securityGroupMembers: SecurityGroupMembers, departments: DepartmentResponse[]) => DepartmentsSecurityListMembers
  addGroup: (name: string) => Promise<string>
  removeGroup: (selectedGroup: SecurityGroup) => Promise<string>
  addSecurityListMembers: (persons: string[], securityListId: string) => Promise<string>
  removeSecurityListMembers: (persons: string[], securityListId: string) => Promise<string>
}

class SecurityGroupsService implements ISecurityGroupsService {
  /**
   * get security groups
   */
  async getSecurityGroups (params: ComboValueParameters): Promise<void> {
    const response: ComboValues = await SecurityAdminService.getComboValues(params);
    if (response.items?.length > 0) {
      const items: ComboValue[] = response.items;
      items.sort((a: ComboValue, b: ComboValue) => sortingList.indexOf(a.label) - sortingList.indexOf(b.label));
      dispatch(setSecurityGroups({
        securityGroups: items.map((item: ComboValue) => ({
          ...item,
          label: (item.label.toUpperCase() === SecurityListIds.DH) ? DHDisplayName : item.label.toUpperCase()
        }))
      }));
    }
  }

  /**
   * get security list members
   */
  async fetchSecurityListMembers (selectedGroup: string): Promise<void> {
    const response: SecurityListMembers = await SecurityAdminService.fetchSecurityListMembers(selectedGroup);

    if (response.items?.length > 0) {
      const items: SecurityListMember[] = response.items;
      dispatch(setSecurityGroupMembers({ securityGroup: selectedGroup, members: items }));
    }
  }

  /**
   * add Members to the departments they belong to for Security lists table
   * sort into non members and members depending if selected or not
   */
  formatSecurityListMembersToDepartments (securityGroupMembers: SecurityGroupMembers, departments: DepartmentResponse[]): DepartmentsSecurityListMembers {
    const members: DepartmentsSecurityListMembers = {
      groupMembers: {},
      nonMembers: {}
    };

    const departmentList: DepartmentList = {};
    departments.forEach((department: DepartmentResponse) => {
      departmentList[department.properties.id] = { ...department.properties };
    });

    const groupMembers: DepartmentMembers = sortMembersByDepartment(securityGroupMembers.members.filter((secListMember: SecurityListMember) => secListMember.selected), departmentList);
    const nonMembers: DepartmentMembers = sortMembersByDepartment(securityGroupMembers.members.filter((secListMember: SecurityListMember) => !secListMember.selected), departmentList);
    members.groupMembers = groupMembers;
    members.nonMembers = nonMembers;
    return members;
  }

  /**
   * add New Security Group
   */
  async addGroup (name: string): Promise<string> {
    return await new Promise((resolve, reject) => {
      if (!name) {
        reject(new Error('securityGroup.addGroup.errorEmpty'));
      } else if (/^\d/.exec(name)) {
        reject(new Error('securityGroup.addGroup.errorDigit'));
      } else if (name.toLowerCase() === 'admin') {
        reject(new Error('securityGroup.addGroup.errorAdmin'));
      } else {
        SecurityAdminService.addSecurityList(name)
          .then(async (resp: SecurityListResponse) => {
            if (UtilityService.isSuccessResponse(resp.responseCode)) {
              await this.getSecurityGroups(defaultComboParams)
                .then(() => {
                  resolve('securityGroup.addGroup.success');
                })
            } else {
              reject(new Error('securityGroup.addGroup.serverError'));
            }
          }).catch((err) => {
            console.error(err);
            reject(new Error('securityGroup.addGroup.serverError'));
          });
      }
    });
  }

  /**
   * Remove Security Group
   */
  async removeGroup (selectedGroup: SecurityGroup): Promise<string> {
    const groupId: string = selectedGroup.value;
    return await new Promise((resolve, reject) => {
      if (groupId.toLowerCase() === 'admin') {
        reject(new Error('securityGroup.delete.errorAdmin'));
      } else {
        SecurityAdminService.removeSecurityList(groupId)
          .then(async (resp: SecurityListResponse) => {
            if (UtilityService.isSuccessResponse(resp.responseCode)) {
              await this.getSecurityGroups(defaultComboParams)
                .then(() => {
                  resolve('securityGroup.delete.success');
                })
            } else {
              reject(new Error('securityGroup.delete.serverError'));
            }
          }).catch((err) => {
            console.error(err);
            reject(new Error('securityGroup.delete.serverError'));
          });
      }
    });
  }

  /**
   * Add selected members to security list chosen
   */
  async addSecurityListMembers (persons: string[], securityListId: string): Promise<string> {
    const restrictedMember: RestrictredMember = store.getState().securityAdmin.restrictedMember;
    if (
      restrictedMember.activeUserPersonId &&
      restrictedMember.activeUserSecurityListId === SecurityListIds.ADMIN &&
      securityListId !== SecurityListIds.ADMIN &&
      persons.includes(restrictedMember.activeUserPersonId)
    ) {
      if (!await confirmAccessLoss(true)) {
        throw new Error('cancel');
      }
    }
    return await new Promise((resolve, reject) => {
      SecurityAdminService.addSecurityListMember(persons, securityListId)
        .then(async (resp: SecurityListResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            await this.fetchSecurityListMembers(securityListId)
              .then(() => {
                const result: string = (persons.length > 1) ? 'securityGroup.confirmLoss.add.successMore' : 'securityGroup.confirmLoss.add.success';
                resolve(result);
              })
          } else {
            reject(new Error('securityGroup.confirmLoss.serverError'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('securityGroup.confirmLoss.serverError'));
        })
    });
  }

  /**
   * Remove selected members from security list chosen
   */
  async removeSecurityListMembers (persons: string[], securityListId: string): Promise<string> {
    const restrictedMember: RestrictredMember = store.getState().securityAdmin.restrictedMember;
    if (restrictedMember.activeUserPersonId && persons.includes(restrictedMember.activeUserPersonId)) {
      if (!await confirmAccessLoss(false)) {
        throw new Error('cancel');
      }
    }
    return await new Promise((resolve, reject) => {
      SecurityAdminService.removeSecurityListMember(persons, securityListId)
        .then(async (resp: SecurityListResponse) => {
          if (UtilityService.isSuccessResponse(resp.responseCode)) {
            await this.fetchSecurityListMembers(securityListId)
              .then(() => {
                const result: string = (persons.length > 1) ? 'securityGroup.confirmLoss.remove.successMore' : 'securityGroup.confirmLoss.remove.success';
                resolve(result);
              })
          } else {
            reject(new Error('securityGroup.confirmLoss.serverError'));
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error('securityGroup.confirmLoss.serverError'));
        })
    });
  }
}

const sortMembersByDepartment = (members: SecurityListMember[], departmentList: DepartmentList): DepartmentMembers => {
  const departmentMembers: DepartmentMembers = {};
  members.forEach((member: SecurityListMember) => {
    const department: Department = departmentList[member.departmentId];
    if (departmentMembers[department.name]) {
      departmentMembers[department.name] = [
        ...departmentMembers[department.name],
        member
      ];
    } else {
      departmentMembers[department.name] = [member];
    }
  });
  return departmentMembers;
}

const confirmAccessLoss = async (isPartialRemoval: boolean): Promise<boolean> => {
  const confirm: boolean = await new Promise((resolve) => {
    ModalService.openCustomModal(
      ConfirmAccessLossModal,
      {
        heading: 'securityGroup.confirmLoss.heading',
        content: (isPartialRemoval) ? 'securityGroup.confirmLoss.content.admin' : 'securityGroup.confirmLoss.content.list',
        confirmButton: 'action.yes',
        callback: () => resolve(true),
        cancel: () => resolve(false)
      }
    )
  })
  return confirm;
}

const securityGroupsService: ISecurityGroupsService = new SecurityGroupsService();
export default securityGroupsService;
