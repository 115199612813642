/*
 * Reducer file for the Subscription slice
 */

import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionSlice } from './constants';
import type { Subscription } from '../../models/Subscription';
import { Currencies } from '../../models/Subscription';

interface SubscriptionState {
  productList: Subscription[]
  currentPlan: Subscription | null
  currency: Currencies
  stripePublicKey: string | null
}

const initialState: SubscriptionState = {
  productList: [],
  currentPlan: null,
  currency: Currencies.USDollars,
  stripePublicKey: null
}

export const subscriptionSlice = createSlice({
  name: SubscriptionSlice,
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload.productList;
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload.plan;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload.currency;
    },
    setStripePublicKey: (state, action) => {
      state.stripePublicKey = action.payload.key;
    }
  }
});

export const { setProductList, setCurrentPlan, setCurrency, setStripePublicKey } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
