import React, { lazy, Suspense } from 'react';

const LazyCancelNotAvailable = lazy(async () => await import('./CancelNotAvailable'));

const CancelNotAvailable = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyCancelNotAvailable {...props} />
  </Suspense>
);

export default CancelNotAvailable;
