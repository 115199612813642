import React, { useRef, useEffect } from 'react';
import type { FC } from 'react';
import styles from './Upload.module.scss';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import FileUpload from '../fileUpload/FileUpload';
import personService from '../../services/person/PersonService';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import { FileTypes } from '../../models/FileTypes';

export enum UploadPage {
  Title = 'Upload'
}

export interface Props {
  pageTitle: UploadPage
}

/**
 * Upload component to show for upload page
 * Contains the common file upload component to handle uploading files
 */
const Upload: FC<Props> = ({ pageTitle }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    personService.fetchDepartments();
  }, [pageTitle])

  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    fileInputRef.current?.click();
  };

  return (
   <Box component={'div'} className={styles.Upload} data-testid="Upload">
     <FileUpload fileInputRef={fileInputRef} fileType={FileTypes.Template}>
       <Grid
         container
         item
         className={styles.uploadBox}
         onClick={handleUploadClick}
       >
         <Grid item>
           <Box component={'span'} className={styles.uploadLabel}>
            {t('upload.uploadLabel')}
           </Box>
         </Grid>
       </Grid>
     </FileUpload>
   </Box>
  )
};

export default Upload;
