import type { FC } from 'react';
import { Grid, Box, DialogContent } from '@mui/material';
import { BorderBlack } from '../../theme/colors';
import BoldContent from '../common/BoldContent/BoldContent.lazy';
import dialogStyles from '../../theme/dialog.module.scss';
import type { ModalIcons } from './ModalIcon/ModalIcon';
import ModalIcon from './ModalIcon/ModalIcon.lazy';

interface Props {
  heading?: string
  content?: string
  variableMap?: Record<string, string>
  shouldShowInfoIcon?: boolean
  iconType?: ModalIcons
}
/**
 * Reusable Dialog Modal Content component
 * contains content of popup
 */
const ModalContent: FC<Props> = (props: Props) => {
  return (
    <DialogContent>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='stretch'
        spacing={2}
      >
        {props.shouldShowInfoIcon !== false && <ModalIcon iconType={props.iconType} />}
        {
          (props.heading !== null && props.heading !== undefined && props.heading !== '')
            ? <Grid item className={dialogStyles.headerGrid}>
                <Box component={'span'} sx={{ color: BorderBlack, fontSize: '1.4rem', fontWeight: 700, lineBreak: 'anywhere', textTransform: 'capitalize' }}>
                  {props.heading}
                </Box>
              </Grid>
            : <></>
        }
        {
          (props.content !== null && props.content !== undefined && props.content !== '')
            ? <Grid item>
                <Box component={'span'} className={dialogStyles.contentText}>
                  <BoldContent content={props.content} variableMap={props.variableMap ?? {}} />
                </Box>
              </Grid>
            : <></>
        }
      </Grid>
    </DialogContent>
  )
}

export default ModalContent;
