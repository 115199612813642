import type { FC } from 'react';
import type { SelectedGroup } from '../../models/DistributionList';
import { Grid, Box, Stack } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import Chip from '../common/buttons/Chip';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { MainBlack, BorderGrey, BlackGrey } from '../../theme/colors';
import scrollBarStyle from '../../theme/sidebar.module.scss';
import DraftService from '../../services/draft/DraftService';
import { ComposeToHeight } from '../../theme/styles';

interface ChipProps {
  groups: SelectedGroup[] | string[]
  onDelete: (groupId: string) => void
  deleteDisabled: boolean
  showMemberNumber: boolean
}

const GroupChips: FC<ChipProps> = (props: ChipProps) => {
  return (
    <>
      {props.groups.map((group: SelectedGroup | string) => {
        let itemId: string, itemLabel: string;
        if (typeof group === 'string') {
          itemId = group;
          itemLabel = group;
        } else {
          itemId = group.id;
          itemLabel = `${String(group.name)}`;
          itemLabel += (props.showMemberNumber) ? ` (${String(group.members.length)}/${String(group.total)})` : '';
        }
        return (
          <Chip
            key={itemId}
            label={itemLabel}
            variant="outlined"
            onClick={() => {
              if (!props.deleteDisabled) {
                props.onDelete(itemId)
              }
            }}
            onDelete={() => {
              if (!props.deleteDisabled) {
                props.onDelete(itemId)
              }
            }}
            deleteDisabled={props.deleteDisabled}
          />
        )
      })}
    </>
  );
}

interface Props {
  groupList: SelectedGroup[]
  groupDepartment: SelectedGroup[]
  selectedExtraEmails: string[]
  deleteDisabled: boolean
  sx?: SxProps<Theme>
  toggleDrawer: () => void
  showMembersCount: boolean
}

/**
 * Compose T0 Component used for the compose page
 * Shows who the will be receiving the message
 * Handles the ui of the to section
 * TODO: scroll css is reused in drawer - look into handling this so not repeated
 */
const ComposeTo: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const handleRemoveDistributionGroup = (groupId: string): void => {
    DraftService.handleRemoveDistributionGroup(groupId);
  }

  const handleRemoveDepartmentGroup = (groupId: string): void => {
    DraftService.handleRemoveDepartmentGroup(groupId);
  }

  const handleRemoveExtraEmails = (email: string): void => {
    DraftService.handleRemoveExtraEmail(email);
  }

  return (
    <Grid item container
      sx={{
        textAlign: 'left',
        borderBottom: `1px solid ${String(BorderGrey)}`,
        paddingLeft: (props.deleteDisabled) ? '16px' : '0px',
        paddingTop: (props.deleteDisabled) ? '26px !important' : '16px'
      }}
      onClick={props.toggleDrawer}
    >
      <Grid item container xs={1} sx={{ maxWidth: '95px !important' }}>
        <Box
          component='span'
          sx={{
            marginTop: '10px',
            color: (props.deleteDisabled) ? BlackGrey : MainBlack,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '20px',
            textTransform: 'capitalize'
          }}
        >
          {t('compose.form.labels.to')}
        </Box>
      </Grid>
      <Grid item xs={11} container className={scrollBarStyle.scrollbar}
        sx={{
          height: `${ComposeToHeight}px`
        }}
      >
        <Stack direction='row' spacing={1} flexWrap={'wrap'}>
          <GroupChips groups={props.groupList} onDelete={handleRemoveDistributionGroup} deleteDisabled={props.deleteDisabled} showMemberNumber={props.showMembersCount} />
          <GroupChips groups={props.groupDepartment} onDelete={handleRemoveDepartmentGroup} deleteDisabled={props.deleteDisabled} showMemberNumber={props.showMembersCount} />
          <GroupChips groups={props.selectedExtraEmails} onDelete={handleRemoveExtraEmails} deleteDisabled={props.deleteDisabled} showMemberNumber={props.showMembersCount} />
        </Stack>
      </Grid>
    </Grid>
  )
};

export default ComposeTo;
