import type { FC } from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import type { Anchor } from '../../models/Anchor';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import DrawerButton from './DrawerButton';
import DrawerContainer from './DrawerContainer';
import type { RootState } from '../../state/store';
import { useSelector } from 'react-redux';

interface Props {
  drawerWidth: number
  open: boolean
  anchor: Anchor
  toggleDrawer: () => void
  buttonText?: string
  children?: React.ReactNode
}

/**
 * Slide Drawer Component for lists and departemnts
 * Used in the compose page & distribution history and for Scripts / Sides
 * Children passed in from parent component and placed inside the drawer container
 */
const SlideDrawer: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const buttonText: string = (props.buttonText !== null && props.buttonText !== undefined) ? t(props.buttonText) : t('slideDrawer.buttonText');

  const isSideBarOpen: boolean = useSelector((state: RootState) => state.sideBar.isSideBarOpen);

  return (
    <Box>
      <DrawerButton
        open={props.open}
        width={props.drawerWidth}
        onClick={props.toggleDrawer}
        endIcon={props.open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        sideBarOpen={isSideBarOpen}
      >
        {buttonText}
      </DrawerButton>
      <DrawerContainer
        width={props.drawerWidth}
        variant='persistent'
        sx={{
          flexShrink: 0
        }}
        open={props.open}
        anchor={props.anchor}
      >
        {props.children}
      </DrawerContainer>
    </Box>
  )
}

export default SlideDrawer;
