import React, { lazy, Suspense } from 'react';
import type { VCardDetailsProps } from './vCardDetails';

const LazyVCardDetails = lazy(async () => await import('./vCardDetails'));

const VCardDetails = (props: VCardDetailsProps) => (
  <Suspense fallback={null}>
    <LazyVCardDetails {...props} />
  </Suspense>
);

export default VCardDetails;
