import React, { lazy, Suspense } from 'react';
import type { PasswordSettingsTabPanelContentProps } from './PasswordSettingsTabPanelContent';

const LazyPasswordSettingsTabPanelContent = lazy(async () => await import('./PasswordSettingsTabPanelContent'));

const PasswordSettingsTabPanelContent = (props: PasswordSettingsTabPanelContentProps) => (
  <Suspense fallback={null}>
    <LazyPasswordSettingsTabPanelContent {...props} />
  </Suspense>
);

export default PasswordSettingsTabPanelContent;
