import React, { lazy, Suspense } from 'react';
import type { NotificationMenuProps } from './NotificationMenu';

const LazyNotificationMenu = lazy(async () => await import('./NotificationMenu'));

const NotificationMenu = (props: NotificationMenuProps) => (
  <Suspense fallback={null}>
    <LazyNotificationMenu {...props} />
  </Suspense>
);

export default NotificationMenu;
