import React, { lazy, Suspense } from 'react';
import type { TabHeadersProps } from './TabHeaders';

const LazyTabHeaders = lazy(async () => await import('./TabHeaders'));

const TabHeaders = (props: TabHeadersProps) => (
  <Suspense fallback={null}>
    <LazyTabHeaders {...props} />
  </Suspense>
);

export default TabHeaders;
