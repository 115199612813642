import React, { lazy, Suspense } from 'react';
import type { UserSettingsProps } from './UserSettings';

const LazyUserSettings = lazy(async () => await import('./UserSettings'));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const UserSettings = (props: UserSettingsProps) => (
  <Suspense fallback={null}>
    <LazyUserSettings {...props} />
  </Suspense>
);

export default UserSettings;
