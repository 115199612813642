import React from 'react';
import type { FC } from 'react';
import styles from './GeneralModal.module.scss';
import dialogStyles from '../../../theme/dialog.module.scss';
import ModalService from '../../../services/ModalService';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle/ModalTitle.lazy';
import TertiaryButton from '../../common/buttons/TertiaryButton';
import SuccessButton from '../../common/buttons/SuccessButton';
import type { ModalIcons } from '../ModalIcon/ModalIcon';
import { Dialog, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export interface GeneralModalProps {
  modalKey: string
  isVisible: boolean
  handleConfirm?: (event: React.SyntheticEvent) => Promise<void>
  handleCancel?: () => Promise<void>
  title: string
  heading?: string
  content?: string
  confirmButton?: string
  buttons?: string
  width?: number
  cancelButton?: string
  isCancelButton?: boolean
  contentVariable?: any
  iconType?: ModalIcons
  hideCloseButton?: boolean
}

/**
 * Reusable General Dialog Popup component
 * Calls ModalTitle component for title
 * calls ModalContent for content of popup
 * buttons to cancel and proceed at bottom
 */
const GeneralModal: FC<GeneralModalProps> = (props: GeneralModalProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const handleClose = () => {
    ModalService.closeCustomModal(props.modalKey);
  }

  const handleCancel = () => {
    if (props.handleCancel !== undefined) {
      props.handleCancel().then(() => {
        handleClose();
      }).catch(() => {
        handleClose();
      })
    } else {
      handleClose();
    }
  }

  const handleConfirm = (event: React.SyntheticEvent): void => {
    if (props.handleConfirm !== undefined) {
      props.handleConfirm(event).then(() => {
        handleClose();
      }).catch(() => {
        handleClose();
      })
    } else {
      handleClose();
    }
  }

  return (
    <Dialog
      data-testid="GeneralModal"
      open={props.isVisible}
      onClose={handleCancel}
      classes={{
        root: `${dialogStyles.dialogRoot} ${styles.GeneralModal}`,
        paper: `${dialogStyles.dialogPaper}`
      }}
      sx={{
        textAlign: 'center',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: (props.width) ? `${String(props.width)}px` : '300px'
          }
        }
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
    >
      <ModalTitle handleClose={handleCancel} title={props.title} hideCloseButton={props.hideCloseButton}/>
      <ModalContent
        heading={(props.heading) ? String(t(props.heading)) : ''}
        content={(props.content) ? String(t(props.content)) : ''}
        variableMap={props.contentVariable ?? {}}
        iconType={props.iconType}
      />
      <DialogActions className={(props.buttons && props.buttons === 'end') ? dialogStyles.dialogActionsEnd : dialogStyles.dialogActionsCenter}>
        {props.isCancelButton !== false && <TertiaryButton
            disableRipple
            className={dialogStyles.cancelButton}
            onClick={handleCancel}
        >
          {(props.cancelButton) ? t(props.cancelButton) : t('action.cancel')}
        </TertiaryButton>}
        <SuccessButton disableRipple onClick={handleConfirm}>{(props.confirmButton) ? t(props.confirmButton) : ''}</SuccessButton>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralModal;
