import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainWhite, CrooglooGreen, DarkerCroolooGreen } from '../../../theme/colors';

/**
 * Custom success button component with custom style using material ui button component
 */
const SuccessButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  backgroundColor: CrooglooGreen,
  color: MainWhite,
  padding: '8px 22px',
  boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '50px',
  '&:hover': { background: DarkerCroolooGreen, color: MainWhite }
}));

export default SuccessButton;
