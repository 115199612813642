import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import TabPanel from '../slideDrawer/TabPanel';
import GroupsTabItem from './GroupsTabItem';
import type { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import type { SecurityGroup } from '../../models/SecurityGroups';
import NewGroup from '../modals/NewGroup/NewGroup.lazy';
import styles from './SecurityGroups.module.scss';
import scrollStyles from '../../theme/sidebar.module.scss';
import TertiaryButton from '../common/buttons/TertiaryButton';
import { CrooglooGreen } from '../../theme/colors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation, type UseTranslationResponse } from 'react-i18next';
import ModalService from '../../services/ModalService';
import { NewGroupModal } from '../modals/NewGroup/constants';
import { SecurityListIds } from '../../models/SecurityList';

export interface DrawerTabsProps {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  selectedGroup: string
  setSelectedGroup: (group: string) => void
}

const addGroup = (): void => {
  ModalService.openCustomModal(
    NewGroupModal,
    {
      heading: 'securityGroup.addGroup.heading',
      content: 'securityGroup.securityGroupName',
      confirmButton: 'action.create'
    }
  );
}

/**
 * Drawer Tabs Component for the Slide Drawer Component containing lists and departemnts
 * TODO: look into moving Tabs and Tab component into resusable component - like TabPanel
 */
const GroupsDrawerTabs: FC<DrawerTabsProps> = (props: DrawerTabsProps) => {
  const [groupList, setGroupList] = useState<SecurityGroup[]>([]);
  const securityGroups: SecurityGroup[] = useSelector((state: RootState) => state.securityAdmin.securityGroups);

  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  useEffect(() => {
    let newGroupList: SecurityGroup[] = [];
    if (securityGroups.length > 0) {
      const adminGroup = securityGroups.find(item => item.value === SecurityListIds.ADMIN);
      if (adminGroup) {
        newGroupList = [adminGroup, ...securityGroups.filter(item => item.value !== SecurityListIds.ADMIN)];
      }
      if (!props.selectedGroup) {
        props.setSelectedGroup(newGroupList[0].value);
      }
    }
    setGroupList(newGroupList);
  }, [securityGroups]);

  return (
    <>
      <TabPanel value={props.tab} index={0}>
        <List className={`${scrollStyles.scrollbar} ${styles.groupList}`}>
          { groupList.map((group: SecurityGroup) => (
            <GroupsTabItem
              key={group.value}
              item={group.label}
              handleSelect={() => { props.setSelectedGroup(group.value); }}
              isSelected={group.value === props.selectedGroup}
            />
          ))}
          <div style={{ padding: '5px' }}>
            <TertiaryButton disableRipple hovercolor={CrooglooGreen} textcolor={CrooglooGreen} onClick={addGroup} startIcon={<AddCircleOutlineIcon />} style={{ float: 'left' }}>
              {t('securityGroup.newGroup')}
            </TertiaryButton>
          </div>
        </List>
      </TabPanel>
      <NewGroup setSelectedGroup={props.setSelectedGroup}/>
    </>
  )
}

export default GroupsDrawerTabs;
