import { createSlice } from '@reduxjs/toolkit';
import { TemplatesSlice } from './constants';

const initialState = {
  toastOpen: false,
  operationStatus: 'success',
  operationMessage: '',
  operationCompleted: true
}

export const templatesSlice = createSlice({
  name: TemplatesSlice,
  initialState,
  reducers: {
    setTemplatesOperationStatus: (state, action) => {
      state.toastOpen = action.payload.toastOpen;
      state.operationStatus = action.payload.operationStatus;
      state.operationMessage = action.payload.operationMessage;
      state.operationCompleted = action.payload.operationCompleted;
    },
    setTemplateOperationCompleted: (state, action) => {
      state.operationCompleted = action.payload.operationCompleted;
    }
  }
});

export const { setTemplatesOperationStatus, setTemplateOperationCompleted } = templatesSlice.actions;
export default templatesSlice.reducer;
