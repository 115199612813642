export interface ViewableDocument {
  id: string
  name: string
  isWatermarked: boolean
}

export const ErrorMessage: string = 'Error getting document from backend';

export const PreloadedFolderDepth = {
  DROPBOX: 1,
  GOOGLEDRIVE: 1,
  CROOGLOO: 2,
  BOX: 1
}

export const TreeRoot: string = 'ROOT';
export const DropBoxRoot: string = 'DROPBOX_ROOT';
export const BoxRoot: string = 'BOX_ROOT';
export const GoogleDriveRoot: string = 'GDRIVE_ROOT';
export const PublishToStudioFolderName: string = 'Publish To Studio';
