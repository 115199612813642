import React, { lazy, Suspense } from 'react';

const LazyHandleInactivity = lazy(async () => await import('./HandleInactivity'));

const HandleInactivity = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyHandleInactivity {...props} />
  </Suspense>
);

export default HandleInactivity;
