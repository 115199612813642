import React from 'react';
import type { FC } from 'react';
import styles from './CallTime.module.scss';
import { t } from 'i18next';

export interface CallTimeProps {
  callTime: string
  productionName: string
  callDate: string
}

/**
 * Component showing top part of dashboard (Welcome, production name and call time if available)
 */
const CallTime: FC<CallTimeProps> = ({ callTime, productionName, callDate }: CallTimeProps) => {
  return (
    <div className={`${styles.callTime}`}>
      {(productionName) && <div className={styles.productionName}>{productionName}</div>}
      {(callTime) && <div className={styles.welcomeMessage}>
        {t('dashboard.callTime.callTime', { time: callTime })}
        {(callDate) ? t('dashboard.callTime.date', { date: callDate }) : ''}
      </div>
      }
    </div>
  );
}

export default CallTime;
