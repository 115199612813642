import React, { lazy, Suspense } from 'react';

const LazyPdfViewer = lazy(async () => await import('./PdfViewer'));

const PdfViewer = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyPdfViewer {...props} />
  </Suspense>
);

export default PdfViewer;
