import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { List, Drawer, ListItemButton, ListItemIcon } from '@mui/material';
import classes from './SideBarButton.module.scss';
import SideBarButton from './SideBarButton';
import { LightGrey, MainBlack, SideBarColor } from '../../theme/colors';
import { styled, type Theme, type CSSObject } from '@mui/material/styles';
import { ReactComponent as Contact } from '../../assets/icons/contact.svg';
import { ReactComponent as Croogloo } from '../../assets/icons/croogloo.svg';
import { ReactComponent as Distribution } from '../../assets/icons/distribution.svg';
import { ReactComponent as Document } from '../../assets/icons/document.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Script } from '../../assets/icons/script.svg';
import { ReactComponent as Security } from '../../assets/icons/security.svg';
import { ReactComponent as Template } from '../../assets/icons/template.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { SideNavTitles, DashboardSideNav } from '../../models/SideNav';
import type { SideNavItem } from '../../models/SideNav';
import type { CrooglooUrl } from '../../models/CrooglooUrl';
import type { RootState } from '../../state/store';
import SideNavService from '../../services/SideNavService';
import { sideBarClosedWidth, sideBarOpenedWidth } from '../../theme/styles';
import { useSelector } from 'react-redux';

const AutoExpand: boolean = true;

const drawerScrollBarTheme = createTheme({
  components: {
    // Name of the component
    MuiDrawer: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          overflow: 'auto',
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '*::-webkit-scrollbar-thumb': {
            minHeight: '0.5em',
            background: '#F2F0F0',
            borderRadius: '50px',
            '*:hover': {
              background: LightGrey,
              cursor: 'pointer'
            },
            '*:active': {
              background: MainBlack,
              opacity: '0.5',
              cursor: 'pointer'
            }
          },
          '*::-webkit-scrollbar-track-piece:start': {
            background: 'transparent'
          },
          '*::-webkit-scrollbar-track-piece:end': {
            background: 'transparent'
          }
        }
      }
    }
  }
});

// Mixin to style the drawer when it is open
const openedMixin = (theme: Theme): CSSObject => ({
  width: sideBarOpenedWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  backgroundColor: SideBarColor
});

// Mixin to style the drawer when it is closed
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: sideBarClosedWidth,
  backgroundColor: SideBarColor
});

interface Props {
  isSideBarOpen: boolean
  forceSetSideBarState?: (forceSet?: boolean) => void
}

// Using styled components to style the drawer
const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isSideBarOpen'
})<Props>(({ theme, isSideBarOpen }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(isSideBarOpen && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!isSideBarOpen && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

// This is the navigational side bar component
const SideBar: FC<Props> = ({ isSideBarOpen, forceSetSideBarState }: Props) => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<SideNavItem>(DashboardSideNav);

  const stateSelectedItem: SideNavItem = useSelector((state: RootState) => state.sideBar.selectedItem);

  useEffect(() => {
    const currentUrl: CrooglooUrl = location.pathname as CrooglooUrl;
    SideNavService.changeSideBarItem(currentUrl);
  }, []);

  useEffect(() => {
    if (stateSelectedItem?.id) {
      setSelectedItem(stateSelectedItem);
    }
  }, [stateSelectedItem]);

  const setDashboardItem = (): void => {
    SideNavService.setSideBarItem(DashboardSideNav);
  }

  return (
    <StyledDrawer
      className={classes.SideBar}
      // onMouseOver={() => { if (forceSetSideBarState) forceSetSideBarState(true); }}
      // onMouseOut={() => { if (forceSetSideBarState) forceSetSideBarState(false); }}
      variant="permanent"
      isSideBarOpen={isSideBarOpen}
      theme={drawerScrollBarTheme}
    >
      <List component="nav"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: sideBarOpenedWidth,
          gap: '5px',
          paddingTop: '18px',
          paddingBottom: '16px',
          ...((isSideBarOpen ?? false) && {
            paddingLeft: '8px'
          }),
          ...(!(isSideBarOpen ?? false) && {
            width: sideBarClosedWidth,
            alignItems: 'center'
          })
        }}
      >
        <ListItemButton disableRipple className={classes.SideBarButton}>
          <ListItemIcon className={classes.SideBarIcon}>
            {isSideBarOpen
              ? <Close onClick={() => { if (forceSetSideBarState) forceSetSideBarState(false); }} />
              : <Croogloo onClick={() => { if (forceSetSideBarState) forceSetSideBarState(true); }}/>
            }
          </ListItemIcon>
        </ListItemButton>
        <NavLink to="/" style={{ textDecoration: 'none' }} onClick={setDashboardItem}>
          <SideBarButton
            title={SideNavTitles.DASHBOARD}
            isSideBarOpen={isSideBarOpen}
            isDashboard={true}
            selectedItem={selectedItem}
          >
            <Home />
          </SideBarButton>
        </NavLink>
        <SideBarButton
          title={SideNavTitles.DISTRIBUTION}
          isSideBarOpen={isSideBarOpen}
          forceSetSideBarState={forceSetSideBarState}
          autoExpand={AutoExpand}
          selectedItem={selectedItem}
        >
          <Distribution />
        </SideBarButton>
        <SideBarButton
            title={SideNavTitles.CONTACTS}
            isSideBarOpen={isSideBarOpen}
            forceSetSideBarState={forceSetSideBarState}
            autoExpand={AutoExpand}
            selectedItem={selectedItem}
        >
          <Contact />
        </SideBarButton>
        <SideBarButton
          title={SideNavTitles.SCRIPTS}
          isSideBarOpen={isSideBarOpen}
          forceSetSideBarState={forceSetSideBarState}
          autoExpand={AutoExpand}
          selectedItem={selectedItem}
        >
          <Script />
        </SideBarButton>
        <SideBarButton
          title={SideNavTitles.DOCUMENTS}
          isSideBarOpen={isSideBarOpen}
          forceSetSideBarState={forceSetSideBarState}
          autoExpand={AutoExpand}
          selectedItem={selectedItem}
        >
          <Document />
        </SideBarButton>
        {/* Photos link disabled until the page gets implemented */}
        {/* <NavLink to="/photos" style={{ textDecoration: 'none' }}>
          <SideBarButton title={'Photos'} isSideBarOpen={isSideBarOpen} >
            <Photo />
          </SideBarButton>
        </NavLink> */}
        <SideBarButton
          title={SideNavTitles.TEMPLATES}
          isSideBarOpen={isSideBarOpen}
          forceSetSideBarState={forceSetSideBarState}
          autoExpand={AutoExpand}
          selectedItem={selectedItem}
        >
          <Template />
        </SideBarButton>
        <SideBarButton
          title={SideNavTitles.SECURITY}
          isSideBarOpen={isSideBarOpen}
          forceSetSideBarState={forceSetSideBarState}
          autoExpand={AutoExpand}
          selectedItem={selectedItem}
        >
          <Security />
        </SideBarButton>
      </List>
    </StyledDrawer>
  )
}

export default SideBar;
