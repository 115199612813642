import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useState, useEffect } from 'react';
import type { StepModel } from './StepsModel';
import steps, { UploadStepIndex } from './StepsModel';
import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styles from './UploadSteps.module.scss';
import FileDestination from './steps/FileDestination/FileDestination.lazy';
import FileInformation from './steps/FileInformation/FileInformation.lazy';
import FileWatermark from './steps/FileWatermark/FileWatermark.lazy';
import ScriptInformation from './steps/ScriptInformation/ScriptInformation.lazy';
import type { FileTypes } from '../../../models/FileTypes';
import type { UploadFileForm } from './UploadFlow';
import type { DirectoryDestination, OtherFileAttachment } from '../../../models/Document';
import type { WatermarkValues } from '../../../models/Upload';
import UploadFileType from './steps/UploadFileType/UploadFileType.lazy';
import type { RootState } from '../../../state/store';
import type { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'react-redux';

const getCurrentStep = (currentStepIndex: number): StepModel => steps[currentStepIndex] ?? steps[UploadStepIndex.FileInformation];
const StepperSteps: number[] = [1, 2, 3];

interface Props {
  currentStepIndex: number
  stepper?: boolean
  submitRequested?: boolean
  onSubmit?: (data: WatermarkValues) => void
  control: Control<UploadFileForm>
  getValues: UseFormGetValues<UploadFileForm>
  setValue: UseFormSetValue<UploadFileForm>
  selectedDestinations: DirectoryDestination[]
  selectDestination: (folder: DirectoryDestination) => void
  fileType: FileTypes | null
  setSelectedFileType: Dispatch<SetStateAction<FileTypes | null>>
}

/**
 * Uplaod Steps Component for the upload popup flow
 * Contains a material ui stepper component with the step icons
 * shows the component for each step and other information (title & message)
 */
const UploadSteps: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const currentFileNumber: number = useSelector((state: RootState) => state.uploadFiles.currentFileNumber);
  const numberOfFiles: number = useSelector((state: RootState) => state.uploadFiles.numberOfFilesInProgress);
  const changedFileName: string | null = useSelector((state: RootState) => state.uploadFiles.changedFileName);
  const currentFile: OtherFileAttachment | null = useSelector((state: RootState) => state.uploadFiles.currentFile);

  const [message, setMessage] = useState<string>('');

  const currentStep: StepModel = getCurrentStep(props.currentStepIndex);
  const currentStepperIndex: number = getStepperNumberFromIndex(props.currentStepIndex);
  let title: string = t(currentStep.title);
  if (numberOfFiles > 1) {
    title += ` (${currentFileNumber} ${t('common.labels.of')} ${numberOfFiles})`;
  }

  useEffect(() => {
    let stepMessage: string = '';
    if ([UploadStepIndex.FileInformation, UploadStepIndex.FileType].includes(props.currentStepIndex)) {
      stepMessage = (changedFileName) ? String(changedFileName) : ((currentFile?.name) ? currentFile.name : '')
    } else {
      stepMessage = t(currentStep.message);
    }
    setMessage(stepMessage);
  }, [props.currentStepIndex]);

  return (
    <div className={styles.UploadSteps}>
      {(props.stepper) && <Grid item className={styles.stepperGrid}>
        <Box className={styles.stepper}>
          <Stepper activeStep={currentStepperIndex} alternativeLabel>
            {StepperSteps.map((step: number) => {
              return (
                <Step key={step}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: styles.stepIcon,
                        completed: styles.completedStepIcon,
                        active: styles.activeStepIcon
                      }
                    }}
                  />
                </Step>
              )
            })}
          </Stepper>
        </Box>
      </Grid>}
      <Grid item>
        <Box component={'h2'} className={styles.stepTitle}>
          {title}
        </Box>
      </Grid>
      <Grid item>
        <Box component={'span'} className={styles.stepMessage}>
          {message}
        </Box>
      </Grid>
      <Grid item className={styles.stepComponent}>
        {(props.currentStepIndex === UploadStepIndex.FileType) &&
          <UploadFileType
            key={'step-0'}
            control={props.control}
            getValues={props.getValues}
            setValue={props.setValue}
            selectedType={props.fileType}
            setSelectedType={props.setSelectedFileType}
          />
        }
        {
            (props.currentStepIndex === UploadStepIndex.FileInformation) &&
            <FileInformation
              key={'step-1'}
              setValue={props.setValue}
            />
        }
        {
          (props.currentStepIndex === UploadStepIndex.ScriptInformation) &&
          <ScriptInformation
            key={'step-2'}
            control={props.control}
            getValues={props.getValues}
            setValue={props.setValue}
          />
        }
          {
            (props.currentStepIndex === UploadStepIndex.FileWatermark) &&
            <FileWatermark
              key={'step-3'}
              submitRequested={props.submitRequested}
              onSubmit={props.onSubmit}
            />
          }
        {
          (props.currentStepIndex === UploadStepIndex.FileDestination) &&
          <FileDestination
            key={'step-4'}
            selected={props.selectedDestinations}
            selectFolder={props.selectDestination}
            fileType={props.fileType}
            category={props.getValues('category')}
          />
        }
      </Grid>
    </div>
  )
}

export default UploadSteps;

// function to display the correct step for the stepper at the top of the upload modals
function getStepperNumberFromIndex (currentStepIndex: number): number {
  const firstStepperValue: number = 0;
  const middleStepperValue: number = 1;
  const finalStepperValue: number = 2;
  // these indexes are all step 1 for the stepper
  const keysWithFirstStepperValue: UploadStepIndex[] = [UploadStepIndex.ScriptInformation];
  // these indexes are all step 2 for the stepper
  const keysWithSecondStepperIndex: UploadStepIndex[] = [UploadStepIndex.FileWatermark, UploadStepIndex.FileInformation];
  let currentStepperIndex = currentStepIndex;
  if (keysWithFirstStepperValue.includes(currentStepIndex)) {
    currentStepperIndex = firstStepperValue;
  } else if (keysWithSecondStepperIndex.includes(currentStepIndex)) {
    currentStepperIndex = middleStepperValue;
  } else if (currentStepIndex >= finalStepperValue) {
    currentStepperIndex = finalStepperValue;
  }
  return currentStepperIndex;
}
