import { store } from '../../state/store';
import type { Person } from '../../models/Person';
import type { DistributionList, SelectedGroup, SelectedMember } from '../../models/DistributionList';
// import type { SecurityGroup } from '../../models/SecurityGroups';
import type { Department } from '../../models/Department';
import { setGroupList, setGroupDepartment, setSelectedExtraEmails, setFieldsToOverwrite } from '../../state/slices/DraftReducer';
import type { Draft } from '../../models/Draft';
import { TemplateDraft } from './constants';
import MessageService from '../message/MessageService';
import type { DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';
import { DistributionMessagesKeys } from '../../services/distribution/constants';
const { dispatch } = store;

const InputValue: string = '_INPUT';

/**
 * Draft Service with functions based around displaying drafts in compose editor and handling of data
 */

interface IDraftService {
  handleListSelect: (member: Person, distributionList: DistributionList) => void
  handleDepartmentSelect: (member: Person, department: DistributionList | Department) => void
  handleGroupSelect: (distributionList: DistributionList) => void
  handleDepartmentGroupSelect: (department: DistributionList | Department) => void
  handleRemoveDistributionGroup: (groupId: string) => void
  handleRemoveDepartmentGroup: (groupId: string) => void
  handleRemoveExtraEmail: (email: string) => void
  handleAddExtraEmail: (email: string) => void
  handleMuiltiExtraEmailAdd: (emails: string[]) => void
  handleExtraEmailSelect: (email: string) => void
  loadDraftLists: (messageKey: DistributionMessagesKeys) => void
  loadDraftDepartments: (departmentList: Department[], messageKey: DistributionMessagesKeys) => void
  isFieldOverwriteValidated: (template: DistributionEmailTemplate, getValues: any) => boolean
  getCurrentDraftByKey: (messageKey: DistributionMessagesKeys) => Draft
}

class DraftService implements IDraftService {
  /**
   * handles the selection of a list member
   * adds the members distributionList to groupList if not included already
   * add the members details to the groupLists members array
   * @param member {Person} the member selected in the slide drawer
   * @param distributionList the list belonging to the selected memeber
   */
  handleListSelect (member: Person, distributionList: DistributionList): void {
    const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
    const groupListIds = (groupList.length > 0) ? groupList.map(list => list.id) : [];
    let newGroupList: SelectedGroup[] = [];
    if (distributionList.id !== null && groupListIds.includes(distributionList.id)) {
      const existingGroup = groupList.find(list => list.id === distributionList.id) as SelectedGroup;
      const existingGroupMembers: SelectedMember[] = existingGroup.members;
      const existingIds: string[] = existingGroupMembers.map((existingMember: SelectedMember) => existingMember.id);
      const memberId: string | undefined = member.id;
      const includesMemberId: boolean = (memberId !== undefined && existingIds.includes(memberId));
      newGroupList = groupList.filter(list => list.id !== distributionList.id);
      if (includesMemberId) { // if member already included - remove
        const members: SelectedMember[] = existingGroup.members.filter((m: SelectedMember) => m.id !== memberId);
        if (members.length > 0) {
          const newGroup: SelectedGroup = { ...existingGroup, members };
          newGroupList = [...newGroupList, newGroup];
        }
      } else if (existingGroup !== undefined && member.id !== undefined) { // if not included add member
        const groupMember: SelectedMember = {
          id: member.id,
          name: member.firstName + ' ' + member.lastName,
          email: member.email
        };
        const newGroupMembers: SelectedMember[] = [...existingGroupMembers, groupMember];
        const newGroup: SelectedGroup = { ...existingGroup, members: newGroupMembers };
        newGroupList = [...newGroupList, newGroup];
      }
    } else if (member.id !== undefined) { // distribution list for member hasn't already been selected
      const group: SelectedGroup = {
        id: distributionList.id,
        total: distributionList.members.length,
        members: [{
          id: member.id,
          name: member.firstName + ' ' + member.lastName,
          email: member.email
        }],
        name: distributionList.name
      };
      newGroupList = [...groupList, group];
    }
    dispatch(setGroupList({ groupList: newGroupList, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * handles the selection of a departent member
   * add the members department to groupDepartment if not included already, removes if already included
   * @param member {Person} the member selected in the slide drawer
   * @param department the department belonging to the selected memeber
   */
  handleDepartmentSelect (member: Person, department: DistributionList | Department): void {
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
    const groupListIds = (groupDepartment.length > 0) ? groupDepartment.map(depart => depart.id) : [];
    let newGroupDepartment: SelectedGroup[] = [];
    if (department.id !== null && groupListIds.includes(department.id)) {
      const existingGroup = groupDepartment.find(depart => depart.id === department.id) as SelectedGroup;
      const existingGroupMembers: SelectedMember[] = existingGroup.members;
      const existingIds: string[] = existingGroupMembers.map((existingMember: SelectedMember) => existingMember.id);
      const memberId: string | undefined = member.id;
      const includesMemberId: boolean = (memberId !== undefined && existingIds.includes(memberId));
      newGroupDepartment = groupDepartment.filter(depart => depart.id !== department.id);
      if (includesMemberId) { // if member already selected - remove
        const members: SelectedMember[] = existingGroup.members.filter((m: SelectedMember) => m.id !== memberId);
        if (members.length > 0) {
          const newGroup: SelectedGroup = { ...existingGroup, members };
          newGroupDepartment = [...newGroupDepartment, newGroup];
        }
      } else if (existingGroup !== undefined && member.id !== undefined) { // add member to selected department
        const groupMember: SelectedMember = {
          id: member.id,
          name: member.firstName + ' ' + member.lastName,
          email: member.email
        };
        const newGroupMembers: SelectedMember[] = [...existingGroupMembers, groupMember];
        const newGroup: SelectedGroup = { ...existingGroup, members: newGroupMembers };
        newGroupDepartment = [...newGroupDepartment, newGroup];
      }
    } else if (member.id !== undefined) { // department has not been selected yet for member - add member and selected department
      const group: SelectedGroup = {
        id: department.id,
        total: (department.members !== undefined && department.members.length > 0) ? department.members.length : 0,
        members: [{
          id: member.id,
          name: member.firstName + ' ' + member.lastName,
          email: member.email
        }],
        name: department.name
      }
      newGroupDepartment = [...groupDepartment, group];
    }
    dispatch(setGroupDepartment({ groupDepartment: newGroupDepartment, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * handles the bulk selection of a distribution List
   * adds all the list and it's members to groupList if not included already, removes if already included
   * @param distributionList {DistributionList} the list selected from checkbox
   */
  handleGroupSelect (distributionList: DistributionList): void {
    const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
    const groupListIds = (groupList.length > 0) ? groupList.map(list => list.id) : [];
    let newGroupList: SelectedGroup[] = [];
    if (distributionList.id !== null && groupListIds.includes(distributionList.id)) { // if list is already selected - remove
      newGroupList = groupList.filter(list => list.id !== distributionList.id);
    } else {
      const members: SelectedMember[] = [];
      distributionList.members.forEach((member: Person) => { // add members if not already selected
        if (member.id !== null && member.id !== undefined) {
          members.push({
            id: member.id,
            name: member.firstName + ' ' + member.lastName,
            email: member.email
          });
        }
      });
      const group: SelectedGroup = {
        id: distributionList.id,
        total: distributionList.members.length,
        members,
        name: distributionList.name
      };
      newGroupList = [...groupList, group];
    }
    dispatch(setGroupList({ groupList: newGroupList, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * handles the bulk selection of a department
   * adds the department and it's members to groupDepartment if not included already, removes if already included
   * @param department {Department} the department selected from checkbox
   */
  handleDepartmentGroupSelect (department: DistributionList | Department): void {
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
    const groupDepartmentIds = (groupDepartment.length > 0) ? groupDepartment.map(depart => depart.id) : [];
    let newGroupDepartment: SelectedGroup[] = [];
    if (department.id !== null && groupDepartmentIds.includes(department.id)) { // if department is already selected - remove
      newGroupDepartment = groupDepartment.filter(depart => depart.id !== department.id);
    } else {
      const members: SelectedMember[] = [];
      if (department.members !== undefined && department.members.length > 0) {
        department.members.forEach((member: Person) => {
          if (member.id !== null && member.id !== undefined) { // add members if not already selected
            members.push({
              id: member.id,
              name: member.firstName + ' ' + member.lastName,
              email: member.email
            });
          }
        });
        const group: SelectedGroup = {
          id: department.id,
          total: department.members.length,
          members,
          name: department.name
        };
        newGroupDepartment = [...groupDepartment, group];
      }
    }
    dispatch(setGroupDepartment({ groupDepartment: newGroupDepartment, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * removes the selected group list from groupList
   * @param groupId {string} the list id to be removed
   */
  handleRemoveDistributionGroup (groupId: string): void {
    const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
    const newGroupDistribution: SelectedGroup[] = groupList.filter(list => list.id !== groupId);
    dispatch(setGroupList({ groupList: newGroupDistribution, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * removes the selected group department from groupDepartment
   * @param groupId {string} the department id to be removed
   */
  handleRemoveDepartmentGroup (groupId: string): void {
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
    const newGroupDepartment: SelectedGroup[] = groupDepartment.filter(department => department.id !== groupId);
    dispatch(setGroupDepartment({ groupDepartment: newGroupDepartment, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * removes the extra email from selected Extra emails
   * @param email {string} the extra email to be removed
   */
  handleRemoveExtraEmail (email: string): void {
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
    const newSelectedExtraEmails: string[] = selectedExtraEmails.filter((extraEmail: string) => extraEmail !== email);
    dispatch(setSelectedExtraEmails({ selectedExtraEmails: newSelectedExtraEmails, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * adding extra email to compose (called from contacts pages)
   * @param email {string} the extra email to add
   */
  handleAddExtraEmail (email: string): void {
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
    let newSelectedExtraEmails: string[] = selectedExtraEmails.filter(extraEmail => extraEmail !== email);
    newSelectedExtraEmails = [...newSelectedExtraEmails, email];
    dispatch(setSelectedExtraEmails({ selectedExtraEmails: newSelectedExtraEmails, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * adding multiple extra emails to compose
   * @param emails {string[]} the extra email list
   */
  handleMuiltiExtraEmailAdd (emails: string[]): void {
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
    // remove duplicate emails just to be safe
    const uniqueSelectedExtraEmails: string[] = selectedExtraEmails.filter(
      (value: string, index: number, self: string[]) => (
        self.indexOf(value) === index
      )
    );
    let newSelectedExtraEmails: string[];
    newSelectedExtraEmails = uniqueSelectedExtraEmails.filter(extraEmail => !emails.includes(extraEmail));
    newSelectedExtraEmails = [...newSelectedExtraEmails, ...emails];
    dispatch(setSelectedExtraEmails({ selectedExtraEmails: newSelectedExtraEmails, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * adds or removes the extra email from selected Extra emails
   * @param email {string} the extra email
   */
  handleExtraEmailSelect (email: string): void {
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;
    let newSelectedExtraEmails: string[] = [];
    if (selectedExtraEmails.includes(email)) {
      newSelectedExtraEmails = selectedExtraEmails.filter(extraEmail => extraEmail !== email);
    } else {
      newSelectedExtraEmails = [...selectedExtraEmails, email];
    }
    dispatch(setSelectedExtraEmails({ selectedExtraEmails: newSelectedExtraEmails, key: DistributionMessagesKeys.Compose }));
  }

  /**
   * get lists included in template / distribution message draft to be included in compose editor
   */
  loadDraftLists (messageKey: DistributionMessagesKeys): void {
    const distributionList: DistributionList[] = store.getState().distribution.distributionList;
    const draft: Draft = store.getState().draft.drafts[messageKey].draft;
    const groupList: SelectedGroup[] = store.getState().draft.drafts[messageKey].groupList;
    // if draft type is distribution message - reset group list selection
    let newGroupList: SelectedGroup[] = (draft.type === TemplateDraft) ? [...groupList] : [];
    if (draft.extraListMembersInputVal !== undefined && draft.extraListMembersInputVal !== null) {
      newGroupList = loadExtraLists(draft.extraListMembersInputVal, newGroupList, distributionList);
    }
    if (draft.distributionListId !== undefined && draft.distributionListId !== null) {
      newGroupList = loadFullLists(draft.distributionListId, newGroupList, distributionList);
    }
    dispatch(setGroupList({ groupList: newGroupList, key: messageKey }));
  }

  /**
   * get departments included in template / distribution message draft to be included in compose editor
   */
  loadDraftDepartments (departmentList: Department[], messageKey: DistributionMessagesKeys): void {
    const draft: Draft = store.getState().draft.drafts[messageKey].draft;
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[messageKey].groupDepartment;
    // if draft type is distribution message - reset group department selection
    let newGroupDepartment: SelectedGroup[] = (draft.type === TemplateDraft) ? [...groupDepartment] : [];
    if (draft.extraListMembersInputVal !== undefined && draft.extraListMembersInputVal !== null) {
      newGroupDepartment = loadExtraDepartments(draft.extraListMembersInputVal, newGroupDepartment, departmentList);
    }
    if (draft.departmentId !== undefined && draft.departmentId !== null) {
      newGroupDepartment = loadFullDepartments(draft.departmentId, newGroupDepartment, departmentList);
    }
    dispatch(setGroupDepartment({ groupDepartment: newGroupDepartment, key: messageKey }));
  }

  /**
   * check if template content selection is okay to overwrite current form content
   */
  isFieldOverwriteValidated (template: DistributionEmailTemplate, getValues: any): boolean {
    let validated: boolean = true;
    const fieldsToOverwrite = [];
    const trimmedRawSubject: string = (template.subject) ? MessageService.getRawSubject(template.subject).trim() : '';
    const currentTrimmedSubject: string = getValues('subject').trim();
    if (template.isSubjectIncluded &&
      currentTrimmedSubject.length > 0 &&
      trimmedRawSubject.length > 0 &&
      !doesInputMatchTemplateSubject(trimmedRawSubject, currentTrimmedSubject)
    ) {
      fieldsToOverwrite.push('compose.templateWarning.labels.subject');
    }

    const trimmedEmailBody: string = (template.emailBody) ? template.emailBody.trim() : '';
    const currentTrimmedEmailBody: string = getValues('unsignedMessage').trim();
    if (template.isEmailBodyIncluded &&
      trimmedEmailBody.length > 0 &&
      currentTrimmedEmailBody.length > 0
    ) {
      fieldsToOverwrite.push('compose.templateWarning.labels.emailBody');
    }

    const trimmedSmsMessage: string = (template.smsMessage) ? MessageService.getRawSubject(template.smsMessage).trim() : '';
    const currentTrimmedSmsMesage: string = getValues('smsmessage').trim();
    if (template.isSMSIncluded &&
      trimmedSmsMessage.length > 0 &&
      currentTrimmedSmsMesage.length > 0
    ) {
      fieldsToOverwrite.push('compose.templateWarning.labels.smsMessage');
    }

    if (fieldsToOverwrite.length > 0) {
      validated = false;
      dispatch(setFieldsToOverwrite({ fields: fieldsToOverwrite }));
    }
    return validated;
  }

  getCurrentDraftByKey (messageKey: DistributionMessagesKeys): Draft {
    const draft: Draft = store.getState().draft.drafts[messageKey].draft;
    return draft;
  }
}

/**
 * return lists where all members are NOT included in template draft
 * @param extraListMembersInputVal
 * @param groupList
 * @param distributionList
 */
const loadExtraLists = (extraListMembersInputVal: string, groupList: SelectedGroup[], distributionList: DistributionList[]): SelectedGroup[] => {
  let newGroupList: SelectedGroup[] = [...groupList];
  extraListMembersInputVal.split(',').forEach((emailInputValue: string) => {
    if (emailInputValue !== undefined && emailInputValue !== null &&
        emailInputValue.trim().length > 0 && emailInputValue.trim() !== '-'
    ) {
      if (emailInputValue.endsWith(InputValue)) {
        emailInputValue = emailInputValue.replace(InputValue, '');
      }
      const membersDistributionList: DistributionList | undefined = getExtraDistributionList(distributionList, emailInputValue);
      if (membersDistributionList !== undefined && membersDistributionList.members.length > 0) {
        const extraMember: Person | undefined = membersDistributionList.members.find((member: Person) => member.id === emailInputValue);
        if (extraMember !== undefined) {
          const extraMemberId = extraMember.id;
          if (extraMemberId !== undefined) {
            const selectedMember: SelectedMember | undefined = getSelectedMember(extraMember);
            if (selectedMember !== undefined) {
              const distributionListId: string = membersDistributionList.id;
              const groupListExists: boolean = findIfGroupExists(newGroupList, distributionListId);
              // now check to see if list in groupList - if no - add
              if (groupListExists) {
                const group: SelectedGroup | undefined = newGroupList.find((group: SelectedGroup) => group.id === distributionListId);
                // take out group and add
                if (group) {
                  let newGroup: SelectedGroup = { ...group };
                  newGroupList = newGroupList.filter((selectedGroup: SelectedGroup) => group.id !== selectedGroup.id);
                  const groupMembers: SelectedMember[] = group.members;
                  if (groupMembers !== undefined && groupMembers.length > 0) {
                    const memberExists: boolean = findIfMemberExists(groupMembers, selectedMember);
                    if (!memberExists) {
                      const newMembers: SelectedMember[] = [...groupMembers, selectedMember];
                      newGroup = { ...group, members: newMembers };
                    }
                  }
                  newGroupList = [...newGroupList, newGroup];
                }
              } else {
                const group: SelectedGroup = {
                  id: distributionListId,
                  total: membersDistributionList.members.length,
                  members: [selectedMember],
                  name: membersDistributionList.name
                }
                newGroupList = [...newGroupList, group];
              }
            }
          }
        }
      }
    }
  });
  return newGroupList;
}

/**
 * get lists with all members included in draft template
 * @param distributionListId
 * @param groupList
 * @param distributionList
 */
const loadFullLists = (distributionListId: string, groupList: SelectedGroup[], distributionList: DistributionList[]): SelectedGroup[] => {
  let newGroupList: SelectedGroup[] = [...groupList];
  distributionListId.split(',').forEach((distributionListValue: string) => {
    if (distributionListValue !== undefined && distributionListValue !== null && distributionListValue.trim() !== '-') {
      // remove list from group. Add full list into group
      newGroupList = newGroupList.filter((group: SelectedGroup) => group.id !== distributionListValue);
      const currentList: DistributionList | undefined = distributionList.find((list: DistributionList) => list.id === distributionListValue);
      if (currentList !== undefined && currentList.members.length > 0) {
        const members: SelectedMember[] = [];
        currentList.members.forEach((member: Person) => {
          if (member.id !== null && member.id !== undefined) {
            members.push({
              id: member.id,
              name: member.firstName + ' ' + member.lastName,
              email: member.email
            });
          }
        });
        const group: SelectedGroup = {
          id: distributionListValue,
          total: currentList.members.length,
          members,
          name: currentList.name
        }
        newGroupList = [...newGroupList, group];
      }
    }
  });
  return newGroupList;
}

/**
 * return departments where all members are NOT included in template draft
 * @param extraListMembersInputVal
 * @param groupDepartment
 * @param departments
 */
const loadExtraDepartments = (extraListMembersInputVal: string, groupDepartment: SelectedGroup[], departments: Department[]): SelectedGroup[] => {
  let newGroupDepartment: SelectedGroup[] = [];
  extraListMembersInputVal.split(',').forEach((emailInputValue: string) => {
    if (emailInputValue !== undefined && emailInputValue !== null &&
        emailInputValue.trim().length > 0 && emailInputValue.trim() !== '-'
    ) {
      if (emailInputValue.endsWith(InputValue)) {
        emailInputValue = emailInputValue.replace(InputValue, '');
      }
      const departmentMembers: Department | undefined = getExtraDepartments(departments, emailInputValue);
      if (departmentMembers && departmentMembers.members.length > 0) {
        const extraMember: Person | undefined = departmentMembers.members.find((member: Person) => member.id === emailInputValue);
        if (extraMember) {
          const extraMemberId = extraMember.id;
          if (extraMemberId !== undefined) {
            const selectedMember: SelectedMember | undefined = getSelectedMember(extraMember);
            if (selectedMember !== undefined) {
              const departmentId: string = departmentMembers.id;
              const groupDepartmentExists: boolean = findIfGroupExists(groupDepartment, departmentId);
              if (groupDepartmentExists) {
                const group: SelectedGroup | undefined = groupDepartment.find((group: SelectedGroup) => group.id === departmentId);
                if (group) {
                  let newGroup: SelectedGroup = { ...group };
                  newGroupDepartment = groupDepartment.filter((selectedGroup: SelectedGroup) => group.id !== selectedGroup.id);
                  const groupMembers: SelectedMember[] = group.members;
                  if (groupMembers !== undefined && groupMembers.length > 0) {
                    const memberExists: boolean = findIfMemberExists(groupMembers, selectedMember);
                    if (!memberExists) {
                      const newMembers: SelectedMember[] = [...groupMembers, selectedMember];
                      newGroup = { ...group, members: newMembers };
                    }
                  }
                  newGroupDepartment = [...newGroupDepartment, newGroup];
                }
              } else {
                const group: SelectedGroup = {
                  id: departmentId,
                  total: departmentMembers.members.length,
                  members: [selectedMember],
                  name: departmentMembers.name
                }
                newGroupDepartment = [...groupDepartment, group];
              }
            }
          }
        }
      }
    }
  });
  return newGroupDepartment;
}

/**
 * return departments where all members are included in template draft
 * @param departmentId
 * @param groupDepartment
 * @param departmentList
 */
const loadFullDepartments = (departmentId: string, groupDepartment: SelectedGroup[], departmentList: Department[]): SelectedGroup[] => {
  let newGroupDepartment: SelectedGroup[] = [];
  departmentId.split(',').forEach((departmentValue: string) => {
    if (departmentValue !== undefined && departmentValue !== null) {
      newGroupDepartment = groupDepartment.filter((group: SelectedGroup) => group.id !== departmentValue);
      const currentDepartment: Department | undefined = departmentList.find((department: Department) => department.id === departmentValue);
      if (currentDepartment?.members !== undefined && currentDepartment?.members.length > 0) {
        const members: SelectedMember[] = [];
        currentDepartment.members.forEach((member: Person) => {
          if (member.id !== null && member.id !== undefined) {
            members.push({
              id: member.id,
              name: member.firstName + ' ' + member.lastName,
              email: member.email
            });
          }
        });
        const group: SelectedGroup = {
          id: departmentValue,
          total: currentDepartment.members.length,
          members,
          name: currentDepartment.name
        }
        newGroupDepartment = [...newGroupDepartment, group];
      }
    }
  })
  return newGroupDepartment;
}

// this is similar tp whats in compose - maybe create member service and add in there
const getSelectedMember = (member: Person): SelectedMember | undefined => {
  let selectedMember: SelectedMember | undefined;
  if (member.id !== undefined) {
    selectedMember = {
      id: member.id,
      name: member.firstName + ' ' + member.lastName,
      email: member.email
    }
  }
  return selectedMember;
}

/**
 * returns distributionList where some members are included in draft template but some are not
 * @param distributionList
 * @param emailInputValue
 */
const getExtraDistributionList = (distributionList: DistributionList[], emailInputValue: string): DistributionList | undefined => {
  let extraDistributionList: DistributionList | undefined;
  if (distributionList !== undefined && distributionList.length > 0) {
    distributionList.forEach((list: DistributionList) => {
      const distributionListMembers: Person[] = list.members;
      if (distributionListMembers !== undefined && distributionListMembers.length > 0) {
        const members = distributionListMembers.map((member) => member.id);
        if (members !== undefined && members.length > 0 && members.includes(emailInputValue)) {
          extraDistributionList = list;
        }
      }
    });
  }
  return extraDistributionList;
}

/**
 * returns department where some members are included in draft template but some are not
 * @param Department
 * @param emailInputValue
 */
const getExtraDepartments = (departments: Department[], emailInputValue: string): Department | undefined => {
  let extraDepartment: Department | undefined;
  if (departments !== undefined && departments.length > 0) {
    departments.forEach((department: Department) => {
      const departmentListMembers: Person[] = department.members;
      if (departmentListMembers !== undefined && departmentListMembers.length > 0) {
        const members = departmentListMembers.map((member) => member.id);
        if (members !== undefined && members.length > 0 && members.includes(emailInputValue)) {
          extraDepartment = department;
        }
      }
    });
  }
  return extraDepartment;
}

// find if a list or department already exists in the message to be sent to backend
const findIfGroupExists = (groupList: SelectedGroup[], groupId: string): boolean => {
  let groupExists: boolean = false;
  if (groupList !== undefined && groupList.length > 0) {
    const groupListIds = groupList.map((group: SelectedGroup) => group.id);
    if (groupListIds.includes(groupId)) {
      groupExists = true;
    }
  }
  return groupExists;
}

// find if member exists in a group already to be sent with message
const findIfMemberExists = (groupMembers: SelectedMember[], selectedMember: SelectedMember): boolean => {
  let memberExists = false;
  const member: SelectedMember | undefined = groupMembers.find((groupMember: SelectedMember) => groupMember.id === selectedMember.id);
  if (member !== undefined) {
    memberExists = true;
  }
  return memberExists;
}

const doesInputMatchTemplateSubject = (subject: string, inputValue: string): boolean => {
  return subject.trim().replace(/^-$/, '').toLowerCase().startsWith(inputValue.toLowerCase().trim())
}

const draftService: IDraftService = new DraftService();
export default draftService;
