import React, { lazy, Suspense } from 'react';

const LazyConfirmAccessLoss = lazy(async () => await import('./ConfirmAccessLoss'));

const ConfirmAccessLoss = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyConfirmAccessLoss {...props} />
  </Suspense>
);

export default ConfirmAccessLoss;
