import React, { lazy, Suspense } from 'react';

const LazyCancelSuccess = lazy(async () => await import('./CancelSuccess'));

const CancelSuccess = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyCancelSuccess {...props} />
  </Suspense>
);

export default CancelSuccess;
