import React, { lazy, Suspense } from 'react';

const LazyWatermarkedFile = lazy(async () => await import('./WatermarkedFile'));

const WatermarkedFile = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyWatermarkedFile {...props} />
  </Suspense>
);

export default WatermarkedFile;
