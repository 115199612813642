import { Tabs, Tab, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import styles from './UserSettings.module.scss';
import PasswordSettingsTabPanelContent from './PasswordSettingsTabPanelContent/PasswordSettingsTabPanelContent.lazy';
import ProductionSettingsTabPanelContent from './ProductionSettingsTabPanelContent/ProductionSettingsTabPanelContent.lazy';
import UserSettingsTabPanelContent from './UserSettingsTabPanelContent/UserSettingsTabPanelContent.lazy';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import progressStyles from '../../theme/loadingProgress.module.scss';

export interface UserSettingsProps {
  pageTitle: UserSettingsPage
  activeTabIndex?: number
}

export enum UserSettingsPage {
  Title = 'Settings'
}

// Gets tab properties for a specific index
function getIndexedTabProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

// Renders a tab panel
function TabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const UserSettings: FC<UserSettingsProps> = ({ pageTitle, activeTabIndex }: UserSettingsProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle]);

  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Translation fct
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [value, setValue] = React.useState(activeTabIndex === undefined ? 0 : activeTabIndex); // Here the active tab is being set, Peter

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIsFetching(true);
    setValue(newValue);
  };

  const SettingsTab = withStyles({
    root: {
      // @ts-expect-error needed to reset parent style
      textTransform: 'none !important',
      lineHeight: 1,
      color: 'black',
      opacity: 0.8,
      '&.Mui-selected': {
        fontWeight: 'bold'
      }
    }

  })(Tab);

  // Note: MyAccountTabPanelContent has been removed until an api is created for saving the photo and user details
  return <div className={styles.UserSettings} data-testid="UserSettings">
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        textColor="inherit"
        indicatorColor="secondary"
        classes={{
          indicator: styles.indicator,
          scroller: styles.scroller
        }}
      >
        <SettingsTab label={t('userSettings.tabs.userSettings')} {...getIndexedTabProps(0)} />
        <SettingsTab label={t('userSettings.tabs.prodSettings')} {...getIndexedTabProps(1)} />
        <SettingsTab label={t('userSettings.tabs.passwordSettings.title')} {...getIndexedTabProps(2)} />
      </Tabs>
    </Box>
    <div className={styles.tabPanelContainer}>
      {
        (isFetching) && <CircularProgress className={progressStyles.circularProgress} />
      }
      <TabPanel value={value} index={0}>
        <UserSettingsTabPanelContent isFetching={isFetching} setIsFetching={setIsFetching}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductionSettingsTabPanelContent isFetching={isFetching} setIsFetching={setIsFetching}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PasswordSettingsTabPanelContent isFetching={isFetching} setIsFetching={setIsFetching}/>
      </TabPanel>
    </div>
  </div>
}

export default UserSettings;
