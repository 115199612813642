import React, { useEffect } from 'react';
import type { FC } from 'react';
import styles from './FileWatermark.module.scss';
import { Checkbox, Box, Grid } from '@mui/material';
import { useController, useForm } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import FormInputTextField from '../../../../forms/FormInputTextField';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { WatermarkValues } from '../../../../../models/Upload';
import type { OptionProps } from '../../../../../models/OptionProps';
import { defaultCheckboxStyles } from '../../../../../theme/styles';

export const OtherOption: string = 'Other';

const RecipientNameValue: string = 'FullName';
const BlankValue: string = 'Blank';
const blankKey: string = 'blank';

const line1: OptionProps[] = [
  {
    key: 'recipientsName',
    value: RecipientNameValue,
    label: 'upload.fileWatermark.options.recipientsName'
  }
];

const line2: OptionProps[] = [
  {
    key: blankKey,
    value: BlankValue,
    label: 'upload.fileWatermark.options.blank'
  },
  {
    key: 'title',
    value: 'Title',
    label: 'upload.fileWatermark.options.title'
  },
  {
    key: 'department',
    value: 'Department',
    label: 'upload.fileWatermark.options.department'
  },
  {
    key: 'date',
    value: 'Date',
    label: 'upload.fileWatermark.options.date'
  },
  {
    key: 'ipAddress',
    value: 'IP Address',
    label: 'upload.fileWatermark.options.ipAddress'
  }
];

interface lineProps {
  items: OptionProps[]
  inputName: string
  name: 'line1' | 'line2' | 'typeline1' | 'typeline2'
  control: Control<WatermarkValues>
}
/**
 * Display the different line components for the watermark
 */

const FileWatermarkLines: FC<lineProps> = ({ items, name, control, inputName }: lineProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const { field } = useController({
    control,
    name
  });

  return (
   <>
     {items.map((option: OptionProps, index: number) => {
       const width = (index === 0) ? 12 : (index % 2 === 0) ? 9 : 3;
       return (
           <Grid item container
             key={`line-two-${option.key}`}
             className={styles.watermarkListItem}
             xs={width}
           >
             <Checkbox
               color={'success'}
               checked={field.value === option.value}
               onChange={(e) => {
                 // send data to react hook form
                 field.onChange(e.target.checked ? option.value : null);
               }}
               sx={{ ...defaultCheckboxStyles }}
             />
             <Box component='span' className={styles.watermarkItem}>
               {t(option.label)}
             </Box>
           </Grid>
       )
     })}
     <Grid item container key={'line-two-other'} className={styles.watermarkListItem}>
       <Grid item xs={3} sx={{ alignSelf: 'flex-end' }}>
         <Checkbox
           color={'success'}
           checked={field.value === OtherOption}
           onChange={(e) => {
             field.onChange(e.target.checked ? OtherOption : null);
           }}
           sx={{ ...defaultCheckboxStyles }}
         />
         <Box component='span' className={styles.watermarkItem}>
           {t('upload.fileWatermark.options.other')}
         </Box>
       </Grid>
       <Grid item xs={9}>
         <FormInputTextField
             id={'line2'}
             name={inputName}
             label={String(t('upload.fileWatermark.line2.label'))}
             placeholder={String(t('upload.fileWatermark.line2.placeholder'))}
             formItemProps={{
               control
             }}
         />
       </Grid>
     </Grid>
   </>
  )
}

export interface FileWatermarkProps {
  key: string
  submitRequested?: boolean
  onSubmit?: (data: WatermarkValues) => void
}

/**
 * File Watermark component to show for upload file popup
 */
const FileWatermark: FC<FileWatermarkProps> = ({ submitRequested, onSubmit }: FileWatermarkProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const defaultValues = {
    line1: '',
    line2: '',
    typeline1: RecipientNameValue,
    typeline2: BlankValue
  };

  const { control, handleSubmit } = useForm<WatermarkValues>({
    defaultValues
  });

  useEffect(() => {
    if (submitRequested && onSubmit) {
      void handleSubmit(onSubmit)();
    }
  }, [submitRequested]);

  return (
    <div className={styles.FileWatermark} data-testid="FileWatermark">
      <Grid item container className={styles.fileLine}>
        <Box component={'span'}>{t('upload.fileWatermark.line1.title')}</Box>
        <Grid item container className={styles.fileLineItems}>
          <FileWatermarkLines items={line1} name="typeline1" inputName="line1" control={control} />
        </Grid>
      </Grid>
    <Grid item container className={styles.fileLine}>
      <Box component={'span'}>{t('upload.fileWatermark.line2.title')}</Box>
      <Grid item container className={styles.fileLineItems}>
        <FileWatermarkLines items={line2} name="typeline2" inputName="line2" control={control} />
      </Grid>
    </Grid>
    </div>
  )
};

export default FileWatermark;
