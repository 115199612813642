import React, { lazy, Suspense } from 'react';

const LazyTextSMS = lazy(async () => await import('./TextSMS'));

const TextSMS = () => (
  <Suspense fallback={null}>
    <LazyTextSMS />
  </Suspense>
);

export default TextSMS;
