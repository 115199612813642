import { SecurityAdminApi } from './auth/constants';
import type { ApiDataType, AuthUser, SecurityProfile } from './auth/constants';
import AuthService from './auth/AuthService';
import type { WSResponse } from '../models/WSResponse';
import { GetMethod, PostMethod } from './constants';
import type { GetComboValuesResponse, FetchAccessPermissionsResponse, AccessPermissionsForm } from '../models/SecurityGroups';
import SecurityAdminService from './SecurityAdmin';
import { updateSecurityProfile } from '../state/slices/AuthReducer';
import UtilityService from './UtilityService';
import { store } from '../state/store';

const { dispatch } = store;

interface IManagePermissionsService {
  getSecurityGroups: () => Promise<GetComboValuesResponse>
  getAccessPermissions: () => Promise<FetchAccessPermissionsResponse>
  saveSecurityGroups: (accessPermissions: AccessPermissionsForm[]) => Promise<string>
}

class ManagePermissionsService implements IManagePermissionsService {
  /**
   * get security groups
   */
  async getSecurityGroups (): Promise<GetComboValuesResponse> {
    const params = {
      entityName: 'entityName: security_list',
      filterAttribute: '-',
      filterValue: '-',
      valueAttrib: 'securityListId',
      displayAttrib: 'securityListName',
      sortAttrib: 'securityListName'
    }
    const response = makeSecurityAdminCall('getComboValues', {
      params,
      type: GetMethod
    })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        return err;
      });
    return await response;
  }

  /**
   * get security groups
   */
  async getAccessPermissions (): Promise<FetchAccessPermissionsResponse> {
    const response = makeSecurityAdminCall('fetchAccessPermissions', {
      type: GetMethod
    })
      .then((result: WSResponse) => {
        return result;
      }).catch((err) => {
        return err;
      });
    return await response;
  }

  /**
   * update user password
   * @param currentPassword
   * @param newPassword
   */
  async saveSecurityGroups (accessPermissions: AccessPermissionsForm[]): Promise<string> {
    const accessPermissionsPayload = {
      value: JSON.stringify(accessPermissions)
    }

    const response = makeSecurityAdminCall('saveAccessPermissions', {
      body: JSON.stringify(accessPermissionsPayload),
      type: PostMethod
    })
      .then(async (result: WSResponse) => {
        if (UtilityService.isSuccessResponse(result.responseCode)) {
          const resp: SecurityProfile = await SecurityAdminService.getSecurityProfile();
          const authState = store.getState().auth;
          const tenantId: string = authState.tenantId;
          dispatch(updateSecurityProfile({ securityProfile: resp }));
          const crooglooAuth: AuthUser = { ...authState.crooglooauth, securityProfile: { [tenantId]: resp } };
          AuthService.setStorageAuth(crooglooAuth);
        }
        return result;
      }).catch((err) => {
        return err;
      });
    return await response;
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeSecurityAdminCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(SecurityAdminApi, method, data);
  return result;
}

const managePermissionsService: IManagePermissionsService = new ManagePermissionsService();
export default managePermissionsService;
