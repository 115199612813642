import React, { lazy, Suspense } from 'react';

const LazyApplyToAll = lazy(async () => await import('./ApplyToAll'));

const ApplyToAll = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyApplyToAll {...props} />
  </Suspense>
);

export default ApplyToAll;
