import React, { lazy, Suspense } from 'react';
import { type ApplyWatermarkProps } from './ApplyWatermark';

const LazyApplyWatermark = lazy(async () => await import('./ApplyWatermark'));

const ApplyWatermark = (props: ApplyWatermarkProps) => (
  <Suspense fallback={null}>
    <LazyApplyWatermark {...props} />
  </Suspense>
);

export default ApplyWatermark;
