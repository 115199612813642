import React, { lazy, Suspense } from 'react';
import type { BasicInfoProps } from './BasicInfo';

const LazyBasicInfo = lazy(async () => await import('./BasicInfo'));

const BasicInfo = (props: BasicInfoProps) => (
  <Suspense fallback={null}>
    <LazyBasicInfo {...props} />
  </Suspense>
);

export default BasicInfo;
