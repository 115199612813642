import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import SecurityGroups from './Index';
import type { Anchor } from '../../models/Anchor';
import GroupsDrawerTabs from './GroupsDrawerTabs';
import SecurityGroupsService from '../../services/SecurityGroupsService';
import type { ComboValueParameters } from '../../models/ComboValues';
import PersonService from '../../services/person/PersonService';
import SecurityAdminService from '../../services/SecurityAdmin';
import MainSection from '../common/MainSection';
import {
  drawerWidth,
  mainPadding,
  headerHeight,
  sideBarOpenedWidth,
  sideBarClosedWidth
} from '../../theme/styles';
import type { RootState } from '../../state/store';

export enum SecurityGroupsPage {
  Title = 'Security Groups'
}

export interface Props {
  pageTitle: SecurityGroupsPage
}

const anchor: Anchor = 'left';

export const defaultComboParams: ComboValueParameters = {
  entityName: 'security_list',
  filterAttribute: '-',
  filterValue: '-',
  valueAttrib: 'securityListId',
  displayAttrib: 'securityListName',
  sortAttrib: 'securityListName'
}

const SecurityGroupsPageIndex: FC<Props> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle])

  // Whether Slide Drawer is open or not
  const [open, setOpen] = useState<boolean>(true);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [tableHeight, setTableHeight] = useState<number>(0);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const isSideBarOpen: boolean = useAppSelector((state: RootState) => state.sideBar.isSideBarOpen);

  // Groups for the drawer
  const [selectedGroup, setSelectedGroup] = useState<string>('');

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    handleResize();
  }, [open, isSideBarOpen]);

  const handleResize = (): void => {
    // dynamically set table height and width
    const windowWidth: number = window.innerWidth;
    const windowHeight: number = window.innerHeight;
    let width = windowWidth - mainPadding - mainPadding;
    const height = windowHeight - headerHeight - 70 - 10;
    if (isSideBarOpen) {
      width = width - sideBarOpenedWidth;
    } else {
      width = width - sideBarClosedWidth;
    }
    if (open) {
      width = width - drawerWidth;
    }
    setTableWidth(width);
    setTableHeight(height);
  }

  // Changes the group selection, clearing any selected scene
  const updateSelectedGroup = (group: string) => {
    if (selectedGroup !== group) {
      setFetching(true);
      setSelectedGroup(group);
      fetchSecurityListMembers(group);
    }
  }

  useEffect(() => {
    PersonService.fetchDepartments();
    getRestrictedMembers();
    getSecurityGroups();
  }, []);

  // functions to handle opening and closing of drawer
  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  const getRestrictedMembers = (): void => {
    SecurityAdminService.fetchRestrictedMembers()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
  }

  // fetch access permissions for all groups
  const fetchSecurityListMembers = (group: string): void => {
    SecurityGroupsService.fetchSecurityListMembers(group)
      .then(() => {
        setFetching(false);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const getSecurityGroups = (): void => {
    SecurityGroupsService.getSecurityGroups(defaultComboParams)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
  }

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
        <SlideDrawer
          drawerWidth={drawerWidth}
          open={open}
          toggleDrawer={toggleDrawer}
          anchor={anchor}
          buttonText='managePermissions.tabs.groupsTab'
        >
          <GroupsDrawerTabs
            tab={tab}
            onChange={handleTabChange}
            selectedGroup={selectedGroup}
            setSelectedGroup={updateSelectedGroup}
          />
        </SlideDrawer>
        <MainSection open={open} drawerwidth={drawerWidth} sx={{ height: '100vh' }}>
          <SecurityGroups
            groupID={selectedGroup}
            isFetching={isFetching}
            tableWidth={tableWidth}
            tableHeight={tableHeight}
          />
        </MainSection>
      </Box>
  )
};

export default SecurityGroupsPageIndex;
