export interface SecurityListResponse {
  category: string
  entityId: string
  communityId: string
  responseCode: string
  responseMessage: string
}

export interface SecurityList {
  securityListId: string
  displayOrder: string
  securityListName: string
}

export interface WSSecurityList {
  properties: SecurityList
  key: {
    kind: string
    name: string
    id: string
  }
  kind: string
}

export enum SecurityListIds {
  ADMIN = 'ADMIN',
  DH = 'DH',
  EXEC = 'EXEC',
  CREW = 'CREW'
}

export const DHDisplayName: string = 'DTR';

export interface PersonSecurityListResponse {
  items: SecurityListIds[]
}
