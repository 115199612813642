import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainBlack, MainWhite, DarkerCroolooGreen } from '../../../theme/colors';

/**
 * Custom special button component with style using material ui button component
 */
const SpecialButton = styled(Button)<{
  backgroundcolor?: string
}>(({ backgroundcolor }) => ({
  background: backgroundcolor ?? MainBlack,
  textTransform: 'capitalize',
  color: MainWhite,
  padding: '5px 22px',
  '&:hover': { background: DarkerCroolooGreen, color: MainWhite }
}));
export default SpecialButton;
