import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainBlack, CrooglooGreen, BlackGrey } from '../../../theme/colors';

/**
 * Custom tertiary button component with custom style using material ui button component
 */

interface Props {
  textcolor?: string
  hovercolor?: string
  activecolor?: string
  nocapitalize?: boolean
}
const TertiaryButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'nocapitalize'
})<Props>(({ textcolor, hovercolor, activecolor, nocapitalize }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  textTransform: nocapitalize ? 'none' : 'capitalize',
  color: textcolor ?? BlackGrey,
  '& path': {
    fill: textcolor ?? BlackGrey
  },
  '&:hover': {
    background: 'transparent',
    color: hovercolor ?? CrooglooGreen,
    '& path': {
      fill: hovercolor ?? CrooglooGreen
    }
  },
  '&:active': {
    background: 'transparent',
    color: activecolor ?? MainBlack,
    '& path': {
      fill: activecolor ?? MainBlack
    }
  }
}));

export default TertiaryButton;
