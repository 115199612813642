import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormInputTextField from '../components/forms/FormInputTextField';
import FormInputPasswordField from '../components/forms/FormInputPasswordField';
import SpecialButton from '../components/common/buttons/SpecialButton';
import AuthService from '../services/auth/AuthService';
import Toast from '../components/common/toast/Toast';
import { useAppDispatch } from '../state/hooks';
import { setPageTitle } from '../state/slices/pageTitle';
import { CrooglooUrl } from '../models/CrooglooUrl';

import logo from '../assets/img/logo_off_white.png';
import './Login.scss';

import ModalService from '../services/ModalService';
import { PasswordRecoveryModal } from '../components/modals/PasswordRecovery/constants';
import PasswordRecovery from '../components/modals/PasswordRecovery/PasswordRecovery.lazy';
import TFAuthenticationLogin from '../components/modals/TFAuthenticationLogin/TFAuthenticationLogin.lazy';

interface LoginForm {
  username: string
  password: string
}

interface GoogleResponse {
  credential: string
}

declare global {
  interface Window {
    handleGoogleSignIn?: any
  }
}

export enum LoginPage {
  Login = 'Login'
}

interface Props {
  pageTitle: LoginPage
}

const Login: FC<Props> = ({ pageTitle }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle])

  const [showToast, setShowToast] = useState<boolean>(false);

  const defaultValues = {
    username: '',
    password: ''
  };

  const { control, handleSubmit } = useForm({
    defaultValues
  });

  const handleShowToast = (): void => {
    setShowToast(!showToast);
  };

  const onSubmitHandler = (data: LoginForm) => {
    AuthService.login({
      username: data.username.toLowerCase(),
      password: data.password
    })
      .then(async (res: Response) => {
        if (res.redirected) {
          await AuthService.authentication();
          navigate(CrooglooUrl.DASHBOARD);
        }
      })
      .catch(() => {
        setShowToast(true);
      });
  };

  // TODO implement PasswordRecovery Api integration
  const promptPasswordRecovery = () => {
    ModalService.openCustomModal(PasswordRecoveryModal, {
      heading: 'login.passwordRecovery.heading',
      content: 'login.passwordRecovery.content',
      confirmButton: 'action.submit'
    });
  };

  const handleGoogleSignIn = (googleResponse: GoogleResponse) => {
    const jwtToken = googleResponse.credential;

    AuthService.googleLogin(jwtToken)
      .then(async (res: Response) => {
        if (res.redirected) {
          await AuthService.authentication();
          navigate(CrooglooUrl.DASHBOARD);
        } else {
          setShowToast(true);
        }
      })
      .catch(() => {
        setShowToast(true);
      });
  };

  const handleTryNewExperience = () => {
    if (process.env.REACT_APP_OLD_UI_URL) {
      window.localStorage.redirectTo = process.env.REACT_APP_OLD_UI_URL;
      window.location.href = window.localStorage.redirectTo;
    }
  }

  useEffect(() => {
    if (window.location.search.includes('redirected=true')) { window.localStorage.removeItem('redirectTo'); }

    if (window.localStorage.redirectTo) { window.location.href = window.localStorage.redirectTo; }

    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  window.handleGoogleSignIn = handleGoogleSignIn;

  return (
    <div className="login-page-container">
      <div className="overlay"></div>
      <div className="container">
        <div className="logo-and-login-text">
          <div>
            <img src={logo} className="logo" />
          </div>
        </div>

        <form id="loginForm" method="POST" className="login-form" onSubmit={(e) => { void handleSubmit(onSubmitHandler)(e) }}>

          <FormInputTextField
            id={'username'}
            name={'username'}
            label={String(t('login.username'))}
            formItemProps={{
              control
            }}
          />

          <Controller
            name={'password'}
            control={control}
            render={({ field: { onChange } }) => (
              <FormInputPasswordField
                id={'password'}
                name={'password'}
                label={String(t('login.password'))}
                passwordCallback={onChange}
              />
            )}
          />

          <SpecialButton
            type="submit"
            id="login_button"
            className="login-button"
            style={{ marginTop: '8px' }}
          >
            Login
          </SpecialButton>

          <SpecialButton
            type="button"
            id="trynew_button"
            className="login-button"
            onClick={handleTryNewExperience}
          >
            Return to the classic experience!
          </SpecialButton>

{/*          <div id="g_id_onload"
               data-client_id="849074252471-u0u1j9iq2qe76qden13cidv2efdvo1e8.apps.googleusercontent.com"
               data-context="signin"
               data-callback="handleGoogleSignIn"
               data-ux_mode="popup"
               data-auto_prompt="false">
          </div>
          <div className="g_id_signin custom_google_signin"
               data-type="standard"
               data-shape="rectangular"
               data-theme="filled_black"
               data-text="signin_with"
               data-size="large"
               data-logo_alignment="left"
               data-width="320">
          </div> */}

          <div className="login-button-subfields">
            <div className="subfields-text">
              {/* todo use dynamic link */}
              <a href="https://croogloo.wpengine.com/">
                New here? Create account
              </a>
            </div>
            <div className="subfields-text">
              <a onClick={promptPasswordRecovery}>
                Forgot password
              </a>
              </div>
              <div className="subfields-text">
              <a href="https://www.mycroogloo.com/privacy.html">
                Privacy Policy
              </a>

            </div>
          </div>
        </form>
      </div>
      <div className="above-footer">
      </div>
      <div className="footer">
        <div className="support-div">
          <a href="https://support.croogloo.com/" className="support-link">
            Need help? Visit our Support Site
          </a>
        </div>
      </div>
      <Toast open={showToast} onClose={handleShowToast} type={'error'} title={String(t('login.validation.invalidData'))}/>
      <PasswordRecovery />
      <TFAuthenticationLogin />
    </div>
  )
};

export default Login;
