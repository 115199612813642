import React, { lazy, Suspense } from 'react';

const LazyUploadBoxes = lazy(async () => await import('./UploadBoxes'));

const UploadBoxes = () => (
  <Suspense fallback={null}>
    <LazyUploadBoxes />
  </Suspense>
);

export default UploadBoxes;
