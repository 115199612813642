import React, { lazy, Suspense } from 'react';

const LazyNoAttachment = lazy(async () => await import('./NoAttachment'));

const NoAttachment = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyNoAttachment {...props} />
  </Suspense>
);

export default NoAttachment;
