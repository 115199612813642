import React, { lazy, Suspense } from 'react';
import type { Props } from './GenerateSides';

const LazyGenerateSides = lazy(async () => await import('./GenerateSides'));

const GenerateSides = (props: Props) => (
  <Suspense fallback={null}>
    <LazyGenerateSides {...props} />
  </Suspense>
);

export default GenerateSides;
