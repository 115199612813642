import React, { lazy, Suspense } from 'react';

const LazyDropboxLogin = lazy(async () => await import('./DropboxLogin'));

const DropboxLogin = () => (
  <Suspense fallback={null}>
    <LazyDropboxLogin />
  </Suspense>
);

export default DropboxLogin;
