import React, { lazy, Suspense } from 'react';

const LazyMoveDocument = lazy(async () => await import('./MoveDocument'));

const MoveDocument = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyMoveDocument {...props} />
  </Suspense>
);

export default MoveDocument;
