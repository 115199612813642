import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import styles from './ExpiredContacts.module.scss';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import type { RootState } from '../../../state/store';
import ModalService from '../../../services/ModalService';
import ModalTitle from '../ModalTitle/ModalTitle.lazy';
import dialogStyles from '../../../theme/dialog.module.scss';
import { ExpiredContactsModal } from './constants';
import SuccessButton from '../../common/buttons/SuccessButton';
import DeleteButton from '../../common/buttons/DeleteButton';
import WhiteButton from '../../common/buttons/WhiteButton';
import type { ExpiredContact } from '../../../models/Contacts';
import PersonService, { ExpiryActions } from '../../../services/person/PersonService';
import Toast from '../../common/toast/Toast';
import type { ToastProps } from '../../../models/Toast';
import { InitialToastValues } from '../../../models/Toast';
import { useSelector } from 'react-redux';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const ExpiredContacts: FC = () => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const modalsState = useSelector((state: RootState) => state.modals.custom[ExpiredContactsModal]);
  const expiredContacts: ExpiredContact[] = useSelector((state: RootState) => state.contacts.expiredContacts);

  const [contacts, setContacts] = useState<ExpiredContact[]>([]);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  useEffect(() => {
    setContacts(expiredContacts);
  }, [expiredContacts]);

  const handleClose = (): void => {
    ModalService.closeCustomModal(ExpiredContactsModal);
  }

  const handleRemove = (contactId: string): void => {
    PersonService.registerExpiryAction(contactId, ExpiryActions.ExpiryActionDistributionListMemberships)
      .then(() => {})
      .catch((err) => {
        setToastStatus({
          message: t(err.message),
          type: 'error',
          isShown: true
        });
      });
  }

  const handleDelete = (contactId: string): void => {
    PersonService.registerExpiryAction(contactId, ExpiryActions.ExpiryActionDelete)
      .then(() => {})
      .catch((err) => {
        setToastStatus({
          message: t(err.message),
          type: 'error',
          isShown: true
        });
      });
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  return (
    <>
    <Dialog
      classes={{
        root: `${dialogStyles.dialogRoot} ${styles.ExpiredContacts}`,
        paper: `${dialogStyles.dialogPaper} ${dialogStyles.dialogGeneralWidth}`
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
      data-testid="ExpiredContacts"
      open={modalsState.isVisible}
      onClose={handleClose}
    >
      <ModalTitle handleClose={handleClose} title={modalsState.title} />
      <DialogContent>
        <Grid container spacing={2} className={dialogStyles.dialogContent}>
          <Grid item>
            <Box component={'span'} className={styles.header}>{t(modalsState.heading)}</Box>
          </Grid>
          {(contacts.length > 0) &&
            <Grid item>
              <TableContainer>
                <Table
                  aria-labelledby="ExpiredContacts"
                  size={'medium'}
                  sx={{ maxWidth: 500 }}
                >
                  <TableBody>
                    {contacts.map((contact: ExpiredContact) => {
                      return (
                        <TableRow key={`row-${contact.id}`}>
                          <TableCell>{contact.firstName + ' ' + contact.lastName}</TableCell>
                          <TableCell>
                            <WhiteButton disableRipple onClick={() => handleRemove(contact.id)}>
                              {t('notifications.contact.modal.remove')}
                            </WhiteButton>
                          </TableCell>
                          <TableCell>
                            <DeleteButton disableRipple onClick={() => handleDelete(contact.id)}>
                              {t('action.delete')}
                            </DeleteButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions className={dialogStyles.dialogActionsCenter}>
        <SuccessButton disableRipple onClick={handleClose}>{t('action.ok')}</SuccessButton>
      </DialogActions>
    </Dialog>
    <Toast open={toastStatus.isShown} onClose={handleCloseToast} type={toastStatus.type} title={toastStatus.message}/>
    </>
  )
};

export default ExpiredContacts;
