export const CalenderApi: string = 'calendarapi';
export const CallSheet: string = 'callsheet';
export const DataMaintenance: string = 'dataMaintenance';
export const DistributionApi: string = 'distributionapi';
export const DocumentsApi: string = 'documentsapi';
export const ScriptsApi: string = 'scriptapi';
export const EntityApi: string = 'entityapi';
export const FileWebApi: string = 'filewebapi';
export const ImportExportApi: string = 'importexportapi';
export const MetadataApi: string = 'metadataAPI';
export const NotificationApi: string = 'notificationapi';
export const PersonApi: string = 'personapi';
export const ProductionApi: string = 'productionapi';
export const ScriptApi: string = 'scriptapi';
export const SearchApi: string = 'search_api';
export const SecurityAdminApi: string = 'securityadminapi';
export const SettingsApi: string = 'settingsapi';
export const SyncApi: string = 'syncapi';
export const TasksApi: string = 'tasksapi';
export const UserApi: string = 'userAPI';
export const ViewApi: string = 'viewAPI';

export const Version1: string = 'v1';
export const Versoin2: string = 'v2';
export const Version9: string = 'v9';

export const DefaultVersion: string = Version1;

type ApiVersionType = Record<string, string>;

export const ApiVersions: ApiVersionType = {
  [CalenderApi]: Version1,
  [CallSheet]: Version9,
  [DataMaintenance]: Version9,
  [DistributionApi]: Version1,
  [DocumentsApi]: Version1,
  [EntityApi]: Version1,
  [FileWebApi]: Version9,
  [ImportExportApi]: Version1,
  [MetadataApi]: Version9,
  [NotificationApi]: Version1,
  [PersonApi]: Versoin2,
  [ProductionApi]: Version9,
  [ScriptApi]: Version1,
  [SearchApi]: Version9,
  [SecurityAdminApi]: Version1,
  [SettingsApi]: Version1,
  [SyncApi]: Version1,
  [TasksApi]: Version1,
  [UserApi]: Version9,
  [ViewApi]: Version9
};

export const PagesWithWriteOnly: string[] = [
  'compose', 'dailyTimeReports', 'distributionList', 'contact', 'upload',
  'uploadVCards', 'uploadDocuments', 'uploadScripts', 'uploadTemplates', 'watermark'
];

export const PagesBypassSecurity: string[] = ['resetPassword', 'termsOfService', 'privacyPolicy'];

export const AuthSlice: string = 'auth';
export const IncrementAmount: number = 1;

export const WriteOnly = 2;
export const ReadAndWrite = 1;

export enum SecurityPageName {
  Agent = 'agent',
  AllContacts = 'allContacts',
  Cast = 'cast',
  Compose = 'compose',
  Contact = 'contact',
  Crew = 'crew',
  DailyTimeReports = 'dailyTimeReports',
  Dashboard = 'dashboard',
  DistributionList = 'distributionList',
  Documents = 'documents',
  Drafts = 'drafts',
  ManagePermissions = 'managePermissions',
  Outbox = 'outbox',
  Photos = 'photos',
  Scripts = 'scripts',
  SecurityLists = 'securityLists',
  SecuritySettings = 'securitySettings',
  Sent = 'sent',
  Sides = 'sides',
  SidesGeneration = 'sidesGeneration',
  Studio = 'studio',
  TaskMonitoring = 'taskMonitoring',
  Templates = 'templates',
  Union = 'union',
  UploadDocuments = 'uploadDocuments',
  UploadScripts = 'uploadScripts',
  UploadTemplates = 'uploadTemplates',
  UploadVCards = 'uploadVCards',
  Vendor = 'vendor',
  Watermark = 'watermark',
  WebTemplate = 'webTemplate',
}

export type SecurityProfile = Record<SecurityPageName, number>;

export type TenantSecurityProfile = Record<string, SecurityProfile>;

export interface ApiDataType {
  body?: BodyInit
  type?: string
  params?: Record<string, string | string[]>
  method?: string
}

export interface AuthenticatedDataType {
  body?: BodyInit
  type?: string
  params?: Record<string, string>
  method?: string
  responseType?: string
}

export interface LoginData {
  username: string
  password: string
}

export interface AuthUser {
  accountStatus?: string
  authenticationToken?: string
  defaultCommunity?: string
  email?: string
  firstName?: string
  lastName?: string
  securityProfile?: TenantSecurityProfile
  token?: string
  urlEndpoint?: string
  userId?: string
  boxToken?: string
  boxRefreshToken?: string
  boxTokenExpiry?: number
  dropboxToken: string
  sms2fa: string
  securityListId?: string
  gContactsAccessToken?: string
  gDriveAccessToken?: string
  planId?: string
  productionOwnerEmail?: string
  productionType?: string
  billingPeriod?: string
  totalNbOfProds?: number
  nbOfAvailableProds?: number
  communitiesIdNameMap?: Record<string, string>
  communitiesAndAccess?: Record<string, boolean>
  isTrial?: boolean
  mobile?: string
  trialDaysLeft?: number
}

export const Response403: string = '403';

export const BoxFoldersApi: string = 'https://api.box.com/2.0/folders/';
export const BoxFilesApi: string = 'https://api.box.com/2.0/files/';
export const BoxTokenApi: string = 'https://api.box.com/oauth2/token';

export interface PdfLinkResponse {
  pdfFileName: string
  isDownloadAllowed: boolean
  isPrintAllowed: boolean
  data: any
}
