import type { FC } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { MainBlack, BorderGrey } from '../../theme/colors';

interface Props {
  children: React.ReactNode
  sx?: any
}

/**
 * Compose Recipient Component used for the compose page
 * Handles to ui of the reply to recipients part of the compose page
 */
const ComposeRecipient: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  return (
    <Grid item container
      sx={{
        textAlign: 'left',
        borderBottom: `1px solid ${String(BorderGrey)}`,
        ...props.sx
      }}
    >
      <Grid item container xs={2} alignItems="center" sx={{ maxWidth: '100px !important' }}>
        <Box component='span'
           sx={{
             color: MainBlack,
             fontFamily: 'Inter',
             fontStyle: 'normal',
             fontWeight: 700,
             fontSize: '1rem',
             lineHeight: '20px',
             textTransform: 'capitalize'
           }}
        >
          {t('compose.form.labels.recipients')}
        </Box>
      </Grid>
      <Grid item xs={10} container alignItems="center" sx={{ height: '60px' }}>
        { props.children }
      </Grid>
    </Grid>
  )
}
export default ComposeRecipient;
