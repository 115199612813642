import type { Location as RouteLocation, RouteObject } from 'react-router-dom';
import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
// createBrowserRouter replaced with createHashRouter for quick fix around the issue of the server and client urls
// hashtag included in the url now so can connect properly to server
// Routes
import Compose, { ComposePage } from './Compose';
import Dashboard, { DashboardPage } from '../components/Dashboard/Dashboard';
import UserSettings from '../components/UserSettings/UserSettings.lazy';
import DistributionLists from '../components/DistributionLists/DistributionLists.lazy';
import Scripts from '../components/Scripts/Scripts.lazy';
import Sides from '../components/Sides/Sides.lazy';
import Upload from '../components/Upload/Upload.lazy';
import DistributionHistory from '../components/DistributionHistory/DistributionHistory.lazy';
import ContactListings from '../components/ContactListings/ContactListings.lazy';
import ManagePermissions from '../components/ManagePermissions/ManagePermissions.lazy';
import SecurityGroups from '../components/SecurityGroups/SecurityGroups.lazy';
import Documents from '../components/Documents/Documents.lazy';
import Templates from '../components/Templates/Templates.lazy';
import DropboxLogin from '../components/DropboxLogin/DropboxLogin.lazy';
import PdfViewer from '../components/PdfViewer/PdfViewer.lazy';
import BoxLogin from '../components/BoxLogin/BoxLogin.lazy';

// Layouts
import RootLayout from '../layouts/RootLayout';
import { DistributionHistoryPage } from '../components/DistributionHistory/DistributionHistory';
import { MessageStatus } from '../models/Message';
import GenerateSides from '../components/GenerateSides/GenerateSides.lazy';
import { AddContactPage, ContactListingsPage, EditContactPage } from '../components/ContactListings/Constants';
import Watermark from '../components/Watermark/Watermark.lazy';
import { DistributionListsPage } from '../components/DistributionLists/Index';
import { ManagePermissionsPage } from '../components/ManagePermissions/ManagePermissions';
import { SecurityGroupsPage } from '../components/SecurityGroups/SecurityGroups';
import { DocumentsPage } from '../components/Documents/Documents';
import { UserSettingsPage } from '../components/UserSettings/UserSettings';
import { GenerateSidesPage } from '../components/GenerateSides/GenerateSides';
import { WatermarkPage } from '../components/Watermark/Watermark';
import Login, { LoginPage } from './Login';
import { SecurityPageName } from '../services/auth/constants';
import { ScriptsPage } from '../components/Scripts/Scripts';
import { SidesPage } from '../components/Sides/Sides';
import { UploadPage } from '../components/Upload/Upload';
import { DistributionMessagesKeys } from '../services/distribution/constants';
import EditContact from '../components/EditContact/EditContact';
import CreateProduction, { CreateProductionPage } from '../components/CreateProduction/CreateProduction';

// This is used instead of BrowserRouter. It is a customizeable router
// function provided by react-router-dom
export const routes: RouteObject[] = createRoutesFromElements([
  <Route key="login" path="/login" element={<Login pageTitle={LoginPage.Login}/>} />,
  <Route key="pdfViewer" path="/pdfViewer/web/viewer.html" element={<PdfViewer />} />,
  <Route key="createProduction" path="/createproduction/:isTrial/:planId" element={ <CreateProduction pageTitle={CreateProductionPage.Title}/> } />,
  <Route key="root-layout" path="/" element={<RootLayout />} >
    <Route index path='/' element={<Dashboard pageTitle={DashboardPage.Title}/>} />
    <Route path="/compose" element={<Compose pageTitle={ComposePage.Compose} />} />
    <Route path="/templates" element={<Templates pageTitle='Templates'/>} />
    <Route path="/generate-sides" element={<GenerateSides pageTitle={GenerateSidesPage.Title} />} />
    <Route path="/user-settings" element={<UserSettings pageTitle={UserSettingsPage.Title}/> } />
    <Route path="/upload" element={<Upload pageTitle={UploadPage.Title} />} />
    <Route path="/outbox" element={<DistributionHistory pageTitle={DistributionHistoryPage.Outbox} messageStatus={MessageStatus.READY} messageKey={DistributionMessagesKeys.Outbox}/> } />
    <Route path="/sent" element={<DistributionHistory pageTitle={DistributionHistoryPage.Sent} messageStatus={MessageStatus.SENT} messageKey={DistributionMessagesKeys.Sent}/>} />
    <Route path="/drafts" element={<DistributionHistory pageTitle={DistributionHistoryPage.Drafts} messageStatus={MessageStatus.DRAFT} messageKey={DistributionMessagesKeys.Drafts}/>} />
    <Route path="/lists" element={<DistributionLists pageTitle={DistributionListsPage.Title} />} />
    <Route path="/scripts" element={<Scripts pageTitle={ScriptsPage.Title} />} />
    <Route path="/watermark" element={<Watermark pageTitle={WatermarkPage.Title} />} />
    <Route path="/sides" element={<Sides pageTitle={SidesPage.Title}/>} />
    <Route path="/contacts" element={<ContactListings pageTitle={ContactListingsPage.Contacts} />} />
    <Route path="/all" element={<ContactListings pageTitle={ContactListingsPage.All} />} />
    <Route path="/agent" element={<ContactListings pageTitle={ContactListingsPage.Agent} />} />
    <Route path="/cast" element={<ContactListings pageTitle={ContactListingsPage.Cast} />} />
    <Route path="/crew" element={<ContactListings pageTitle={ContactListingsPage.Crew} />} />
    <Route path="/studio" element={<ContactListings pageTitle={ContactListingsPage.Studio} />} />
    <Route path="/vendor" element={<ContactListings pageTitle={ContactListingsPage.Vendor} />} />
    <Route path="/union" element={<ContactListings pageTitle={ContactListingsPage.Union} />} />
    <Route path="/manage-permissions" element={<ManagePermissions pageTitle={ManagePermissionsPage.Title} />} />
    <Route path="/files" element={<Documents pageTitle={DocumentsPage.Title} />} />
    <Route path="/security-groups" element={<SecurityGroups pageTitle={SecurityGroupsPage.Title} />} />
    <Route path="/dropbox" element={<DropboxLogin /> } />
    <Route path="/editContact/:contactEditId" element={<EditContact pageTitle={EditContactPage.Title} />} />
    <Route path="/addContact" element={<EditContact pageTitle={AddContactPage.Title} />} />
    <Route path="box" element={<BoxLogin />} />
  </Route>
]);

const customRouter = createHashRouter(routes);

// Mapping page titles to the security page name of a user's security profile
const securityPageNamesByTitle: Record<string, SecurityPageName> = {
  [ContactListingsPage.Contacts]: SecurityPageName.AllContacts,
  [ContactListingsPage.All]: SecurityPageName.AllContacts,
  [ContactListingsPage.Agent]: SecurityPageName.Agent,
  [ContactListingsPage.Cast]: SecurityPageName.Cast,
  [ContactListingsPage.Crew]: SecurityPageName.Crew,
  [ContactListingsPage.Studio]: SecurityPageName.Studio,
  [ContactListingsPage.Vendor]: SecurityPageName.Vendor,
  [ContactListingsPage.Union]: SecurityPageName.Union,

  [DistributionHistoryPage.Drafts]: SecurityPageName.Drafts,
  [DistributionHistoryPage.Outbox]: SecurityPageName.Outbox,
  [DistributionHistoryPage.Sent]: SecurityPageName.Sent,

  [DistributionListsPage.Title]: SecurityPageName.DistributionList,
  [SecurityGroupsPage.Title]: SecurityPageName.SecurityLists,
  [ManagePermissionsPage.Title]: SecurityPageName.ManagePermissions,
  [DocumentsPage.Title]: SecurityPageName.Documents,
  [WatermarkPage.Title]: SecurityPageName.Watermark,
  [UserSettingsPage.Title]: SecurityPageName.SecuritySettings,
  [ScriptsPage.Title]: SecurityPageName.Scripts,
  [SidesPage.Title]: SecurityPageName.Sides
}

/**
 * Retrieves the name of the page used by the user's security profile to
 * determine access rights to the user's current location.
 *
 * @param param0 The users current location.
 *
 * @returns The name of the page associated with the user's location.
 */
export const getSecurityPageName: (location: RouteLocation) => SecurityPageName | undefined = ({ pathname }: RouteLocation) => {
  // We use a try catch to avoid doing a lot of type checks and avoid blocking
  // a proper page load if anything goes wrong. If we were to accidentally render an
  // element that should be inaccessible, the back-end would block the corresponding
  // API calls anyway.
  try {
    const route: RouteObject | undefined = routes[3].children?.find(r => pathname.startsWith(r.path as string));
    const element: JSX.Element = (route?.element ?? route?.children?.at(0)?.element) as JSX.Element;
    return securityPageNamesByTitle[element.props.pageTitle];
  } catch (e: unknown) {
    console.error('Failed to find security page name for path ', pathname, e);
  }
}

export default customRouter;
