import React, { lazy, Suspense } from 'react';

const LazyEditFileCategory = lazy(async () => await import('./EditFileCategory'));

const EditFileCategory = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyEditFileCategory {...props} />
  </Suspense>
);

export default EditFileCategory;
