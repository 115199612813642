import { useState } from 'react';
import type { FC, SyntheticEvent } from 'react';
import type { Department } from '../../models/Department';
import type { DistributionList, SelectedGroup } from '../../models/DistributionList';
import type { Person } from '../../models/Person';
import Header from './Header';
import Member from './Member';
import ListAccordion from '../common/ListAccordion';

interface Props {
  item: DistributionList | Department
  group: SelectedGroup[]
  handleGroupSelect: (type: DistributionList | Department) => void
  handleSelect: (member: Person, type: DistributionList | Department) => void
}

/**
 * Tab Item Component for the Slide Drawer Component containing lists or departemnts tabs
 * Each tab item has a list item with a header compoent and a member component passing into the custmom list accordion
 */
const TabItem: FC<Props> = (props: Props) => {
  const [expand, setExpand] = useState<boolean>(false);
  const handleHeaderSelect = (type: DistributionList | Department): void => {
    props.handleGroupSelect(type);
  }

  const handleMemberSelect = (event: SyntheticEvent, member: Person, type: DistributionList | Department): void => {
    event.stopPropagation();
    props.handleSelect(member, type);
  }

  const handleOnExpand = (): void => {
    setExpand(!expand);
  }

  return (
    <>
      <ListAccordion
        itemId={props.item.id}
        summary={
          <Header
            type={props.item} group={props.group}
            handleSelect={handleHeaderSelect}
          />
        }
        details={
          <Member
            type={props.item} group={props.group}
            handleSelect={handleMemberSelect}
            isExpanded={expand}
          />
        }
        hasChildren={true}
        minHeight={'64px'}
        onExpand={handleOnExpand}
      />
    </>
  )
}

export default TabItem;
