import React, { lazy, Suspense } from 'react';

const LazyLinkOptions = lazy(async () => await import('./LinkOptions'));

const LinkOptions = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyLinkOptions {...props} />
  </Suspense>
);

export default LinkOptions;
