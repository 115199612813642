import { useState, useEffect, useRef } from 'react';
import type { FC } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import SideBarDivider from '../components/sideBar/SideBarDivider';
import SideBar from '../components/sideBar/SideBar';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { TempBlack, MainWhite } from '../theme/colors';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Header from '../components/header/Header';
import AuthService from '../services/auth/AuthService';
import ProductionService from '../services/production/ProductionService';
import ModalService from '../services/ModalService';
import { HandleInactivityModal } from '../components/modals/HandleInactivity/constants';
import HandleInactivity from '../components/modals/HandleInactivity/HandleInactivity.lazy';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../state/store';
import { DashboardPage } from '../components/Dashboard/Dashboard';
import { CrooglooUrl } from '../models/CrooglooUrl';
import styles from './RootLayout.module.scss';
import SideNavService from '../services/SideNavService';
import type { SideNavItem } from '../models/SideNav';
import { setSideBarState } from '../state/slices/SideBar';
import { sideBarClosedWidth, sideBarMarginWidth } from '../theme/styles';

const openDrawerWidth = 250;

interface MainAreaProps {
  open: boolean
}

// Using styled components to style the main area of the app
const MainArea = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'open'
})<MainAreaProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `calc(100% - ${sideBarClosedWidth}px - ${sideBarMarginWidth}px)`,
  marginLeft: `calc(${sideBarClosedWidth} + ${sideBarMarginWidth}px)`,
  height: '100vh',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
  flexDirection: 'row',
  ...(open && {
    marginLeft: `calc(${openDrawerWidth} + ${sideBarMarginWidth}px)`,
    width: `calc(100% - ${openDrawerWidth}px - ${sideBarMarginWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

// Temporary style given to grid items to demo material UI
const GridItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? TempBlack : MainWhite,
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: 0,
  width: '100%',
  display: 'flex'
}));

// This is the main layout component of the app
const RootLayout: FC = () => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const inactivityModalsState = useSelector((state: RootState) => state.modals.custom[HandleInactivityModal]);
  const selectedSideNavItem: SideNavItem = useSelector((state: RootState) => state.sideBar.selectedItem);

  const pageTitle = useSelector((state: RootState) => state.pageTitle.value);

  const idleTimeRef = useRef(0);
  const MAX_IDLE_MINS = 20; // NOTE: must also update related text in security settings if we change that
  const intervalRef = useRef<any>();

  const authorize = () => {
    if (AuthService.checkToken()) {
      AuthService.init(() => {
        AuthService.setSubscriptionInfo();
        setIsAuthorized(true);
      });
    } else {
      navigate(CrooglooUrl.LOGIN);
    }
  };

  const checkInactivityTimeoutSetting = (done: (isInactivityTimeoutEnabled: boolean) => void) => {
    ProductionService.fetchUserSetting()
      .then((isInactivityTimeoutEnabled) => {
        done(isInactivityTimeoutEnabled);
      })
      .catch((err) => {
        console.error(err);
        done(true);
      });
  };

  function handleInactivity () {
    idleTimeRef.current = 0; // prevent multiple calls to retrieve the user setting and

    if (inactivityModalsState?.isVisible) {
      return;
    }

    // make sure we only check again in MAX_IDLE_MINS if the setting is currently
    // that the user should not be logged out
    console.debug('checking inactivity timeout setting');
    checkInactivityTimeoutSetting(isInactivityTimeoutEnabled => {
      console.debug('isInactivityTimeoutEnabled: ', isInactivityTimeoutEnabled);
      if (isInactivityTimeoutEnabled) {
        ModalService.openCustomModal(HandleInactivityModal, {
          heading: 'common.handleInactivity.heading',
          content: String(t('common.handleInactivity.content', { MAX_IDLE_MINS })),
          confirmButton: 'action.logout',
          callback: () => {
            idleTimeRef.current = 0;
          }
        });
      }
    });
  }

  function timerIncrement () {
    idleTimeRef.current = idleTimeRef.current + 1;

    if (idleTimeRef.current >= MAX_IDLE_MINS) {
      try {
        handleInactivity();
      } catch (e) {
        console.error(e);
        idleTimeRef.current = 0; // must reset to avoid sending a request every time the timer is incremented if the error keeps happening
      }
    }
  }

  const setupIdleTimout = () => {
    idleTimeRef.current = 0;

    intervalRef.current = setInterval(timerIncrement, 60000);

    document.addEventListener('mousemove', () => {
      idleTimeRef.current = 0;
    });

    document.addEventListener('keypress', () => {
      idleTimeRef.current = 0;
    });
  };

  useEffect(() => {
    authorize();
  }, []);

  useEffect(() => {
    // catch a url change in the browser search bar. If not correct access, deny entry to user
    if (isAuthorized) {
      const currentUrl: CrooglooUrl = location.pathname as CrooglooUrl;
      if (!SideNavService.checkSideNavAccess(currentUrl)) {
        const urlToStayOn: CrooglooUrl = selectedSideNavItem.url;
        navigate(urlToStayOn, { replace: true });
      } else {
        SideNavService.changeSideBarItem(currentUrl);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isAuthorized) {
      setupIdleTimout();
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isAuthorized]);

  const toggleOrForceSideBarState = (forcedState?: boolean) => {
    const openSideBar: boolean = (forcedState !== undefined) ? forcedState : !isSideBarOpen;
    setSideBarOpen(openSideBar);
    dispatch(setSideBarState({ state: openSideBar }));
  };

  if (!isAuthorized) {
    return null;
  }

  return (
    <Grid container direction={'row'} spacing={0}>
      <SideBar isSideBarOpen={isSideBarOpen} forceSetSideBarState={toggleOrForceSideBarState} />
      <SideBarDivider />
      <MainArea
        spacing={0}
        open={isSideBarOpen}
        container
        direction={'column'}
        sx={{
          overflowY: (pageTitle === DashboardPage.Title) ? 'auto' : 'hidden'
        }}
      >
        <GridItem item>
          <Header useBackground={pageTitle === DashboardPage.Title} />
        </GridItem>
        <GridItem item className={styles.appNormal}>
          <DndProvider backend={HTML5Backend}>
            <Outlet />
          </DndProvider>
        </GridItem>
      </MainArea>
      <HandleInactivity />
    </Grid>
  );
};

export default RootLayout;
