import React, { lazy, Suspense } from 'react';

const LazyPendingContacts = lazy(async () => await import('./PendingContacts'));

const PendingContacts = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyPendingContacts {...props} />
  </Suspense>
);

export default PendingContacts;
