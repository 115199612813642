import React, { lazy, Suspense } from 'react';

const LazyAttachmentError = lazy(async () => await import('./AttachmentError'));

const AttachmentError = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyAttachmentError {...props} />
  </Suspense>
);

export default AttachmentError;
