import React, { lazy, Suspense } from 'react';
import type { GeneralModalProps } from './GeneralModal';

const LazyGeneralModal = lazy(async () => await import('./GeneralModal'));

const GeneralModal = (props: GeneralModalProps) => (
  <Suspense fallback={null}>
    <LazyGeneralModal {...props} />
  </Suspense>
);

export default GeneralModal;
