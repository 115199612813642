import React from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { Grid, Tabs, Tab, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { GenerateScripts } from '../../../models/Script';
import styles from '../GenerateSides.module.scss';
import ScriptService from '../../../services/ScriptService';

interface Props {
  selectedScripts: GenerateScripts[]
  tabIndex: number
  handleChange: (script: GenerateScripts) => void
  remove: (script: GenerateScripts, index: number) => void
  isScriptPanelOpen: boolean
  setScriptPanelOpen: Dispatch<SetStateAction<boolean>>
}

const SceneSelectionTabHeaders: FC<Props> = (props: Props) => {
  const {
    selectedScripts,
    tabIndex,
    handleChange,
    remove,
    isScriptPanelOpen,
    setScriptPanelOpen
  } = props;

  const tabs: string[] = selectedScripts.map((script: GenerateScripts) => ScriptService.getScriptDisplayName(script.episode, script.color));

  const handleTabSelect = (event: React.SyntheticEvent, newTab: number): void => {
    event.stopPropagation();
    const script: GenerateScripts | undefined = selectedScripts.find((selected: GenerateScripts, index: number) => index === newTab);
    if (script) {
      handleChange(script);
    }
  }

  const handleTabClose = (event: React.SyntheticEvent, tabIndex: number): void => {
    event.stopPropagation();
    const script: GenerateScripts | undefined = selectedScripts.find((selected: GenerateScripts, index: number) => index === tabIndex);
    if (script) {
      remove(script, tabIndex);
      if (isScriptPanelOpen) setScriptPanelOpen(false);
    }
  }

  return (
     <Grid container sx={{ width: '100%' }} className={styles.sceneTabHeader}>
         <Tabs
           value={tabIndex}
           aria-label="basic-tabs"
           TabIndicatorProps={{
             className: styles.tabIndicator
           }}
           classes={{
             root: styles.tabContainer,
             flexContainer: styles.flexContainer
           }}
           onChange={handleTabSelect}
         >
           {tabs.map((tab: string, index: number) => {
             return (
               <Tab
                 key={`tab-${index}`}
                 label={
                   <div>
                     <Typography>{tab}</Typography>
                     <IconButton
                       aria-label='close'
                       size='small'
                       onClick={(e: React.SyntheticEvent) => handleTabClose(e, index)}
                       className={styles.tabClose}
                       disableRipple
                     >
                       <CloseIcon fontSize={'small'}/>
                     </IconButton>
                   </div>
                 }
                 id={`simple-tab-${index}`}
                 aria-controls={`simple-tabpanel-${index}`}
                 disableRipple
                 classes={{
                   root: styles.tabRoot,
                   selected: styles.selectedTab
                 }}
                 onClick={() => { if (isScriptPanelOpen) setScriptPanelOpen(false) }}
               />
             )
           })}
         </Tabs>
     </Grid>
  )
}

export default SceneSelectionTabHeaders;
