import type { CrooglooFileTypes } from './FileTypes';
import { AccessLevel } from '../services/auth/PermissionService';
import { SecurityPageName } from '../services/auth/constants';
import { GeneralDocumentImage, IMG_DIRECTORY } from './Template';

export interface DocumentCard {
  itemid: string
  id: string
  type: CrooglooFileTypes
  subType: string
  data: string
  name: string
  datemod: string
  shared: string
  category: string
  watermark: boolean
  isEncrypted: boolean
  archived: boolean
  isPublishedToStudio: boolean
  cloudProvider: string
  sortValue: number
  size: number
  linked?: boolean
}

export interface DocumentCardResponse {
  category: string
  displayOrder: number
  entityId: string
  fileName: string
  fileSize: string
  isArchived: boolean
  isSmartFolder: boolean
  line01: string
  line01_label: string
  line02: string
  line02_label: string
  line03: string
  line03_label: string
  line04: string
  line04_label: string
  line05: string
  line05_label: string
  line06: string
  line06_label: string
  line07: string
  line07_label: string
  line08: string
  line08_label: string
  line09: string
  line09_label: string
  line10: string
  line10_label: string
  line11: string
  line11_label: string
  line12: string
  line12_label: string
  subtitle: string
  title: string
  viewName: string
  isPublishedToStudio?: boolean
  cloudProvider?: string
}

export interface Document {
  approval: string
  approvedBy: string
  approvedDate: string
  bucket: string
  category: string
  callSheetDocId?: string
  children: {
    value: string
  }
  color: string
  docDescription: string
  episode: string
  fileExtension: string
  fileName: string
  fileURL: string
  firstWatermarkLine: string
  id: string
  image: string
  isSubmitted: string
  isWatermarked: string
  lastModifBaseTime: string
  limitTo: string
  limitToType: string
  parentFolder: string
  parents: {
    value: string
  }
  priority: string
  secondWatermarkLine: string
  showInHub: string
  showInReports: string
  size: string
  source: string
  subcategory: string
  submittedBy: string
  submittedDate: string
  submittedTo: string
  subtitle: string
  timeCreated: string
  title: string
  uploader_name: string
  uploader_token: string
  versionId: string
  watermarkWithEncryption: string
  parentPath?: string[][]
}

export interface DocumentsResponse {
  properties: Document
  key: {
    kind: string
    name: string
    id: string
  }
  kind: string
}

export interface DocumentResponseItems {
  items: DocumentsResponse[]
}

export interface CollectionFile {
  id: string
  root: boolean
  parent: string
  text: string
  icon: string
  state?: {
    opened: boolean
  }
  li_attr: {
    id: string
    url?: string
    type: string
    isWatermark: string
    size: string
    subcategory?: string
    isFilled?: string
    treeIndex: number
    isPublishedToStudio: boolean
    cloudProviderName: string
    path?: string
    fileType: FileType
  }
}

export interface CollectionTreeFile extends CollectionFile {
  children: CollectionTreeFile[]
}

export interface FolderType {
  folderId: string
  nodeId: string
}

export type FileCollectionMap = Record<string, CollectionFile>;

export type CollectionTreeMap = Record<string, CollectionTreeFile>;

export interface FileAttachment {
  id: string
  fileName: string
  url: string
  isWatermarked: string
  size: number
  subcategory: string
  isPublishedToStudio: boolean
  cloudProvider: string
  fileExtension: FileType
  type: CrooglooFileTypes
}

export interface OtherFileAttachment {
  id: string
  name: string
  uploadURL: string
  size: number
  type: CrooglooFileTypes
}

export type UrlsToAdd = FileAttachment | OtherFileAttachment

export const enum FileType {
  Other,
  PDF = 'application/pdf'
}

export interface ShortenedFolder {
  id: string
  fileURL: string
  parentFolder: string
  title: string
}

export interface DirectoryDestination {
  id: string
  fileURL: string
  parentFolder: string
  title: string
  subDirectories: DirectoryDestination[]
}

export const enum DashboardCategories {
  DISTRO = 'latestDistro',
  TEMPLATES = 'productionForms'
}

export const enum PaperWallDistroCategories {
  CALLSHEET = 'callsheet',
  SCRIPT = 'script',
  PREPSCHEDULE = 'prepschedule',
  SHOOTING = 'shooting',
  CREWLIST = 'crewList',
  CHRONO = 'chrono',
  ONELINER = 'oneliner',
  SIDE = 'sides'
}

export interface DistroFile {
  id: string
  fileName: string
  isWatermarked: string
  fileExtension: string
  timeCreated: string
  fileURL: string
  parentId: string
  lastModifBaseTime: string
  size: number
  subcategory: string
}

export type PaperWallDistro = Record<PaperWallDistroCategories, DistroFile | null>

export const initialPaperWallDistro: PaperWallDistro = {
  [PaperWallDistroCategories.CALLSHEET]: null,
  [PaperWallDistroCategories.CREWLIST]: null,
  [PaperWallDistroCategories.SCRIPT]: null,
  [PaperWallDistroCategories.SIDE]: null,
  [PaperWallDistroCategories.PREPSCHEDULE]: null,
  [PaperWallDistroCategories.SHOOTING]: null,
  [PaperWallDistroCategories.ONELINER]: null,
  [PaperWallDistroCategories.CHRONO]: null
}

export interface DistroFileAccess {
  page: SecurityPageName
  level: AccessLevel
}

export type PaperWallDistroAccess = Record<PaperWallDistroCategories, DistroFileAccess>

export const PaperWallDistroSecurityLevels: PaperWallDistroAccess = {
  [PaperWallDistroCategories.CALLSHEET]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.CREWLIST]: {
    page: SecurityPageName.Crew,
    level: AccessLevel.READ_WRITE
  },
  [PaperWallDistroCategories.SCRIPT]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.SIDE]: {
    page: SecurityPageName.SidesGeneration,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.PREPSCHEDULE]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.SHOOTING]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.ONELINER]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  },
  [PaperWallDistroCategories.CHRONO]: {
    page: SecurityPageName.Compose,
    level: AccessLevel.READ_ONLY
  }
}

export type PaperWallImages = Record<PaperWallDistroCategories, string>

export const PaperWallCategoryImages: PaperWallImages = {
  [PaperWallDistroCategories.CALLSHEET]: GeneralDocumentImage,
  [PaperWallDistroCategories.CREWLIST]: IMG_DIRECTORY + 'crewList.png',
  [PaperWallDistroCategories.SCRIPT]: IMG_DIRECTORY + 'scripts.png',
  [PaperWallDistroCategories.SIDE]: IMG_DIRECTORY + 'sides.png',
  [PaperWallDistroCategories.PREPSCHEDULE]: IMG_DIRECTORY + 'prepSchedule.png',
  [PaperWallDistroCategories.SHOOTING]: IMG_DIRECTORY + 'shootingSchedule.png',
  [PaperWallDistroCategories.ONELINER]: IMG_DIRECTORY + 'onelineSchedule.png',
  [PaperWallDistroCategories.CHRONO]: IMG_DIRECTORY + 'chronoSchedule.png'
}
