import React, { lazy, Suspense } from 'react';
import type { AddDistributionListProps } from './AddDistributionList';

const LazyAddDistributionList = lazy(async () => await import('./AddDistributionList'));

const AddDistributionList = (props: AddDistributionListProps) => (
  <Suspense fallback={null}>
    <LazyAddDistributionList {...props}/>
  </Suspense>
);

export default AddDistributionList;
