import type { FC } from 'react';
import { Grid, Box, Checkbox, TextField, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import type { OptionProps } from '../../../models/OptionProps';
import { FileTypes } from '../../../models/FileTypes';
import UtilityService from '../../../services/UtilityService';
import type { AttachmentItem } from '../SaveTemplateFlow';
import { InitiailAttachmentItem } from '../SaveTemplateFlow';
import { MainWhite, WhiteGrey, BlackGrey, BorderGrey } from '../../../theme/colors';
import { defaultCheckboxStyles } from '../../../theme/styles';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

interface Props {
  selectedItems: AttachmentItem[]
  handleSelectedItems: (selectedItems: AttachmentItem[]) => void
}

const MaxNoOfRows: number = 15;

/**
 * Attachment Options Component for the save template flow - step 2
 * TODO: have the form here and in step 3 using the same form?
 */
const AttachmentOptions: FC<Props> = ({ selectedItems, handleSelectedItems }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const options: OptionProps[] = UtilityService.getFileCategories(FileTypes.ReportsAndSchedules)
    .map((option: OptionProps) => ({ ...option, label: t(option.label) }));

  const onClick = (index: number): void => {
    let newSelectedItems: AttachmentItem[] = [...selectedItems];
    let attachmentItem: AttachmentItem | undefined = selectedItems.find((item: AttachmentItem, itemIndex: number) => index === itemIndex);
    if (attachmentItem) {
      if (attachmentItem.checked) {
        attachmentItem = { ...attachmentItem, checked: false };
        newSelectedItems = selectedItems.filter((item: AttachmentItem, itemIndex: number) => itemIndex !== index);
        const itemsChecked: number = selectedItems.filter((item: AttachmentItem) => item.checked).length;
        if (selectedItems.length === MaxNoOfRows && itemsChecked === MaxNoOfRows) { // don't remove just uncheck
          newSelectedItems = [...newSelectedItems.slice(0, index), attachmentItem, ...newSelectedItems.slice(index)];
        } else if (selectedItems.length === 1) {
          newSelectedItems = [attachmentItem];
        }
      } else {
        attachmentItem = { ...attachmentItem, checked: true };
        newSelectedItems = selectedItems.filter((item: AttachmentItem, itemIndex: number) => itemIndex !== index);
        newSelectedItems = [...newSelectedItems.slice(0, index), attachmentItem, ...newSelectedItems.slice(index)];
        if (selectedItems.length < MaxNoOfRows) {
          newSelectedItems = [...newSelectedItems, InitiailAttachmentItem];
        }
      }
    }
    handleSelectedItems(newSelectedItems);
  }

  const changeCategory = (event: React.SyntheticEvent, index: number, value: string): void => {
    event.stopPropagation();
    let newSelectedItems: AttachmentItem[] = [...selectedItems];
    let attachmentItem: AttachmentItem | undefined = selectedItems.find((item: AttachmentItem, itemIndex: number) => index === itemIndex);
    if (attachmentItem) {
      attachmentItem = { ...attachmentItem, subcategory: value };
      newSelectedItems = selectedItems.filter((item: AttachmentItem, itemIndex: number) => itemIndex !== index);
      newSelectedItems = [...newSelectedItems.slice(0, index), attachmentItem, ...newSelectedItems.slice(index)];
    }
    handleSelectedItems(newSelectedItems);
  }

  const changeQuantity = (event: React.SyntheticEvent, index: number): void => {
    event.stopPropagation();
    const value: number = Number((event.target as HTMLInputElement).value);
    let newSelectedItems: AttachmentItem[] = [...selectedItems];
    let attachmentItem: AttachmentItem | undefined = selectedItems.find((item: AttachmentItem, itemIndex: number) => index === itemIndex);
    if (attachmentItem) {
      attachmentItem = { ...attachmentItem, quantity: value };
      newSelectedItems = selectedItems.filter((item: AttachmentItem, itemIndex: number) => itemIndex !== index);
      newSelectedItems = [...newSelectedItems.slice(0, index), attachmentItem, ...newSelectedItems.slice(index)];
    }
    handleSelectedItems(newSelectedItems);
  }

  return (
      <Grid
        item
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        spacing={2}
      >
      {selectedItems.map((item: AttachmentItem, index: number) => {
        const itemQuantity = (item.quantity === 0) ? '' : item.quantity;
        return (
        <Grid
          item
          container
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          key={`attachment-option-${index}`}
        >
          <Grid item xs={1}>
            <Checkbox
              color={'success'}
              onChange={() => onClick(index)}
              checked={item.checked}
              sx={{ ...defaultCheckboxStyles }}
            />
          </Grid>
            <Grid container item xs={5}>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box component={'span'}>{t('compose.saveTemplate.attachmentOptions.attach')}</Box>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='quantity'
                  type='number'
                  value={itemQuantity}
                  onChange={(event: React.SyntheticEvent) => changeQuantity(event, index)}
                  sx={{
                    height: '40px',
                    '& .MuiOutlinedInput-root': {
                      height: 'inherit',
                      borderRadius: '12px',
                      border: `1px solid ${BorderGrey} !important`,
                      '&:hover': {
                        background: WhiteGrey,
                        border: `1px solid ${BorderGrey}`
                      },
                      '& fieldset': {
                        border: 'none',
                        height: 'inherit'
                      },
                      '&.Mui-focused': {
                        border: `1px solid ${BorderGrey}`,
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
              <Grid container item xs={6}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box component={'span'}>{t('compose.saveTemplate.attachmentOptions.latest')}</Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        width: '100%',
                        height: '40px',
                        border: `1px solid ${BorderGrey}`,
                        borderRadius: '12px',
                        color: BlackGrey,
                        background: MainWhite,
                        '&:hover': {
                          background: WhiteGrey
                        },
                        '&:active': {
                          background: MainWhite,
                          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none'
                        }
                      }}
                      value={item.subcategory}
                    >
                      {options.map((option: OptionProps) => (
                        <MenuItem
                          key={option.key}
                          value={option.value}
                          onClick={(event: React.SyntheticEvent) => changeCategory(event, index, option.value)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
              </Grid>
          </Grid>
        )
      })}
      </Grid>
  )
}

export default AttachmentOptions;
