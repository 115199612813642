import { store } from '../state/store';
import { SettingsApi } from './auth/constants';
import type { ApiDataType } from './auth/constants';
import AuthService from './auth/AuthService';
import type { WSResponse } from '../models/WSResponse';
import { PatchMethod } from './constants';
import { setCachedReplyToEmail } from '../state/slices/DistributionReducer';
import UtilityService from './UtilityService';
const { dispatch } = store;

const ReplyToProp: string = 'reply-to email';

interface ChangeWatermark {
  firstLine: string
  secondLine: string
  fileId: string
}

interface ISettingsService {
  fetchCachedData: () => Promise<WSResponse>
  updateCachedData: (email: string) => void
  changeWatermarkSettings: (data: ChangeWatermark) => Promise<WSResponse>
}

class SettingsService implements ISettingsService {
  /**
   * fetch cachedData for reply-to email
   */
  async fetchCachedData (): Promise<WSResponse> {
    return await makeSettingsCall('fetchCachedData', {
      params: {
        propName: ReplyToProp
      }
    });
  }

  /**
   * update cachedData for reply-to email
   */
  updateCachedData (email: string): void {
    const chachedEmail: string | null = store.getState().distribution.cachedReplyToEmail;
    if (email !== chachedEmail) {
      makeSettingsCall('updateCachedData', {
        params: {
          propName: ReplyToProp,
          propValue: email
        },
        type: PatchMethod
      }).then((result: WSResponse) => {
        if (!UtilityService.isSuccessResponse(result.responseCode)) {
          console.error('Server Error');
        } else {
          dispatch(setCachedReplyToEmail({ email }))
        }
      }).catch(() => {
        console.error('failed to cache reply-to email');
      })
    }
  }

  async changeWatermarkSettings (data: ChangeWatermark): Promise<WSResponse> {
    return await makeSettingsCall('changeWatermarkSettings', {
      params: {
        isWatermarked: '1',
        firstLine: data.firstLine,
        secondLine: data.secondLine,
        addEncryption: 'false',
        alt: 'json'
      },
      body: JSON.stringify({
        value: data.fileId
      }),
      type: PatchMethod
    });
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeSettingsCall = async (method: string, data: ApiDataType): Promise<any> => {
  return await AuthService.apiCall(SettingsApi, method, data)
}

const settingsService: ISettingsService = new SettingsService();
export default settingsService;
