import { ImportExportApi } from './auth/constants';
import type { ApiDataType } from './auth/constants';
import AuthService from './auth/AuthService';
import { PostMethod } from './constants';
import type { WSResponse } from '../models/WSResponse';
import UtilityService from './UtilityService';
import DownloadNotifications from './notifications/DownloadNotifications';
import moment from 'moment';

interface IImportExportService {
  exportDistributionListToExcel: (selected: string[]) => Promise<void>
  downloadAllFiles: () => Promise<void>
}

class ImportExportService implements IImportExportService {
  async exportDistributionListToExcel (selected: string[]): Promise<void> {
    return await makeImportExportCall('exportDistributionListToExcel', {
      params: {
        distributionList: selected.join(',')
      },
      type: PostMethod
    })
      .then((resp: WSResponse) => {
        if (resp) {
          setTimeout(function () {
            if (resp.responseMessage) {
              window.location.href = resp.responseMessage;
            }
          }, 500);
        }
      })
      .catch((err) => {
        console.error(err);
        throw new Error('error');
      })
  }

  /**
   * Download All files after cancelling subscription
   */
  async downloadAllFiles (): Promise<void> {
    await makeImportExportCall('downloadAllFiles', {})
      .then((resp: WSResponse) => {
        if (UtilityService.isSuccessResponse(resp.responseCode) && resp.responseMessage) {
          const responseMessage: string[] = resp.responseMessage.split(',');
          DownloadNotifications.add(false, false, responseMessage[0], responseMessage[1], moment().valueOf());
        } else {
          throw new Error('error downloading files');
        }
      })
      .catch((err) => {
        console.error(err.message);
        throw new Error('error downloading files');
      })
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeImportExportCall = async (method: string, data: ApiDataType): Promise<any> => {
  const result = await AuthService.apiCall(ImportExportApi, method, data);
  return result;
}

const importExportService: IImportExportService = new ImportExportService();
export default importExportService;
