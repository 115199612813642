export interface Subscription {
  billingPeriod: string
  isOffered: boolean
  isVisibleInStore: boolean
  name: string
  planId: string
  price: number
  productionType: string
  replacedBy: string
  totalNbOfProds: number
}

export enum Currencies {
  CADollars = 'cad',
  USDollars = 'usd'
}

export enum Countries {
  Canada = 'canada',
  US = 'united-states'
}

export const CountryCurrencyMap: Record<Countries, Currencies> = {
  [Countries.Canada]: Currencies.CADollars,
  [Countries.US]: Currencies.USDollars
}

export interface ShippingDetails {
  name: string
  address: string
  city: string
  state: string
  postalCode: string
  country: string
}

export interface BillingDetails {
  name: string
  address: string
  city: string
  state: string
  postalCode: string
  country: string
}
