import React, { lazy, Suspense } from 'react';
import { type Props } from './Upload';

const LazyUpload = lazy(async () => await import('./Upload'));

const Upload = (props: Props) => (
  <Suspense fallback={null}>
    <LazyUpload {...props} />
  </Suspense>
);

export default Upload;
