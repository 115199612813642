/*
 * Reducer file for the SecurityAdmin slice
 */

import { createSlice } from '@reduxjs/toolkit';
import { SecurityAdminSlice } from './constants';
import type { SecurityList } from '../../models/SecurityList';
import type { SecurityGroup, RestrictredMember, SecurityGroupMembersList } from '../../models/SecurityGroups';
import type { SecurityListMember } from '../../models/SecurityListMembers';

export interface SecurityAdminState {
  securityLists: SecurityList[]
  securityGroups: SecurityGroup[]
  restrictedMember: RestrictredMember
  securityGroupMembers: SecurityGroupMembersList
}

const initialState: SecurityAdminState = {
  securityLists: [],
  securityGroups: [],
  restrictedMember: {
    productionOwnerPersonId: '',
    activeUserPersonId: '',
    activeUserSecurityListId: ''
  },
  securityGroupMembers: {}
}

export const securityAdminSlice = createSlice({
  name: SecurityAdminSlice,
  initialState,
  reducers: {
    setSecurityLists: (state, action) => {
      state.securityLists = action.payload.securityLists;
    },
    setSecurityGroups: (state, action) => {
      state.securityGroups = action.payload.securityGroups;
    },
    addRestrictedMember: (state, action) => {
      const restrictedMember: RestrictredMember = action.payload.restrictedMember;
      state.restrictedMember = restrictedMember;
    },
    setSecurityGroupMembers: (state, action) => {
      const securityGroup: string = action.payload.securityGroup;
      const members: SecurityListMember[] = action.payload.members;
      state.securityGroupMembers[securityGroup] = {
        id: securityGroup,
        members
      }
    }
  }
});

export const {
  setSecurityLists, setSecurityGroups, addRestrictedMember, setSecurityGroupMembers
} = securityAdminSlice.actions;
export default securityAdminSlice.reducer;
