import type { FC, SyntheticEvent } from 'react';
import { Grid } from '@mui/material';
import TabItem from '../DistributionLists/TabItem';

interface Props {
  item: string
  handleSelect: (event: SyntheticEvent) => void
  isSelected: boolean
}

/**
 * Tab Item Component for the Slide Drawer Component containing lists or departemnts tabs
 * Each tab item has a list item with a header compoent and a member component passing into the custmom list accordion
 */
const GroupsTabItem: FC<Props> = (props: Props) => {
  return (
    <>
      <Grid container alignItems={'center'}>
        <TabItem
          key={`list-${props.item}`}
          value={props.item}
          label={props.item}
          handleItemSelect={props.handleSelect}
          selected={props.isSelected}
        />
      </Grid>
    </>
  )
}

export default GroupsTabItem;
