import ScriptService from './ScriptService';
import DocumentService from './documents/DocumentService';
import type { CalenderEntity, CalenderResponse, ClosestCallSheet } from '../models/CalenderEntity';
import { CalenderEntities } from '../models/CalenderEntity';
import type { WSCalenderResponse } from '../models/WSResponse';
import {
  setClosestCallSheet, setLoadingData, setPaperWallDistros, setRestrictedCommunity
} from '../state/slices/DashboardReducer';
import type { DistroFile, DocumentCard } from '../models/Document';
import {
  FileType, PaperWallDistroCategories,
  PaperWallDistroSecurityLevels, DashboardCategories
} from '../models/Document';
import { CrooglooFileTypes } from '../models/FileTypes';
import PermissionService, { AccessLevel } from './auth/PermissionService';
import { SecurityPageName } from './auth/constants';
import { store } from '../state/store';
import type { Moment } from 'moment';
import moment from 'moment';

const { dispatch } = store;

export const PaperWallDistros: PaperWallDistroCategories[] = [
  PaperWallDistroCategories.CALLSHEET,
  PaperWallDistroCategories.CREWLIST,
  PaperWallDistroCategories.SCRIPT,
  PaperWallDistroCategories.SIDE,
  PaperWallDistroCategories.PREPSCHEDULE,
  PaperWallDistroCategories.SHOOTING,
  PaperWallDistroCategories.ONELINER,
  PaperWallDistroCategories.CHRONO
];

const PaperWallDistroLength: number = PaperWallDistros.length - 1;
export const DashboardTimeId: string = 'DASHBOARDTIME';

interface IDashboardService {
  loadDashboardCallTime: () => void
  loadPaperDistros: () => void
  openFile: (paperWallDistro: DistroFile) => Promise<void>
  checkDtrAvailable: (securityListId: string, adminSecurityList: string, dhSecurityList: string) => boolean
  hasComposePermission: () => boolean
  hasSentPermission: () => boolean
  hasNavigatePermission: (isAdminUser: boolean, paperCategory: PaperWallDistroCategories) => boolean
  getDashboardCategoryList: () => DashboardCategories[]
  checkRestrictedCommunity: () => void
}

/**
 * Dashboard Service handles getting any data from backend for dashboard
 */
class DashboardService implements IDashboardService {
  loadDashboardCallTime (): void {
    ScriptService.getCalendarEntities(CalenderEntities.CallSheet)
      .then((resp: WSCalenderResponse) => {
        const entityItems: CalenderResponse[] = resp.items;
        const entities: CalenderEntity[] = entityItems.map((entity: CalenderResponse) => ({
          ...entity.properties
        }));
        let closestCallSheet: ClosestCallSheet | undefined;
        let closestCallSheetDiff: number | null = null;
        const today: Moment = moment();
        entities.forEach((entity: CalenderEntity) => {
          const date: Moment = moment(entity.date + ' ' + entity.month + ', ' + entity.year, 'DD MMMM, YYYY');
          const daysDiff: number = date.diff(today, 'days');
          if (daysDiff >= 0 && (!closestCallSheetDiff || closestCallSheetDiff > daysDiff)) {
            const callTime: Moment = moment(entity.callTime, 'HHmm');
            closestCallSheet = {
              date: date.format('MMMM Do'),
              callTime: callTime.format('h:mm A')
            };
            closestCallSheetDiff = daysDiff;
          }
        });
        if (closestCallSheet) {
          const invalidDate: string = 'Invalid date';
          if (invalidDate !== closestCallSheet.callTime && invalidDate !== closestCallSheet.date) {
            dispatch(setClosestCallSheet({ callSheet: closestCallSheet }));
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  loadPaperDistros (): void {
    PaperWallDistros.forEach((paperWallDistro: PaperWallDistroCategories, index: number) => {
      loadPaperWallDocuments(paperWallDistro, index);
    });
  }

  /**
   * open file from dashboard in file view or download if not possible in file viewer
   * @param paperWallDistro
   */
  async openFile (paperWallDistro: DistroFile): Promise<void> {
    if (String(CrooglooFileTypes.PDF) === paperWallDistro.fileExtension) {
      await DocumentService.openFile({
        id: paperWallDistro.id,
        fileName: paperWallDistro.fileName,
        url: paperWallDistro.fileURL,
        isWatermarked: paperWallDistro.isWatermarked,
        size: paperWallDistro.size,
        subcategory: paperWallDistro.subcategory,
        isPublishedToStudio: false,
        cloudProvider: '',
        fileExtension: FileType.PDF,
        type: CrooglooFileTypes.PDF
      });
    } else {
      await DocumentService.fetchDownloadLink(paperWallDistro.id)
        .then((url: string) => {
          if (!url) {
            throw new Error('documents.download.error');
          }
          DocumentService.handleDownloadFile(url, paperWallDistro.fileName);
        })
        .catch((err) => {
          console.error(err);
          throw new Error('documents.download.error');
        });
    }
  }

  /**
   * Check if dtr panel is available to the user based on their dtr permission, security list amd if folder exists
   * @param securityListId
   * @param adminSecurityList
   * @param dhSecurityList
   */
  checkDtrAvailable (securityListId: string, adminSecurityList: string, dhSecurityList: string): boolean {
    let dtrAvailable: boolean = false;
    if (PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.DailyTimeReports)) {
      const timeReports: DocumentCard[] = store.getState().documents.checkDocuments ?? [];
      if ([adminSecurityList, dhSecurityList].includes(securityListId)) {
        if (timeReports.length > 0) {
          dtrAvailable = true;
        }
      }
    }
    return dtrAvailable;
  }

  /**
   * Check to see if the user have the correct permissions to view email and text on the dashboard
   */
  hasComposePermission (): boolean {
    return PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Compose);
  }

  /**
   * Check to see if the user has the correct permission to view sent distribution page from the dashboard
   */
  hasSentPermission (): boolean {
    return PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Sent);
  }

  /**
   * Check to see if the user has the correct permissions for the relevant pages
   * to be able to navigate to them from the dashboard
   * @param isAdminUser
   * @param paperCategory
   */
  hasNavigatePermission (isAdminUser: boolean, paperCategory: PaperWallDistroCategories): boolean {
    let hasCorrectPermission: boolean = false;
    if (isAdminUser &&
      PermissionService.hasPermission(
        PaperWallDistroSecurityLevels[paperCategory].level, PaperWallDistroSecurityLevels[paperCategory].page
      )
    ) {
      hasCorrectPermission = true;
    }
    return hasCorrectPermission;
  }

  /**
   * Get dashboard categories from list
   * This used to remove dashboard categories depending on the users community profile
   * That has been removed for now. But this function is still in place as it may be added back in
   */
  getDashboardCategoryList (): DashboardCategories[] {
    const DashboardCategoryList: DashboardCategories[] = [
      DashboardCategories.DISTRO, DashboardCategories.TEMPLATES
    ];
    return DashboardCategoryList;
  }

  /**
   * Check to see if the id of the community of the user is in the array of restricted communites
   * This hides some parts of the dashboard if the user is one of the communites
   * Note: just have an array of communities for now
   * There may be a better way to do this eventually with Security Profile
   * For now this is the only way
   */
  checkRestrictedCommunity (): void {
    const RestrictedCommunities: string[] = [DashboardTimeId, 'DASHBOARDASSISTANT'];
    const authState = store.getState().auth;
    const communityId: string = authState.tenantId;
    const restrictedCommunity = RestrictedCommunities.includes(communityId);
    dispatch(setRestrictedCommunity({ restrictedCommunity, communityId }));
  }
}

function loadPaperWallDocuments (paperWallDistro: PaperWallDistroCategories, index: number): void {
  DocumentService.fetchDocumentsBySubcategory(String(paperWallDistro))
    .then((files: DistroFile[]) => {
      if (files.length > 0) {
        files = sortFiles(files);
        const file: DistroFile = files[0];
        if (file) {
          dispatch(setPaperWallDistros({
            category: paperWallDistro,
            file
          }));
        }
      }
      if (PaperWallDistroLength === index) {
        dispatch(setLoadingData({ loadingData: false }));
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

function sortFiles (files: DistroFile[]): DistroFile[] {
  return files.sort((a: DistroFile, b: DistroFile) => {
    return Number(a.lastModifBaseTime) > Number(b.lastModifBaseTime) ? -1 : 1;
  })
}

const dashboardService: IDashboardService = new DashboardService();
export default dashboardService;
