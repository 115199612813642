import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import './createProduction.css';
import actionBoxIcon from '../../assets/icons/bitmap/clapboard.png';
import logo from '../../assets/img/CreateProduction/logo_off_white.png';
import background from '../../assets/img/create_prod_bg.jpg';

import authService from '../../services/auth/AuthService';
import { Currencies, type Subscription } from '../../models/Subscription';
import { useParams } from 'react-router-dom';
import utilityService from '../../services/UtilityService';
import { useForm } from 'react-hook-form';
import { Backdrop, CircularProgress } from '@mui/material';
import GeneralModal from '../modals/GeneralModal/GeneralModal';
import { ConfirmModal } from '../modals/ConfirmSend';
import { ModalIcons } from '../modals/ModalIcon/ModalIcon';

const publicApiUrl = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT ?? ''}/_ah/api/productionapi/v9`

export enum CreateProductionPage {
  Title = 'Create Production'
}

interface Props {
  pageTitle: CreateProductionPage
}

let productionTypeById: Subscription | undefined;

// This is the link for Assistant: http://localhost:8080/createproduction.html?isTrial=0&plan=price_1LQc1iLWuCSbPXuvfWxAx0bh
// This is the link for Time: http://localhost:8080/createproduction.html?isTrial=0&plan=price_1MW0BSLWuCSbPXuviBcJJQ1n
// This is the link for Office: http://localhost:8080/createproduction.html?isTrial=0&plan=price_1LQc3CLWuCSbPXuvtWXRlQFk
const CreateProduction: FC<Props> = ({ pageTitle }: Props) => {
  const { planId } = useParams();

  const [signature, setSignature] = useState<string>('');
  const [feedback, setFeedback] = useState<string>('');
  const [launchProductionLabel] = useState<string>('Launch Production');
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [successDialogContent, setSuccessDialogContent] = useState<string>('');

  const [showNameTakenDialog, setShowNameTakenDialog] = useState<boolean>(false);
  const [nameTakenContent, setNameTakenContent] = useState<string>('');
  const [nameTakenHeader, setNameTakenHeader] = useState<string>('');

  const [showPleaseWaitDialog, setShowPleaseWaitDialog] = useState<boolean>(false);

  const [showCreateConfirm, setShowCreateConfirm] = useState<boolean>(false);
  const [confirmDialogContent, setConfirmDialogContent] = useState<string>('');

  const defaultValues = {
    production_name: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    how_did_you_hear: ''
  }

  const { getValues, register } = useForm({
    defaultValues
  });

  useEffect(() => {
    void (async () => {
      const productLists = await authService.fetchStoreProducts(Currencies.USDollars, planId ?? '');

      productionTypeById = productLists.find(product => product.planId === planId);

      if (productionTypeById) {
        const planName = productionTypeById.name.replace('Croogloo ', '');
        // Based on the production type, modify the text and colours we show on the page
        if (planName === 'Production Assistant') {
          buildHtmlForProductionAssistant();
        }
        if (planName === 'Production Time') {
          buildHtmlForProductionTime();
        }
        if (planName === 'Production Office') {
          buildHtmlForProductionOffice();
        }
      }
    })();
  }, []);

  const buildHtmlForProductionAssistant = () => {
    // Set the colour of the package text

    const packageElement = document.getElementById('package');

    if (packageElement) {
      packageElement.innerHTML = 'Side Kit Includes:';
      packageElement.style.color = '#F04937';
    }

    const packageInfoElements = document.querySelectorAll('[id="package_info"]');
    // Append the right checkmark image
    // const checkmarkElementHtml = '<img alt="checkbox" src="/assets/img/registration/red_checkmark.png" alt="checkmark" id="checkmark">';
    // Modify the package information to be correct
    if (packageInfoElements.length === 4) {
      packageInfoElements[0].innerHTML = 'Sides';
      packageInfoElements[1].innerHTML = 'Watermark';
      packageInfoElements[2].innerHTML = 'Cloud Sync';
      packageInfoElements[3].innerHTML = 'Publish to Studio';
    } else {
      console.error('Could not build package contents information, incorrect number of elements: ' + String(packageInfoElements.length));
    }

    setFeedback('“Using Croogloo has been such an\n infinite help, our Executive\n' +
      'Producer actually said that these\n' +
      'are the best sides he has ever\n' +
      'seen so thank you x a million!!”');
    setSignature('Kait D. \nAssistant Production Coordinator');
  }

  const buildHtmlForProductionTime = () => {
    // Set the colour of the package text and add a tooltip on hover
    const packageElement = document.getElementById('package');

    if (packageElement) {
      packageElement.innerHTML = 'Reports Kit Includes:';
      packageElement.classList.remove('text_futura_26');
      packageElement.style.color = '#F4DA1C';
      packageElement.classList.add('text_futura_larger');
    }

    const packageInfoElements = document.querySelectorAll('[id="package_info"]');

    // Modify the package information to be correct
    if (packageInfoElements.length === 4) {
      packageInfoElements[0].innerHTML = 'Sides';
      packageInfoElements[1].innerHTML = 'Daily Time Report';
      packageInfoElements[2].innerHTML = 'Daily Product Report Grid';
      packageInfoElements[3].innerHTML = 'Weekly Timesheet in EP or C&C';
    } else {
      console.error('Could not build package contents information, incorrect number of elements: ' + String(packageInfoElements.length));
    }

    // document.getElementById('feedbackBox').innerHTML = '“Loving that all the DTRs get<br>combined into one excel file -<br>so convenient for me”'
    setFeedback('“Loving that all the DTRs get\ncombined into one excel file -\nso convenient for me”');

    setSignature('Ryley\nProduction Coordinator');
  }

  const buildHtmlForProductionOffice = () => {
    const packageElement = document.getElementById('package');

    if (packageElement) {
      // Set the colour of the package text and add a tooltip hover
      packageElement.innerHTML = 'Distribution Kit Includes:';
      packageElement.style.color = '#0B24F8';
      packageElement.classList.remove('text_futura_26');
      packageElement.classList.add('text_futura_larger');
    }

    const packageInfoElements = document.querySelectorAll('[id="package_info"]');

    if (packageInfoElements.length === 4) {
      packageInfoElements[0].innerHTML = 'Sides';
      packageInfoElements[1].innerHTML = 'Watermark';
      packageInfoElements[2].innerHTML = 'Email Distribution';
      packageInfoElements[3].innerHTML = 'Recipient Tracking';
    } else {
      console.error('Could not build package contents information, incorrect number of elements: ' + String(packageInfoElements.length));
    }

    const featuresContainer = document.getElementById('included_in_every_package_container');

    if (featuresContainer) {
      featuresContainer.innerHTML +=
        '<div class="text_futura_small_pt text_black includes">✓ Live Onboarding</div>';
    }

    // setLaunchProductionLabel('Send Distribution!');
    /* document.getElementById('feedbackBox').innerHTML = '“I’ve used Croogloo for several<br>shows. It’s intuitive, user<br>' +
      'friendly, and incredibly easy to<br>use. Watermarking and<br>distribution is a breeze.”'; */

    setFeedback('“I’ve used Croogloo for several\nshows. It’s intuitive, user\n' +
      'friendly, and incredibly easy to\nuse. Watermarking and\ndistribution is a breeze.”');

    setSignature('Bobby\nProduction Coordinator');
  }

  const createProduction = () => {
    const values = getValues();

    setShowCreateConfirm(true);
    setConfirmDialogContent('Confirm Your Email Address\n' +
      'Is the following email address correct?\n' +
      values.email);
  }

  const handleRedirectConfirm = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      window.location.href = window.location.origin;
      setShowSuccessDialog(false);

      resolve();
    });
  }

  const handleEmailUnavailableConfirm = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      setShowNameTakenDialog(false);

      resolve();
    });
  }

  const handlePleaseWaitConfirm = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      setShowPleaseWaitDialog(false);

      const values = getValues();

      void (async () => {
        const url = `${publicApiUrl}/validateNewProductionInfo?communityId=` +
          `${values.production_name}&newUserEmail=${encodeURIComponent(values.email)}`

        const response = await fetch(url, {
          method: 'POST',
          credentials: 'omit',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const result = await response.json();
        if (result && result.responseCode === '2') {
          // CREATE THE PRODUCTION

          const createProdUrl = `${publicApiUrl}/createProductionEnvironment?` +
            `communityId=${values.production_name.toUpperCase()}` +
            `&productionDescription=${values.production_name}` +
            `&productionType=${productionTypeById?.productionType ?? ''}` +
            `&productionOwnerEmail=${encodeURIComponent(values.email)}` +
            `&planId=${planId ?? ''}`;

          const response = await fetch(createProdUrl, {
            method: 'POST',
            credentials: 'omit',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          const result = await response.json();

          if (result && result.responseCode === '0') {
            const createProdAdminUrl = `${publicApiUrl}/createPaidProductionAdmin` +
              `?tenantId=${encodeURIComponent(values.production_name.toUpperCase())}` +
              `&email=${encodeURIComponent(values.email)}` +
              `&password1=${values.password}` +
              `&firstName=${encodeURIComponent(values.first_name)}` +
              `&lastName=${encodeURIComponent(values.last_name)}` +
              `&mobile&url=${encodeURIComponent(window.location.origin)}` +
              `&productionType=${productionTypeById?.productionType ?? ''}` +
              `&howDidYouHearAboutUs=${values.how_did_you_hear}`;

            const adminResponse = await fetch(createProdAdminUrl, {
              method: 'POST',
              credentials: 'omit',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            const result = await adminResponse.json();

            if (result.responseCode === '0') {
              setShowBackdrop(false);

              setSuccessDialogContent(
                'Creation Successful\n' +
                'We\'ve sent an email to\n' +
                `${values.email}\n` +
                'to validate your account.\n' +
                'Your free trial has begun.'
              );
              setShowSuccessDialog(true);
            } else {
              setShowBackdrop(false);

              setNameTakenHeader('Info');
              setNameTakenContent('Error creating production.')
              setShowNameTakenDialog(true);
            }
          }
        } else {
          setShowBackdrop(false);

          if (result.responseCode === '1') {
            setNameTakenContent('This email address is already taken. If you are the proud\n owner of a Croogloo account' +
              ', you can log in now and\ncreate a new production environment from the user menu.');
            setNameTakenHeader('Email Address Unavailable');
          } else {
            setNameTakenContent('A production with that name already exists.');
            setNameTakenHeader('Production Name Unavailable');
          }

          setShowNameTakenDialog(true);
        }
      })();

      resolve();
    });
  }

  const handleConfirmLanuch = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      setShowCreateConfirm(false);

      setShowBackdrop(true);
      setShowPleaseWaitDialog(true);

      resolve();
    });
  }

  const handleConfirmCancel = async (): Promise<void> => {
    return await new Promise((resolve) => {
      setShowCreateConfirm(false);

      resolve();
    });
  }

  return (
    <div className="container">

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GeneralModal
        modalKey={ConfirmModal}
        isVisible={showCreateConfirm}
        title={''}
        width={400}
        content={confirmDialogContent}
        heading={'Confirm Your Email Address'}
        confirmButton={'Yes, Launch'}
        cancelButton={'No, Fix it!'}
        handleConfirm={handleConfirmLanuch}
        handleCancel={handleConfirmCancel}
        hideCloseButton={false}
        iconType={ModalIcons.Question}
      />

      <GeneralModal
        modalKey={ConfirmModal}
        isVisible={showSuccessDialog}
        title={''}
        width={400}
        content={successDialogContent}
        heading={'Creation Successful'}
        confirmButton={'OK'}
        isCancelButton={false}
        handleConfirm={handleRedirectConfirm}
        hideCloseButton={false}
        iconType={ModalIcons.Success}
      />

      <GeneralModal
        modalKey={ConfirmModal}
        isVisible={showNameTakenDialog}
        title={''}
        width={400}
        content={nameTakenContent}
        heading={nameTakenHeader}
        confirmButton={'OK'}
        isCancelButton={false}
        handleConfirm={handleEmailUnavailableConfirm}
        hideCloseButton={false}
        iconType={ModalIcons.Warning}
      />

      <GeneralModal
        modalKey={ConfirmModal}
        isVisible={showPleaseWaitDialog}
        title={''}
        width={400}
        content={
          'Please wait up to one minute while we create your production. A pop-up will appear if it succeeded or failed with why and you can try again.'}
        heading={'Action!'}
        confirmButton={'OK'}
        isCancelButton={false}
        handleConfirm={handlePleaseWaitConfirm}
        hideCloseButton={false}
        iconType={ModalIcons.Info}
      />

      <div className="form_container">
        <div className="top_section">
          <div className="header_nav">
            <div className="logo_prod">
              <a href="https://croogloo.com">
                <img src={logo} alt="logo"/>
              </a>
            </div>
            <div className="login_menu">
{/*              <div className="login_button">
                <div className="text_futura_original text_white text_align_center"><a
                  href="https://mycroogloo.com/login.html"
                  className="login_no_underline text_futura_original text_white text_align_center">Login</a></div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="content_parent">
          <div className="image-container">
            <div className="cp_content">
              <div className="info_container package_info">
                <div id="package" className="package text_futura_26"></div>
                <div className="package_container">
                  {/* These will be built in javascript based on the package that the user selects */}
                  {/* <div class="black_line"></div> */}
                  <div className="text_futura_small_500 text_black" id="package_info"></div>
                  {/* <div class="black_line"></div> */}
                  <div className="text_futura_small_500 text_black" id="package_info"></div>
                  {/* <div class="black_line"></div> */}
                  <div className="text_futura_small_500 text_black" id="package_info"></div>
                  {/* <div class="black_line"></div> */}
                  <div className="text_futura_small_500 text_black" id="package_info"></div>
                  {/* <div class="black_line"></div> */}
                </div>
                <hr className="separator"/>
                <div className="included_in_every_package_container" id="included_in_every_package_container">
                  <div className="text_futura_larger text_black included_in_every_package"
                       style={{ fontSize: '16px' }}>Included in every package
                  </div>
                  <div className="text_futura_small_pt text_black includes">✓ Daily backups of your data to prevent
                    permanent
                    data loss
                  </div>
                  <div className="text_futura_small_pt text_black includes">✓ SSL data encryption (the same as banks)
                  </div>
                  <div className="text_futura_small_pt text_black includes">✓ Unlimited Accounts</div>
                  <div className="text_futura_small_pt text_black includes">✓ Unlimited Storage</div>
                  <div className="text_futura_small_pt text_black includes">✓ Advanced Security Settings</div>
                  {/* Two elements get added here if the production type is Office */}
                </div>

                <div id="feedbackBox" className="feedback_box">
                  {utilityService.breakText(feedback)}
                </div>
                <div id="feedbackSignatureBox" className="feedback_signature_box">{utilityService.breakText(signature ?? '')}</div>
              </div>
              <div className="info_container registration_info">
                <form>
                  <div className="flex_center_container">
                    <img src={actionBoxIcon} alt="Action Box" style={{ transform: 'rotate(338deg)', height: '120px' }}/>
                  </div>
                  <div className="production_header">LAUNCH YOUR PRODUCTION</div>
                  <div className="registration_fields_div" id="registration_fields_div_id">
                    <div className="registration_fields">

                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>Production Name</span>
                        <input {...register('production_name')} className="input_field_long futura_input_field" type="text"
                               id="production_name" required/>
                      </div>

                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>Email<span style={{ marginLeft: '5px' }}
                                                                   className="text_futura_small_pt text_black">(Username)</span></span>
                        <input className="input_field_short futura_input_field" type="email" {...register('email')} id="email"
                               autoComplete="off" required/>
                      </div>

                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>Password</span>
                        <input className="input_field_short futura_input_field" type="password" {...register('password')}
                               id="password"
                               autoComplete="off" required/>

                      </div>

                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>First Name</span>
                        <input className="input_field_short futura_input_field" type="text" {...register('first_name')}
                               id="first_name"
                               required/>
                      </div>
                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>Last Name</span>
                        <input className="input_field_short futura_input_field" type="text" {...register('last_name')}
                               id="last_name"
                               required/>
                      </div>

                      <div className="text_futura_larger text_black input_div">
                        <span><i aria-hidden="true"></i>How did you hear about us?</span>
                        <select {...register('how_did_you_hear')} className="input_field_long futura_input_field"
                                id="how_did_you_hear"
                                required>
                          <option disabled selected>Please Select One</option>
                          <option value="google">Google</option>
                          <option value="facebook">Facebook</option>
                          <option value="linkedin">LinkedIn</option>
                          <option value="twitter">Twitter</option>
                          <option value="blogpost">Blog Post</option>
                          <option value="instagram">Instagram</option>
                          <option value="gad">Gad Tisch</option>
                          <option value="wordofmouth">Word of Mouth</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex_center_container">
                    <input id="launch_production" className="text_futura_larger text_white launch_production_button"
                           type="button"
                           value={launchProductionLabel} onClick={createProduction}/>
                  </div>
                </form>

              </div>
            </div>
            <img src={background} alt="Your Image" className="image"/>
          </div>
        </div>

      </div>
      <div className="bottom_section">

      </div>

    </div>
  )
}

export default CreateProduction;
