import { store } from '../../state/store';
import {
  PagesWithWriteOnly,
  PagesBypassSecurity,
  WriteOnly,
  ReadAndWrite
} from './constants';
import type { SecurityPageName, TenantSecurityProfile } from './constants';
import customRouter, { getSecurityPageName } from '../../routes/customRouter';

interface IPermissionService {
  hasAccess: (pageName: SecurityPageName) => boolean
  hasPermission: (requiredPermission: number, pageName?: SecurityPageName) => boolean
}

class PermissionService implements IPermissionService {
  /*
     * Checks to see if the user has the correct permissions and access levels to view the page
     * returns boolean (true/false)
     * @param {string} pageName: the name of the page the user wants to view
     */
  hasAccess (pageName: SecurityPageName): boolean {
    // get the auth redux states from the store
    const authState = store.getState().auth;
    const tenantId: string = (authState.tenantId !== null) ? authState.tenantId : '';
    const securityProfile: TenantSecurityProfile = (authState.securityProfile !== null) ? authState.securityProfile : {};

    const requiredPermission: number = PagesWithWriteOnly.includes(pageName) ? WriteOnly : ReadAndWrite;

    return checkAccess(securityProfile, tenantId, pageName, requiredPermission);
  }

  /**
   * Checks to see if the user has the correct permissions for the current page
   * to view an item/component on that page returns boolean (true/false)
   *
   * @param {AccessLevel} requiredPermission The required value for the user to view the item/component
   * @param {pageName} securityPageName The page whose access rights
   * must be checked. Leave undefined for the active page.
   */
  hasPermission (requiredPermission: AccessLevel, pageName?: SecurityPageName): boolean {
    // get the auth redux states from the store
    const authState = store.getState().auth;
    const tenantId: string = authState.tenantId;
    const securityProfile: TenantSecurityProfile = (authState.securityProfile !== null) ? authState.securityProfile : {};

    // If no security page name is provided, use the user's current location
    if (!pageName) {
      const currentLocation = customRouter.state.location;
      pageName = getSecurityPageName(currentLocation);
    }

    if (!pageName) {
      // No security page associated the provided path. We assume that it's a public/unprotected page.
      return true;
    }

    return checkAccess(securityProfile, tenantId, pageName, requiredPermission);
  }
}

const checkAccess = (securityProfile: TenantSecurityProfile, tenantId: string, pageName: SecurityPageName, requiredPermission: AccessLevel): boolean => {
  const byPassSecurity: boolean = PagesBypassSecurity.includes(pageName);
  return (byPassSecurity || (securityProfile?.[tenantId]?.[pageName] !== null && securityProfile?.[tenantId]?.[pageName] >= requiredPermission));
}

interface RestrictedOption {
  hasPermission?: () => boolean
}

// Filter to remove objects for which permission was not granted
export const permissionFilter = (obj: RestrictedOption) => !obj.hasPermission || obj.hasPermission();

// Access level for security profile
export enum AccessLevel { NO_ACCESS = 0, READ_ONLY = 1, READ_WRITE = 2 };

const service: IPermissionService = new PermissionService();
export default service;
