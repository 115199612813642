import React, { lazy, Suspense } from 'react';

const LazyESignature = lazy(async () => await import('./ESignature'));

const ESignature = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyESignature {...props} />
  </Suspense>
);

export default ESignature;
