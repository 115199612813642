import React, { lazy, Suspense } from 'react';
import type { NewGroupProps } from './NewGroup';

const LazyNewGroup = lazy(async () => await import('./NewGroup'));

const NewGroup = (props: NewGroupProps) => (
  <Suspense fallback={null}>
    <LazyNewGroup {...props} />
  </Suspense>
);

export default NewGroup;
