import Drawer from '@mui/material/Drawer';
import styled from '@mui/material/styles/styled';
import { drawerContainerStyles } from '../../theme/styles';

/**
 * drawer container component for open and closed slide drawer
 */
const DrawerContainer = styled(Drawer)<{
  width: number
}>(drawerContainerStyles);

export default DrawerContainer;
