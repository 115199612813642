import { useEffect, useState } from 'react';
import type { FC } from 'react';
import classes from './SideBarButton.module.scss';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SideBarNavList from './SideBarNavList';
import CustomTooltip from '../CustomToolTip';
import { useDispatch, useSelector } from 'react-redux';
import { setExpandedMenu } from '../../state/slices/SideBar';
import type { RootState } from '../../state/store';
import type { SideNavTitles, SideNavItem } from '../../models/SideNav';
import { SideNavGroups } from '../../models/SideNav';
import PermissionService from '../../services/auth/PermissionService';

interface SideBarButtonProps {
  children: React.ReactNode
  title: SideNavTitles
  isSideBarOpen: boolean
  isDashboard?: boolean
  forceSetSideBarState?: (state?: boolean) => void
  autoExpand?: boolean
  selectedItem: SideNavItem
}

// This component represents each button in the side bar
const SideBarButton: FC<SideBarButtonProps> = ({
  children,
  title,
  isDashboard,
  isSideBarOpen,
  forceSetSideBarState,
  autoExpand,
  selectedItem
}: SideBarButtonProps) => {
  const expandedItemName = useSelector((state: RootState) => state.sideBar.expandedItemName);

  const [navListOpen, setNavListOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [listItems, setListItems] = useState<SideNavItem[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const items: SideNavItem[] = SideNavGroups[title].filter(
      (item: SideNavItem) => !item.securityPage || PermissionService.hasAccess(item.securityPage)
    );
    setListItems(items);
  }, [title]);

  useEffect(() => {
    if (listItems.some((item: SideNavItem) => item.id === selectedItem.id) || String(title) === selectedItem.title) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (expandedItemName !== title) {
      setNavListOpen(false);
    }
  }, [expandedItemName]);

  // Top buttons clicked (not those inside lists)
  const onButtonClicked = () => {
    if (autoExpand && isSideBarOpen && navListOpen) {
      setNavListOpen(false);
      return;
    }

    if (autoExpand) {
      setNavListOpen(true);
      dispatch(setExpandedMenu({ expandedItemName: title }))
    }

    // When reclicking already expaned item
    if (autoExpand && isSideBarOpen) return;

    if (forceSetSideBarState) forceSetSideBarState();
  }

  const handleToolTipOpen = (): void => {
    !(isSideBarOpen ?? false) && setTooltipOpen(true);
  };

  const handleToolTipClose = (): void => {
    setTooltipOpen(false);
  };

  return (
    <div className={classes.SideBar}>
      {(listItems.length > 0 || isDashboard)
        ? <>
          <CustomTooltip title={title} placement={'right'} open={tooltipOpen} onOpen={handleToolTipOpen} onClose={handleToolTipClose} className={`${classes.SideBarButton} ${isSelected ? classes.Selected : ''}`}>
            <ListItemButton onClick={onButtonClicked}
              disableRipple
              className={`${classes.SideBarButton} ${(isSelected) ? classes.SelectedBarButton : ''}`}
              sx={{
                width: (!isSideBarOpen) ? '48px' : '170px'
              }}
            >
              <ListItemIcon className={classes.SideBarIcon}>
                {children}
              </ListItemIcon>
              {(isSideBarOpen ?? false) && <div className={isSelected ? classes.Selected : ''}>{title}</div>}
            </ListItemButton>
          </CustomTooltip>
          {(isSideBarOpen ?? false) &&
            <SideBarNavList
              open={navListOpen}
              selectedItem={selectedItem}
              listItems={listItems}
            />
          }
        </>
        : <></>
      }
    </div>
  )
};

export default SideBarButton;
