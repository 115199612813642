import React, { lazy, Suspense } from 'react';

const LazyInactiveContacts = lazy(async () => await import('./InactiveContacts'));

const InactiveContacts = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyInactiveContacts {...props} />
  </Suspense>
);

export default InactiveContacts;
