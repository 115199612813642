/*
 * Reducer file for the Contacts slice
 */

import { createSlice } from '@reduxjs/toolkit';
import { ContactsSlice } from './constants';
import type { Person } from '../../models/Person';
import type { ExpiredContact, Categories } from '../../models/Contacts';
import { ContactListingsPage } from '../../components/ContactListings/Constants';
import { ToastIcons } from '../../models/Toast';
import type { CrooglooUrl } from '../../models/CrooglooUrl';
import type { AlertColor } from '@mui/material/Alert';

export interface ContactsState {
  pageContacts: Record<ContactListingsPage, Person[]>
  currentPage: ContactListingsPage | null
  selectedContacts: string[]
  editContactId: string | null
  preselectedCategory: Categories | null
  contactSaving: boolean
  search: string | null
  contactsToastState: ContactsToastState
  extraMembers: Person[]
  predefinedEmail: string | null
  expiredContacts: ExpiredContact[]
  contactsToEdit: Person[]
  fetchingContacts: boolean
  resetForm: boolean
  fallbackPage: CrooglooUrl | null
}

export interface ContactsToastState {
  message: string
  isToastOpen: boolean
  status: AlertColor
  icon: ToastIcons
}

const initialState: ContactsState = {
  pageContacts: {
    [ContactListingsPage.All]: [],
    [ContactListingsPage.Agent]: [],
    [ContactListingsPage.Cast]: [],
    [ContactListingsPage.Contacts]: [],
    [ContactListingsPage.Crew]: [],
    [ContactListingsPage.Studio]: [],
    [ContactListingsPage.Vendor]: [],
    [ContactListingsPage.Union]: []
  },
  currentPage: null,
  selectedContacts: [],
  editContactId: null,
  preselectedCategory: null,
  contactSaving: false,
  search: null,
  extraMembers: [],
  contactsToastState: {
    message: '',
    isToastOpen: false,
    status: 'success' as AlertColor,
    icon: ToastIcons.Success
  },
  predefinedEmail: null,
  expiredContacts: [],
  contactsToEdit: [],
  fetchingContacts: true,
  resetForm: false,
  fallbackPage: null
}

export const contactsSlice = createSlice({
  name: ContactsSlice,
  initialState,
  reducers: {
    setContactsForPage: (state, action) => {
      const key: ContactListingsPage = action.payload.key;
      state.currentPage = key;
      state.pageContacts[key] = action.payload.contacts;
    },
    handleSelectedContact: (state, action) => {
      const contact = action.payload.contact;
      const selectedContacts = [...state.selectedContacts];
      const existingContact = selectedContacts.find(selectedContact => selectedContact === contact);
      const newSelectedContacts = selectedContacts.filter(selectedContact => selectedContact !== action.payload.contact);
      if (existingContact === undefined) {
        newSelectedContacts.push(action.payload.contact);
      }
      state.selectedContacts = newSelectedContacts;
    },
    resetSelectedContacts: (state, action) => {
      state.selectedContacts = [];
    },
    setEditContactId: (state, action) => {
      state.editContactId = action.payload.contactId;
    },
    setPreselectedCategory: (state, action) => {
      state.preselectedCategory = action.payload.preselectedCategory;
    },
    setContactIsSaving: (state, action) => {
      state.contactSaving = action.payload.isSaving;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setToastState: (state, action) => {
      state.contactsToastState = action.payload;
    },
    setExtraMembers: (state, action) => {
      state.extraMembers = action.payload.contacts;
    },
    setPredefinedEmail: (state, action) => {
      state.predefinedEmail = action.payload.predefinedEmail
    },
    addExpiredContact: (state, action) => {
      let expiredContacts: ExpiredContact[] = state.expiredContacts;
      const contactToAdd: ExpiredContact = action.payload.contact;
      if (!expiredContacts.some((expiredContact: ExpiredContact) => expiredContact.id === contactToAdd.id)) {
        expiredContacts = [...expiredContacts, contactToAdd];
      }
      state.expiredContacts = expiredContacts;
    },
    removeExpiredContact: (state, action) => {
      const contactId: string = action.payload.contactId;
      let expiredContacts: ExpiredContact[] = state.expiredContacts;
      expiredContacts = expiredContacts.filter((expiredContact: ExpiredContact) => expiredContact.id !== contactId);
      state.expiredContacts = expiredContacts;
    },
    setContactsToEdit: (state, action) => {
      state.contactsToEdit = action.payload.contacts;
    },
    updateContact: (state, action) => {
      const currentPage: ContactListingsPage | null = state.currentPage;
      const editContactId: string = action.payload.contactId;
      if (currentPage && editContactId) {
        const contacts: Person[] = state.pageContacts[currentPage];
        const index: number = contacts.findIndex((contact: Person) => contact.id === editContactId);
        if (index >= 0 && index < contacts.length) {
          contacts[index] = { ...contacts[index], ...action.payload.data };
        }
        state.pageContacts[currentPage] = contacts;
      }
    },
    removeContacts: (state, action) => {
      const currentPage: ContactListingsPage | null = state.currentPage;
      const contactIds: string[] = action.payload.contactId;
      if (currentPage && contactIds.length > 0) {
        let contacts: Person[] = state.pageContacts[currentPage];
        contacts = contacts.filter((contact: Person) => contact.id && !contactIds.includes(contact.id));
        state.pageContacts[currentPage] = contacts;
      }
    },
    setFetchingContacts: (state, action) => {
      state.fetchingContacts = action.payload.fetching;
    },
    setResetForm: (state, action): void => {
      state.resetForm = action.payload.resetForm;
    },
    setFallbackPage: (state, action): void => {
      state.fallbackPage = action.payload.page;
    }
  }
});

export const {
  setContactsForPage,
  handleSelectedContact,
  resetSelectedContacts,
  setEditContactId,
  setPreselectedCategory,
  setContactIsSaving,
  setSearch,
  setExtraMembers,
  setToastState,
  setPredefinedEmail,
  addExpiredContact,
  removeExpiredContact,
  setContactsToEdit,
  updateContact,
  removeContacts,
  setFetchingContacts,
  setResetForm,
  setFallbackPage
} = contactsSlice.actions;
export default contactsSlice.reducer;
