import { ContactCrooglooUrls, CrooglooUrl } from '../models/CrooglooUrl';
import type { SideNavItem } from '../models/SideNav';
import { AllSideNavItems, DashboardSideNav } from '../models/SideNav';
import { setSelectedItem } from '../state/slices/SideBar';
import PermissionService from './auth/PermissionService';
import { setSearch } from '../state/slices/Contacts';
import { setDocumentParams, setDocumentPath, DefaultDocumentName } from '../state/slices/Documents';
import { store } from '../state/store';

const { dispatch } = store;

interface ISideNavService {
  changeSideBarItem: (currentUrl: CrooglooUrl) => void
  setSideBarItem: (item: SideNavItem) => void
  checkSideNavAccess: (url: CrooglooUrl) => boolean
  getSideNavItem: (url: CrooglooUrl) => SideNavItem
  setPreSideNavData: (item: SideNavItem) => void
}

class SideNavService implements ISideNavService {
  changeSideBarItem (currentUrl: CrooglooUrl): void {
    const newNavItem: SideNavItem = this.getSideNavItem(currentUrl);
    const currentNavItem: SideNavItem = store.getState().sideBar.selectedItem;
    if (newNavItem !== currentNavItem) {
      this.setSideBarItem(newNavItem);
    }
  }

  setSideBarItem (item: SideNavItem): void {
    dispatch(setSelectedItem({ selectedItem: item }));
  }

  checkSideNavAccess (url: CrooglooUrl): boolean {
    const sideNavItem: SideNavItem = this.getSideNavItem(url);
    return (!sideNavItem.securityPage || PermissionService.hasAccess(sideNavItem.securityPage));
  }

  getSideNavItem (url: CrooglooUrl): SideNavItem {
    let urlToCheck: CrooglooUrl = url;
    // if add/edit contact - have side bar set to previous contact page or all contacts
    if ([CrooglooUrl.ADDCONTACT, CrooglooUrl.EDITCONTACT].includes(url)) {
      const fallBackPage: CrooglooUrl | null = store.getState().contacts.fallbackPage;
      urlToCheck = fallBackPage ?? CrooglooUrl.ALLCONTACTS;
    }
    if (ContactCrooglooUrls.includes(url)) {
      dispatch(setSearch(''));
    }
    let currentNavItem: SideNavItem | undefined = AllSideNavItems.find((item: SideNavItem) => item.url === urlToCheck);
    if (!currentNavItem) {
      currentNavItem = DashboardSideNav;
    }
    return currentNavItem;
  }

  setPreSideNavData (item: SideNavItem): void {
    if ([CrooglooUrl.FILES].includes(item.url)) {
      dispatch(setDocumentPath({ path: [DefaultDocumentName] }));
      dispatch(setDocumentParams({ params: [] }));
    }
  }
}

const sideNavService: ISideNavService = new SideNavService();
export default sideNavService;
