import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './SearchFilesFlow.module.scss';
import dialogStyles from '../../theme/dialog.module.scss';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, Grid, LinearProgress, List } from '@mui/material';
import type { RootState } from '../../state/store';
import SuccessButton from '../common/buttons/SuccessButton';
import ModalService from '../../services/ModalService';
import ModalTitle from '../modals/ModalTitle/ModalTitle.lazy';
import { SearchFilesModal, WindowHeight, WindowLeft, WindowTop, WindowWidth } from './StepsModel';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ListAccordion from '../common/ListAccordion';
import { ReactComponent as FolderIcon } from '../../assets/icons/folder.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { ReactComponent as DropboxIcon } from '../../assets/icons/dropbox.svg';
import { ReactComponent as BoxIcon } from '../../assets/icons/box.svg';
import { BlackGrey, LightBlack } from '../../theme/colors';
import DocumentTreeService from '../../services/documents/DocumentTreeService';
import { makeStyles } from '@mui/styles';
import { BoxRoot, DropBoxRoot, TreeRoot, GoogleDriveRoot } from '../../services/documents/constants';
import Toast from '../common/toast/Toast';
import type { ToastProps } from '../../models/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { AlertColor } from '@mui/material/Alert';
import type { CollectionTreeFile, FileType, UrlsToAdd } from '../../models/Document';
import type { AuthUser } from '../../services/auth/constants';
import progressStyles from '../../theme/loadingProgress.module.scss';
import scrollBarStyle from '../../theme/sidebar.module.scss';
import DropboxAuthenticate from './DropboxAuthenticate';
import { defaultCheckboxStyles } from '../../theme/styles';
import { ReactComponent as DriveIcon } from '../../assets/icons/googleDrive.svg';
import GoogleLogin, { googleButtonId } from '../GoogleLogin/GoogleLogin';
import { GoogleRequestType } from '../../models/Google';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles({
  documentIcons: {
    '& path': {
      fill: BlackGrey
    }
  }
});

const originalPaddingLeft: number = 15;
const originalChild: number = 0;

interface Props {
  acceptedFileTypes?: FileType[]
  maxNumberOfFiles?: number
  noDropbox?: boolean
  search?: boolean
  setIsLoading?: () => void
}

interface TreeNodeProps {
  node: CollectionTreeFile
  clickHeader: (node: CollectionTreeFile) => void
  selectDocument: (node: CollectionTreeFile) => void
  selected: string[]
  child: number
  showDropboxPopup: () => void
  noDropbox?: boolean
}

const TreeNode: FC<TreeNodeProps> = ({ node, clickHeader, selectDocument, selected, child, showDropboxPopup, noDropbox }) => {
  const crooglooAuth: AuthUser = useSelector((state: RootState) => state.auth.crooglooauth);
  const classes = useStyles();
  const checked: boolean = selected.includes(node.id);
  const paddingLeft = originalPaddingLeft * child;
  child++;

  let icon: ReactNode = <FileIcon className={classes.documentIcons}/>;
  if (node.icon === 'folder') {
    icon = <FolderIcon className={classes.documentIcons}/>;
  } else if (node.icon === 'dropbox') {
    icon = <DropboxIcon className={classes.documentIcons}/>;
  } else if (node.icon === 'box') {
    icon = <BoxIcon className={classes.documentIcons}/>;
  } else if (node.icon === 'googleDrive') {
    icon = <DriveIcon className={classes.documentIcons}/>;
  }

  const onExpand = (): void => {
    clickHeader(node);
  }

  const clickRoot = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (node.id === DropBoxRoot) {
      if (!crooglooAuth.dropboxToken) {
        showDropboxPopup();
      }
    } else if (node.id === BoxRoot) {
      if (!crooglooAuth.boxToken) {
        // TODO: have box window popup to allow login like dropbox
      }
    } else if (node.id === GoogleDriveRoot) {
      if (!DocumentTreeService.hasGoogleAccessToken()) {
        const googleSignInButton = document.getElementById(googleButtonId);
        if (googleSignInButton) {
          googleSignInButton.click();
        }
      }
    }
  }

  if (noDropbox && node.id === DropBoxRoot) return <></>;

  return (
      <ListAccordion
          itemId={node.id}
          summary={
            <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => clickRoot(event)}
            >
              <Checkbox
                color={'success'}
                checked={checked}
                onClick={(event) => {
                  event.stopPropagation();
                  if (node.id) {
                    selectDocument(node);
                  }
                }}
                sx={{ ...defaultCheckboxStyles }}
              />
              {icon}
              <Box
                  component={'span'}
                  sx={{
                    color: BlackGrey,
                    fontWeight: 'normal',
                    fontSize: '0.875rem',
                    margin: '10px'
                  }}
              >
                {`${String(node.text)}`}
              </Box>
            </Box>
          }
          details={
            (node.children.length > 0)
              ? <List>
                {node.children.map((childNode: CollectionTreeFile) => {
                  return (
                    <TreeNode
                        key={`node-${childNode.id}`}
                        node={childNode}
                        clickHeader={clickHeader}
                        selectDocument={selectDocument}
                        selected={selected}
                        child={child}
                        showDropboxPopup={showDropboxPopup}
                    />
                  )
                })}
                </List>
              : <></>
          }
          hasChildren={(node.children.length > 0)}
          onExpand={onExpand}
          expandReverse={true}
          paddingLeft={`${paddingLeft}px`}
      />
  )
}

/**
 * The search Files template component
 * Contains mui dialog component along with the SearchFiles component
 */
const SearchFilesFlow: FC<Props> = (props: Props) => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[SearchFilesModal]);
  const { fileHierarchy, loadingFiles } = useSelector((state: RootState) => state.documentTree);
  const [documents, setDocuments] = useState<CollectionTreeFile[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);
  const [dropboxPopup, setDropboxPopup] = useState<any>(null);
  const [documentsSearch] = useState<string>('');

  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  useEffect(() => {
    setDocuments(fileHierarchy);
    setSelected(DocumentTreeService.checkSelectedHierarchy(selected));
  }, [fileHierarchy]);

  const filteredDocuments: CollectionTreeFile[] = useMemo(() => {
    return filterHierarchy(documents, documentsSearch.toLowerCase(), props.acceptedFileTypes ?? [])
  }, [documentsSearch, documents]);

  const { maxNumberOfFiles } = props;
  let message: string = '';

  if (typeof maxNumberOfFiles !== 'undefined') {
    message = String(t('compose.searchFiles.SelectDocuments.message', { maxNumberOfFiles }));
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleShowToast = (toastType: AlertColor, message: string, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  const handleGoogleMessage = (message: string, type: AlertColor, icon: ToastIcons) => {
    handleShowToast(type, message, icon);
  }

  const handleClose = (): void => {
    ModalService.closeCustomModal(SearchFilesModal);
    setSelected([]);
  }

  const handleSuccess = (): void => {
    handleCloseToast();
    if (props.maxNumberOfFiles && selected.length > props.maxNumberOfFiles) {
      handleShowToast('error', 'upload.error.selectMax', ToastIcons.Info);
    } else {
      props.setIsLoading?.();
      handleClose();
      DocumentTreeService.addFiles(selected)
        .then(async (urlsToAdd: UrlsToAdd[]) => await DocumentTreeService.addUploadedFiles(urlsToAdd))
        .then((showToast: boolean) => {
          if (showToast) {
            handleShowToast('success', 'upload.selectFiles.success', ToastIcons.Success);
          }
        })
        .catch((err) => {
          console.error(err);
          handleShowToast('error', err.message, ToastIcons.Info);
        })
        .finally(() => props.setIsLoading?.());
    }
  }

  const showDropboxPopup = (): void => {
    const popup: any = window.open('/#/dropbox', '', `width=${WindowWidth},height=${WindowHeight},left=${WindowLeft},top=${WindowTop}`);
    setDropboxPopup(popup);
  }

  const closeDropboxPopup = (): void => {
    setDropboxPopup(null);
  }

  const clickHeader = (node: CollectionTreeFile): void => {
    if (node.id === DropBoxRoot || node.li_attr.type === 'dropbox-folder') {
      DocumentTreeService.handleOpenDropboxNode(node)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    } else if (node.id === GoogleDriveRoot || node.li_attr.type === 'gDrive-folder') {
      DocumentTreeService.handleOpenGDriveNode(node)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    } else if (node.id === BoxRoot || node.li_attr.type === 'box-folder') {
      // TODO: handle fetching of box files
    } else if (node.id === TreeRoot || node.li_attr.type === 'Folder') {
      DocumentTreeService.handleOpenTreeNode(node)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const selectDocument = (node: CollectionTreeFile): void => {
    const nodeId: string = node.id;
    let newSelected = [...selected];
    const childrenIds: string[] = node.children.map((child: CollectionTreeFile) => child.id);
    if (selected.includes(nodeId)) {
      newSelected = newSelected.filter((id: string) => id !== nodeId && !childrenIds.includes(id));
    } else {
      const allIdsToAdd: string[] = [...childrenIds, nodeId];
      newSelected = [...newSelected, ...allIdsToAdd];
    }
    setSelected(newSelected);
  }

  return (
    <>
    <Dialog
      data-testid="SearchFilesFlow"
      classes={{
        root: `${dialogStyles.dialogRoot} ${styles.SearchFilesFlow}`,
        paper: `${dialogStyles.dialogPaper} ${styles.searchFilesFlowPaper}`
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
      open={modalsState.isVisible}
      onClose={handleClose}
    >
      <ModalTitle handleClose={handleClose} title={modalsState.title} />
      <DialogContent sx={{ overflowY: 'hidden' }}>
        <Grid container className={dialogStyles.dialogContent}>
          <Grid item>
            <InfoIcon className={dialogStyles.dialogIcon} />
            <Box
              component={'h2'}
              sx={{
                fontStyle: 'normal',
                marginTop: 0,
                fontWeight: 700,
                fontSize: '1.5rem',
                lineHeight: '29px'
              }}
            >
              {t(modalsState.heading)}
            </Box>
          </Grid>
          <Grid
            container
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              padding: 0
            }}
          >
          {(message && message !== '') && <Grid item sx={{ textAlign: 'left' }}>
            <Box
              component={'span'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '150%',
                letterSpacing: '0.15px',
                color: LightBlack,
                paddingBottom: '5px'
              }}
            >
              {t(message)}
            </Box>
          </Grid>}
            {loadingFiles
              ? <LinearProgress classes={{ colorPrimary: progressStyles.linearPrimary, barColorPrimary: progressStyles.barLinearPrimary }} />
              : <></>
            }

            {/* <SearchBar placeholder={'compose.searchFiles.SelectDocuments.inputPlaceholder'} margin={'10px 0px'} onChange={handleSearch} /> */}
            <List
              className={`${styles.listContent} ${scrollBarStyle.scrollbar}`}
            >
              {

                filteredDocuments.map((node: CollectionTreeFile) => {
                  return (
                  <TreeNode
                    key={`node-${node.id}`}
                    node={node}
                    clickHeader={clickHeader}
                    selectDocument={selectDocument}
                    selected={selected}
                    child={originalChild}
                    showDropboxPopup={showDropboxPopup}
                    noDropbox={props.noDropbox ?? undefined}
                  />
                  )
                })}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={dialogStyles.dialogActionsEnd}>
        <SuccessButton
          onClick={handleSuccess}
          disableRipple
        >
          {t('action.select')}
        </SuccessButton>
      </DialogActions>
    </Dialog>
    <DropboxAuthenticate dropboxPopup={dropboxPopup} closeDropboxPopup={closeDropboxPopup} initiateDropbox={true} />
    <GoogleLogin handleShowToast={handleGoogleMessage} requestType={GoogleRequestType.Compose}><></></GoogleLogin>
    <Toast
      open={toastStatus.isShown}
      onClose={handleCloseToast}
      type={toastStatus.type}
      title={String(t(toastStatus.message, { max: maxNumberOfFiles }))}
      icon={toastStatus.icon}
    />
    </>
  )
}

export default SearchFilesFlow;

function filterHierarchy (documents: CollectionTreeFile[], search: string, acceptedFileTypes: FileType[]): CollectionTreeFile[] {
  if (acceptedFileTypes.length === 0 && !search) {
    return documents;
  }
  const filteredDocuments: CollectionTreeFile[] = documents.reduce((accum: CollectionTreeFile[], document: CollectionTreeFile) => {
    if (document.children.length > 0) {
      const filtered: CollectionTreeFile[] = filterHierarchy(document.children, search, acceptedFileTypes);
      if (filtered.length > 0) {
        return [...accum, { ...document, children: filtered }];
      }
    }
    const documentWithoutChildren: CollectionTreeFile = { ...document, children: [] };
    if (search) {
      if (!document.text.toLowerCase().includes(search)) {
        return accum;
      }
    }
    if (acceptedFileTypes.length > 0) {
      if (!['dropbox-folder', 'Folder'].includes(document.li_attr.type) && !acceptedFileTypes.includes(document.li_attr.fileType)) {
        return accum;
      }
    }
    return [...accum, documentWithoutChildren];
  }, [])

  return filteredDocuments;
}
