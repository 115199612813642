import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { MainWhite, ToastPink, ToastHover } from '../../../theme/colors';

/**
 * Custom delete button component with style using material ui button component
 */
const DeleteButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  backgroundColor: ToastPink,
  color: MainWhite,
  padding: '8px 22px',
  boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: '50px',
  '&:hover': { background: ToastHover, color: MainWhite }
}));
export default DeleteButton;
