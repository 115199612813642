import React, { lazy, Suspense } from 'react';
import type { WatermarkProps } from './Watermark';

const LazyWatermark = lazy(async () => await import('./Watermark'));

const Watermark = (props: WatermarkProps) => (
  <Suspense fallback={null}>
    <LazyWatermark {...props} />
  </Suspense>
);

export default Watermark;
