import React, { lazy, Suspense } from 'react';

const LazyMergeFiles = lazy(async () => await import('./MergeFiles'));

const MergeFiles = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyMergeFiles {...props} />
  </Suspense>
);

export default MergeFiles;
