import { store } from '../state/store';
import {
  addFiles,
  clearFiles,
  removeFiles,
  setFilesInProgress,
  setUploadFilesInProgress,
  setShowUploadFlow,
  setCurrentFileNumber,
  resetShowUploadFlow,
  setApplyToAll,
  setApplyToAllAsked,
  setFetching,
  setUploadDestination,
  setPredefinedFileType,
  setCurrentFile,
  setChangedFileName,
  setIsWatermarkAvailable,
  setToastStatus,
  resetToastStatus
} from '../state/slices/UploadFiles';
import type {
  DirectoryDestination,
  FileAttachment,
  OtherFileAttachment,
  DocumentResponseItems,
  DocumentsResponse
} from '../models/Document';
import { FileType } from '../models/Document';
import AuthService from './auth/AuthService';
import { PostMethod } from './constants';
import type { FileToUpload, FileToUploadAttribute } from '../models/Upload';
import { UploadFileLocation } from '../models/Upload';
import DocumentService, { RootFolderName, RootFolderDisplayName } from './documents/DocumentService';
import DistributionService from './distribution/DistributionService';
import type { UploadFileForm } from '../components/fileUpload/UploadFlow/UploadFlow';
import type { AuthUser } from './auth/constants';
import UtilityService, { MegabyteConverter } from './UtilityService';
import type { FileTypes, PageMenus } from '../models/FileTypes';
import type { WSResponse } from '../models/WSResponse';
import ScriptService from './ScriptService';
import NotificationUtility from './notifications/NotificationUtility';
import { NotificationStatus, NotificationType, UploadStatus } from '../models/Notifications';
import type { ToastProps } from '../models/Toast';
import ModalService from './ModalService';
import { ApplyToAllModal } from '../components/modals/ApplyToAll/constants';
import { FileIsTooLargeModal } from '../components/modals/FileTooLarge/constants';
import { FileAlreadyExistModal } from '../components/modals/FileAlreadyExist/constants';
import { RenameExistingFileModal } from '../components/modals/RenameExistingFile/constants';
import { CrooglooFileTypes } from '../models/FileTypes';
import type { UseFormGetValues } from 'react-hook-form';
import moment from 'moment';
import { t } from 'i18next';

const { dispatch } = store;

export const DefaultFileCategory: string = 'other';
const BucketId: string = '8810931_';
export const GoogleUploadUrl: string = 'https://storage.googleapis.com/';
export const MaxAttachmentsSize: number = 20; // in Mb

interface IUploadService {
  addUploadedFile: (file: FileAttachment) => void
  removeUploadedFile: (fileId: string) => void
  clearUploadedFiles: () => void
  addMultipleFiles: (files: FileAttachment[]) => void
  processFileUpload: (
    selectedDestinations: DirectoryDestination[], getValues: UseFormGetValues<UploadFileForm>, fileType: FileTypes,
  ) => Promise<void>
  callUploadServlet: (fileId: string, file: File) => Promise<void>
  saveFileEntity: (
    file: FileAttachment, uploadedFileURL: string,
    parents: string[], getValues: UseFormGetValues<UploadFileForm>,
    fileType: FileTypes, source: string
  ) => Promise<void>
  callImportServlet: (files: File[], listIds: string[], departmentId: string, departmentName: string) => Promise<void>
  getBucketName: () => string
  getDefaultRootDestination: (fileId: string, folderId?: string) => DirectoryDestination[]
  getFileAttachmentsFromUploadedFilesJSON: (uploadedFilesJSON: string) => FileAttachment[]
  startUploadProcess: (uploadFiles: File[], uploadDestination?: UploadFileLocation, predefinedFileType?: FileTypes) => void
  startImportUploadProcess: (files: OtherFileAttachment[], location?: UploadFileLocation) => void
  handleNextFile: () => void
  handleProcessFile: (file: OtherFileAttachment, currentFileNumber: number) => void
  startFileChecks: (file: OtherFileAttachment) => Promise<void>
  showUploadFlow: (file: OtherFileAttachment) => void
  hideUploadFlow: () => void
  stopUploadProcess: () => void
  setFetchingFile: (fetching: boolean) => void
  showUploadError: (message: string) => void
  showUploadToast: (toastStatus: ToastProps) => void
  closeUploadToast: () => void
  uploadedFileToOtherAttachment: (file: File) => OtherFileAttachment
  getUploadedFileUrl: (file: OtherFileAttachment) => string
  processImportFolderUpload: (selectedDestinations: DirectoryDestination[]) => Promise<void>
}

class UploadService implements IUploadService {
  addUploadedFile (file: FileAttachment): void {
    dispatch(addFiles({ files: [file] }));
  }

  removeUploadedFile (fileId: string): void {
    dispatch(removeFiles({ files: [fileId] }))
  }

  clearUploadedFiles (): void {
    dispatch(clearFiles({}));
  }

  addMultipleFiles (files: FileAttachment[]): void {
    dispatch(addFiles({ files }));
  }

  async callUploadServlet (fileId: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    const uploadProgress = (progress: number): void => {
      NotificationUtility.updateUploadBox({
        fileId,
        progress
      })
    }

    await AuthService.authenticatedCall('/UploadServletV2', {
      method: PostMethod,
      body: formData,
      type: 'multipart/form-data',
      params: {
        fileName: fileId
      }
    },
    uploadProgress)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
  }

  async processFileUpload (
    selectedDestinations: DirectoryDestination[], getValues: UseFormGetValues<UploadFileForm>, fileType: FileTypes
  ): Promise<void> {
    const currentFile: OtherFileAttachment | null = store.getState().uploadFiles.currentFile;
    if (!currentFile) return;
    this.setFetchingFile(true);
    this.hideUploadFlow();
    const currentFileNumber: number = store.getState().uploadFiles.currentFileNumber;
    const uploadFilesInProgress: File[] = store.getState().uploadFiles.uploadFilesInProgress;
    const changedFileName: string | null = store.getState().uploadFiles.changedFileName;
    const fileId: string = currentFile.id;
    const fileName: string = changedFileName ?? currentFile.name;
    const uploadedFileURL: string = this.getUploadedFileUrl(currentFile);
    const parents: string[] = selectedDestinations.map((destination: DirectoryDestination) => destination.id);
    const category: string = getValues('category');
    const fileAttachment: FileAttachment = {
      id: fileId,
      fileName,
      url: uploadedFileURL,
      isWatermarked: (getValues('watermark')) ? '1' : '0',
      size: currentFile.size,
      subcategory: category ?? 'other',
      isPublishedToStudio: false,
      cloudProvider: '',
      fileExtension: (UtilityService.getFileExtension(fileName) === 'pdf') ? FileType.PDF : FileType.Other,
      type: CrooglooFileTypes.File
    }
    NotificationUtility.addUploadBox(fileId, fileName);
    NotificationUtility.addNotification(fileId, fileName, moment().valueOf(), NotificationType.Upload, true);
    if ([CrooglooFileTypes.DropboxFile, CrooglooFileTypes.BoxFile].includes(currentFile.type)) {
      await AuthService.authenticatedCall(`${currentFile.uploadURL}&destfile=${fileId}`, {});
    } else if (currentFile.type === CrooglooFileTypes.GDriveFile) {
      await AuthService.authenticatedCall(currentFile.uploadURL, {});
    } else {
      await this.callUploadServlet(currentFile.id, uploadFilesInProgress[currentFileNumber - 1]);
    }
    await this.saveFileEntity(fileAttachment, uploadedFileURL, parents, getValues, fileType, 'local_system');
  }

  async saveFileEntity (
    file: FileAttachment, uploadedFileURL: string,
    parents: string[], getValues: UseFormGetValues<UploadFileForm>,
    fileType: FileTypes, source: string
  ): Promise<void> {
    const authState = store.getState().auth;
    const uploadDestination: UploadFileLocation | null = store.getState().uploadFiles.uploadDestination;
    const crooglooAuth: AuthUser = authState.crooglooauth;
    const fileExtension: string = UtilityService.getFileExtension(file.fileName);
    const bucketName: string = this.getBucketName();
    let wmFirstLine: string = getValues('wmFirstLine') ?? '';
    if (wmFirstLine === 'Other') {
      wmFirstLine = 'Other~' + getValues('wmFirstOtherField');
    }
    let wmSecondLine: string = getValues('wmSecondLine') ?? '';
    if (wmSecondLine === 'Other') {
      wmSecondLine = 'Other~' + getValues('wmSecondOtherField');
    }
    const secListsWithAccess: string[] = getValues('shared');
    if (crooglooAuth.securityListId && !secListsWithAccess.includes(crooglooAuth.securityListId.trim().toUpperCase())) {
      secListsWithAccess.push(crooglooAuth.securityListId.trim().toUpperCase());
    }
    const documentCategory: string = 'DOCUMENT';
    const newFileAttributes: FileToUploadAttribute[] = [
      { id: 'id', value: file.id },
      { id: 'category', value: documentCategory },
      { id: 'limitToType', value: 'securityList' },
      { id: 'limitTo', value: secListsWithAccess.join(',') },
      { id: 'isWatermarked', value: getValues('watermark') ? 1 : 0 },
      { id: 'firstWatermarkLine', value: wmFirstLine },
      { id: 'secondWatermarkLine', value: wmSecondLine },
      { id: 'watermarkWithEncryption', value: getValues('wmWithEncryption') },
      { id: 'episode', value: getValues('episode') },
      { id: 'color', value: getValues('color') },
      { id: 'description' },
      { id: 'fileExtension', value: fileExtension },
      { id: 'bucket', value: bucketName },
      { id: 'showInReports', value: '0' },
      { id: 'submittedTo', value: '' },
      { id: 'fileURL', value: uploadedFileURL },
      { id: 'image', value: '' },
      { id: 'approval', value: '' },
      { id: 'approvedDate', value: '' },
      { id: 'approvedBy', value: '' },
      { id: 'showInHub', value: '0' },
      { id: 'title', value: file.fileName },
      { id: 'isSubmitted', value: '0' },
      { id: 'size', value: file.size },
      { id: 'priority', value: '0' },
      { id: 'subtitle', value: file.fileName },
      { id: 'submittedDate', value: '' },
      { id: 'fileName', value: file.fileName },
      { id: 'docDescription', value: '' },
      { id: 'submittedBy', value: '' },
      { id: 'parentFolder', value: (parents.length > 0) ? parents[0] : RootFolderName },
      { id: 'parents', value: parents.join(',') },
      { id: 'children', value: '' },
      { id: 'uploader_token', value: authState.userToken },
      { id: 'timeCreated', value: moment().toISOString() },
      { id: 'subcategory', value: file.subcategory },
      { id: 'lastModifBaseTime', value: moment().valueOf() },
      { id: 'source', value: source }
    ];

    const newFile: FileToUpload = {
      _ID: file.id,
      _NAME: file.fileName,
      entityType: documentCategory,
      pageName: (uploadDestination === UploadFileLocation.Compose) ? 'data' : 'documents',
      tenantId: authState.tenantId,
      attributes: newFileAttributes
    };
    if (uploadDestination === UploadFileLocation.Compose) {
      await DistributionService.saveAttachment(newFile)
        .then(async (resp: WSResponse) => {
          await handleResponse(resp, file, getValues);
          this.addUploadedFile(file);
          handleSuccessUpload(file.id);
        })
        .catch((err) => {
          console.error(err);
          handleFailedUpload(file.id);
        });
    } else {
      newFile._fileType = fileType;
      const page: PageMenus = UtilityService.getPageMenu(fileType);
      await DocumentService.saveDocument(file.fileName, newFile, page)
        .then(async (resp: WSResponse) => {
          await handleResponse(resp, file, getValues);
          handleSuccessUpload(file.id);
        })
        .catch((err) => {
          console.error(err);
          handleFailedUpload(file.id);
        })
    }
  }

  async callImportServlet (files: File[], listIds: string[], departmentId: string, departmentName: string): Promise<void> {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append('file', file);
    });

    const fileId: string = UtilityService.uniqueUuid();

    NotificationUtility.addNotification(fileId, '', moment().valueOf(), NotificationType.VCard, true);
    await AuthService.authenticatedCall('/importVcard', {
      method: PostMethod,
      body: formData,
      type: 'multipart/form-data',
      params: {
        isCast: 'false',
        deptId: departmentId,
        deptName: departmentName,
        distroListIds: listIds.join(',')
      }
    }).then(() => {
      NotificationUtility.updateNotificationData({
        referenceId: fileId,
        status: NotificationStatus.Complete
      });
    }).catch((err) => {
      console.error(err);
      NotificationUtility.updateNotificationData({
        referenceId: fileId,
        status: NotificationStatus.Failed
      })
      throw new Error('import.vCard.error.server');
    })
  }

  /**
   * get the bucket name to upload file to
   */
  getBucketName (): string {
    const authState = store.getState().auth;
    return BucketId + authState.tenantId.toLowerCase();
  }

  /**
   * return a default destination for a file which is usually root
   */
  getDefaultRootDestination (fileId: string, folderId?: string): DirectoryDestination[] {
    const directory: string = folderId ?? RootFolderName;
    const bucketName: string = this.getBucketName();
    return [{
      fileURL: `${GoogleUploadUrl}${bucketName}/${fileId}/${directory}`,
      id: directory,
      parentFolder: '',
      subDirectories: [],
      title: 'Documents'
    }]
  }

  /**
   * return an array of file attachments from a distribution message uploadedFilesJSON string param
   * @param uploadedFilesJSON
   */
  getFileAttachmentsFromUploadedFilesJSON (uploadedFilesJSON: string): FileAttachment[] {
    const attachments: FileAttachment[] = [];
    const uploadedFiles = JSON.parse(uploadedFilesJSON);
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file: any) => {
        attachments.push({
          id: file.id,
          fileName: file.fileName,
          url: file.url,
          isWatermarked: file.isWatermarked ?? '0',
          size: file.size ?? 0,
          subcategory: file.subcategory,
          isPublishedToStudio: file.isPublishedToStudio ?? false,
          cloudProvider: '',
          fileExtension: (UtilityService.getFileExtension(file.fileName) === 'pdf') ? FileType.PDF : FileType.Other,
          type: CrooglooFileTypes.File
        });
      });
    }
    return attachments;
  }

  startUploadProcess (uploadFiles: File[], uploadDestination?: UploadFileLocation, predefinedFileType?: FileTypes): void {
    dispatch(setUploadFilesInProgress({ files: uploadFiles }));
    if (uploadDestination) {
      dispatch(setUploadDestination({ destination: uploadDestination }));
    }
    if (predefinedFileType) {
      dispatch(setPredefinedFileType({ type: predefinedFileType }));
    }
    const files: OtherFileAttachment[] = uploadFiles.map((file: File) => {
      return this.uploadedFileToOtherAttachment(file);
    });
    dispatch(setFilesInProgress({ files }));
    this.handleNextFile();
  }

  startImportUploadProcess (files: OtherFileAttachment[], location?: UploadFileLocation): void {
    dispatch(setFilesInProgress({ files }));
    if (location) {
      dispatch(setUploadDestination({ destination: location }));
    }
    this.handleNextFile();
  }

  handleNextFile (): void {
    this.hideUploadFlow();
    this.setFetchingFile(false);
    const fileList: OtherFileAttachment[] = store.getState().uploadFiles.filesInProgress;
    const currentFileNumber: number = store.getState().uploadFiles.currentFileNumber;
    if (fileList.length > 0) {
      const file: OtherFileAttachment = fileList[currentFileNumber];
      if (file) {
        this.handleProcessFile(file, currentFileNumber);
      } else {
        this.stopUploadProcess();
      }
    } else {
      this.stopUploadProcess();
    }
  }

  handleProcessFile (file: OtherFileAttachment, currentFileNumber: number): void {
    const applyToAllAsked: boolean = store.getState().uploadFiles.applyToAllAsked;
    if (currentFileNumber > 0 && !applyToAllAsked) {
      ModalService.openCustomModal(ApplyToAllModal, {
        heading: 'upload.applyToAll.heading',
        content: 'upload.applyToAll.content',
        confirmButton: 'action.yes',
        cancel: 'action.no',
        callback: (apply: boolean) => {
          dispatch(setApplyToAll({ applyToAll: apply }));
          dispatch(setApplyToAllAsked({ asked: true }));
          dispatch(setCurrentFileNumber({ number: currentFileNumber + 1 }));
          void this.startFileChecks(file);
        }
      });
    } else {
      dispatch(setCurrentFileNumber({ number: currentFileNumber + 1 }));
      void this.startFileChecks(file);
    }
  }

  async startFileChecks (file: OtherFileAttachment): Promise<void> {
    const MaxSizeInMegs: number = MaxAttachmentsSize * MegabyteConverter;
    const uploadDestination: UploadFileLocation | null = store.getState().uploadFiles.uploadDestination;
    const uploadFiles: FileAttachment[] = store.getState().uploadFiles.files;
    const fileName: string = file.name;
    if (uploadDestination === UploadFileLocation.Compose && uploadFiles.length > 0 &&
      uploadFiles.some((uploadedFile: FileAttachment) => uploadedFile.fileName === fileName)) {
      this.showUploadToast({
        message: t('compose.attachment.exists', { fileName }),
        type: 'warning',
        isShown: true
      });
      this.handleNextFile();
    } else if (file.size > MaxSizeInMegs) {
      /**
       * if file is too big let user know and move onto next file
      */
      ModalService.openCustomModal(FileIsTooLargeModal, {
        heading: 'upload.fileIsTooLarge.title',
        content: t('upload.fileIsTooLarge.content').toString().replace('{size}', MaxAttachmentsSize.toString()),
        confirmButton: 'upload.fileIsTooLarge.ok',
        callback: () => {
          this.handleNextFile();
        }
      });
    } else {
      this.setFetchingFile(true);
      const result: DocumentResponseItems = await DocumentService.fetchDocumentsByName(fileName);
      /**
       * if file with same name exists in backend give the user the option to rename the file
       * if uploading to compose the user has an option to reuse the file with the same name
       */
      if (result && result.items.length > 0) {
        let redundantFileId: string = '';
        let i: number = 0;
        const items: DocumentsResponse[] = result.items;
        let parentFolder: DocumentsResponse | null = null;
        while (redundantFileId === '' && i < items.length) {
          if (items[i].properties.id) {
            redundantFileId = items[i].properties.id;
            parentFolder = items[i];
          }
          i++;
        }
        this.setFetchingFile(false);
        if (parentFolder) {
          const folderName: string = getParentFolder(parentFolder);
          if (uploadDestination === UploadFileLocation.Compose) {
            ModalService.openCustomModal(FileAlreadyExistModal, {
              heading: 'upload.fileAlreadyExists.title',
              content: 'upload.fileAlreadyExists.compose.content',
              confirmButton: 'action.yes',
              cancel: 'upload.fileAlreadyExists.compose.no',
              metaData: [result.items[0].properties.fileName, folderName, redundantFileId],
              callback: (changedFileName: string, continueWithFile: boolean) => {
                if (continueWithFile) {
                  dispatch(setChangedFileName({ name: changedFileName }));
                  this.showUploadFlow(file);
                } else {
                  this.handleNextFile();
                }
              }
            });
          } else {
            ModalService.openCustomModal(RenameExistingFileModal, {
              heading: 'upload.fileAlreadyExists.title',
              content: 'upload.fileAlreadyExists.other.content',
              confirmButton: 'action.ok',
              metaData: [result.items[0].properties.fileName, folderName],
              callback: (changedFileName: string, continueWithFile: boolean) => {
                if (continueWithFile) {
                  dispatch(setChangedFileName({ name: changedFileName }));
                  this.showUploadFlow(file);
                } else {
                  this.handleNextFile();
                }
              }
            });
          }
        } else {
          this.showUploadFlow(file);
        }
      } else {
        this.setFetchingFile(false);
        this.showUploadFlow(file);
      }
    }
  }

  showUploadFlow (file: OtherFileAttachment): void {
    const isWatermarkAvailable: boolean = UtilityService.getFileExtension(file.name) === 'pdf';
    dispatch(setCurrentFile({ file }));
    dispatch(setIsWatermarkAvailable({ available: isWatermarkAvailable }));
    dispatch(setShowUploadFlow({ show: true }));
  }

  hideUploadFlow (): void {
    dispatch(setShowUploadFlow({ show: false }));
  }

  stopUploadProcess (): void {
    dispatch(resetShowUploadFlow({}));
  }

  setFetchingFile (fetching: boolean): void {
    dispatch(setFetching({ fetchingFile: fetching }));
  }

  showUploadError (message: string): void {
    this.showUploadToast({
      message: t(message),
      type: 'error',
      isShown: true
    });
  }

  showUploadToast (toastStatus: ToastProps): void {
    dispatch(setToastStatus({ status: toastStatus }));
  }

  closeUploadToast (): void {
    dispatch(resetToastStatus({}));
  }

  uploadedFileToOtherAttachment (file: File): OtherFileAttachment {
    const fileName: string = file.name;
    const fileId: string = UtilityService.generateUniqueId(fileName);
    const bucketName: string = this.getBucketName();
    const uploadedFileURL: string = `${GoogleUploadUrl}${bucketName}/${fileId}`;
    return {
      id: fileId,
      name: fileName,
      uploadURL: uploadedFileURL,
      size: file.size,
      type: CrooglooFileTypes.File
    }
  }

  getUploadedFileUrl (file: OtherFileAttachment): string {
    let uploadUrl: string = file.uploadURL;
    if ([CrooglooFileTypes.DropboxFile, CrooglooFileTypes.BoxFile, CrooglooFileTypes.GDriveFile].includes(file.type)) {
      const bucketName: string = this.getBucketName();
      uploadUrl = `${GoogleUploadUrl}${bucketName}/${file.id}`;
    }
    return uploadUrl;
  }

  async processImportFolderUpload (selectedDestinations: DirectoryDestination[]): Promise<void> {
    const currentFile: OtherFileAttachment | null = store.getState().uploadFiles.currentFile;
    if (currentFile) {
      const formData = new FormData();
      formData.append('fileIdList', currentFile.id);
      formData.append('parentCrooglooFolderId', selectedDestinations[0].id);
      await AuthService.authenticatedCall(currentFile.uploadURL, {
        method: PostMethod,
        body: formData,
        type: 'multipart/form-data'
      })
    } else {
      throw new Error('error with importing folder');
    }
  }
}

const handleSuccessUpload = (fileId: string): void => {
  NotificationUtility.updateUploadBox({
    fileId,
    status: UploadStatus.Complete
  });
  NotificationUtility.updateNotificationData({
    referenceId: fileId,
    status: NotificationStatus.Complete
  })
}

const handleFailedUpload = (fileId: string): void => {
  NotificationUtility.updateUploadBox({
    fileId,
    status: UploadStatus.Failed
  });
  NotificationUtility.updateNotificationData({
    referenceId: fileId,
    status: NotificationStatus.Failed
  })
}

const handleResponse = async (resp: WSResponse, file: FileAttachment, getValues: UseFormGetValues<UploadFileForm>): Promise<void> => {
  if (resp && UtilityService.isSuccessResponse(resp.responseCode)) {
    if (getValues('isParseChecked')) {
      await ScriptService.parseScript({
        fileName: file.fileName,
        description: file.fileName,
        fileId: file.id,
        episode: getValues('episode'),
        color: getValues('color'),
        version: getValues('version')
      });
    }
  } else {
    throw new Error('invalid server response');
  }
}

/**
 * get the parent folder for the most recent file of the same name
 * @param item
 */
const getParentFolder = (item: DocumentsResponse): string => {
  let folderName: string = RootFolderDisplayName;
  const parentFolders = item.properties.parentPath ?? [];
  if (parentFolders.length > 0) {
    const NumberOfParentFolders: number = parentFolders.length;
    const LastParent: string[] = parentFolders[NumberOfParentFolders - 1]; // most recent parent
    const FolderNameIndex = LastParent.length - 1; // index for the name of the folder (last index in array)
    folderName = LastParent[FolderNameIndex];
  }
  return folderName;
}

const uploadService: IUploadService = new UploadService();
export default uploadService;
