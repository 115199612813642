import type { FC } from 'react';
import { FormControl, FormControlLabel, Checkbox, Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import type { OptionProps } from '../../models/OptionProps';
import { defaultCheckboxStyles } from '../../theme/styles';
import { Controller } from 'react-hook-form';

interface Props {
  name: string
  checkChecked: (item: string) => boolean
  onClick: (item: string) => void
  options: OptionProps[]
  formItemProps: any
  labelSx?: SxProps<Theme>
}

const FormCheckbox: FC<Props> = (props: Props) => {
  const color = 'success';

  return (
    <FormControl size={'small'}>
      <Box
        component={'span'}
        sx={{
          paddingTop: '10px',
          display: 'flex',
          justifyContent: 'space-around'
        }}
      >
       {props.options.map((option: OptionProps) => {
         return (
           <FormControlLabel sx={props.labelSx ?? {}}
             control={
               <Controller
                 {...props.formItemProps}
                 name={props.name}
                 render={() => {
                   return (
                     <Checkbox
                       color={color}
                       checked={props.checkChecked(option.value)}
                       onChange={() => props.onClick(option.value)}
                       disabled={option.disabled ?? false}
                       sx={{ ...defaultCheckboxStyles }}
                     />
                   )
                 }}
               />
             }
             label={option.label}
             key={option.key}
           />
         )
       })}
      </Box>
    </FormControl>
  )
}

export default FormCheckbox;
