import React, { lazy, Suspense } from 'react';
import { type Props } from './SecurityGroups';

const LazySecurityGroups = lazy(async () => await import('./SecurityGroups'));

const SecurityGroups = (props: Props) => (
  <Suspense fallback={null}>
    <LazySecurityGroups {...props} />
  </Suspense>
);

export default SecurityGroups;
