export const SubjectRegex = /^no subject$/i;
export const FirstNameRegex = /\{firstName}/;
export const LastNameRegex = /\{lastName}/;
export const EmailRegex = /<span[^>]*personalized-email-content[^>]*>\s*\{(firstName|lastName)\}\s*<\/span>/;
export const DepartmentCastId: string = 'DEPARTMENT_CAST';
export const SelectedTags: string[] = ['#distribution'];
export const NoSubject: string = 'No Subject';

export const ListItemTag: string = 'li';
export const ParagraphTag: string = 'p';
export const DivTag: string = 'div';
export const ReactQuillClassMap: Record<string, string> = {
  'ql-size-small': 'font-size: 0.75em;',
  'ql-size-normal': 'font-size: 1em;',
  'ql-size-large': 'font-size: 1.5em;',
  'ql-size-huge': 'font-size: 2.5em;',
  'ql-align-left': 'text-align: left;',
  'ql-align-right': 'text-align: right;',
  'ql-align-center': 'text-align: center;',
  'ql-align-justify': 'text-align: justify;'
};
