/*
 * Reducer file for the Auth slice
 */
import { createSlice } from '@reduxjs/toolkit';
import { AuthSlice, IncrementAmount } from '../../services/auth/constants';
import type { TenantSecurityProfile, AuthUser } from '../../services/auth/constants';
import type { SubscriptionInfo } from '../../models/SubscriptionInfo';

export interface AuthState {
  currentPage: string
  securityProfile: TenantSecurityProfile
  tenantId: string
  urlEndpoint: string
  accessToken: string
  userToken: string
  crooglooauth: AuthUser
  isSharedAccess: boolean
  allowedAPIMethods: string[]
  runningAPICalls: number
  runningGDriveCalls: number
  otherAvailableProductions: string[]
  tempLoginAuth: AuthUser | null
}

const initialState: AuthState = {
  currentPage: '',
  securityProfile: {},
  tenantId: '',
  urlEndpoint: '',
  accessToken: '',
  userToken: '',
  crooglooauth: {
    accountStatus: '',
    authenticationToken: '',
    defaultCommunity: '',
    email: '',
    firstName: '',
    lastName: '',
    securityProfile: {},
    token: '',
    urlEndpoint: '',
    userId: '',
    dropboxToken: '',
    sms2fa: ''
  },
  isSharedAccess: false,
  allowedAPIMethods: [],
  runningAPICalls: 0,
  runningGDriveCalls: 0,
  otherAvailableProductions: [],
  tempLoginAuth: null
}

export enum ProductionTypes {
  OFFICE = 'office',
  TIME = 'time',
  ASSISTANT = 'assistant'
}

/*
 * Create the reducer using createSlice
 * 3 properties -
 * name: Used in action types (String)
 * initialState: Initial state for the respective reducer
 * reducers: object of reducer functions. Key names will be used to generate actions
 */
export const authSlice = createSlice({
  name: AuthSlice,
  initialState,
  reducers: {
    login: (state, action) => {
      state.securityProfile = action.payload.authUser.securityProfile;
      state.tenantId = action.payload.authUser.defaultCommunity;
      state.urlEndpoint = action.payload.authUser.urlEndpoint;
      state.accessToken = action.payload.authUser.authenticationToken;
      state.userToken = action.payload.authUser.token;
      state.isSharedAccess = (action.payload.authUser.isSharedAccess !== null) ? action.payload.authUser.isSharedAccess : false;
      state.crooglooauth = action.payload.authUser;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    incrementAPICalls: (state) => {
      state.runningAPICalls = state.runningAPICalls + IncrementAmount
    },
    decrementAPICalls: (state) => {
      state.runningAPICalls -= IncrementAmount
    },
    incrementGDriveCalls: (state) => {
      state.runningGDriveCalls = state.runningGDriveCalls + IncrementAmount
    },
    decrementGDriveCalls: (state) => {
      state.runningGDriveCalls -= IncrementAmount
    },
    setDropboxToken: (state, action) => {
      state.crooglooauth.dropboxToken = action.payload.accessToken;
    },
    setBoxTokens: (state, action) => {
      state.crooglooauth.boxToken = action.payload.boxToken;
      state.crooglooauth.boxRefreshToken = action.payload.boxRefreshToken;
      state.crooglooauth.boxTokenExpiry = action.payload.boxTokenExpiry;
    },
    updateSecurityProfile: (state, action) => {
      const tenantId: string = state.tenantId;
      state.crooglooauth = { ...state.crooglooauth, securityProfile: { [tenantId]: action.payload.securityProfile } };
      state.securityProfile = { [tenantId]: action.payload.securityProfile };
    },
    setGContactsToken: (state, action) => {
      state.crooglooauth.gContactsAccessToken = action.payload.accessToken;
    },
    setGDriveToken: (state, action) => {
      state.crooglooauth.gDriveAccessToken = action.payload.token;
    },
    setSubscriptionInfo: (state, action) => {
      const subscriptionInfo: SubscriptionInfo = action.payload.subscriptionInfo;
      state.crooglooauth = {
        ...state.crooglooauth,
        securityListId: subscriptionInfo.securityListId,
        planId: subscriptionInfo.planId,
        productionOwnerEmail: subscriptionInfo.productionOwnerEmail,
        productionType: subscriptionInfo.productionType,
        billingPeriod: subscriptionInfo.billingPeriod,
        totalNbOfProds: subscriptionInfo.totalNbOfProds,
        nbOfAvailableProds: Number(subscriptionInfo.nbOfAvailableProds),
        isTrial: subscriptionInfo.isTrial,
        trialDaysLeft: subscriptionInfo.trialDaysLeft
      }
    },
    setOtherAvailableProductions: (state, action) => {
      state.otherAvailableProductions = action.payload.productions;
    },
    setTempLoginAuth: (state, action) => {
      state.tempLoginAuth = action.payload.crooglooAuth;
    }
  }
});

// export the reducer functions/actions to be used throughout the app
// can be called by using the stores dispatch function - dispatch(incrementAPICalls())
export const {
  login, setCurrentPage, incrementAPICalls, decrementAPICalls,
  incrementGDriveCalls, decrementGDriveCalls, setDropboxToken,
  setBoxTokens, updateSecurityProfile, setGContactsToken, setSubscriptionInfo,
  setGDriveToken, setOtherAvailableProductions, setTempLoginAuth
} = authSlice.actions;
export default authSlice.reducer;
