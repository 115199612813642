import MaterialChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { MainBlack, CrooglooGreen, WhiteGreen } from '../../../theme/colors';

interface Props {
  deleteDisabled?: boolean
}

/**
 * Custom chip component with custom styling using material ui chip component
 */
const Chip = styled(MaterialChip, {
  shouldForwardProp: (prop) => prop !== 'deleteDisabled'
})<Props>(({ deleteDisabled }) => ({
  margin: '5px !important',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '20px',
  padding: '0px, 12px, 0px, 12px',
  '&:hover': {
    backgroundColor: 'transparent !important',
    borderColor: !deleteDisabled && MainBlack,
    color: !deleteDisabled && MainBlack,
    '& .MuiChip-deleteIcon': {
      color: MainBlack
    }
  },
  '&:active': {
    backgroundColor: deleteDisabled ? '' : `${String(WhiteGreen)} !important`,
    color: !deleteDisabled && CrooglooGreen,
    borderColor: !deleteDisabled && CrooglooGreen,
    boxShadow: 'none',
    '& .MuiChip-deleteIcon': {
      color: CrooglooGreen
    }
  },
  '.MuiChip-deleteIcon': {
    display: deleteDisabled ? 'none' : 'initial'
  }
}));

export default Chip;
