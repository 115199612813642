import React, { lazy, Suspense } from 'react';

const LazyMissingFiles = lazy(async () => await import('./MissingFiles'));

const MissingFiles = () => (
  <Suspense fallback={null}>
    <LazyMissingFiles />
  </Suspense>
);

export default MissingFiles;
