export interface StepModel {
  key: StepKeys
  title: string
  message: string
}

export enum UploadStepIndex {
  FileType = 0,
  FileInformation = 1,
  ScriptInformation = 2,
  FileWatermark = 3,
  FileDestination = 4
}

export enum StepKeys {
  FileType = 'FileType',
  FileInformation = 'FileInformation',
  ScriptInformation = 'ScriptInformation',
  FileWatermark = 'FileWatermark',
  FileDestination = 'FileDestination'
}

const steps: StepModel[] = [
  {
    key: StepKeys.FileType,
    title: 'upload.fileInformation.title',
    message: ''
  },
  {
    key: StepKeys.FileInformation,
    title: 'upload.selectCategory.title',
    message: ''
  },
  {
    key: StepKeys.ScriptInformation,
    title: 'scripts.viewer.parseFile.heading',
    message: ''
  },
  {
    key: StepKeys.FileWatermark,
    title: 'upload.fileWatermark.title',
    message: 'upload.fileWatermark.message'
  },
  {
    key: StepKeys.FileDestination,
    title: 'upload.fileDestination.title',
    message: ''
  }
]

export default steps;
