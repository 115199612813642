import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, ListItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WhiteGrey, LightGrey } from '../../theme/colors';

interface Props {
  itemId: string
  summary: React.ReactElement
  details: React.ReactElement
  hasChildren?: boolean
  onExpand?: () => void
  isExpanded?: boolean
  minHeight?: string
  expandReverse?: boolean
  paddingLeft?: string
}

/**
 * List Accordion component to be used in the slide drawer
 * Contains the summary/header and the details of the lists in the slide drawer
 */
const ListAccordion: FC<Props> = (props: Props) => {
  const [expand, setExpand] = useState<boolean>(props.isExpanded ?? false);

  const toggleAccordian = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (props.onExpand) {
      if (props.hasChildren) { props.onExpand(); }
    }
    if (props.hasChildren) setExpand(!expand);
  }

  return (
    <ListItem
      key={props.itemId}
      sx={{
        display: 'flow-root',
        padding: 0,
        border: `1px solid ${WhiteGrey}`,
        minHeight: props.minHeight ?? 'auto',
        '& .MuiAccordion-root': {
          boxShadow: 'none'
        }
      }}
    >
    <Accordion expanded={expand}>
    <AccordionSummary
      onClick={toggleAccordian}
      expandIcon={
        (props.hasChildren)
          ? <Box component={'div'}
              sx={{
                width: 20,
                padding: '0px 10px'
              }}>
              <ExpandMoreIcon
                sx={{
                  borderRadius: '65px',
                  '&:hover': {
                    background: LightGrey
                  }
                }}
              />
            </Box>
          : <Box component={'div'}
              sx={{
                width: 20,
                padding: '0px 10px'
              }}
            >
              &nbsp;
            </Box>
      }
      sx={{
        display: 'flex',
        flexDirection: props.expandReverse ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        padding: 0,
        paddingLeft: props.paddingLeft ?? 0,
        minHeight: props.minHeight ?? 'auto',
        '&.Mui-expanded': {
          minHeight: props.minHeight ?? 'auto'
        },
        '& .MuiAccordionSummary-content': {
          margin: '0px'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: '8px 0px'
        },
        '&:hover': {
          background: WhiteGrey
        },
        '& .MuiTypography-root': {
          fontSize: '1rem'
        }
      }}
    >
      {props.summary}
    </AccordionSummary>
    <AccordionDetails
      sx={{
        padding: '0px',
        '& .MuiList-root': {
          padding: '0px'
        }
      }}
    >
      {props.details}
    </AccordionDetails>
    </Accordion>
  </ListItem>
  )
}

export default ListAccordion;
