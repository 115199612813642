import * as React from 'react';
import type { FC, SyntheticEvent } from 'react';
import type { SxProps, Theme, AutocompleteRenderInputParams } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { Grid, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { BorderGrey, BlackGrey, MainWhite, Silver, BorderBlack, WhiteGrey } from '../../theme/colors';
import { ChipDropDownHeight } from '../../theme/styles';
import Chip from '../common/buttons/Chip';
import scrollBarStyle from '../../theme/sidebar.module.scss';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export interface ChipDropdownData {
  id: string
  name: string
  label?: string
  inputValue?: string
  disabled?: boolean
  header?: boolean
}

interface ChipsDropdownProps {
  data: ChipDropdownData[]
  readValue: ChipDropdownData[]
  setValue: (value: ChipDropdownData) => void
  sx?: SxProps<Theme>
  placeholder?: string
  showChips: boolean
  addOptions?: boolean
  itemsSx?: SxProps<Theme>
  wrapItems?: boolean
  noMaxHeight?: boolean
}

const ChipsDropdown: FC<ChipsDropdownProps> = ({
  data,
  readValue,
  setValue,
  sx,
  placeholder,
  showChips,
  addOptions,
  itemsSx,
  wrapItems,
  noMaxHeight
}: ChipsDropdownProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const filter = createFilterOptions<ChipDropdownData>();

  const handleClick = (event: SyntheticEvent, value: ChipDropdownData): void => {
    event.stopPropagation();
    setValue(value);
  }

  let placeholderToShow: string = '';
  if (!showChips) {
    placeholderToShow = placeholder ?? '';
  } else {
    placeholderToShow = (readValue.length === 0 && placeholder) ? placeholder : '';
  }

  return (
    <Grid sx={{ ...sx }}>
      <Autocomplete
        sx={{
          maxHeight: (noMaxHeight) ? 'none' : ChipDropDownHeight,
          width: '100%',
          color: BlackGrey,
          background: MainWhite,
          '& .MuiOutlinedInput-root': {
            fontSize: 'inherit',
            border: `1px solid ${BorderGrey}`,
            borderRadius: '50px',
            '&:hover': {
              background: WhiteGrey,
              border: `1px solid ${BorderGrey}`
            },
            '& fieldset': {
              border: 'none',
              height: 'inherit'
            },
            '&.Mui-focused': {
              border: `1px solid ${BorderBlack}`,
              boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
            }
          }
        }}
        size={'small'}
        multiple
        autoHighlight
        freeSolo={addOptions ?? false}
        id={'select-dropdown'}
        options={data}
        value={readValue}
        onChange={(event: SyntheticEvent, newValue) => {
          const newValueLength: number = newValue.length;
          if (Array.isArray(newValue) && newValueLength > 0) {
            const optionChosen: ChipDropdownData | string = newValue[newValueLength - 1];
            if (optionChosen && typeof optionChosen !== 'string' && 'id' in optionChosen) {
              handleClick(event, optionChosen);
            }
          }
        }}
        disableClearable
        clearOnBlur
        isOptionEqualToValue={(option: ChipDropdownData, value: ChipDropdownData) => option.id === value.id}
        getOptionLabel={(option: ChipDropdownData | string) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label ?? option.name;
        }}
        ListboxProps={{
          className: scrollBarStyle.scrollbar
        }}
        filterOptions={(options: ChipDropdownData[], params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          if (addOptions) {
            const isExisting: boolean = options.some((option: ChipDropdownData) => option.name.toLowerCase().includes(inputValue.toLowerCase()));
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                id: inputValue,
                name: inputValue,
                label: `${t('watermark.drawer.addNew')} ${inputValue}`,
                inputValue
              });
            }
          }
          return filtered;
        }}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: ChipDropdownData, { selected }) => {
          return (option.header)
            ? (<MenuItem
                key={option.id}
                disableRipple
                sx={{
                  backgroundColor: MainWhite,
                  padding: '5px',
                  '&:hover': {
                    background: MainWhite,
                    cursor: 'auto'
                  },
                  '&:active': {
                    background: MainWhite,
                    cursor: 'auto'
                  }
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    color: BorderBlack
                  }}
                >
                  {option.label ?? option.name}
                </Typography>
              </MenuItem>)
            : (<MenuItem
                {...props}
                key={option.id}
                disableRipple
                onClick={(event: SyntheticEvent) => {
                  if (!option.disabled) {
                    handleClick(event, option)
                  }
                }}
                disabled={option.disabled ?? false}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  padding: '5px',
                  background: selected ? `${WhiteGrey} !important` : MainWhite,
                  '&:hover': {
                    background: WhiteGrey
                  }
                }}
              >
                <Typography
                  noWrap={!wrapItems}
                  sx={{
                    color: selected ? Silver : BorderBlack,
                    fontSize: '0.875rem',
                    whiteSpace: (wrapItems) ? 'normal' : 'nowrap',
                    ...itemsSx
                  }}
                >
                  {option.label ?? option.name}
                </Typography>
              </MenuItem>
              )
        }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              placeholder={String(t(placeholderToShow))}
            />
          )
        }}
        renderTags={(values: ChipDropdownData[]) => {
          if (showChips) {
            return (
              values.map((item: ChipDropdownData) => (
                <Box key={item.id} sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.1 } }>
                  <Chip
                    key={item.id}
                    label={item.label ?? item.name}
                    variant='outlined'
                    onClick={(event: SyntheticEvent) => handleClick(event, item)}
                    onDelete={(event: SyntheticEvent) => handleClick(event, item)}
                  />
                </Box>
              ))
            )
          }
        }}
      />
    </Grid>
  );
}

export default ChipsDropdown;
